/* eslint-disable no-unused-vars */
import React from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { slugify } from '../../utils'
import Card from './Card'


const DealStage = props => {
  const { model, config } = props
  const { options } = config.fields.find(f => f.name === 'sale_status')
  return (
    <Card
      bodyclass="listings no-top-padding"
      background
      header={
        <h3>Deal Stage</h3>
      }
      body={
        <div className="lead-progress">
          {options.map((option, oidx) => {
            const slug = slugify(option.value)
            let date_value = getIn(model, `${slug.replace('-', '_')}_date`)
            if (slug === 'closed') {
              date_value = getIn(model, 'registered_date')
            }
            if (slug === 'conditions-met' && !model.suspensive_conditions?.length) {
              return null
            }
            if (slug === 'deposit-received' && !model.deposit_required) {
              return null
            }
            return (
              <React.Fragment key={`stage-${slug}`}>
                <div className="lead-progress-item">
                  <div className={classNames('lead-progress-icon', { [slug]: date_value })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
                  <div className="lead-progress-label">{option.label}</div>
                  <div className="lead-progress-meta">{date_value ? date_value : ' '}</div>
                </div>
                {oidx !== options.length - 1 ? <div className="lead-progress-divider" /> : null}
              </React.Fragment>
            )
          })}
        </div>
      }
    />
  )
}

DealStage.propTypes = {
  config: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  model: PropTypes.object
}

export default DealStage
