/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
import { Field, Formik, getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import templateconfig from '../../../../config/template.json'
import { CACHE, PERMISSIONS, SETTINGS, SITE, MINUSER } from '../../../../selectors'
import { handleSubmitError, uniqueArray, reorder } from '../../../../utils'
import { Button } from '../../../ui/Button'
import SimpleTable from '../../simpletable/SimpleTable'
import CustomForm from '../CustomForm'
import Card from '../../Card'
import FieldGroup from '../FieldGroup'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import TemplateEditSidebar from '../../../../containers/TemplateEditSidebar'
import TextInput from './Text'


const TemplateManager = ({
  createModel,
  fetchMany,
  deleteModel,
  bulkEditModel,
  updateModel,
  config,
  cache,
  user,
  match,
  toggleWideSidebar
}) => {
  const [ terms, setTerms ] = useState({
    brochures: '',
    reports: '',
    valuations: '',
    signatures: '',
    marketingEmails: ''
  })
  const [ searching, setSearching ] = useState({
    brochures: false,
    reports: false,
    valuations: false,
    signatures: false,
    marketingEmails: false,
    brandAssets: false
  })
  const [ template, setTemplate ] = useState({ edit: null, list: [] })

  const brochuresList = useRef()
  const reportsList = useRef()
  const valuationsList = useRef()
  const signaturesList = useRef()
  const marketingEmailsList = useRef()
  const brandAssetsList = useRef()
  const form = useRef()

  const fetchTemplates = ({ params, resolve, reject }) => {
    const values = {
      modelname: 'templates',
      conflicts: true,
      params
    }
    return fetchMany({ values, resolve, reject })
  }

  const toggleEdit = () => {
    toggleWideSidebar('show-template-manager')
  }

  const editTemplate = (data, list) => {
    setTemplate({ edit: data, list })
    toggleEdit()
  }

  const deleteTemplate = (templateData, callback) => new Promise((resolve, reject) => {
    const values = {
      modelname: 'templates',
      selected: [ templateData.id ]
    }
    // eslint-disable-next-line no-alert
    const confirmation = confirm('Are you sure you want to delete this template?')
    if (confirmation) {
      return deleteModel({ values, resolve, reject })
    }
    return reject('')
  }).then(r => {
    callback(r)
  }).catch(() => {})

  const dragEnded = ({ oldIndex, newIndex, data, callback }) => {
    if (oldIndex !== newIndex) {
      let results = uniqueArray(data, 'id')
      results = reorder(results, oldIndex, newIndex)
      results = results.map((r, idx) => {
        r.ordinal = idx
        return r
      })
      const values = {
        modelname: 'templates',
        toarray: true,
        noloader: true,
        nonotify: true,
        ...results
      }
      callback(results)
      new Promise((resolve, reject) => bulkEditModel(({ values, resolve, reject }))).then(r => {
        callback(r)
      }).catch(e => {
        callback(data)
        handleSubmitError(e)
      })
    }
  }

  const settings = getIn(cache, `settings.${user.agent.site.id}`)
  return (
    <div className="template-manager">
      <Card
        header={
          <>
            <h3>Brochure Templates</h3>
            <div className="details-section-buttons tablemeta">
              <div className="template-add">
                <Button type="button" onClick={() => {
                  setTemplate({
                    edit: {
                      template_type: 'brochure'
                    },
                    list: brochuresList
                  })
                  toggleEdit()
                }} className="btn btn-red btn-round">Add Template</Button>
              </div>
            </div>
          </>
        }
        background
        body={
          <Formik
            initialValues={{
              template_type: 'brochure'
            }}
            enableReinitialize={true}
            onSubmit={values => {
              setTerms({ ...terms, brochures: values.term })
            }}
          >{formik => (
              <>
                <div className="templates-search">
                  <CustomForm
                    component="div"
                    render={() => (
                      <div className="search-fields">
                        <div className="field col-lg-4">
                          <Field
                            id="brochure-search"
                            name="term"
                            placeholder="Keyword Search"
                            component={TextInput}
                            className="term"
                            bounce={true}
                            show_search
                            suffix={<Button icon="#icon24-Search" type="button" disabled={searching.brochures} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                          />
                        </div>
                        {terms.brochures &&
                        <div className="reset-group">
                          <strong>This list is filtered</strong>
                          <Button
                            id="keyword-search-btn"
                            tabIndex="-1"
                            type="button"
                            icon="#icon16-Refresh"
                            onClick={() => {
                              formik.setFieldValue('term', '')
                              formik.setFieldTouched('term', true)
                              formik.submitForm()
                            }}
                            disabled={searching.brochures}
                            className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                          >
                            Reset
                          </Button>
                        </div>
                        }
                      </div>
                    )}
                  />
                </div>
                <div className="templatesbody">
                  <SimpleTable
                    user={user}
                    config={config}
                    header={[
                      {
                        label: 'Default',
                        name: 'front_end',
                        input: 'Check',
                        classes: [ 'text-left' ],
                        orderable: false,
                        edit: true,
                        table_edit: true
                      },
                      {
                        label: 'Name',
                        name: 'display_name',
                        orderable: false
                      },
                      {
                        label: 'Category',
                        name: 'meta.category.name',
                        orderable: false,
                        orderby: 'category__name'
                      },
                      {
                        label: 'Listing Type',
                        name: 'model',
                        orderable: false
                      },
                      {
                        label: 'ID',
                        name: 'name',
                        orderable: false
                      }
                    ]}
                    getClass={el => {
                      brochuresList.current = el
                    }}
                    updateModel={({ values, resolve, reject, data, callback }) => {
                      const results = data.map(r => ({ ...r }))
                      updateModel({ values, resolve, reject })
                      results.forEach(d => {
                        if (d.front_end) {
                          d.front_end = false
                        }
                      })
                      results.forEach(d => {
                        if (d.id === values.id) {
                          d.front_end = values.front_end
                        }
                      })
                      callback(results)
                    }}
                    action={fetchTemplates}
                    params={{
                      template_type: 'brochure',
                      term: terms.brochures,
                      meta_fields: [ 'category' ],
                      get_all: 1
                    }}
                    parser={response => {
                      const data = {
                        options: response
                      }
                      return data
                    }}
                    sortable
                    dragEnded={dragEnded}
                    rowActions={(row, data) => (
                      <>
                        <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => editTemplate(data, brochuresList.current)} title="Edit Template" type="button" />
                        <Button icon="#icon16-Copy" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => {
                          setTemplate({
                            edit: {
                              ...data, id: null,
                              name: null,
                              display_name: null
                            },
                            list: brochuresList
                          })
                          toggleEdit()
                        }} title="Duplicate Template" type="button" />
                        {data.status !== 'Inactive' ? (
                          <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteTemplate(data, () => {
                            brochuresList.current.refreshPage()
                          })} title="Archive Brochure" type="button" />
                        ) : null}
                      </>
                    )}
                  />
                </div>
              </>
            )}
          </Formik>
        }
      />
      <Card
        header={
          <>
            <h3>Report Templates</h3>
            <div className="details-section-buttons tablemeta">
              <div className="template-add">
                <Button type="button" onClick={() => {
                  setTemplate({
                    edit: {
                      template_type: 'report'
                    },
                    list: reportsList
                  })
                  toggleEdit()
                }} className="btn btn-red btn-round">Add Template</Button>
              </div>
            </div>
          </>
        }
        background
        body={
          <Formik
            initialValues={{
              template_type: 'report'
            }}
            enableReinitialize={true}
            onSubmit={values => {
              setTerms({ ...terms, reports: values.term })
            }}
          >{formik => (
              <>
                <div className="templates-search">
                  <CustomForm
                    component="div"
                    render={el => (
                      <div className="search-fields">
                        <div className="field col-lg-4">
                          <Field
                            id="report-search"
                            name="term"
                            placeholder="Keyword Search"
                            component={TextInput}
                            className="term"
                            bounce={true}
                            show_search
                            form_el={el}
                            suffix={<Button icon="#icon24-Search" type="button" disabled={searching.reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                          />
                        </div>
                        {terms.reports &&
                        <div className="reset-group">
                          <strong>This list is filtered</strong>
                          <Button
                            id="keyword-search-btn"
                            tabIndex="-1"
                            type="button"
                            icon="#icon16-Refresh"
                            onClick={() => {
                              formik.setFieldValue('term', '')
                              formik.setFieldTouched('term', true)
                              formik.submitForm()
                            }}
                            disabled={searching.reports}
                            className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                          >
                            Reset
                          </Button>
                        </div>
                        }
                      </div>
                    )}
                  />
                </div>
                <div className="templatesbody">
                  <SimpleTable
                    user={user}
                    config={config}
                    header={[
                      {
                        label: 'Name',
                        name: 'display_name',
                        orderable: false
                      },
                      {
                        label: 'Category',
                        name: 'meta.category.name',
                        orderable: false,
                        orderby: 'category__name'
                      },
                      {
                        label: 'Listing Type',
                        name: 'model',
                        orderable: false
                      },
                      {
                        label: 'ID',
                        name: 'name',
                        orderable: false
                      }
                    ]}
                    getClass={el => {
                      reportsList.current = el
                    }}
                    updateModel={({ values, resolve, reject, data, callback }) => {
                      const results = data.map(r => ({ ...r }))
                      updateModel({ values, resolve, reject })
                      results.forEach(d => {
                        if (d.front_end) {
                          d.front_end = false
                        }
                      })
                      results.forEach(d => {
                        if (d.id === values.id) {
                          d.front_end = values.front_end
                        }
                      })
                      callback(results)
                    }}
                    action={fetchTemplates}
                    params={{
                      template_type: 'report',
                      term: terms.reports,
                      meta_fields: [ 'category' ],
                      get_all: 1
                    }}

                    parser={response => {
                      const data = {
                        options: response
                      }
                      return data
                    }}
                    sortable
                    dragEnded={dragEnded}
                    rowActions={(row, data) => (
                      <>
                        <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => editTemplate(data, reportsList)} title="Edit Template" type="button" />
                        <Button icon="#icon16-Copy" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => {
                          setTemplate({
                            edit: {
                              ...data, id: null,
                              name: null,
                              display_name: null
                            },
                            list: reportsList
                          })
                          toggleEdit()
                        }} title="Duplicate Template" type="button" />
                        {data.status !== 'Inactive' ? (
                          <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteTemplate(data, () => {
                            reportsList.refreshPage()
                          })} title="Archive Report" type="button" />
                        ) : null}
                      </>
                    )}
                  />
                </div>
              </>
            )}
          </Formik>
        }
      />
      {settings.valuation_addon ? (
        <Card
          header={

            <><h3>Valuation Templates</h3>
              <div className="details-section-buttons tablemeta">
                <div className="template-add">
                  <Button type="button" onClick={() => {
                    setTemplate({
                      edit: {
                        template_type: 'valuation'
                      },
                      list: valuationsList
                    })
                    toggleEdit()
                  }} className="btn btn-red btn-round">Add Template</Button>
                </div>
              </div>
            </>
          }
          background
          body={
            <Formik
              initialValues={{
                template_type: 'valuation',
                model__in: [ 'valuation' ]
              }}
              enableReinitialize={true}
              onSubmit={values => {
                setTerms({ ...terms, valuations: values.term })
              }}
            >{formik => (
                <>
                  <div className="templates-search">
                    <CustomForm
                      component="div"
                      render={el => (
                        <div className="search-fields">
                          <div className="field col-lg-4">
                            <Field
                              id="report-search"
                              name="term"
                              placeholder="Keyword Search"
                              component={TextInput}
                              className="term"
                              bounce={true}
                              show_search
                              form_el={el}
                              suffix={<Button icon="#icon24-Search" type="button" disabled={searching.reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                            />
                          </div>
                          {terms.valuationss &&
                          <div className="reset-group">
                            <strong>This list is filtered</strong>
                            <Button
                              id="keyword-search-btn"
                              tabIndex="-1"
                              type="button"
                              icon="#icon16-Refresh"
                              onClick={() => {
                                formik.setFieldValue('term', '')
                                formik.setFieldTouched('term', true)
                                formik.submitForm()
                              }}
                              disabled={searching.reports}
                              className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                            >
                              Reset
                            </Button>
                          </div>
                          }
                        </div>
                      )}
                    />
                  </div>
                  <div className="templatesbody">
                    <SimpleTable
                      user={user}
                      config={config}
                      header={[
                        {
                          label: 'Name',
                          name: 'display_name',
                          orderable: false
                        },
                        {
                          label: 'Listing Type',
                          name: 'model',
                          orderable: false
                        },
                        {
                          label: 'ID',
                          name: 'name',
                          orderable: false
                        }
                      ]}
                      getClass={el => {
                        valuationsList.current = el
                      }}
                      updateModel={({ values, resolve, reject, data, callback }) => {
                        const results = data.map(r => ({ ...r }))
                        updateModel({ values, resolve, reject })
                        results.forEach(d => {
                          if (d.front_end) {
                            d.front_end = false
                          }
                        })
                        results.forEach(d => {
                          if (d.id === values.id) {
                            d.front_end = values.front_end
                          }
                        })
                        callback(results)
                      }}
                      action={fetchTemplates}
                      params={{
                        template_type: 'valuation',
                        model__in: [ 'valuation' ],
                        term: terms.valuations,
                        meta_fields: [ 'category' ],
                        get_all: 1
                      }}
                      parser={response => {
                        const data = {
                          options: response
                        }
                        return data
                      }}
                      sortable
                      dragEnded={dragEnded}
                      rowActions={(row, data) => (
                        <>
                          <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => editTemplate(data, valuationsList.current)} title="Edit Template" type="button" />
                          <Button icon="#icon16-Copy" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => {
                            setTemplate({
                              edit: {
                                ...data, id: null,
                                name: null,
                                display_name: null
                              },
                              list: valuationsList
                            })
                            toggleEdit()
                          }} title="Duplicate Template" type="button" />
                          {data.status !== 'Inactive' ? (
                            <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteTemplate(data, () => {
                              valuationsList.current.refreshPage()
                            })} title="Archive Valuation" type="button" />
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </>
              )}
            </Formik>
          }
        />
      ) : null}
      {settings.email_stationery_addon ? (
        <Card
          header={

            <><h3>Signature Templates</h3>
              <div className="details-section-buttons tablemeta">
                <div className="template-add">
                  <Button type="button" onClick={() => {
                    setTemplate({
                      edit: {
                        template_type: 'signature'
                      },
                      list: signaturesList
                    })
                    toggleEdit()
                  }} className="btn btn-red btn-round">Add Template</Button>
                </div>
              </div>
            </>
          }
          background
          body={
            <Formik
              initialValues={{
                template_type: 'signature',
                model__in: [ 'agent' ]
              }}
              enableReinitialize={true}
              onSubmit={values => {
                setTerms({ ...terms, signatures: values.term })
              }}
            >{formik => (
                <>
                  <div className="templates-search">
                    <CustomForm
                      component="div"
                      render={el => (
                        <div className="search-fields">
                          <div className="field col-lg-4">
                            <Field
                              id="report-search"
                              name="term"
                              placeholder="Keyword Search"
                              component={TextInput}
                              className="term"
                              bounce={true}
                              show_search
                              form_el={el}
                              suffix={<Button icon="#icon24-Search" type="button" disabled={searching.reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                            />
                          </div>
                          {terms.signaturess &&
                          <div className="reset-group">
                            <strong>This list is filtered</strong>
                            <Button
                              id="keyword-search-btn"
                              tabIndex="-1"
                              type="button"
                              icon="#icon16-Refresh"
                              onClick={() => {
                                formik.setFieldValue('term', '')
                                formik.setFieldTouched('term', true)
                                formik.submitForm()
                              }}
                              disabled={searching.reports}
                              className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                            >
                              Reset
                            </Button>
                          </div>
                          }
                        </div>
                      )}
                    />
                  </div>
                  <div className="templatesbody">
                    <SimpleTable
                      user={user}
                      config={config}
                      header={[
                        {
                          label: 'Name',
                          name: 'display_name',
                          orderable: false
                        },
                        {
                          label: 'Listing Type',
                          name: 'model',
                          orderable: false
                        },
                        {
                          label: 'ID',
                          name: 'name',
                          orderable: false
                        }
                      ]}
                      getClass={el => {
                        signaturesList.current = el
                      }}
                      updateModel={({ values, resolve, reject, data, callback }) => {
                        const results = data.map(r => ({ ...r }))
                        updateModel({ values, resolve, reject })
                        results.forEach(d => {
                          if (d.front_end) {
                            d.front_end = false
                          }
                        })
                        results.forEach(d => {
                          if (d.id === values.id) {
                            d.front_end = values.front_end
                          }
                        })
                        callback(results)
                      }}
                      action={fetchTemplates}
                      params={{
                        template_type: 'signature',
                        model__in: [ 'agent' ],
                        term: terms.signatures,
                        meta_fields: [ 'category' ],
                        get_all: 1
                      }}
                      parser={response => {
                        const data = {
                          options: response
                        }
                        return data
                      }}
                      sortable
                      dragEnded={dragEnded}
                      rowActions={(row, data) => (
                        <>
                          <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => editTemplate(data, signaturesList.current)} title="Edit Template" type="button" />
                          <Button icon="#icon16-Copy" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => {
                            setTemplate({
                              edit: {
                                ...data, id: null,
                                name: null,
                                display_name: null
                              },
                              list: signaturesList
                            })
                            toggleEdit()
                          }} title="Duplicate Template" type="button" />
                          {data.status !== 'Inactive' ? (
                            <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteTemplate(data, () => {
                              signaturesList.current.refreshPage()
                            })} title="Archive Signature" type="button" />
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </>
              )}
            </Formik>
          }
        />
      ) : null}
      {settings.bulk_mailing_addon ? (
        <Card
          header={

            <><h3>Marketing Email Templates</h3>
              <div className="details-section-buttons tablemeta">
                <div className="template-add">
                  <Button type="button" onClick={() => {
                    setTemplate({
                      edit: {
                        template_type: 'marketing-email'
                      },
                      list: marketingEmailsList
                    })
                    toggleEdit()
                  }} className="btn btn-red btn-round">Add Template</Button>
                </div>
              </div>
            </>
          }
          background
          body={
            <Formik
              initialValues={{
                template_type: 'marketing-email',
                model__in: [ 'residential', 'commercial', 'holiday', 'project' ]
              }}
              enableReinitialize={true}
              onSubmit={values => {
                setTerms({ ...terms, marketingEmails: values.term })
              }}
            >{formik => (
                <>
                  <div className="templates-search">
                    <CustomForm
                      component="div"
                      render={el => (
                        <div className="search-fields">
                          <div className="field col-lg-4">
                            <Field
                              id="report-search"
                              name="term"
                              placeholder="Keyword Search"
                              component={TextInput}
                              className="term"
                              bounce={true}
                              show_search
                              form_el={el}
                              suffix={<Button icon="#icon24-Search" type="button" disabled={searching.reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                            />
                          </div>
                          {terms.marketing_emails &&
                          <div className="reset-group">
                            <strong>This list is filtered</strong>
                            <Button
                              id="keyword-search-btn"
                              tabIndex="-1"
                              type="button"
                              icon="#icon16-Refresh"
                              onClick={() => {
                                formik.setFieldValue('term', '')
                                formik.setFieldTouched('term', true)
                                formik.submitForm()
                              }}
                              disabled={searching.reports}
                              className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                            >
                              Reset
                            </Button>
                          </div>
                          }
                        </div>
                      )}
                    />
                  </div>
                  <div className="templatesbody">
                    <SimpleTable
                      user={user}
                      config={config}
                      header={[
                        {
                          label: 'Name',
                          name: 'display_name',
                          orderable: false
                        },
                        {
                          label: 'Listing Type',
                          name: 'model',
                          orderable: false
                        },
                        {
                          label: 'ID',
                          name: 'name',
                          orderable: false
                        }
                      ]}
                      getClass={el => {
                        marketingEmailsList.current = el
                      }}
                      updateModel={({ values, resolve, reject, data, callback }) => {
                        const results = data.map(r => ({ ...r }))
                        updateModel({ values, resolve, reject })
                        results.forEach(d => {
                          if (d.front_end) {
                            d.front_end = false
                          }
                        })
                        results.forEach(d => {
                          if (d.id === values.id) {
                            d.front_end = values.front_end
                          }
                        })
                        callback(results)
                      }}
                      action={fetchTemplates}
                      params={{
                        template_type: 'marketing-email',
                        model__in: [ 'residential', 'commercial', 'holiday', 'project' ],
                        term: terms.marketingEmails,
                        meta_fields: [ 'category', 'preview' ],
                        get_all: 1
                      }}
                      parser={response => {
                        const data = {
                          options: response
                        }
                        return data
                      }}
                      sortable
                      dragEnded={dragEnded}
                      rowActions={(row, data) => (
                        <>
                          <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => editTemplate(data, marketingEmailsList.current)} title="Edit Template" type="button" />
                          <Button icon="#icon16-Copy" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => {
                            setTemplate({
                              edit: {
                                ...data, id: null,
                                name: null,
                                display_name: null
                              },
                              list: marketingEmailsList
                            })
                            toggleEdit()
                          }} title="Duplicate Template" type="button" />
                          {data.status !== 'Inactive' ? (
                            <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteTemplate(data, () => {
                              marketingEmailsList.current.refreshPage()
                            })} title="Archive Template" type="button" />
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </>
              )}
            </Formik>
          }
        />
      ) : null}
      {settings.brand_assets_addon ? (
        <Card
          header={

            <><h3>Brand Asset Templates</h3>
              <div className="details-section-buttons tablemeta">
                <div className="template-add">
                  <Button type="button" onClick={() => {
                    setTemplate({
                      edit: {
                        template_type: 'brand-asset'
                      },
                      list: brandAssetsList
                    })
                    toggleEdit()
                  }} className="btn btn-red btn-round">Add Template</Button>
                </div>
              </div>
            </>
          }
          background
          body={
            <Formik
              initialValues={{
                template_type: 'brand-asset',
                model__in: [ 'residential', 'commercial', 'holiday', 'project' ]
              }}
              enableReinitialize={true}
              onSubmit={values => {
                setTerms({ ...terms, brandAssets: values.term })
              }}
            >{formik => (
                <>
                  <div className="templates-search">
                    <CustomForm
                      component="div"
                      render={el => (
                        <div className="search-fields">
                          <div className="field col-lg-4">
                            <Field
                              id="report-search"
                              name="term"
                              placeholder="Keyword Search"
                              component={TextInput}
                              className="term"
                              bounce={true}
                              show_search
                              form_el={el}
                              suffix={<Button icon="#icon24-Search" type="button" disabled={searching.reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                            />
                          </div>
                          {terms.brand_assets &&
                          <div className="reset-group">
                            <strong>This list is filtered</strong>
                            <Button
                              id="keyword-search-btn"
                              tabIndex="-1"
                              type="button"
                              icon="#icon16-Refresh"
                              onClick={() => {
                                formik.setFieldValue('term', '')
                                formik.setFieldTouched('term', true)
                                formik.submitForm()
                              }}
                              disabled={searching.reports}
                              className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                            >
                              Reset
                            </Button>
                          </div>
                          }
                        </div>
                      )}
                    />
                  </div>
                  <div className="templatesbody">
                    <SimpleTable
                      user={user}
                      config={config}
                      header={[
                        {
                          label: 'Name',
                          name: 'display_name',
                          orderable: false
                        },
                        {
                          label: 'Listing Type',
                          name: 'model',
                          orderable: false
                        },
                        {
                          label: 'ID',
                          name: 'name',
                          orderable: false
                        }
                      ]}
                      getClass={el => {
                        marketingEmailsList.current = el
                      }}
                      updateModel={({ values, resolve, reject, data, callback }) => {
                        const results = data.map(r => ({ ...r }))
                        updateModel({ values, resolve, reject })
                        results.forEach(d => {
                          if (d.front_end) {
                            d.front_end = false
                          }
                        })
                        results.forEach(d => {
                          if (d.id === values.id) {
                            d.front_end = values.front_end
                          }
                        })
                        callback(results)
                      }}
                      action={fetchTemplates}
                      params={{
                        template_type: 'brand-asset',
                        model__in: [ 'residential', 'commercial', 'holiday', 'project', 'agent' ],
                        term: terms.brandAssets,
                        meta_fields: [ 'category', 'preview' ],
                        get_all: 1
                      }}
                      parser={response => {
                        const data = {
                          options: response
                        }
                        return data
                      }}
                      sortable
                      dragEnded={dragEnded}
                      rowActions={(row, data) => (
                        <>
                          <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => editTemplate(data, marketingEmailsList.current)} title="Edit Template" type="button" />
                          <Button icon="#icon16-Copy" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => {
                            setTemplate({
                              edit: {
                                ...data, id: null,
                                name: null,
                                display_name: null
                              },
                              list: brandAssetsList
                            })
                            toggleEdit()
                          }} title="Duplicate Template" type="button" />
                          {data.status !== 'Inactive' ? (
                            <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteTemplate(data, () => {
                              brandAssetsList.current.refreshPage()
                            })} title="Archive Template" type="button" />
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </>
              )}
            </Formik>
          }
        />
      ) : null}
      <WideSidebar sidebar={'show-template-manager'}>
        <TemplateEditSidebar
          key="template-manager-edit"
          toggleLookup={toggleEdit}
          template={template.edit}
          config={config}
          list={template.list}
          match={match}
        />
      </WideSidebar>
    </div>
  )
}

TemplateManager.propTypes = {
  user: PropTypes.object,
  cache: PropTypes.object,
  fields: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  updatetemplate: PropTypes.func,
  deletetemplate: PropTypes.func,
  modelname: PropTypes.string,
  fetchMany: PropTypes.func,
  deleteModel: PropTypes.func,
  toggleWideSidebar: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  bulkEditModel: PropTypes.func
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  let modelname = ownProps.modelname ? ownProps.modelname : null
  let modelid = ownProps.modelid ? ownProps.modelid : null

  if (ownProps.match && ownProps.match.params) {
    modelname = modelname ? modelname : ownProps.match.params.model
    modelid = modelid ? modelid : ownProps.match.params.id
  }

  const site = SITE(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state, siteid)
  const cache = CACHE(state)
  const user = MINUSER(state)
  const permissions = PERMISSIONS(state)
  const branches_allowed = user.getIn([ 'agent', 'branches_allowed' ])

  // Minimize user
  const agent = Map({ id: user.getIn([ 'agent', 'id' ]), site, branches_allowed })
  const minuser = Map({
    permissions,
    agent
  })

  // Minimize cache
  let mincache = Map({ settings: Map({}) }) // We need to send only cache which field group needs
  mincache = mincache.mergeDeepIn([ 'settings', siteid ], settings)// We need settings for the current site
  mincache = mincache.set(`${modelname}`, Map({}))
  if (cache.get(modelname)) {
    if (ownProps.match) {
      mincache = mincache.mergeDeepIn([ modelname, modelid ], cache.getIn([ modelname, modelid ])) // We need the current model data in cache too
    } else { // Pass entire cache if no id
      mincache = mincache.mergeDeepIn([ modelname ], cache.get(modelname))
    }
  }
  templateconfig.config.fields.forEach(field => {
    if (field.modelname) { mincache = mincache.set(field.modelname, cache.get(field.modelname)) }
    if (field.fields) { // Field array
      field.fields.forEach(fafield => {
        if (fafield.modelname) { mincache = mincache.set(fafield.modelname, cache.get(fafield.modelname)) }
      })
    }
    if (field.caches) {
      field.caches.forEach(mn => {
        mincache[mn] = cache.get(mn)
      })
    }
    if (field.input === 'LocationSelect') { mincache.set('branches', cache.get('branches')) }
  })

  return {
    cache: mincache,
    user: minuser
  }
}


export default connect(mapStateToProps, null)(withImmutablePropsToJS(TemplateManager))
