/* eslint-disable new-cap */
import { eachDayOfInterval, eachWeekOfInterval, endOfDay, endOfMonth, endOfWeek, isWithinInterval, startOfDay, startOfMonth, startOfWeek, eachMonthOfInterval, isSameDay } from 'date-fns'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { components } from 'react-select'
import isEqual from 'react-fast-compare'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Line, LineChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'

import date_options from '../../config/date-options.json'
import { fetchMany, fetchListingHistory } from '../../actions'
import { CONFIG, MODELNAME } from '../../selectors'
import { sortBy, valueFormat, textToDate, groupBy, getTextWidth } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import CheckInput from '../common/forms/inputs/Check'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import { Button } from '../ui/Button'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const fillTicksData = (_ticks, data, num, labels) => {
  const ticks = [ ..._ticks ]
  const filled = ticks.map(currentTick => {
    const tick_data = { date: currentTick, created: new Date(currentTick) }
    labels.forEach(label => {
      const date_range = data.filter(it => {
        let newStart = startOfMonth(currentTick)
        let newEnd = endOfMonth(currentTick)
        if (num < 0.46) {
          newStart = startOfDay(currentTick)
          newEnd = endOfDay(currentTick)
        } else if (Math.ceil(num) < 10) {
          newStart = startOfWeek(currentTick)
          newEnd = endOfWeek(currentTick)
        }
        return isWithinInterval(it.reportingDate, { start: newStart, end: newEnd })
      })
      const total = date_range.map(it => it[label.key] || 0)
        .reduce((prevValue, currentValue) => prevValue + parseFloat(currentValue), 0)
      tick_data[label.label] = parseInt(total, 10)
    })
    return tick_data
  })
  return filled
}

const getTicks = (startDate, endDate, num) => {
  if (!startDate || !endDate) {
    return []
  }
  if (num < 0.46) {
    return eachDayOfInterval({ start: startDate, end: endDate })
  } else if (Math.ceil(num) < 10) {
    return eachWeekOfInterval({ start: startDate, end: endDate })
  }
  return eachMonthOfInterval({ start: startDate, end: endDate })
}

const getDateFormat = (date, days) => {
  const value = (days >= 180 ? valueFormat('monthyear', date) : valueFormat('daymonth', date))
  return value
}

const SocialPerformanceWidget = ({ model, config, className, actions }) => {
  const [ dateData, setDateData ] = useState({ period: null, start: null, end: null, days: 0 })
  const [ { statistics, filledData }, setStatistics ] = useState({ statistics: [], filledData: [] })
  const [ labels, setLabels ] = useState([
    { key: 'postEngagements', label: 'Engagement', color: '#FC495D', hide: false },
    { key: 'linkClicks', label: 'Clicks', color: '#10294D', hide: false },
    { key: 'impressions', label: 'Views', color: '#70859E', hide: false },
    { key: 'reach', label: 'Reach', color: '#34BFDE', hide: false }
  ])
  const [ overlay_ga, setOverlayGa ] = useState(false)
  const [ hover, setHover ] = useState(false)

  const abortController = useRef(new AbortController())

  const filterData = () => {
    const portals = getIn(model, 'meta.portals')
    if (!portals) {
      return
    }
    const flow = portals.find(portal => portal.portal === 21)
    if (!flow) {
      return
    }
    const data = getIn(flow, 'meta.statistics.portal_statistics.facebookDailyInsights')
    if (data) {
      const sortedStatistics = sortBy(data.map(statistic => {
        statistic.reportingDate = new Date(statistic.reportingDate)
        return statistic
      }).filter(statistic => (statistic.reportingDate >= dateData.start)), 'reportingDate')

      const num = (dateData.days / 365) * 12

      const ticks = getTicks(
        dateData.start,
        dateData.end,
        num
      )
      const newFilledData = fillTicksData(ticks, sortedStatistics, num, labels)
      setStatistics({ statistics: sortedStatistics, filledData: newFilledData })
    }
  }


  const fetchListingStats = () => {
    const created = valueFormat('shortdate', new Date().setDate(new Date().getDate() - dateData.days))
    if (model.id) {
      new Promise((resolve, reject) => actions.fetchListingHistory({
        modelname: config.modelname,
        id: model.id,
        params: {
          branch: [ 'branches' ].includes(config.modelname) ? model.id : null,
          created__gte: created
        },
        signal: abortController.current.signal,
        resolve,
        reject
      })).then(r => {
        // eslint-disable-next-line no-use-before-define
        const { analytics } = filterListingStats(r)
        const newStatistics = statistics.map(statistic => {
          const ga_stat = analytics.find(day => isSameDay(day.reportingDate, statistic.reportingDate))
          if (ga_stat) {
            return { ...statistic, 'Social Traffic': 0, 'All Traffic': 0, ...ga_stat }
          }
          return { ...statistic, 'Social Traffic': 0, 'All Traffic': 0 }
        })
        const num = (dateData.days / 365) * 12

        const ticks = getTicks(
          dateData.start,
          dateData.end,
          num
        )
        const newFilledData = fillTicksData(ticks, newStatistics, num, labels)
        setStatistics({ statistics: newStatistics, filledData: newFilledData })
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    }
  }

  useEffect(() => {
    const option = date_options.find(o => o.value === 'LAST_7_DAYS')
    const newPeriod = option.value
    const newDateData = textToDate(option.value)
    setDateData({ period: newPeriod, ...newDateData })
    return () => {
      abortController.current.abort()
    }
  }, [])

  useEffect(() => {
    filterData()
  }, [ dateData.days ])

  useEffect(() => {
    if (overlay_ga) {
      const new_labels = [
        { key: 'postEngagements', label: 'Engagement', color: '#FC495D', hide: false },
        { key: 'linkClicks', label: 'Clicks', color: '#10294D', hide: false },
        { key: 'impressions', label: 'Views', color: '#70859E', hide: false },
        { key: 'reach', label: 'Reach', color: '#34BFDE', hide: false },
        { key: 'Social Traffic', label: 'Social Traffic', color: '#B2C2D4', hide: false },
        { key: 'All Traffic', label: 'All Traffic', color: '#CED7E2', hide: false }
      ]
      if (!isEqual(new_labels, labels)) {
        setLabels(new_labels)
      }
      fetchListingStats()
    } else {
      const new_labels = [
        { key: 'postEngagements', label: 'Engagement', color: '#FC495D', hide: false },
        { key: 'linkClicks', label: 'Clicks', color: '#10294D', hide: false },
        { key: 'impressions', label: 'Views', color: '#70859E', hide: false },
        { key: 'reach', label: 'Reach', color: '#34BFDE', hide: false }
      ]
      if (!isEqual(new_labels, labels)) {
        setLabels(new_labels)
      }
    }
  }, [ overlay_ga, dateData.days ])

  const filterListingStats = results => {
    const analytics = Object.keys(results.listing).sort((a, b) => {
      if (new Date(a) > new Date(b)) {
        return 1
      }
      if (new Date(a) < new Date(b)) {
        return -1
      }
      return 0
    }).map(day => {
      const stats = results.listing[day]
      const vals = {
        name: day,
        reportingDate: new Date(getIn(stats, 'created')),
        'All Traffic': getIn(stats, 'times_viewed', 0) >= 0 ? getIn(stats, 'times_viewed', 0) : 0,
        'Social Traffic': getIn(stats, 'social_traffic', 0) >= 0 ? getIn(stats, 'social_traffic', 0) : 0
      }
      return vals
    })

    let newDataKeys = []
    let newAnalyticsKeys = []
    if (analytics) {
      newDataKeys = Object.keys(analytics[0]).filter(k => k !== 'name')
      newAnalyticsKeys = analytics.map(day => ({ ...day }))
    }
    newAnalyticsKeys = newAnalyticsKeys.sort((a, b) => {
      if (new Date(a.name) > new Date(b.name)) {
        return 1
      }
      if (new Date(a.name) < new Date(b.name)) {
        return -1
      }
      return 0
    })

    let groups = []
    switch (dateData.days) {
      case 90:
        // Group data by weeks
        groups = groupBy(newAnalyticsKeys, 'name', day => valueFormat('week', day))
        newAnalyticsKeys = Object.keys(groups).sort((a, b) => {
          if (new Date(groups[a][0].name) > new Date(groups[b][0].name)) {
            return 1
          }
          if (new Date(groups[a][0].name) < new Date(groups[b][0].name)) {
            return -1
          }
          return 0
        }).map(k => {
          const week_days = groups[k]
          const week = {
            name: [ ...week_days ].pop().name
          }
          week_days.forEach(day => {
            Object.keys(day).filter(d => d !== 'name').map(d => {
              if (!getIn(week, d)) {
                week[d] = 0
              }
              week[d] += getIn(day, d, 0) ? getIn(day, d, 0) : 0
            })
          })
          return week
        })
        break
      case 365:
        // Group data by months
        groups = groupBy(newAnalyticsKeys, 'name', day => valueFormat('monthyear', day))
        newAnalyticsKeys = Object.keys(groups).sort((a, b) => {
          if (new Date(groups[a][0].name) > new Date(groups[b][0].name)) {
            return 1
          }
          if (new Date(groups[a][0].name) < new Date(groups[b][0].name)) {
            return -1
          }
          return 0
        }).map(k => {
          const months = groups[k]
          const month = {
            name: [ ...months ].pop().name
          }
          months.forEach(day => {
            Object.keys(day).filter(d => d !== 'name').map(d => {
              if (!getIn(month, d)) {
                month[d] = 0
              }
              month[d] += getIn(day, d, 0) ? getIn(day, d, 0) : 0
            })
          })
          return month
        })
        break
      default:
        break
    }

    return { analytics, newAnalyticsKeys, newDataKeys }
  }

  const longestLabelLength = (data, keys) => {
    const length = data ? (
      data
        .map(c => {
          const bigger = Math.max(...keys.map(k => c[k]))
          const val = valueFormat('number', bigger)
          return (getTextWidth(val.toString()) * 1.2) + 5
        })
        .reduce((acc, cur) => (cur > acc ? cur : acc), 0)
    ) : 60
    return length
  }

  const handleLegendMouseEnter = e => {
    if (!hover) {
      setHover(e.dataKey)
    }
  }

  const handleLegendMouseLeave = () => {
    setHover(null)
  }

  const selectBar = e => {
    const newLabels = labels.map(label => {
      const l = { ...label }
      if (label.label === e.dataKey) {
        l.hide = !l.hide
      }
      return l
    })
    setLabels(newLabels)
  }

  const showActions = false
  return (
    <Card
      classes={className ? className : 'grid-col-1'}
      background
      header={
        <>
          <h3>Social Performance</h3>
          <div className="details-section-buttons social-performance-filters min-flex tablemeta">
            <div className="filter-overlay-ga">
              <CheckInput
                name="overlay_ga"
                id="overlay_ga"
                label="Overlay GA"
                field={{
                  name: 'overlay_ga',
                  value: overlay_ga
                }}
                type="checkbox"
                form={{
                  values: { overlay_ga: overlay_ga },
                  errors: {},
                  touched: {},
                  setFieldValue: async (field_name, value) => {
                    setOverlayGa(value)
                  },
                  setFieldTouched: async () => {}
                }}
              />
            </div>
            <div className="filter-date-range">
              <InlineSelect
                id="period"
                name="period"
                className="inline-select"
                classNamePrefix="inline"
                defaultValue={date_options.find(o => o.value === 'LAST_7_DAYS')}
                selectedValue={dateData.period}
                options={date_options.filter(o => (o.value.includes('LAST') && /\d/.test(o.value)))}
                onChange={e => {
                  const newPeriod = e.value
                  const newDateData = textToDate(newPeriod)
                  setDateData({ period: newPeriod, ...newDateData })
                }}
                components={{
                  Option: CustomOption
                }}
              />
            </div>
          </div>
        </>
      }
      body={
        <div className="social-performance-widget">
          {statistics.length ? (
            <div className={'flex-container flex-quality flex-3'}>
              <ResponsiveContainer width="100%" height={250} minWidth="0" legendWrap={!showActions}>{() => (
                <LineChart width={750} height={250} data={filledData}>
                  <CartesianGrid stroke="#F3F5F8" />
                  <XAxis
                    dataKey="date"
                    hasTick
                    padding="no-gap"
                    tickFormatter={date => getDateFormat(date, dateData.days)}
                    tick={{ fontSize: 9, stroke: '#B2C2D4', strokeWidth: 1 }}
                    tickLine={{ stroke: '#F3F5F8', strokeWidth: 1 }}
                    axisLine={{ stroke: '#F3F5F8', strokeWidth: 1 }}
                  />
                  <YAxis
                    width={longestLabelLength(filledData, labels.map(label => label.label))}
                    hasTick
                    axisLine={false}
                    domain={[ 'dataMin', 'dataMax' ]}
                    tick={{ fontSize: 9, stroke: '#B2C2D4', strokeWidth: 1 }}
                    tickLine={{ stroke: 'none', strokeWidth: 1 }}
                    tickCount={9}
                  />
                  <Tooltip cursor={{ fill: '#FAFBFD' }} labelFormatter={date => getDateFormat(date, dateData.days)} />
                  {labels.map((label, idx) => (
                    <Line
                      key={`bar-${idx}`}
                      dataKey={label.label}
                      strokeWidth={2}
                      stroke={label.color}
                      hide={label.hide}
                      isAnimationActive={false}
                      strokeOpacity={Number(
                        hover === label.label || !hover ? 1 : 0.3
                      )}
                    />
                  ))}
                </LineChart>
              )}
              </ResponsiveContainer>
              <div className="customized-legend" style={{ paddingLeft: longestLabelLength(filledData, labels.map(label => label.label)) }}>
                {
                  labels.map((label, eid) => (
                    <span
                      onClick={e => {
                        e.dataKey = label.label
                        selectBar(e)
                      }}
                      onMouseOver={e => {
                        e.dataKey = !label.hide ? label.label : null
                        handleLegendMouseEnter(e)
                      }}
                      onMouseOut={e => {
                        e.dataKey = !label.hide ? label.label : null
                        handleLegendMouseLeave(e)
                      }}
                      style={{
                        opacity: (hover === label.label || !hover) && !label.hide ? 1 : 0.3
                      }}
                      key={`overlay-legend-${eid}`} className="legend-item"
                    >
                      <svg width={10} height={10} viewBox="0 0 10 10">
                        <circle cx={5} cy={5} r={5} fill={label.color} />
                      </svg>
                      <span className="legend-label">{label.label}</span>
                    </span>
                  ))
                }
                <div className="powered-by">
                  POWERED BY <svg viewBox="0 1 92 32"><use href="/images/glyphs.svg#glyph-Flow" /></svg>
                </div>
              </div>
            </div>
          ) : (
            <div className="flow-placeholder">
              <img width="180" src={`${process.env.PUBLIC_URL}/images/flow-placeholder.png`} />
              <div className="flow-placeholder-text">Want <span>extra visibility & lead exposure</span><br />for your brand & listings using the latest performance marketing solution from our partner Flow?</div>
              <Button component="a" target="_blank" rel="noopener" href="https://go.propdata.net/propdata-fuel-dynamic-paid-ads" className="btn btn-primary">Enquire Now</Button>
            </div>
          )}
        </div>
      }
    />
  )
}

SocialPerformanceWidget.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  config: PropTypes.object,
  model: PropTypes.object
}


const mapStateToProps = state => {
  const modelname = MODELNAME(state)
  const config = CONFIG(state, modelname)
  return ({
    config
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany, fetchListingHistory }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(SocialPerformanceWidget))
