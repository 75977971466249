import classNames from 'classnames'
import merge from 'deepmerge'
import { Formik, getIn, setIn } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { NavLink } from 'react-router-dom'

import { history } from '../../store'
import { hasAddons, hasPermission, isConditional, isInViewport, parseURL, slugify, sortBy, handleSubmitError, breakpoint, valueFormat } from '../../utils'
import validate from '../../validate'
import { Button } from '../ui/Button'
import CustomForm from './forms/CustomForm'
import FieldGroup from './forms/FieldGroup'
import ModelActions from './ModelActions'
import HorizontalTabs from './tabs/HorizontalTabs'
import Tab from './tabs/Tab'
import Loader from './Loader'


class ModelAdd extends React.Component {
  constructor(props) {
    super(props)
    this.timers = {}
    this.state = {
      required: false,
      collapsed: false,
      redirect: false,
      quality: false,
      webedit: [ 'residential', 'commercial', 'holiday' ].includes(props.config.modelname) ? getIn(props.cache.settings, `${props.user.agent.site.id}.default_listing_webedit`, false) : false,
      scrollTop: null,
      initvals: {},
      init: false,
      offset: 0,
      showActions: breakpoint.matches,
      showJump: breakpoint.matches,
      selectedGroup: {},
      currentGroup: {},
      active_portals: Object.keys(props.portals).filter(pk => getIn(props, `portals.${pk}.active`)).map(pk => getIn(props, `portals.${pk}.meta.portal.slug`)).filter(slug => slug)
    }
    this.isConditional = isConditional.bind(this)
    this.toggleWebEdit = this.toggleWebEdit.bind(this)
    this.toggleRequired = this.toggleRequired.bind(this)
    this.toggleQuality = this.toggleQuality.bind(this)
    this.toggleCollapsed = this.toggleCollapsed.bind(this)
    this.redirectSchema = this.redirectSchema.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.initModel = this.initModel.bind(this)
    this.hasPermission = this.hasPermission.bind(this)
    this.assignTeamAgents = this.assignTeamAgents.bind(this)
    this.renderGroups = this.renderGroups.bind(this)
    this.renderTabs = this.renderTabs.bind(this)
    this.renderList = this.renderList.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.hashChanged = this.hashChanged.bind(this)
    this.hashLinkScroll = this.hashLinkScroll.bind(this)
    this.getCurrentElement = this.getCurrentElement.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
    this.updateScroller = this.updateScroller.bind(this)
    this.cancelOtherScrolls = this.cancelOtherScrolls.bind(this)
    this.setInitVals = this.setInitVals.bind(this)
    this.assignListingFields = this.assignListingFields.bind(this)
    this.actions = {
      ...props.actions,
      setInitVals: this.setInitVals,
      assignTeamAgents: this.assignTeamAgents,
      assignListingFields: this.assignListingFields,
      toggleWebEdit: this.toggleWebEdit,
      toggleRequired: this.toggleRequired,
      toggleQuality: this.toggleQuality,
      toggleCollapsed: this.toggleCollapsed,
      selectReferralManager: this.selectReferralManager.bind(this)
    }
    this.ignored_fields = []
  }

  componentDidMount() {
    const { model, modelaction, modelname, modelid, actions } = this.props
    breakpoint.addEventListener('change', this.toggleActions)
    this.props.actions.dismissNotice() // Also for duplicate
    window.addEventListener('hashchange', this.hashChanged, false)
    if (!model && modelaction === 'duplicate' && modelname && modelid) { // This is a duplicate add
      actions.fetchOne(modelname, modelid)
    }
    this.setState({ initvals: this.initModel(), init: true })
    const tab_el = document.querySelector('.viewhead')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + tab_el.getBoundingClientRect().height
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    this.checkPerms(true)
    const options = {
      root: this.scroller,
      threshold: [ 0.0, 0.5, 1.0 ],
      rootMargin: '20px'
    }
    this.observer = new IntersectionObserver(() => {
      this.getCurrentElement()
    }, options)
    this.jumpobserver = new IntersectionObserver(() => {
      this.handleUpdate()
    }, options)
    if (document.querySelector('.cardtoggle-jump')) {
      this.jumpobserver.observe(document.querySelector('.cardtoggle-jump'))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkPerms()

    if ((!this.state.initvals || !Object.keys(this.state.initvals).length) &&
      this.props.model && Object.keys(this.props.model).length) {
      this.setState({ initvals: this.initModel(this.props.model) })
    }
    if (!isEqual(this.props.model, prevProps.model)) {
      this.setState({ initvals: this.initModel(this.props.model) })
    }
    if (!isEqual(this.props.sourcemodel, prevProps.sourcemodel)) {
      this.setState({ initvals: this.initModel(this.props.model) })
    }
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      this.setState({ initvals: this.initModel(this.form.values) })
    }
    if (!isEqual(prevProps.portals, this.props.portals)) {
      this.setState({
        active_portals: Object.keys(this.props.portals).map(pk => {
          if (
            this.props.globalportals &&
            this.props.portals[pk] &&
            this.props.portals[pk].portal &&
            this.props.globalportals[this.props.portals[pk].portal]
          ) {
            return this.props.globalportals[this.props.portals[pk].portal].slug
          }
          return null
        }).filter(p => p)
      })
    }
    if (this.props.model && this.props.match.params.action === 'valuation') {
      if (this.props.model.status !== 'Published') {
        const redirect = parseURL(this.props.routeConfig.valuation.redirect, { site: this.props.user.agent.site.id })
        this.props.actions.registerRedirect(redirect)
      }
    }
    const tab_el = document.querySelector('.viewhead')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top + tab_el.getBoundingClientRect().height
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    if (document.querySelector('.cardtoggle-jump')) {
      this.jumpobserver.unobserve(document.querySelector('.cardtoggle-jump'))
      this.jumpobserver.observe(document.querySelector('.cardtoggle-jump'))
    }
    if (this.state.webedit !== prevState.webedit) {
      const visibleGroups = Object.keys(this.props.config.fieldgroups).filter(group => document.querySelector(`#${slugify(group)}`))
      this.setState({ visibleGroups })
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleActions)
    window.removeEventListener('hashchange', this.hashChanged, false)
    Object.keys(this.timers).forEach(timer => {
      clearTimeout(this.timers[timer])
    })
    if (this.observer) {
      this.observer.disconnect()
    }
    if (this.jumpobserver) {
      this.jumpobserver.disconnect()
    }
  }

  setInitVals(values) {
    this.setState({
      initvals: values
    })
  }

  initObserver(el) {
    this.observer.observe(el)
  }

  setIgnoreFields(field, unset = false) {
    if (this.ignored_fields && !this.ignored_fields.includes(field) && !unset) {
      this.ignored_fields = [ ...this.ignored_fields, field ]
    } else if (this.ignored_fields && this.ignored_fields.includes(field) && unset) {
      this.ignored_fields.filter(f => f !== field)
    }
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (!e.matches && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  checkPerms(onmount = false) {
    if (!this.hasPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.add.redirect, { site: this.props.user.agent.site.id })
      this.props.actions.registerRedirect(redirect)
    } else if (onmount) {
      this.hashLinkScroll()
    }
  }

  assignListingFields(field, form) { // Populates agent fields when team is selected
    setTimeout(() => { // wait for sagas to run
      const { listing_model, model_type } = form.values
      const listing = getIn(this.props.cache, `${listing_model}.${this.form.values[field.name]}`, getIn(this.props.cache, `${model_type}.${this.form.values[field.name]}`))
      if (!listing) { return }
      const mappings = [
        'property_type',
        'location',
        'farm_name',
        'street_number',
        'street_name',
        'unit_number',
        'complex_name',
        'floor_number',
        'building_name',
        'erf_number',
        'share_block_number',
        'sectional_scheme_name',
        'section_number',
        'section_plan_number',
        'mandate_type'
      ]
      let updates = {}
      let touched = {}
      mappings.forEach(listing_field => {
        if (getIn(listing, listing_field)) {
          updates = setIn(updates, listing_field, getIn(listing, listing_field))
          touched = setIn(touched, listing_field, !!getIn(listing, listing_field))
        }
      })
      this.setInitVals({ ...form.values, ...updates })
      form.setValues({ ...form.values, ...updates }).then(() => {
        form.setTouched({ ...form.touched, ...touched })
      })
    }, 150)
  }

  initModel(model) {
    const {
      user, modelname, modelid, modelaction, sourcemodel, config, portals, actions, addons, cache
    } = this.props
    const site_region = getIn(cache.settings, `${user.agent.site.id}.region`)
    const lightstone_mapping = {
      erf_number: 'lightstone_data.erfNumber',
      street_number: 'lightstone_data.streetNumber',
      unit_number: 'lightstone_data.unit',
      map_x_position: 'lightstone_data.cadPoint.coordinates[0]',
      map_y_position: 'lightstone_data.cadPoint.coordinates[1]',
      street_name: [ 'lightstone_data.streetName', 'lightstone_data.streetType' ],
      sectional_scheme_name: 'lightstone_data.sectionalSchemeName',
      section_number: 'lightstone_data.sectionalSchemeUnitNumber',
      section_plan_number: 'lightstone_data.sectionalSchemeNumber',
      complex_name: 'lightstone_data.sectionalSchemeName'
    }

    let initvals = {}
    if (model && modelaction === 'duplicate') { // This is a duplicate add
      if (Object.keys(model).length > 0) { // We have the model
        initvals = { ...model }
        const keys = Object.keys(initvals)
        const dedupes = config.fields.filter(f => f.dedupe && keys.includes(f.name)).map(f => f.name)
        dedupes.forEach(key => {
          if (key === 'street_number' && model.unit_number) {
            return
          }
          delete initvals[key]
        })
      } else { // The model still needs to load ie. hard refreshed page
        actions.fetchOne(modelname, modelid)
      }
      Object.keys(initvals).forEach(k => {
        if (initvals[k] === null) { return } // Ignore null values
        const fields = config.fields.filter(field => field.name === k)
        fields.forEach(f => {
          if (f && this.isConditional(f, 'edit', false, { values: model, initialValues: model })) {
            if (f.defaultvalue !== undefined && f.defaultvalue !== null) {
              initvals[f.name] = f.defaultvalue
              if (f.defaultvalue === 'Square Metres' && !addons.includes('metric_units')) {
                initvals[f.name] = 'Square Feet'
              }
            }
            if ((f.input === 'TextNotes' && f.type === 'tel') || f.input === 'Float') { // Ensure float-type fields are converted accordingly
              initvals[k] = initvals[k] !== undefined && initvals[k] !== null ? (
                String(parseFloat(initvals[k]).toFixed(1))
              ) : initvals[k]
            }
            if (f.container) {
              if (initvals[f.container]) { // Contained fields using container name and field name
                if (f.edit && f.name in initvals[f.container]) {
                  if (f.name === 'images') { // Images only require ID
                    initvals[f.name] = initvals[f.container][f.name] ? (
                      initvals[f.container][f.name].map(i => i.id)
                    ) : null
                  } else {
                    initvals[f.name] = initvals[f.container][f.name]
                  }
                }
              } else if (getIn(initvals, f.container)) { // Contained using dot notation ie. meta.agent_of_the_month
                initvals[f.name] = getIn(initvals, f.container)
              }
            }
            if (f.file) { initvals[f.name] = null }
            if (modelname === 'projects') {
              if (f.name === 'property_types') {
                if (initvals[f.name]) {
                  initvals[f.name].forEach(pt => { pt.plan_name = null })
                }
              }
            }
            if (f.relatedfield && initvals[f.relatedfield] && initvals.meta && initvals.meta[f.modelname]) { // Contained fields
              let meta = initvals.meta[f.modelname]
              if (Array.isArray(meta)) { meta = meta.find(o => o.id === initvals[f.relatedfield]) }
              if (f.edit) { initvals[f.name] = meta[f.name] }
            }
          }
        })
      })
      if ([ 'residential', 'commercial', 'holiday' ].includes(modelname) && model.meta.portals) {
        initvals.portals = {}
        model.meta.portals.forEach(p => {
          const pidx = Object.keys(portals)
            .filter(pk => portals[pk] && portals[pk].portal && portals[pk].meta)
            .find(pk => portals[pk].portal === p.portal)
          if (pidx) {
            const { slug } = portals[pidx].meta.portal
            initvals.portals[slug] = { portal: p.portal, active: p.active }
          }
        })
      }
      if ([ 'images' ].includes(modelname)) {
        initvals.file = []
        initvals.file.push(model.id)
      }
      if (site_region === 'ae') {
        initvals.documents = []
        initvals.private_documents = []
      }
    } else if (model && modelaction === 'valuation') {
      if (Object.keys(model).length > 0) { // We have the model
        initvals.status = 'Valuation'
        initvals.listing_type = 'For Sale'
        initvals.valuation = model.id
        Object.keys(lightstone_mapping).forEach(k => {
          const maps = getIn(lightstone_mapping, k)
          let value = getIn(model, maps)
          if (Array.isArray(maps)) {
            value = maps.map(mk => getIn(model, mk)).join(' ')
          }
          initvals[k] = value
        })
      } else { // The model still needs to load ie. hard refreshed page
        actions.fetchOne(modelname, modelid) // valuations / referrals
      }
    } else if (sourcemodel && sourcemodel.model) {
      if (sourcemodel.model === 'referrals') {
        if (config.domain === 'listings') {
          initvals.status = 'Active'
          initvals.listing_type = 'For Sale'
          initvals.tags = sourcemodel.tags
          initvals.branch = sourcemodel.recipient_branch
          initvals.agent = sourcemodel.recipient_agent
          initvals.lightstone_id = sourcemodel.seller_lightstone_id
          initvals.lightstone_data = sourcemodel.lightstone_data
          initvals.owners = [ sourcemodel.created_contact ]
          initvals.property_type = sourcemodel.seller_property_types[0]
          if (sourcemodel.seller_lightstone_id) {
            Object.keys(lightstone_mapping).forEach(k => {
              const maps = getIn(lightstone_mapping, k)
              let value = getIn(sourcemodel, maps)
              if (Array.isArray(maps)) {
                value = maps.map(mk => getIn(sourcemodel, mk)).join(' ')
              }
              if (!initvals[k]) { initvals[k] = value }
            })
          }
        }
      }
      if (sourcemodel.model === 'valuations') {
        if (config.domain === 'listings') {
          initvals.status = 'Valuation'
          initvals.listing_type = 'For Sale'
          initvals.tags = sourcemodel.tags
          initvals.branch = sourcemodel.branch
          initvals.agent = sourcemodel.agent
          initvals.location = sourcemodel.location
          initvals.lightstone_id = sourcemodel.lightstone_id
          initvals.owners = [ sourcemodel.owner ]
          if (sourcemodel.lightstone_data) {
            if (sourcemodel.lightstone_data.bondDetails.length) {
              initvals.bond_bank = sourcemodel.lightstone_data.bondDetails[0].bondInstitution
              initvals.bond_bank_balance = sourcemodel.lightstone_data.bondDetails[0].bondamount
              initvals.bond_bank_account_number = sourcemodel.lightstone_data.bondDetails[0].bondNumber
            }
            if (sourcemodel.lightstone_data.municipalValuation) {
              initvals.municipal_value = sourcemodel.lightstone_data.municipalValuation.municipalValuation
            }
            if (sourcemodel.lightstone_data.propertyDetails) {
              if (sourcemodel.lightstone_data.propertyDetails.erfNumber) {
                initvals.erf_number = sourcemodel.lightstone_data.propertyDetails.erfNumber
              }
              if (sourcemodel.lightstone_data.propertyDetails.size) {
                initvals.land_size = sourcemodel.lightstone_data.propertyDetails.size
              }
              if (sourcemodel.lightstone_data.propertyDetails.streetName) {
                initvals.street_name = sourcemodel.lightstone_data.propertyDetails.streetName
              }
              if (sourcemodel.lightstone_data.propertyDetails.streetNumber) {
                initvals.street_number = sourcemodel.lightstone_data.propertyDetails.streetNumber
              }
              if (sourcemodel.lightstone_data.propertyDetails.cadastrePoint.coordinates) {
                initvals.map_x_position = getIn(sourcemodel, 'lightstone_data.propertyDetails.cadastrePoint.coordinates[1]')
                initvals.map_y_position = getIn(sourcemodel, 'lightstone_data.propertyDetails.cadastrePoint.coordinates[0]')
              }
              if (sourcemodel.valuation_extras && sourcemodel.valuation_extras.recommended_market_price) {
                initvals.valuation_price = getIn(sourcemodel, 'valuation_extras.recommended_market_price')
              }
            }
          }
        }
      }
      if ([ 'residential', 'commercial' ].includes(sourcemodel.model)) {
        if (config.modelname === 'deals') {
          [
            'branch',
            'location',
            'lightstone_id',
            'lightstone_data',
            'property_type',
            'street_number',
            'street_name',
            'unit_number',
            'complex_name',
            'building_name',
            'floor_number',
            'erf_number',
            'farm_number',
            'farm_name',
            'share_block_number',
            'sectional_scheme_name',
            'section_number',
            'section_plan_number',
            'mandate_type'
          ].forEach(f => {
            initvals = setIn(initvals, f, getIn(sourcemodel, f))
          })
          const nurl = new URL(window.location.href)
          const params = new URLSearchParams(nurl.search)
          initvals.model_type = sourcemodel.model
          initvals.model_id = sourcemodel.id
          initvals.selling_agents = [
            sourcemodel.agent,
            sourcemodel.agent_2,
            sourcemodel.agent_3,
            sourcemodel.agent_4
          ].filter(a => a)
          initvals.sellers = sourcemodel.owners
          getIn(config, 'fields', []).forEach(f => {
            if (f.defaultvalue || f.defaultvalue === false) {
              initvals[f.name] = f.defaultvalue
            }
            if (f && this.isConditional(f, 'edit', false, { values: initvals, initialValues: initvals })) {
              if (f.defaultvalue || f.defaultvalue === false) {
                if (f.defaultvalue.replace) {
                  initvals[f.name] = parseURL(f.defaultvalue, { agent: this.props.user.agent })
                } else {
                  initvals[f.name] = f.defaultvalue
                }
                if (f.defaultvalue === 'Square Metres' && !this.props.addons.includes('metric_units')) {
                  initvals[f.name] = 'Square Feet'
                }
              } else if (params.get(f.name)) {
                if (f.multi) {
                  initvals[f.name] = params.get(f.name).split(',')
                  initvals[f.name] = initvals[f.name].map(v => {
                    if (!isNaN(v)) {
                      return Number(v)
                    }
                    return v
                  })
                } else {
                  const v = params.get(f.name)
                  initvals[f.name] = !isNaN(v) ? Number(v) : v
                }
              }
              const arrayfields = [ 'FieldArray', 'SizeBreakdowns', 'OnShowEvents', 'LandUseBreakdowns', 'TranslatableTextArea' ]
              if (arrayfields.includes(f.input) && this.isConditional(f, 'required', false, { values: model, initialValues: model })) {
                initvals[f.name] = [ {} ]
              }
              if (f.input === 'Map') {
                initvals[f.name] = { lat: undefined, lng: undefined }
              }
              if (f.name === 'branch' && !getIn(initvals, f.name)) {
                if (user.agent.branches && user.agent.branches.length === 1) {
                  initvals[f.name] = user.agent.branches[0] // auto assign current user branch if there's only 1
                }
              }
            }
          })
        }
      }
      if (sourcemodel.model === 'vacancypro') {
        const full_names = sourcemodel.agent ? sourcemodel.agent.split(' ') : [ '', '' ]
        let price_per_square = null
        if (sourcemodel.price && sourcemodel.size) {
          price_per_square = sourcemodel.price / sourcemodel.size
        }
        initvals.source_ref = `vacancypro-${sourcemodel.id}`
        initvals.status = 'Active'
        initvals.listing_type = 'To Let'
        initvals.branch = this.props.user.agent.id !== 0 ? this.props.user.agent.branches[0] : null
        initvals.agent = this.props.user.agent.id !== 0 ? this.props.user.agent.id : null
        initvals.location = sourcemodel.suburb_id
        initvals.bedrooms = sourcemodel.bedrooms
        initvals.bathrooms = sourcemodel.bathrooms
        initvals.garages = sourcemodel.garages
        initvals.monthly_rates = sourcemodel.rates_levies
        initvals.floor_size = sourcemodel.size
        initvals.price = price_per_square.toFixed(2)
        initvals.gross_monthly_rental = sourcemodel.price
        initvals.property_type = sourcemodel.property_type
        initvals.map_x_position = sourcemodel.geo_lat
        initvals.map_y_position = sourcemodel.geo_long
        initvals.description = sourcemodel.content
        initvals.building_name = sourcemodel.building_name
        initvals.unit_number = sourcemodel.unit_number
        initvals.floor_number = sourcemodel.floor_number
        initvals.complex_name = sourcemodel.complex_name
        initvals.street_number = sourcemodel.street_number
        initvals.street_name = sourcemodel.street_name
        if (sourcemodel.available_from) {
          if (sourcemodel.available_from === 'Immediate') {
            initvals.available_from = 'Immediately'
          } else if (sourcemodel.available_from === 'Contact') {
            initvals.available_from = 'Negotiable'
          } else {
            initvals.available_from = 'Specific Date'
            initvals.occupation_date = valueFormat('shortdate', sourcemodel.available_from)
          }
        }
        initvals.contact_lookup_autofill = {
          term: sourcemodel.agent_email,
          first_name: full_names[0],
          last_name: full_names[1],
          source: 'Other',
          cell_number: sourcemodel.agent_mobile.replace(/["']/g, ''),
          legal_basis: 'Legitimate interest - other',
          entity_type: 'Natural Person',
          email: sourcemodel.agent_email
        }
      }
    } else if (!modelid) { // A fresh add
      const nurl = new URL(window.location.href)
      const params = new URLSearchParams(nurl.search)
      const defaultModel = { ...model }
      getIn(config, 'fields', []).forEach(f => {
        if (f.defaultvalue || f.defaultvalue === false) {
          defaultModel[f.name] = f.defaultvalue
        }
        if (f && this.isConditional(f, 'edit', false, { values: { ...defaultModel, ...initvals }, initialValues: { ...defaultModel, ...initvals } })) {
          if (f.defaultvalue || f.defaultvalue === false) {
            if (f.defaultvalue.replace) {
              initvals[f.name] = parseURL(f.defaultvalue, { agent: this.props.user.agent })
            } else {
              initvals[f.name] = f.defaultvalue
            }
            if (f.defaultvalue === 'Square Metres' && !this.props.addons.includes('metric_units')) {
              initvals[f.name] = 'Square Feet'
            }
          } else if (params.get(f.name)) {
            if (f.multi) {
              initvals[f.name] = params.get(f.name).split(',')
            } else {
              initvals[f.name] = params.get(f.name)
            }
          }
          const arrayfields = [ 'FieldArray', 'SizeBreakdowns', 'OnShowEvents', 'LandUseBreakdowns', 'TranslatableTextArea' ]
          if (arrayfields.includes(f.input) && this.isConditional(f, 'required', false, { values: model, initialValues: model })) {
            initvals[f.name] = [ {} ]
          }
          if (f.input === 'Map') {
            initvals[f.name] = { lat: undefined, lng: undefined }
          }
          if (f.name === 'branch') {
            if (user.agent.branches && user.agent.branches.length === 1) {
              initvals[f.name] = user.agent.branches[0] // auto assign current user branch if there's only 1
            }
          }
          if ([ 'agent', 'introduction_agent' ].includes(f.name) && !this.props.user.permissions.includes('is_prop_data_user')) {
            initvals[f.name] = user.agent.id // auto assign current user agent
          }
        }
      })
    }
    if (Object.keys(initvals) === 0) { initvals = false }
    Object.keys(initvals).forEach(f => { // Remove fields which the user is not allowed to edit
      if (![ 'id', 'portals' ].includes(f)) {
        const fielddupes = config.fields.filter(fi => fi.name === f)
        let keepval = false
        fielddupes.forEach(field => {
          if (hasPermission(field.permissions, user.permissions)) {
            keepval = true // Only one positive allow is required to keep the field value
          }
        })
        if ([ 'contact_lookup_autofill' ].includes(f)) { keepval = true } // Exceptions
        if (!keepval) { delete initvals[f] }
      }
    })
    Object.keys(initvals).filter(k => k.includes('.')).forEach(k => {
      initvals = setIn(initvals, k, initvals[k])
    })
    return initvals
  }

  toggleWebEdit(e) {
    e.target.blur()
    this.setState({ webedit: !this.state.webedit, quality: false, required: false })
  }

  toggleRequired(e) {
    e.preventDefault()
    this.setState({ required: !this.state.required, quality: false, webedit: false })
  }

  toggleQuality(e) {
    e.preventDefault()
    this.setState({ quality: !this.state.quality, required: false, webedit: false })
  }

  toggleCollapsed(e) {
    e.preventDefault()
    e.target.blur()
    this.setState({ collapsed: !this.state.collapsed })
  }

  assignTeamAgents(field, form) { // Populates agent fields when team is selected
    setTimeout(() => { // wait for sagas to run
      const team = getIn(this.props.cache, `teams.${this.form.values[field.name]}`)
      if (!team) { return }
      const agents = team.agents.filter(a => a !== team.team_leader) || []
      const updated_agents = {
        agent: team.team_leader,
        agent_2: getIn(agents, 0),
        agent_3: getIn(agents, 1),
        agent_4: getIn(agents, 2)
      }
      const touched = {
        agent: !!team.team_leader,
        agent_2: !!getIn(agents, 0),
        agent_3: !!getIn(agents, 1),
        agent_4: !!getIn(agents, 2)
      }
      form.setValues({ ...form.values, ...updated_agents }).then(() => {
        form.setTouched({ ...form.touched, ...touched })
      })
    }, 150)
  }

  hasPermission() {
    const { user, config, addons } = this.props
    if (config.addons) { return hasAddons(config.addons, addons) } // Entire module is disabled
    const requiredPermissions = this.props.routeConfig.add.permissions
    if (user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true }
    const hasAddPermissions = requiredPermissions.filter(perm => perm.endsWith('_add'))
    return hasPermission(hasAddPermissions, user.permissions)
  }

  redirectSchema(schema) { this.setState({ redirect: schema }) } // Fired on submit via the ContextMenu

  handleSubmit(values, actions) {
    const valid = merge({}, values)
    Object.keys(valid).forEach(k => {
      if (Array.isArray(valid[k]) && !this.props.config.fields.find(f => f.name === k && f.multi) && !k.includes('review_providers')) {
        valid[k] = getIn(valid, `${k}.0`, null) // Arrays passed in will be mutated to objects if not configed as multi
      }

      let field = this.props.config.fields.find(f => f.name === k && f.group)
      if (!field) { field = this.props.config.fields.find(f => f.name === k) }
      if (field && field.input === 'TextNotes' && field.type === 'tel') { valid[k] = valid[k] ? parseFloat(valid[k]).toFixed(1) : valid[k] }
      if (field && field.parent) { // This is a contained value ie. branch deactivate and listings reassignment
        if (valid[field.parent]) {
          valid[field.parent][k] = valid[k]
        } else {
          valid[field.parent] = {}
          valid[field.parent][k] = valid[k]
        }
        delete valid[k]
      }

      if (field && field.rename) { // Used for renaming fields at save time ie. user -> username
        valid[field.rename] = String(valid[k])
        delete valid[k]
      }

      if (k === 'web_ref' && valid[k] === '') { delete valid[k] } // Dont send blank webref

      if (field && field.input === 'ContactLookup' && field.multi) {
        valid[field.name] = getIn(valid, field.name, [])
        if (valid[field.name]) {
          valid[field.name] = valid[field.name].filter(f => f)
        }
      }

      // don't send empty array fields
      if (field && [ 'FieldArray', 'SizeBreakdowns', 'OnShowEvents', 'LandUseBreakdowns', 'TranslatableTextArea', 'SortableTexts' ].includes(field.input) && field.multi) {
        valid[field.name] = getIn(valid, field.name, [])
        if (valid[field.name]) {
          valid[field.name] = valid[field.name].filter(f => {
            if (f && (field.fields?.some(fe => f[fe.name]) || !field.fields)) {
              return true
            }
            return false
          })
        }
      }

      if (field && field.input === 'FieldList' && field.multi) {
        valid[field.name] = valid[field.name].filter(v => v)
      }

      if (k === 'portals' && ((valid.display_on_website && valid.feed_to_portals) || this.props.config.servicename === 'article')) { // Only set portals if display on site is on and feed to portals is enabled
        const raw = valid[k] // Copy initial raw portals object
        valid[k] = [] // Create a new portals list based on cached config
        if (raw) {
          Object.keys(raw).forEach(pk => {
            const pidx = Object.keys(this.props.portals).find(pid => this.props.portals[pid].meta.portal.slug === pk) // Find the config for the portal by slug
            const pconf = { ...raw[pk] } // Initialise final portals object for update payload
            if (pidx && this.props.portals[pidx].active) { // An active portal setting was found
              if (this.props.model && this.props.modelaction === 'duplicate') {
                if (pconf[this.props.config.servicename]) {
                  delete pconf[this.props.config.servicename]
                }
                if (pconf.reference) { delete pconf.reference }
                if (pconf.id) { delete pconf.id }
              } else {
                pconf[this.props.config.servicename] = valid.id // Set the ID for the modelname
              }
              pconf.portal = this.props.portals[pidx].portal
              if (raw[pk].expiration_date) { pconf.expiration_date = raw[pk].expiration_date }
              if (raw[pk].active && (
                !this.state.portals ||
                !this.state.initvals.portals[pk] ||
                !this.state.initvals.portals[pk].active
              )) {
                pconf.last_message = 'Pending syndication'
                pconf.feed_status = 'pending'
              }
              valid[k].push(pconf)
            }
          })
        }
      }
    })
    return new Promise((resolve, reject) => {
      if (this.props.match.params.model === 'images') {
        valid.id = valid.file
        valid.accepted = true
        delete valid.file
        this.props.actions.updateModel({ values: valid, resolve, reject })
      } else {
        clearTimeout(this.timers.create_autosave)
        this.props.actions.createModel({ values: valid, autosaved: true, resolve, reject })
      }
    }).then(r => {
      actions.setSubmitting(false)
      actions.setTouched({})
      if (this.state.redirect) { // This works off a schema stored in local state
        this.props.actions.registerRedirect(parseURL(this.state.redirect, {
          id: r.id,
          site: this.props.match.params.site
        }))
      } else {
        this.props.actions.registerRedirect(`/secure/${this.props.match.params.site}/${this.props.config.modelname}/${r.id}`)
      }
    }).catch(e => {
      handleSubmitError(e, actions, this.form)
    })
  }

  renderList() {
    const { config } = this.props
    const hasTabs = !!config.tabs
    return (
      <div
        className={classNames('jumplist', { sticky: this.state.sticky, show: this.state.showJump })}
        id={'slider-jumplist-groups'}
      >
        <div className="jumplist-inner" ref={el => { this.sliderref = el }}>
          { !hasTabs && !this.state.showJump ? (
            <span ref={el => (this.cardtoggle = el)} className={`cardtoggle-jump${this.state.sticky ? ' sticky' : ''}`}>
              <Button type="button" className="btn btn-icon-right" onClick={() => this.setState({ showJump: true })}>
                {getIn(this.state, 'currentGroup.label', Object.keys(config.fieldgroups)[0])}
              </Button>
            </span>
          ) : null }
          {config?.fieldgroups ? Object.keys(config.fieldgroups).map((group, gidx) => {
            if (document.querySelector(`#${slugify(group)}`)) {
              this.initObserver(document.querySelector(`#${slugify(group)}`))
              const location = merge({}, this.props.location)
              location.hash = `#${slugify(group)}`
              return (
                <NavLink
                  key={`jl-${gidx}`}
                  aria-current="step"
                  isActive={() => slugify(group) === this.state.currentGroup.id}
                  to={{ hash: `#${slugify(group)}`, search: this.props.location.search }}
                  onClick={() => {
                    const element = this.scroller.querySelector(`#${slugify(group)}`)
                    this.setState({ showJump: false }, () => {
                      this.scrollTo(element)
                    })
                  }}
                >
                  {group}
                </NavLink>
              )
            }
            return null
          }) : null}
        </div>
      </div>
    )
  }

  hashChanged() {
    this.hashLinkScroll()
  }

  getCurrentElement() {
    if (document.querySelectorAll('.card')) {
      clearTimeout(this.timers.currentGroup)
      this.timers.currentGroup = setTimeout(() => {
        let cards = document.querySelectorAll('.card')
        cards = Array.prototype.slice.call(cards)
        const visible = cards.filter(c => isInViewport(c))
        const currentAvailable = sortBy(cards.map(c => ({ id: c.getAttribute('id'), label: c.querySelector('h3') ? c.querySelector('h3').textContent : null, top: c.getBoundingClientRect().top, height: c.getBoundingClientRect().height })), 'top')
        const currentVisible = sortBy(visible.map(c => ({ id: c.getAttribute('id'), label: c.querySelector('h3') ? c.querySelector('h3').textContent : null, top: c.getBoundingClientRect().top, height: c.getBoundingClientRect().height })), 'top')
        let current
        if (this.state.selectedGroup && this.state.selectedGroup.id) {
          current = currentVisible.find(c => c.id === this.state.selectedGroup.id)
        }
        if (!current) {
          current = currentAvailable.filter(c => c.top + c.height >= (208 + (!this.state.showActions ? 65 : 0)))[0]
        }
        if (current && current.id !== getIn(this.state.currentGroup, 'id')) {
          this.setState({ currentGroup: current }, () => {
            clearTimeout(this.timers.push)
            this.timers.push = setTimeout(() => {
              history.replace({ hash: current.id, search: this.props.location.search })
            }, 300)
          })
        }
      }, 70)
    }
  }

  cancelOtherScrolls() {
    clearTimeout(this.timers.scroll_update)
  }

  updateScroller() {
    const { hash } = window.location
    if (this.scroller) {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      this.scrollTo(element)
    } else {
      clearTimeout(this.timers.scroll_update)
      this.timers.scroll_update = setTimeout(this.updateScroller, 300)
    }
  }

  hashLinkScroll() {
    const { hash } = window.location
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      clearTimeout(this.timers.scroll_update)
      this.timers.scroll_update = setTimeout(this.updateScroller, 300)
    } else {
      window.scrollTo(0, 0)
    }
  }

  scrollTo(el) {
    if (!el) { return }
    const scrollTop = el.offsetTop - (!this.state.showActions ? 48 : 20)
    const currentGroup = { id: el.getAttribute('id'), label: el.querySelector('h3') ? el.querySelector('h3').textContent : '', top: el.getBoundingClientRect().top, height: el.getBoundingClientRect().height }
    if (
      scrollTop !== this.state.scrollTop
      || currentGroup !== this.state.currentGroup
      || this.state.selectedGroup !== currentGroup
    ) {
      this.setState({ scrollTop, currentGroup, selectedGroup: currentGroup }, () => {
        this.scroller.scrollTo({
          top: scrollTop,
          behavior: 'smooth'
        })
      })
    }
  }

  handleUpdate() {
    if (!this.state.showActions) {
      const toggleBox = this.cardtoggle ? this.cardtoggle.getBoundingClientRect() : {}
      const toggleBoxTop = toggleBox.top - 64 - 68 - 44
      if (toggleBoxTop <= 0 && !this.state.sticky) {
        this.setState({ sticky: true })
      } else if (toggleBoxTop > 0 && this.state.sticky) {
        this.setState({ sticky: false })
      }
    }
  }

  renderTabs () {
    const { config, model, user, match, location, routeConfig } = this.props
    const tabs = {}
    Object.keys(config.fieldgroups).forEach(g => {
      if (!tabs[config.fieldgroups[g].tab]) {
        tabs[config.fieldgroups[g].tab] = []
      }
      tabs[config.fieldgroups[g].tab].push({ ...config.fieldgroups[g], name: g })
    })
    return (
      <HorizontalTabs
        config={routeConfig}
        location={location}
        match={match}
        model={model}
        defaultTab={slugify(config.tabs[0])}
        user={{ permissions: user.permissions, agent: user.agent }}
      >
        { config.tabs.map((tab, tabidx) => (
          <Tab key={`tab-${tabidx}`} className="cardbody" tab={slugify(tab)} label={tab}>
            {tabs[tab].map((group, gidx) => {
              const fields = config.fields.filter(field =>
                field.group === group.name &&
                  field.edit &&
                  hasPermission(field.permissions, user.permissions)
              ).map(field => {
                if (field.group === 'Publish' && field.name.split('.').length > 2) {
                  const parts = field.name.split('.')
                  const meta = parts[0]
                  const pslug = parts[1]
                  if (meta === 'portals') {
                    if (!this.state.active_portals.includes(pslug)) { // If portal is not active in agency settings, hide input
                      field.edit = false
                    }
                  }
                }
                if (field.input === 'ContactLookup') {
                  field.model = model
                }
                return field
              })
              return fields.length ? (
                <FieldGroup
                  gidx={gidx}
                  groupname={group.name}
                  match={match}
                  card={group.card}
                  background
                  classes={config.fieldgroups[group.name].classes}
                  fields={fields}
                  active_portals={this.state.active_portals}
                  required={this.state.required}
                  quality={this.state.quality}
                  webedit={this.state.webedit}
                  collapsed={this.state.collapsed}
                  setIgnoreFields={this.setIgnoreFields}
                  setInitVals={this.setInitVals}
                />
              ) : null
            })}
          </Tab>
        )
        ) }
      </HorizontalTabs>
    )
  }

  renderGroups() {
    const { config, match, model } = this.props
    if (!config.fieldgroups) { return null }
    return Object.keys(config.fieldgroups).map((group, gidx) => {
      const current = slugify(group) === this.state.currentGroup.id
      let classes = config.fieldgroups[group].classes || ''
      if (current) {
        classes = `${classes} active`
      }
      return (
        <FieldGroup
          key={`fg-${gidx}`}
          id={slugify(group)}
          groupname={group}
          gidx={gidx}
          match={match}
          model={model}
          columns={config.fieldgroups[group].columns}
          classes={classes}
          fields={config.fields}
          active_portals={this.state.active_portals}
          required={this.state.required}
          quality={this.state.quality}
          webedit={this.state.webedit}
          collapsed={this.state.collapsed}
          setIgnoreFields={this.setIgnoreFields}
          setInitVals={this.setInitVals}
        />
      )
    })
  }

  selectReferralManager(cb, form) {
    const { cache } = this.props
    const recipients = getIn(form.values, 'recipients')
    const new_recipients = recipients?.map(recipient => {
      const r = { ...recipient }
      if (r.recipient_branch && !r.recipient_agent) {
        const branch = getIn(cache, `branches.${r.recipient_branch}`)
        if (branch?.referrals_managers?.length) {
          r.recipient_agent = branch.referrals_managers[0]
        }
      }
      return r
    })
    form.setFieldValue('recipients', new_recipients).then(() => {
      form.setFieldTouched('recipients', false, false)
    })
  }

  render() {
    const { modelname } = this.props.config
    const { config, user, model, modelaction } = this.props
    const hasTabs = !!config.tabs
    if (!this.state.init || (!model && modelaction === 'duplicate')) { return <Loader /> }
    return (
      <Formik
        initialValues={{
          ...this.state.initvals,
          modelname: modelname,
          endpoint: config.endpoint
        }}
        initialTouched={{}}
        validationSchema={getIn(validate, `${user.agent.site.region}.${modelname}`, getIn(validate, `default.${modelname}`))}
        validateOnChange={false}
        validateOnBlur={getIn(this.form, 'submitCount') > 0 && getIn(this.form, 'errors') ? true : false}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}
      >{formik => {
          this.form = formik
          return (
            <CustomForm
              model={this.props.model ? true : false}
              autosave
              className={this.props.className}
              actions={this.actions}
              mode="add"
              modelname={config.modelname}
              userid={user.agent.id}
              onChange={(changes, form) => {
                if (Object.keys(form.touched).length > 0) { // Only fire when touched
                  let defaultchanges = 0
                  changes.forEach(changedfield => {
                    if (Object.keys(form.touched).includes(changedfield) && changedfield in form.values) {
                      config.fields.filter(f => {
                        if (Object.hasOwn(f, 'defaultvalue')) { defaultchanges += 1 }
                      })
                      config.fields.filter(f => f.name === changedfield && f.onchange).forEach(cb => {
                        this.actions[cb.onchange](cb, form) // Perform the required action
                      })
                    }
                  })
                  if (changes.length > 0 && changes.length > defaultchanges) {
                    clearTimeout(this.timers.create_autosave)
                    this.timers.create_autosave = setTimeout(() => this.actions.autosaveForm({
                      userid: user.agent.id,
                      modelname: config.modelname,
                      mode: 'add',
                      values: form.values
                    }),
                    3000)
                  }
                }
                // enable default portals if there are any and website display has been set and feed_to_portals exists in the config ie. not projects
                const feed_to_portals = this.props.config.fields?.find(f => f.name === 'feed_to_portals')
                if (
                  [ 'residential', 'commercial', 'projects', 'holiday' ].includes(modelname) &&
                  form.values.status === 'Active' &&
                  form.values.branch &&
                  changes.includes('display_on_website') &&
                  feed_to_portals &&
                  this.isConditional(feed_to_portals, 'edit', false, form)
                ) {
                  const defaulted_portals = []
                  getIn(this.props.cache.settings, `${this.props.user.agent.site.id}.portals.agency`).filter(p => p.default_new_listings && p.active).forEach(p => {
                    const global_portal = getIn(this.props.cache.settings, `${this.props.user.agent.site.id}.portals.global`).find(g => g.id === p.portal && g.active)
                    const branch_portal = getIn(this.props.cache.settings, `${this.props.user.agent.site.id}.portals.branch`).find(b => b.branch_id === form.values.branch && b.portal === p.portal && b.active)
                    if (global_portal && (!global_portal.branch_configured || branch_portal)) {
                      const slug = this.props.cache.portals[p.id].meta.portal.slug
                      defaulted_portals.push(slug)
                    }
                  })
                  if (defaulted_portals) {
                    form.setFieldTouched('feed_to_portals', true).then(() => {
                      form.setFieldValue('feed_to_portals', form.values.display_on_website)
                    })
                    if (form.values.display_on_website) {
                      defaulted_portals.forEach(slug => {
                        if (!getIn(form.touched, `portals.${slug}.active`)) {
                          form.setFieldTouched(`portals.${slug}.active`, true).then(() => {
                            form.setFieldValue(`portals.${slug}.active`, true)
                          })
                        }
                      })
                    }
                  }
                }
              }}
              render={() => (
                <>
                  <div className="viewhead">
                    <ModelActions
                      isSubmitting={formik.isSubmitting}
                      redirectSchema={this.redirectSchema}
                      actions={this.actions}
                      statusmsg={formik.status ? formik.status.msg : false}
                      modelname={this.props.config.modelname}
                    >
                      {this.state.showActions && (
                        <>
                          <div className="card-legend">
                            {config.legend ?
                              config.legend.map((legend, ind) => (
                                <legend
                                  key={`leg-${ind}`}
                                  onClick={e => {
                                    if (legend.action) {
                                      return this[legend.action](e)
                                    }
                                    return null
                                  }}
                                  className={classNames(legend.className, 'btn btn-white btn-icon-left', {
                                    active: getIn(this.state, legend.className)
                                  })}
                                >
                                  Show {legend.label.toLowerCase()} fields only
                                </legend>
                              )) : null}
                          </div>
                          <span ref={el => (this.cardtoggle = el)} className="cardtoggle-required" >
                            {[ 'residential', 'commercial', 'holiday' ].includes(config.modelname) ? (
                              <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleWebEdit} icon={this.state.webedit ? '#icon16-ListAlt' : '#icon16-WebsiteListing'}>
                                {this.state.webedit ? 'Show all fields' : 'Show website listing fields' }
                              </Button>
                            ) : null}
                            <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleCollapsed} icon='#icon16-Collapse'>
                              {this.state.collapsed ? 'Expand groups' : 'Collapse groups' }
                            </Button>
                          </span>
                        </>
                      )}
                    </ModelActions>
                  </div>
                  <div className={`view model${!hasTabs ? ' model-form' : ''}`}>
                    { !hasTabs && this.renderList() }
                    <div ref={el => (this.scroller = el)} className="viewcontent">
                      <div className="view-list">
                        {(!this.state.showActions) && <div className="card-meta">
                          <div className="card-toggles">
                            <div className="card-legend">
                              {config.legend ?
                                config.legend.map((legend, ind) => (
                                  <legend
                                    key={`leg-${ind}`}
                                    onClick={e => {
                                      if (legend.action) {
                                        return this[legend.action](e)
                                      }
                                      return null
                                    }}
                                    className={classNames(legend.className, 'btn btn-white btn-icon-left', {
                                      active: getIn(this.state, legend.className)
                                    })}
                                  >
                                      Show {legend.label.toLowerCase()} fields only
                                  </legend>
                                )) : null}
                            </div>
                            <span className="cardtoggle-required">
                              {[ 'residential', 'commercial', 'holiday' ].includes(config.modelname) ? (
                                <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleWebEdit} icon={this.state.webedit ? '#icon16-ListAlt' : '#icon16-WebsiteListing'}>
                                  {this.state.webedit ? 'Show all fields' : 'Show website listing fields' }
                                </Button>
                              ) : null}
                              <Button type="button" className="btn btn-white btn-icon-left btn-icon-16" onClick={this.toggleCollapsed} icon='#icon16-Collapse'>
                                {this.state.collapsed ? 'Expand groups' : 'Collapse groups' }
                              </Button>
                            </span>
                          </div>
                        </div>}
                        { !hasTabs && this.renderGroups() }
                        { hasTabs && this.renderTabs() }
                      </div>
                    </div>
                  </div>
                </>
              )}
            />
          )
        }}
      </Formik>
    )
  }
}

ModelAdd.propTypes = {
  routeConfig: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  cache: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  portals: PropTypes.object,
  modelname: PropTypes.string,
  globalportals: PropTypes.array,
  addons: PropTypes.array,
  model: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
  sourcemodel: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
  actions: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  className: PropTypes.string,
  modelaction: PropTypes.string,
  modelid: PropTypes.number
}

export default ModelAdd
