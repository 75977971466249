import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import merge from 'deepmerge'
import classnames from 'classnames'

import country_codes from '../../../config/countrycodes.json'
import extras from '../../../config/extras.json'
import templates from '../../../config/template.json'
import vehicles from '../../../config/vehicle.json'
import tags from '../../../config/tag.json'
import { composeOptionLabel, hasAddons, hasPermission, isConditional, parseURL, sortBy, uniqueArray } from '../../../utils'
import QueryBuilder from '../QueryBuilder'
import HelpText from '../HelpText'
import { Button } from '../../ui/Button'
import AssetLookupInput from './inputs/AssetLookup'
import AssociationsInput from './inputs/Associations'
import AsyncCreateSelectInput from './inputs/AsyncCreateSelect'
import AsyncSelectInput from './inputs/AsyncSelect'
import CheckInput from './inputs/Check'
import CheckGroupInput from './inputs/CheckGroup'
import CheckNotesInput from './inputs/CheckNotes'
import ColorPickerInput from './inputs/ColorPicker'
import CommissionTableInput from './inputs/CommissionTable'
import ConflictDeselectInput from './inputs/ConflictDeselect'
import ContactLookupInput from './inputs/ContactLookup'
import CountryCodePhoneInput from './inputs/CountryCodePhone'
import CreateSelectInput from './inputs/CreateSelect'
import CurrencyInput from './inputs/Currency'
import DateInput from './inputs/Date'
import DeedsLookupInput from './inputs/DeedsLookup'
import DependentSelectInput from './inputs/DependentSelect'
import ExtrasInput from './inputs/Extras'
import FieldArrayInput from './inputs/FieldArray'
import FieldListInput from './inputs/FieldList'
import FileDropzoneInput from './inputs/FileDropzone'
import FileUploadInput from './inputs/FileUpload'
import FloatInput from './inputs/Float'
import GallerySelectorInput from './inputs/GallerySelector'
import HiddenInput from './inputs/Hidden'
import ImageCropInput from './inputs/ImageCrop'
import ListingLookupInput from './inputs/ListingLookup'
import LocationSelectInput from './inputs/LocationSelect'
import MapInput from './inputs/Map'
import OnShowEventsInput from './inputs/OnShowEvents'
import PreviewInput from './inputs/Preview'
import SelectInput from './inputs/Select'
import SelectNotesInput from './inputs/SelectNotes'
import ServicedAttributeLookupInput from './inputs/ServicedAttributeLookup'
import EditorInput from './inputs/LexicalEditor'
import SortableTextsInput from './inputs/SortableTexts'
import TagManagerInput from './inputs/TagManager'
import RecipientLookupInput from './inputs/RecipientLookup'
import SnippetManagerInput from './inputs/SnippetManager'
import VehicleManagerInput from './inputs/VehicleManager'
import TemplateManagerInput from './inputs/TemplateManager'
import TextInput from './inputs/Text'
import TextAreaInput from './inputs/TextArea'
import TranslatableTextAreaInput from './inputs/TranslatableTextArea'
import TextNotesInput from './inputs/TextNotes'
import Tip from './Tip'
import SectionHeading from './SectionHeading'
import ParkingRatioInput from './inputs/ParkingRatio'
import SizeBreakdownsInput from './inputs/SizeBreakdowns'
import LandUseBreakdownsInput from './inputs/LandUseBreakdowns'
import URLTemplateInput from './inputs/URLTemplate'
import AsyncCheckGroupInput from './inputs/AsyncCheckGroup'
import CodeEditorInput from './inputs/CodeEditor'
import { VersionList as VersionListInput } from './inputs/version-list'
import RadioGroupInput from './inputs/RadioGroup'
import TimeInput from './inputs/Time'
import VacancyProInput from './inputs/VacancyProLookup'
import UrlOrUploadInput from './inputs/UrlOrUpload'
import { formikUseField } from './customFormikUseField'


const AssetLookup = formikUseField(AssetLookupInput)
const Associations = formikUseField(AssociationsInput)
const AsyncCreateSelect = formikUseField(AsyncCreateSelectInput)
const AsyncSelect = formikUseField(AsyncSelectInput)
const AsyncCheckGroup = formikUseField(AsyncCheckGroupInput)
const Check = formikUseField(CheckInput)
const CheckGroup = formikUseField(CheckGroupInput)
const CheckNotes = formikUseField(CheckNotesInput)
const ColorPicker = formikUseField(ColorPickerInput)
const CommissionTable = formikUseField(CommissionTableInput)
const ConflictDeselect = formikUseField(ConflictDeselectInput)
const ContactLookup = formikUseField(ContactLookupInput)
const CountryCodePhone = formikUseField(CountryCodePhoneInput)
const CreateSelect = formikUseField(CreateSelectInput)
const Currency = formikUseField(CurrencyInput)
const Date = formikUseField(DateInput)
const DeedsLookup = formikUseField(DeedsLookupInput)
const DependentSelect = formikUseField(DependentSelectInput)
const Extras = formikUseField(ExtrasInput)
const FieldArray = formikUseField(FieldArrayInput)
const FieldList = formikUseField(FieldListInput)
const FileDropzone = formikUseField(FileDropzoneInput)
const FileUpload = formikUseField(FileUploadInput)
const Float = formikUseField(FloatInput)
const GallerySelector = formikUseField(GallerySelectorInput)
const Hidden = formikUseField(HiddenInput)
const ImageCrop = formikUseField(ImageCropInput)
const ListingLookup = formikUseField(ListingLookupInput)
const LocationSelect = formikUseField(LocationSelectInput)
const Map = formikUseField(MapInput)
const OnShowEvents = formikUseField(OnShowEventsInput)
const Preview = formikUseField(PreviewInput)
const RecipientLookup = formikUseField(RecipientLookupInput)
const Select = formikUseField(SelectInput)
const SelectNotes = formikUseField(SelectNotesInput)
const ServicedAttributeLookup = formikUseField(ServicedAttributeLookupInput)
const Editor = memo(EditorInput)
const SortableTexts = formikUseField(SortableTextsInput)
const TagManager = formikUseField(TagManagerInput)
const SnippetManager = formikUseField(SnippetManagerInput)
const VehicleManager = formikUseField(VehicleManagerInput)
const TemplateManager = formikUseField(TemplateManagerInput)
const Text = formikUseField(TextInput)
const TextArea = formikUseField(TextAreaInput)
const TranslatableTextArea = formikUseField(TranslatableTextAreaInput)
const TextNotes = formikUseField(TextNotesInput)
const SizeBreakdowns = formikUseField(SizeBreakdownsInput)
const LandUseBreakdowns = formikUseField(LandUseBreakdownsInput)
const URLTemplate = formikUseField(URLTemplateInput)
const CodeEditor = formikUseField(CodeEditorInput)
const RadioGroup = formikUseField(RadioGroupInput)
const Time = formikUseField(TimeInput)
const VacancyPro = formikUseField(VacancyProInput)
const VersionList = formikUseField(VersionListInput)
const ParkingRatio = formikUseField(ParkingRatioInput)
const UrlOrUpload = formikUseField(UrlOrUploadInput)


export function switchInput (field = false) { // Need to use old school function here as we bind 'this'
  const s = { ...field }
  const { user, cache, actions, modelname, match, addons, form } = this.props
  const { agent } = user || {}
  const { site } = agent || {}
  const settings = getIn(cache, `settings.${site.id}`, {})
  const permissions = getIn(user, 'permissions', [])
  if (s.edit) { s.edit = s.edit.toString() }
  switch (field.input) {
    case 'AssetLookup':
      s.actions = actions
      s.cache = cache
      s.component = AssetLookup
      s.settings = settings
      s.user = this.props.user
      s.modelname = this.props.modelname
      s.modelid = this.props.modelid
      s.initialValue = getIn(this.props.form.initialValues, s.name)
      break
    case 'AsyncCreateSelect':
      s.component = AsyncCreateSelect
      s.user = {
        permissions
      }
      if (field.extraparams && this.props.form) { // Used for form submission
        s.params = parseURL(
          field.extraparams,
          this.props.form.values,
          agent,
          settings,
          cache
        )
      }
      s.actions = {
        cacheDelta: actions.cacheDelta
      }
      break
    case 'AsyncSelect':
      s.component = AsyncSelect
      if (field.extraparams && this.props.form) { // Used for form submission
        s.params = parseURL(
          field.extraparams,
          this.props.form.values,
          agent,
          settings,
          cache,
          0, // index
          field
        )
        if (field.limit && this.props.form && this.props.form.status) {
          Object.keys(field.limit).forEach(f => {
            const limitfield = field.limit[f]
            if (this.props.form.status[f] && this.isConditional(limitfield, 'conditions', false, this.props.form)) {
              s.params += `&${limitfield.param}=${this.props.form.status[f].toString()}`
            }
          })
        }
      }
      s.actions = {
        cacheDelta: actions.cacheDelta
      }
      s.modelname = this.props.modelname
      break
    case 'AsyncCheckGroup':
      s.component = AsyncCheckGroup
      if (field.extraparams && this.props.form) { // Used for form submission
        s.params = parseURL(
          field.extraparams,
          this.props.form.values,
          agent,
          settings,
          cache,
          0, // index
          field
        )
        if (field.limit && this.props.form && this.props.form.status) {
          Object.keys(field.limit).forEach(f => {
            const limitfield = field.limit[f]
            if (this.props.form.status[f] && this.isConditional(limitfield, 'conditions', false, this.props.form)) {
              s.params += `&${limitfield.param}=${this.props.form.status[f].toString()}`
            }
          })
        }
      }
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.fetchP24Urls = actions.fetchP24Urls
      s.settings = settings
      s.modelname = this.props.modelname
      s.actions = {
        cacheDelta: actions.cacheDelta
      }
      break
    case 'Check':
      s.component = Check
      s.copyfrom = field.copyfrom
      s.copyto = field.copyto
      s.modelname = this.props.modelname
      s.type = 'checkbox'
      s.readonly = field.permissions && user ? (
        !hasPermission(field.permissions, permissions)
      ) : false
      if (field.required) { s.classes += ' required' }
      if (field.readonly) { s.classes += ' disabled' }
      if (field.linkedfield) {
        s.domain = cache.settings[site.id].domain
      }
      break
    case 'CheckNotes':
      s.component = CheckNotes
      s.type = 'checkbox'
      s.modelname = this.props.modelname
      break
    case 'CheckGroup':
      s.component = CheckGroup
      if (field.required) { s.classes += ' required' }
      if (field.readonly) { s.classes += ' disabled' }
      break
    case 'Currency':
      s.component = Currency
      s.actions = {}
      s.actions[s.onBlur] = actions[s.onBlur]
      break
    case 'ColorPicker':
      s.component = ColorPicker
      break
    case 'ConflictDeselect':
      s.component = ConflictDeselect
      break
    case 'CommissionTable':
      s.component = CommissionTable
      s.actions = actions
      s.cache = cache
      s.renderField = this.renderField
      s.settings = settings
      s.user = this.props.user
      s.setInitVals = this.props.setInitVals
      break
    case 'ContactLookup':
      s.component = ContactLookup
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.settings = settings
      s.model = s.model ? s.model : null
      s.deeds_owner = this.props.form ? this.props.form.values.deeds_owner : false
      s.user = { permissions }
      if (field.extraparams && this.props.form) { // Used for form submission
        s.params = parseURL(
          field.extraparams,
          this.props.form.values,
          agent,
          settings,
          cache,
          0, // index
          field
        )
      }
      break
    case 'CreateSelect':
      s.component = CreateSelect
      s.user = {
        permissions
      }
      s.dependents = field.dependents // List of fields which depend on this one
      break
    case 'Date':
      s.component = Date
      s.defaultDate = field.defaultDate
      break
    case 'DeedsLookup':
      s.component = DeedsLookup
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.setInitVals = this.props.setInitVals
      break
    case 'VacancyProLookup':
      s.component = VacancyPro
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.fetchVacancyPro = actions.fetchVacancyPro
      break
    case 'DependentSelect':
      s.component = DependentSelect
      s.modelname = modelname
      break
    case 'Editor':
      s.component = Editor
      s.modelname = modelname
      s.uploadFile = actions.uploadFile
      s.fetchMany = actions.fetchMany
      s.settings = settings
      s.user = this.props.user
      s.location = form && form.values && form.values.location ? form.values.location : false
      break
    case 'FieldArray':
      s.actions = actions
      s.cache = cache
      s.component = FieldArray
      s.renderField = this.renderField
      s.user = this.props.user
      s.setInitVals = this.props.setInitVals
      break
    case 'GallerySelector':
      s.actions = actions
      s.cache = cache
      s.component = GallerySelector
      s.user = this.props.user
      s.modelname = this.props.modelname
      s.modelid = this.props.modelid
      s.initialValue = getIn(this.props.form.initialValues, s.name)
      break
    case 'FieldList':
      s.actions = actions
      s.cache = cache
      s.component = FieldList
      s.renderField = this.renderField
      s.user = this.props.user
      break
    case 'FileDropzone':
      s.component = FileDropzone
      s.modelname = modelname
      s.uploadFile = actions.uploadFile
      s.downloadImages = actions.downloadImages
      s.fetchMany = actions.fetchMany
      s.changeCaption = actions.changeCaption
      s.changeDate = actions.changeDate
      s.rotateImage = actions.rotateImage
      s.notifyUser = actions.notifyUser
      if (
        getIn(cache, `settings.${site.id}.portals.agency`)
      ) {
        const portals = getIn(cache, `settings.${site.id}.portals.agency`, []).map(p => {
          const pconf = getIn(cache, `settings.${site.id}.portals.global`, []).find(pc => pc.id === p.portal)
          return pconf
        })
        s.portals = sortBy(portals, 'id')
      }
      break
    case 'FileUpload':
      s.component = FileUpload
      s.modelname = modelname
      s.uploadFile = actions.uploadFile
      break
    case 'UrlOrUpload':
      s.component = UrlOrUpload
      s.modelname = modelname
      s.uploadFile = actions.uploadFile
      break
    case 'Button':
      s.component = Button
      s.modelname = modelname
      s.actions = {
        ...actions,
        copyTo: ({
          args,
          form: formik
        }) => {
        // eslint-disable-next-line no-console
          const { from, to } = args
          this.props.setInitVals({ ...formik.values, [to]: getIn(formik.values, from) })
        }
      }
      break
    case 'Float':
      s.component = Float
      s.actions = {}
      s.actions[s.onBlur] = actions[s.onBlur] || null
      break
    case 'Hidden':
      s.component = Hidden
      break
    case 'ImageCrop':
      s.component = ImageCrop
      s.modelname = modelname
      s.uploadFile = actions.uploadFile
      s.notifyUser = actions.notifyUser
      s.site = site
      break
    case 'Preview':
      s.component = Preview
      break
    case 'RadioGroup':
      s.component = RadioGroup
      s.options = field.options
      break
    case 'ListingLookup':
      s.component = ListingLookup
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.settings = settings
      s.modelname = this.props.modelname
      s.user = { permissions }
      s.currency = settings.default_currency
      if (field.extraparams && this.props.form) { // Used for form submission
        s.params = parseURL(
          field.extraparams,
          this.props.form.values,
          agent,
          settings,
          cache,
          0, // index
          field
        )
      }
      break
    case 'RecipientLookup':
      s.component = RecipientLookup
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.updateMailRecipients = actions.updateMailRecipients
      s.settings = settings
      s.modelname = this.props.modelname
      s.user = { permissions }
      if (field.extraparams && this.props.form) { // Used for form submission
        s.params = parseURL(
          field.extraparams,
          this.props.form.values,
          agent,
          settings,
          cache,
          0, // index
          field
        )
      }
      break
    case 'Select':
      s.component = Select
      s.dependents = field.dependents // List of fields which depend on this one
      s.user = this.props.user
      break
    case 'SelectNotes':
      s.component = SelectNotes
      s.fetchMany = actions.fetchMany
      s.dependents = field.dependents // List of fields which depend on this one
      break
    case 'ServicedAttributeLookup':
      s.component = ServicedAttributeLookup
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.modelname = modelname
      s.form = this.props.form
      break
    case 'SortableTexts':
      s.component = SortableTexts
      break
    case 'TagManager':
      s.component = TagManager
      s.cache = cache
      s.user = user
      s.config = tags.config
      s.configs = this.props.configs
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.createModel = actions.createModel
      s.updateModel = actions.updateModel
      s.deleteModel = actions.deleteModel
      s.cacheDelta = actions.cacheDelta
      s.toggleWideSidebar = actions.toggleWideSidebar
      break
    case 'SnippetManager':
      s.component = SnippetManager
      s.cache = cache
      s.user = user
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.createModel = actions.createModel
      s.updateModel = actions.updateModel
      s.deleteModel = actions.deleteModel
      s.toggleWideSidebar = actions.toggleWideSidebar
      break
    case 'VehicleManager':
      s.component = VehicleManager
      s.cache = cache
      s.user = user
      s.config = vehicles.config
      s.configs = this.props.configs
      s.location = this.props.location
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.createModel = actions.createModel
      s.updateModel = actions.updateModel
      s.deleteModel = actions.deleteModel
      s.bulkEditModel = actions.bulkEditModel
      break
    case 'TemplateManager':
      s.component = TemplateManager
      s.cache = cache
      s.user = user
      s.config = templates.config
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.createModel = actions.createModel
      s.updateModel = actions.updateModel
      s.deleteModel = actions.deleteModel
      s.bulkEditModel = actions.bulkEditModel
      break
    case 'TextArea':
      s.cache = cache
      s.component = TextArea
      s.modelname = modelname
      s.fetchMany = actions.fetchMany
      s.toggleWideSidebar = actions.toggleWideSidebar
      s.location = form && form.values && form.values.location ? form.values.location : false
      if (
        getIn(cache, `settings.${site.id}.portals.agency`)
      ) {
        const portals = getIn(cache, `settings.${site.id}.portals.agency`, []).map(p => {
          const pconf = getIn(cache, `settings.${site.id}.portals.global`, []).find(pc => pc.id === p.portal)
          return pconf
        })
        s.portals = sortBy(portals, 'id')
      }
      if (s.clevercompose) {
        s.clevercompose = this.isConditional(s, 'clevercompose', false, this.props.form)
      }
      break
    case 'TranslatableTextArea':
      s.actions = actions
      s.component = TranslatableTextArea
      s.modelname = modelname
      s.cache = cache
      s.user = user
      s.location = form && form.values && form.values.location ? form.values.location : null
      if (
        getIn(cache, `settings.${site.id}.portals.agency`) && ([ 'residential', 'commercial', 'holiday' ].includes(modelname))
      ) {
        const portals = getIn(cache, `settings.${site.id}.portals.agency`, []).map(p => {
          const pconf = getIn(cache, `settings.${site.id}.portals.global`, []).find(pc => pc.id === p.portal)
          return pconf
        })
        s.portals = sortBy(portals, 'id')
      }
      break
    case 'CodeEditor':
      s.component = CodeEditor
      break
    case 'VersionList':
      s.actions = {
        ...s.actions,
        updateModel: actions.updateModel,
        registerRedirect: actions.registerRedirect
      }
      s.component = VersionList
      s.user = user
      s.match = match
      s.model = getIn(cache, `${modelname}.${match.params.id}`, null)
      break
    case 'TextNotes':
      s.component = TextNotes
      s.fetchMany = actions.fetchMany
      break
    case 'LocationSelect':
      s.component = LocationSelect
      s.cache = cache
      s.user = user
      if (field.extraparams && this.props.form) { // Used for form submission
        s.params = parseURL(
          field.extraparams,
          this.props.form.values,
          agent,
          settings
        )
      }
      if (field.limit && this.props.form && this.props.form.status) {
        Object.keys(field.limit).forEach(f => {
          const limitfield = field.limit[f]
          if (this.props.form.status[f] && this.isConditional(limitfield, 'conditions', false, this.props.form)) {
            s.params += `&${limitfield.param}=${this.props.form.status[f].toString()}`
          }
        })
      }
      if ([ 'locations', 'suburbs' ].includes(s.modelname) && field.locations) {
        s.force_filter = field.locations
      }
      if ([ 'areas' ].includes(s.modelname) && field.areas) {
        s.force_filter = field.areas
      }
      break
    case 'Map':
      s.component = Map
      s.settings = {
        gmaps_key: settings.gmaps_key,
        region: settings.region
      }
      break
    case 'OnShowEvents':
      s.actions = actions
      s.cache = cache
      s.component = OnShowEvents
      s.renderField = this.renderField
      s.user = user
      break
    case 'SizeBreakdowns':
      s.actions = actions
      s.cache = cache
      s.settings = settings
      s.component = SizeBreakdowns
      s.renderField = this.renderField
      s.user = user
      break
    case 'CountryCodePhone':
      s.component = CountryCodePhone
      s.options = country_codes.map(c => ({ label: `${c.dial_code} (${c.code})`, value: c.dial_code }))
      break
    case 'Associations':
      s.component = Associations
      s.fetchMany = actions.fetchMany
      s.fetchOne = actions.fetchOne
      s.cache = cache
      break
    case 'Extras': {
      s.component = Extras
      s.options = []
      if (
        getIn(cache, `settings.${site.id}.portals.agency`)
      ) {
        const portals = getIn(cache, `settings.${site.id}.portals.agency`, []).map(p => {
          const pconf = getIn(cache, `settings.${site.id}.portals.global`, []).find(pc => pc.id === p.portal)
          return pconf.slug
        })
        s.portals = sortBy(getIn(cache, `settings.${site.id}.portals.agency`, []).map(p => {
          const pconf = getIn(cache, `settings.${site.id}.portals.global`, []).find(pc => pc.id === p.portal)
          return pconf
        }), 'id')
        const parsedOptions = s._value?.split('\n') || []
        const removeOptions = []
        s.options = merge([], extras.options)
          .map(o => {
            if (o.options) {
              o.options = [ ...o.options ]
                .map(so => {
                  so.group = o.label
                  if (parsedOptions.includes(so.value)) {
                    removeOptions.push(so.value)
                  }
                  return so
                })
                .filter(so => (so.portals ? so.portals.some(p => portals.includes(p)) : true))
            }
            if (o.portals) {
              o.portals = o.portals.filter(p => portals.includes(p))
            }
            if (parsedOptions.includes(o.value)) {
              removeOptions.push(o.value)
            }
            return o
          })
          .filter(o => {
            if (o.portals) {
              return o.portals.some(p => portals.includes(p))
            }
            if (o.options) {
              return o.options.some(so => so.portals.some(p => portals.includes(p)))
            }
            return true
          })
        s.options = merge(
          s.options,
          parsedOptions.filter(o => !removeOptions.includes(o)).map(o => ({ value: o, label: o }))
        )
      }
      break
    }
    case 'Tip': {
      s.component = Tip
      s.classes += ' tip-input'
      break
    }
    case 'SectionHeading': {
      s.component = SectionHeading
      s.classes += ' section-heading-input'
      break
    }
    case 'HelpText':
      s.component = HelpText
      break
    case 'URLTemplate':
      s.component = URLTemplate
      break
    case 'ParkingRatio': {
      s.component = ParkingRatio
      s.actions = actions
      s.cache = cache
      s.renderField = this.renderField
      s.user = user
      break
    }
    case 'LandUseBreakdowns':
      s.actions = actions
      s.cache = cache
      s.settings = settings
      s.component = LandUseBreakdowns
      s.renderField = this.renderField
      s.user = user
      break
    case 'Time':
      s.component = Time
      break
    default:
      s.component = Text
  }
  if (s.options && addons) {
    s.options = s.options.filter(o => hasAddons(o.addons, addons))
  }
  if (s.options && user) {
    s.user = user
    s.cache = s.cache ? { ...s.cache, settings: cache.settings } : { settings: cache.settings }
    s.options = s.options.map(o => {
      if ('aidx' in s) {
        return {
          ...o,
          aidx: s.aidx,
          aname: s.aname,
          name: s.name
        }
      }
      return o
    }).filter(o => this.isConditional(o, 'show', true, this.props.form)).filter(o => hasPermission(o.permissions, permissions))
    s.options = uniqueArray(s.options, s.optionvalue || 'value')
  }
  if (s.onBlur && !(s.onBlur instanceof Function)) {
    if (!s.actions) {
      s.actions = {}
    }
    s.actions[s.onBlur] = actions[s.onBlur]
  }
  if (s.onChange && !(s.onChange instanceof Function)) {
    if (!s.actions) {
      s.actions = {}
    }
    s.actions[s.onChange] = actions[s.onChange]
  }
  return s
}

class FieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isConditional = isConditional.bind(this)
    this.switchInput = switchInput.bind(this)
    this.fetchInitial = false
    this.state = {
      init: false
    }
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { field } = this.props
    /* Below is how fields in edit mode which already have values
      * trigger a fetch to populate the cache with their respective
      * data which in turn triggers a render in the FieldGroup as
      * the watched data has been changed.
    */
    // let value = this.props.form ? this.props.form.values[field.name] : null
    // // Nested values need to be accommodated for as well
    const value = this.props.form ? getIn(this.props.form.values, field.name) : null
    const vals = composeOptionLabel(this.props.cache, value, field.optionlabel, field.labelseparator)
    if (field.edit && field.modelname && field._value && !vals && !this.fetchInitial && !field.config) {
      new Promise((resolve, reject) => {
        let params = {}
        if (field.params) {
          const query = new QueryBuilder(field.params)
          params = query.getAllArgs()
        }
        const related_values = Array.isArray(value) ? value : [ value ]
        params.get_all = true
        let fetch = false
        if (field.optionvalue) {
          params[`${field.optionvalue}__in`] = uniqueArray(related_values.filter(v => ![ undefined, null ].includes(v)))
          if (params[`${field.optionvalue}__in`].length) {
            fetch = true
          }
        } else {
          params.id__in = uniqueArray(related_values.filter(v => ![ undefined, null, 'default' ].includes(v)))
          if (params.id__in.length) {
            fetch = true
          }
        }
        if (fetch) {
          const values = {
            modelname: field.modelname,
            labelseparator: field.labelseparator,
            labelformat: field.labelformat,
            conflict: true,
            noloader: true,
            signal: this.AbortController.signal,
            params
          }
          return this.props.actions.fetchMany({ values, resolve, reject })
        }
        return resolve()
      }).then(() => {
        if (this._is_mounted) {
          this.fetchInitial = true
          this.setState({ init: this.fetchInitial })
        }
      }).catch(e => {
        if (e.status !== 408) { console.error(e) }
      })
    } else {
      this.fetchInitial = true
      this.setState({ init: this.fetchInitial })
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  renderField(field, error, removeLabels = false) {
    /* Renders a specific field in a group and
     * decorates fields which are dependent on
     * others or which require callouts.
    */
    const { component: Field, ...specific } = this.switchInput(field)
    if (field.input === 'ContactLookup') {
      specific.setIgnoreFields = this.props.setIgnoreFields
    }
    if (field.input === 'ResetButton') {
      return null
    }
    if (field.input === 'Button') {
      return (
        <Field
          component={Button}
          type="button"
          icon={field.icon}
          className={classnames(field.classes)}
          onClick={() => {
            if (specific.actions[field.action]) {
              specific.actions[field.action]({
                type: field.action,
                id: field.id,
                args: field.args,
                label: field.label,
                form: this.props.form,
                setInitVals: this.props.setInitVals
              })
            }
          }}
        >{field.text}</Field>
      )
    }
    const props = { // Default input props
      ...specific, // Input field specific input props
      name: field.parent ? `${field.parent}.${field.name}` : field.name,
      label: (removeLabels) ? false : field.label,
      placeholder: field.placeholder ? field.placeholder : '',
      help: field.help,
      match: this.props.match,
      counter: field.counter,
      error,
      key: `${field.name}`
    }
    if (field.name.includes('{index}')) {
      props.name = field.name.replace('.{index}', '')
    }
    if (field.maxlen) { props.maxLength = field.maxlen }
    if (removeLabels) { props.placeholder = field.label }
    if (props.label && props.label.indexOf('Seller') !== -1 && this.props.form.values) {
      if (this.props.form.values.listing_type === 'To Let') {
        props.label = props.label.replace('Seller', 'Landlord')
      }
    }
    if ([ 'FieldArray', 'OnShowEvents' ].includes(field.input)) {
      const models = field.fields.filter(f => f.modelname).map(f => f.modelname)
      props.cache = {}
      models.forEach(m => { props.cache[m] = { ...this.props.cache[m] } })
      props.cache.settings = {}
      props.cache.settings[this.props.user.agent.site.id] = this.props.cache.settings[this.props.user.agent.site.id]
      props.allowdelete = field.allowdelete
    }
    if (field.modelname) { // This field references another model
      props.modelname = field.modelname
      props.dependent = field.dependent
      props.fetchMany = this.props.actions.fetchMany
      props.fetchOne = this.props.actions.fetchOne
      props.createModel = this.props.actions.createModel
      if (field.input !== 'LocationSelect') {
        props.cache = { ...this.props.cache[field.modelname] }
      }
    }
    if (this.props.meta) { props.meta = this.props.meta } // FileDropzone imagery meta
    if (props._required) {
      props.required = props._required === 'true'
    } else {
      props.required = this.isConditional(props, 'required', false, this.props.form)
    }
    if (field.readonly || (!('readonly' in specific) || specific.readonly === false)) {
      props.readonly = this.isConditional(field, 'readonly', false, this.props.form)
    }
    props.disabled = this.isConditional(field, 'disabled', false, this.props.form, this.props.user, this.props.cache)
    props.protected = this.isConditional(field, 'protected', false, this.props.form)
    props.orderable = field.orderable ? field.orderable.toString() : ''
    props.key = `field-${field.name}`
    props.id = `field-${field.name}`
    if (this.props.creator) { // Ensure unique field ids
      props.id = `field-${this.props.creator}-${field.name}`
    } else {
      props.id = `field-${field.name}`
    }
    if (![ 'FieldArray', 'OnShowEvents' ].includes(field.input)) {
      delete props.edit // Remove unwanted fields
      delete props.required
      delete props.quality
      delete props.orderable
      delete props.dedupe
    }
    if (this.props.force_filter) {
      props.force_filter = this.props.force_filter
    }
    delete props.detailCols
    if (props.tip) {
      const tips = { before: [], after: [] }
      props.tip.forEach((tip, tidx) => {
        const tipkey = `tip-${props.name}-${tidx}`
        if (tip.after) {
          tips.after.push(React.createElement(Tip, { key: tipkey, ...tip }))
        } else {
          tips.before.push(React.createElement(Tip, { key: tipkey, ...tip }))
        }
      })
      const tippedfield = []
      tips.before.forEach(tip => tippedfield.push(tip))
      tippedfield.push(React.createElement(Field, props))
      tips.after.forEach(tip => tippedfield.push(tip))
      return tippedfield
    }
    return React.createElement(Field, props)
  }

  render() {
    const { field, error, removeLabels } = this.props
    return this.renderField(field, error, removeLabels)
  }
}

FieldComponent.propTypes = {
  error: PropTypes.object,
  user: PropTypes.object,
  field: PropTypes.object,
  removeLabels: PropTypes.bool,
  form: PropTypes.object,
  force_filter: PropTypes.object,
  meta: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  cache: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  creator: PropTypes.string,
  setIgnoreFields: PropTypes.func,
  setInitVals: PropTypes.func
}

export default FieldComponent

// FieldComponent.whyDidYouRender = true
