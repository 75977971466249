/* eslint-disable no-nested-ternary */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Line, LineChart, CartesianGrid, Legend, XAxis, YAxis } from 'recharts'

// import { getRandomColor, valueFormat } from '../../utils'
import Card from '../common/Card'
import Loader from '../common/Loader'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import { useBreakPoint } from '../../hooks/useBreakPoint'
import { valueFormat } from '../../utils'


const CustomizedAxisTick = props => {
  const { x, y, payload, dayCount } = props
  if (payload.value === 'auto') { return payload.value }
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {dayCount === 13 ? valueFormat('monthyear', new Date(payload.value)) : null}
        {dayCount === 14 ? valueFormat('daymonth', new Date(payload.value)) : null}
        {dayCount === 30 || dayCount === 31 ? valueFormat('day', new Date(payload.value)) : null}
      </text>
    </g>
  )
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  dayCount: PropTypes.number,
  payload: PropTypes.object
}


const MarketingEmailEventDetailWidget = props => {
  const [ totals, setTotals ] = useState({})
  const keys = {
    delivered: 'Delivered',
    unsubscribe: 'Unsubscribed',
    open: 'Opened',
    click: 'Clicked',
    spamreport: 'Spam',
    bounce: 'Bounced',
    dropped: 'Dropped'
  }
  const showActions = useBreakPoint()

  const getColour = key => {
    if (key === 'delivered') {
      return '#70859E'
    } else if (key === 'unsubscribe') {
      return '#70859E'
    } else if (key === 'open') {
      return '#B2C2D4'
    } else if (key === 'click') {
      return '#FC495D'
    } else if (key === 'spamreport') {
      return '#10294D'
    } else if (key === 'bounce') {
      return '#10294D'
    } else if (key === 'dropped') {
      return '#10294D'
    }
    return '#FC495D'
  }

  useEffect(() => {
    if (props.statistics) {
      const t = {
        delivered: 0,
        unsubscribe: 0,
        open: 0,
        click: 0,
        spamreport: 0,
        bounce: 0,
        dropped: 0
      }
      // props.statistics.forEach(stat => {

      // })
      setTotals(t)
    }
  }, [ props.statistics ])

  return (
    <Card
      classes="grid-col-1"
      bodyclass="bar-graph"
      background
      body={
        <div className="stats grid-container grid-5">
          <div className={classNames('grid-col-5')}>
            {props.loading ? (
              <div className="empty flex-container" style={{ height: 300 }}><Loader inline className="large" /></div>
            ) : (
              // <BarGraph data={days} dataKeys={types}>
              //   {({ width }) => {
              //     const interval = width < 750 ?
              //       parseInt(days.length / parseInt(days.length * (width / 750), 10), 10) : 0
              //     return (
              //       <>
              //         <Legend verticalAlign="top" align="left" iconType="square" iconSize={9} formatter={renderColorfulLegendText} />
              //         <CartesianGrid vertical={false} stroke="#F3F5F8" />
              //         <XAxis interval={interval} axisLine={false} dataKey="name" tick={<CustomizedAxisTick dayCount={days.length} />} />
              //         <YAxis width={longestLabelLength(days, types)} axisLine={false} tick={{ stroke: '#B2C2D4', strokeWidth: 1 }} tickLine={{ stroke: 'none', strokeWidth: 1 }} />
              //         <Tooltip cursor={{ fill: '#FAFBFD' }} />
              //         {types.map((key, idx) => (
              //           <Bar key={`bar-${idx}`} barSize={10} dataKey={key} fill={getRandomColor(idx)} radius={[ 10, 10, 0, 0 ]} />
              //         ))}
              //       </>
              //     )
              //   }}
              // </BarGraph>
              <ResponsiveContainer width="100%" height={250} minWidth="0" legendWrap={!showActions}>{() => (
                <LineChart width={750} height={250} data={props.statistics}>
                  <Legend
                    wrapperStyle={{ width: showActions ? 'auto' : '100%', height: showActions ? '100%' : null, top: 0, left: 0 }}
                    iconType="circle"
                    iconSize={9}
                    layout={showActions ? 'vertical' : 'horizontal'}
                    verticalAlign={'top'} align="left"
                    // content={args => renderCustomizedLegend(args, currency)}
                    data={totals}
                  />
                  <CartesianGrid vertical={true} stroke="#F3F5F8" />
                  <XAxis
                    dataKey="date"
                    hasTick
                    padding="no-gap"
                    tickFormatter={date => valueFormat('daymonth', date)}
                    tick={{ fontSize: 9, stroke: '#B2C2D4', strokeWidth: 1 }}
                    tickLine={{ stroke: '#F3F5F8', strokeWidth: 1 }}
                    axisLine={{ stroke: '#F3F5F8', strokeWidth: 1 }}
                  />
                  <YAxis
                    width={60}
                    // width={longestLabelLength(props.statistics, keys.map(key => getLabel(key)), currency, font.current)}
                    hasTick
                    axisLine={false}
                    domain={[ 'dataMin', 'dataMax' ]}
                    tick={{ fontSize: 9, stroke: '#B2C2D4', strokeWidth: 1 }}
                    tickLine={{ stroke: 'none', strokeWidth: 1 }}
                    tickCount={9}
                    minTickGap={1}
                  />
                  {/* <Tooltip cursor={{ fill: '#FAFBFD' }} formatter={value => valueFormat('currency', value, { currency: currency })} labelFormatter={getDate} /> */}
                  {Object.keys(keys).map((key, idx) => (
                    <Line key={`bar-${idx}`} dataKey={key} strokeWidth={3} isAnimationActive={false} stroke={getColour(key)} />
                  ))}
                </LineChart>
              )}
              </ResponsiveContainer>
            )}
          </div>
        </div>
      }
    />
  )
}

export default MarketingEmailEventDetailWidget


MarketingEmailEventDetailWidget.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  index: PropTypes.number,
  model: PropTypes.object,
  cache: PropTypes.object,
  settings: PropTypes.object,
  config: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  match: PropTypes.object,
  statistics: PropTypes.array,
  modelid: PropTypes.number,
  loading: PropTypes.bool
}
