import merge from 'deepmerge'
import { getIn, Formik, Field } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { exportData } from '../../actions'
import country_codes from '../../config/countrycodes.json'
import { capitalize, generateAddress } from '../../utils'
import { Button } from '../ui/Button'
import Card from './Card'
import MetaDetail from './MetaDetail'
import MatchesForm from './ProfileMatchesForm'
import SimpleTable from './simpletable/SimpleTable'
import Tag from './Tag'
import WhatsAppButton from './WhatsAppButton'
import CheckGroup from './forms/inputs/CheckGroup'
import SelectInput from './forms/inputs/Select'
import CheckInput from './forms/inputs/Check'


const getContactNumber = profile => {
  let contact_number = profile.meta.contact.cell_number
  if (!contact_number && profile.meta.contact.work_number) {
    contact_number = profile.meta.contact.work_number
  }
  if (!contact_number && profile.meta.contact.home_number) {
    contact_number = profile.meta.contact.home_number
  }
  contact_number = contact_number && contact_number.match(/\d+/g) ? contact_number.match(/\d+/g).join('') : null
  if (contact_number && contact_number.startsWith('0')) {
    if (profile.meta.contact.meta.branch) {
      contact_number = contact_number.replace('0', getIn(profile, [ 'meta', 'contact', 'meta', 'branch', 'country_code' ], '0'))
    } else {
      const base_country = (profile.meta.areas && profile.meta.areas.length) ? profile.meta.areas[0].country : 'South Africa'
      const country = country_codes.find(c => c.name === base_country)
      contact_number = contact_number.replace('0', country.dial_code)
    }
  }
  return contact_number
}


const renderLocations = profile => {
  const { areas, suburbs } = profile.meta
  let locations = []
  let area_suburbs = []
  if (areas) {
    areas.forEach(area => {
      if (suburbs) {
        area_suburbs = suburbs.filter(suburb => suburb.area === area.area).map(suburb => (<Tag key={`loc-${area.id}-${suburb.id}`} value={[ area.area, suburb.suburb ].join(', ')} />))
      }
      if (area_suburbs.length) {
        locations = locations.concat(area_suburbs)
      } else {
        locations.push(<Tag key={`loc-${area.id}`} value={`${area.area}, All Suburbs`} />)
      }
    })
  }

  return locations
}

const Matches = ({ user, model, cache, configs, actions }) => {
  const [ selected, setSelected ] = useState([])
  const [ matches, setMatches ] = useState(model.matches)
  const [ email, setEmail ] = useState(false)
  const tableState = useRef()

  useEffect(() => {
    setEmail(null)
  }, [ selected.length ])

  const toggleEmailForm = (e, reset = false) => {
    if (e) { e.preventDefault() }
    setEmail(!email)
    if (reset) {
      setSelected([])
      tableState.current({ selected: [] })
    }
  }

  const generatePropertyMatches = () => {
    new Promise((resolve, reject) => {
      const cleanvalues = {
        id__in: selected.join(','),
        profile: model.id,
        profile_user: user.agent.id
      }
      const params = {
        params: cleanvalues,
        modelname: model.listing_model,
        args: {
          action: 'report',
          template: 'property-matches-report'
        },
        label: 'Property Matches',
        no_loader: true,
        resolve,
        reject
      }
      return actions.exportData(params)
    }).catch(e => {
      console.error(e)
    })
  }

  const generateAgentBroker = () => {
    new Promise((resolve, reject) => {
      const cleanvalues = {
        id__in: selected.join(','),
        profile: model.id,
        profile_user: user.agent.id
      }
      const params = {
        params: cleanvalues,
        modelname: model.listing_model,
        args: {
          action: 'report',
          template: 'property-matches-seller-report'
        },
        label: 'Property Matches (Agent)',
        no_loader: true,
        resolve,
        reject
      }
      return actions.exportData(params)
    }).catch(e => {
      console.error(e)
    })
  }

  const statistics = getIn(model, 'meta.statistics', {})
  const hot = getIn(statistics, 'hot_matches', 0)
  const mild = getIn(statistics, 'warm_matches', 0)
  const cold = getIn(statistics, 'cold_matches', 0)

  const contact_number = getContactNumber(model)

  const initial_fields = [
    {
      label: 'No.',
      name: 'idx',
      classes: 'text-right',
      orderable: false,
      format: 'number'
    },
    {
      label: <svg viewBox="0 0 32 32" className="btmstar"><use href="/images/glyphs.svg#glyph-Star" /></svg>,
      name: 'score',
      orderable: false,
      format: 'score'
    },
    {
      label: 'Web Ref',
      name: 'meta.listing.web_ref',
      orderable: false,
      format: 'listing_popup',
      link: '/secure/:site/:model/:id'
    },
    {
      label: 'Address',
      name: 'address',
      orderable: false,
      link: '/secure/:site/:model/:id'
    },
    {
      label: 'Date Added',
      name: 'created',
      orderable: true,
      format: 'datetime'
    },
    {
      label: 'Area',
      name: 'area',
      orderable: true,
      modelname: 'location',
      optionlabel: [ 'area' ]
    },
    {
      label: 'Suburb',
      name: 'suburb',
      orderable: true,
      modelname: 'location',
      optionlabel: [ 'suburb' ],
      labelseparator: ', '
    },
    {
      label: 'Branch',
      name: 'branch',
      orderable: true,
      modelname: 'branch',
      optionlabel: [ 'name' ],
      link: '/secure/:site/branches/:branch'
    },
    {
      label: 'Property Type',
      name: 'property_type',
      orderable: true
    }
  ]
  const residential_fields = [
    {
      label: 'Bedrooms',
      name: 'bedrooms',
      orderable: true,
      format: 'number'
    },
    {
      label: 'Bathrooms',
      name: 'bathrooms',
      orderable: true,
      format: 'number'
    }
  ]
  const commercial_fields = [
    {
      label: 'Zoning',
      name: 'zoning',
      orderable: true
    },
    {
      label: 'Land Size',
      name: 'land_size',
      orderable: true,
      format: 'measurement_type'
    }
  ]
  const end_fields = [
    {
      label: 'Floor Size',
      name: 'floor_size',
      orderable: true,
      format: 'measurement_type'
    },
    {
      label: 'Price',
      name: 'price',
      orderable: true,
      format: 'currency'
    }
  ]
  const settings = cache.settings[user.agent.site.id]
  const currency = settings.default_currency
  const modelid = model.id
  return (
    <div>
      <Card
        background
        header={
          <h3>Match Criteria</h3>
        }
        bodyclass="no-top-padding"
        body={
          <div>
            <div className="meta-group">
              <MetaDetail
                className="col-lg-6"
                label={'Contact'}
                value={model.meta && model.meta.contact ? `${model.meta.contact.first_name} ${model.meta.contact.last_name ? model.meta.contact.last_name : ''}` : null}
                field={{ name: 'profile_type' }}
                url={`/secure/${settings.id}/contacts/${model.contact}/details`}
              />
              <MetaDetail
                className="col-lg-6"
                label={'Profile Type'}
                value={model.profile_type}
                field={{ name: 'profile_type' }}
              />
              <MetaDetail
                className="col-lg-6"
                label={'Listing Type'}
                value={capitalize(model.listing_model)}
                field={{ name: 'listing_model' }}
              />
              <MetaDetail
                className="col-lg-6 tagged"
                label={'Locations'}
                render={() => renderLocations(model)}
                field={{ name: 'area' }}
              />
              <MetaDetail
                className="col-lg-6"
                label={'Property Types'}
                value={model.property_types && model.property_types.length ? model.property_types.join(', ') : 'Any'}
                field='property_types'
              />
              { model.listing_model === 'residential' ? (
                <MetaDetail
                  className="col-lg-6"
                  label={'Price Range'}
                  value={[ model.price_from, model.price_to ]}
                  format="price_range"
                  field={{ currency: currency, name: 'price_from' }}
                />
              ) : (
                <>
                  <MetaDetail
                    className="col-lg-6"
                    label={'Floor Size Range'}
                    value={[ model.floor_size_from, model.floor_size_to ]}
                    format="size_range"
                    field={{ name: 'land_size_from' }}
                  />
                  <MetaDetail
                    className="col-lg-6"
                    label={'Land Size Range'}
                    value={[ model.erf_size_from, model.erf_size_to ]}
                    format="size_range"
                    field={{ name: 'erf_size_from' }}
                  />
                </>
              )}
            </div>
          </div>
        }
      />

      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={() => { }}
      >{formikBag => (
          <>
            <Card
              background={true}
              bodyclass="no-top-padding"
              body={
                <>
                  <div className="match-filters form-row">
                    <div><h3>Filter Matches By</h3></div>
                    <div className="filters-container">
                      <div>
                        <Field
                          component={CheckGroup}
                          usenames
                          id="matches_on"
                          label="Matches on"
                          options={[
                            {
                              label: 'Property Type',
                              name: 'type_match'
                            },
                            {
                              label: 'Suburb',
                              name: 'suburb_match'
                            },
                            {
                              label: model.model === 'residential' ? 'Price Range' : 'Size Range',
                              name: 'criteria_match'
                            }
                          ]}
                        />
                      </div>
                      <div className="form-group check-input-group">
                        <Field
                          name="agent_only"
                          id="agent_only_field"
                          component={CheckInput}
                          type="checkbox"
                          label="Show only my profiles"
                        />
                      </div>
                      <div className="form-group score">
                        <Field
                          name="score"
                          id="score"
                          label="Matches score"
                          component={SelectInput}
                          multi
                          options={[
                            { value: 3, label: 'Hot Matches' },
                            { value: 2, label: 'Mild Matches' },
                            { value: 1, label: 'Cold Matches' }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }
            />
            <Card
              background
              classes="maxcard"
              header={
                <>
                  <h3 className="flex-heading">Listing Matches</h3>
                  <div className="details-section-buttons">
                    <p className="hotcoldcard">
                      <svg viewBox="0 0 32 32" className="btmstar smaller hot"><use href="/images/glyphs.svg#glyph-Star" /></svg>
                      <p className="hotcoldvalue">Hot({hot})</p>
                      <svg viewBox="0 0 32 32" className="btmstar smaller warm"><use href="/images/glyphs.svg#glyph-Star" /></svg>
                      <p className="hotcoldvalue" >Mild({mild})</p>
                      <svg viewBox="0 0 32 32" className="btmstar smaller cold"><use href="/images/glyphs.svg#glyph-Star" /></svg>
                      <p className="hotcoldvalueend" >Cold({cold})</p>
                    </p>

                    { (selected.length > 0) ? (
                      <div className="details-section-buttons">
                        {contact_number &&
                          <WhatsAppButton
                            className="btn btn-subtle"
                            type="button"
                            resetButtonStyle={false}
                            separator=" : "
                            phone={contact_number}
                            title="Properties matching your requirements"
                            url={`${settings.website_url}/results/all-properties/?custom_title=Properties Matching Your Requirements&listing_type=${capitalize(model.listing_model)} ${model.profile_type === 'Buyer' ? 'For Sale' : 'To Let'}&listing_ids=${selected.map(s => {
                              const listing = matches.find(m => m.id === s)
                              if (settings.is_eos3) {
                                const eos3 = listing.meta.portals.find(p => p.portal === 16)
                                return eos3 ? eos3.reference : null
                              }
                              return listing.id
                            }).filter(s => s).join(',')}`}
                          >
                            WhatsApp Match{selected.length > 1 ? 'es' : ''}
                          </WhatsAppButton>
                        }
                        <Button
                          type="button"
                          className="btn btn-subtle"
                          onClick={e => { toggleEmailForm(e, false) }}
                        >Email Match{selected.length > 1 ? 'es' : ''}</Button>
                        <Button
                          type="button"
                          className="btn btn-subtle"
                          onClick={generatePropertyMatches}
                        >Generate Matches PDF</Button>
                        <Button
                          type="button"
                          className="btn btn-subtle"
                          onClick={generateAgentBroker}
                        >Generate Agent/Broker PDF</Button>
                      </div>
                    ) : null}
                  </div>
                </>
              }
              bodyclass="no-top-padding"
              body={
                <>
                  {(email && selected.length > 0) ? (
                    <MatchesForm
                      selected={selected}
                      user={user}
                      model={model}
                      cache={cache}
                      toggleEmailForm={toggleEmailForm}
                    />
                  ) : null}
                  <SimpleTable
                    paginated
                    selectable
                    onSelect={selectData => {
                      setSelected(selectData.selected)
                      tableState.current = selectData.state
                    }}
                    config={configs[model.listing_model.toLowerCase()]}
                    action={actions.fetchMatches}
                    params={{
                      limit: 50,
                      profile: modelid,
                      meta_fields: [
                        'branch',
                        'agent',
                        'agent_2',
                        'agent_3',
                        'agent_4',
                        'portals',
                        'listing_images__0'
                      ],
                      ...formikBag.values
                    }}
                    parser={(data, prevData) => {
                      const newData = {
                        options: data.results.map(l => ({
                          ...l,
                          address: generateAddress(l)
                        })),
                        hasMore: !!data.next
                      }
                      setMatches(merge(newData.options, prevData ? prevData : []))
                      return newData
                    }}
                    header={
                      (model.listing_model === 'residential') ? (
                        [ ...initial_fields, ...residential_fields, ...end_fields ]
                      ) : (
                        [ ...initial_fields, ...commercial_fields, ...end_fields ]
                      )
                    }
                    user={user}
                    currency={currency}
                  />
                </>
              }
            />
          </>
        )}
      </Formik>
    </div>
  )
}

Matches.propTypes = {
  user: PropTypes.object,
  model: PropTypes.object,
  match_count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  loading_matches: PropTypes.bool,
  settings: PropTypes.object,
  cache: PropTypes.object,
  config: PropTypes.object,
  exportData: PropTypes.func,
  fetchMatches: PropTypes.func,
  alertAgentPropertyLead: PropTypes.func,
  currency: PropTypes.string,
  configs: PropTypes.object,
  actions: PropTypes.object
}

const mapDispatchToProps = dispatch => bindActionCreators({
  exportData
}, dispatch)

export default connect(null, mapDispatchToProps)(Matches)
