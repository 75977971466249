/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import ReactDOM from 'react-dom/client'
import {
  $setSelection
} from 'lexical'
import { $getSelection, COMMAND_PRIORITY_HIGH } from 'lexical'
import { mergeRegister } from '@lexical/utils'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { fetchMany } from '../../../../../../actions'
import SnippetModal from '../../../../modals/SnippetModal'
import { OPEN_SNIPPETS_COMMAND } from '../commands'


function positionEditorElement(editor, rect, root) {
  if (rect === null || root === null) {
    editor.style.opacity = '0'
    editor.style.top = '-1000px'
    editor.style.left = '-1000px'
  } else {
    const editorRect = root.getBoundingClientRect()
    editor.style.opacity = '1'
    editor.style.top = `${rect.top + rect.height - editorRect.top + 10}px`
    editor.style.left = `${rect.left - editorRect.left}px`
    const inner = editor.getBoundingClientRect()
    if (inner.right > window.innerWidth) {
      editor.style.left = `${rect.right - inner.width}px`
    }
  }
}

const SnippetsPlugin = props => {
  const [ editor ] = useLexicalComposerContext()
  const editorRef = useRef(null)
  const rootRef = useRef(null)
  const [ lastSelection, setLastSelection ] = useState(null)

  const rootEl = editor.getRootElement()
  const [ open, setOpen ] = useState(false)

  const updateSnippetEditor = useCallback(() => {
    const selection = $getSelection()
    const editorElem = editorRef.current
    const rootElement = editor.getRootElement()
    const nativeSelection = window.getSelection()
    if (
      selection !== null &&
      rootEl !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0)
      let rect = rootEl.getBoundingClientRect()
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement
        while (inner.firstElementChild !== null) {
          inner = inner.firstElementChild
        }
        const rootRect = inner.getBoundingClientRect()
        rect = {
          top: rootRect.top,
          height: 25,
          left: rootRect.left
        }
      } else if (domRange.commonAncestorContainer.classList && !domRange.commonAncestorContainer.classList.contains('editor-input') && domRange.commonAncestorContainer.getBoundingClientRect) {
        rect = domRange.commonAncestorContainer.getBoundingClientRect()
      } else if (!domRange.commonAncestorContainer.getBoundingClientRect) {
        rect = domRange.getBoundingClientRect()
      }
      positionEditorElement(editorElem, rect, rootEl)
      setLastSelection(selection.clone())
    }
  }, [ editor, editorRef, rootEl ])


  useEffect(() => {
    const onKeyDown = event => {
      if (event.ctrlKey && event.keyCode === 32) {
        editor.dispatchCommand(OPEN_SNIPPETS_COMMAND, event)
      }
    }
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateSnippetEditor()
        })
      }),
      editor.registerCommand(
        OPEN_SNIPPETS_COMMAND,
        event => {
          event.preventDefault()
          setOpen(true)
          if (!rootRef.current) {
            rootRef.current = ReactDOM.createRoot(editorRef.current)
          }
          if (!rootRef.current?._internalRoot) {
            rootRef.current = ReactDOM.createRoot(editorRef.current)
          }
          rootRef.current.render(
            <SnippetModal
              rootRef={rootRef.current}
              parentref={{ current: rootEl }}
              name={props.field.name}
              value={props.field.value}
              modelname={props.modelname}
              location={props.location}
              onSelect={snippet => {
                editor.update(() => {
                  if (lastSelection) {
                    $setSelection(lastSelection)
                  }
                  const selection = $getSelection()
                  if (selection && snippet) {
                    selection.insertRawText(snippet)
                  }
                })
                setOpen(false)
              }}
              editor={true}
              setSnippet={() => {
                setOpen(false)
                rootRef.current = null
              }}
              position={0}
              fetchMany={props.fetchMany}
            />
          )
          setTimeout(() => {
            document.querySelector('#snippet-search').focus()
          })
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerRootListener((rootElement, prevRootElement) => {
        if (prevRootElement !== null) {
          prevRootElement.removeEventListener('keydown', onKeyDown)
        }
        if (rootElement !== null) {
          rootElement.addEventListener('keydown', onKeyDown)
        }
      })
    )
  }, [ editor, editorRef, rootEl, lastSelection ])
  return <div ref={editorRef} id={`input-${props.field.name}-snippet-modal`} className={`snippet-modal${open ? ' showing' : ''}`}></div>
}

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchMany
}, dispatch)

export default connect(null, mapDispatchToProps)(withImmutablePropsToJS(SnippetsPlugin))
