import { ErrorMessage, getIn } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import React, { useRef } from 'react'
import HelpText from './../../HelpText'
import OnShowHistoryButton from './OnShowHistoryButton'
import Label from './Label'


/** Return the next value for a checkbox
 ** This is a modified version of the default checkbox code:
 ** https://github.com/jaredpalmer/formik/blob/0e0cf9ea09ec864dd63c52cf775f862795ef2cf4/packages/formik/src/Formik.tsx#L1153
 */
function getValueForCheckbox(
  currentValue,
  checked,
  type,
  valueProp
) {
  let currentArrayOfValues = []
  let isValueInArray = false
  let index = -1

  // If the currentValue was not a boolean we want to return an array
  if (!Array.isArray(currentValue)) {
    if (valueProp === undefined) {
      return checked
    } else if (type === 'radio') {
      return valueProp
    }
  } else {
    // If the current value is already an array, use it
    currentArrayOfValues = currentValue
    index = currentValue.indexOf(valueProp)
    isValueInArray = index >= 0
  }

  // If the checkbox was checked and the value is not already present in the array we want to add the new value to the array of values
  if (checked && valueProp !== undefined && !isValueInArray) {
    return currentArrayOfValues.concat(valueProp)
  }

  // If the checkbox was unchecked and the value is not in the array, simply return the already existing array of values
  if (!isValueInArray) {
    return currentArrayOfValues
  }

  // If the checkbox was unchecked and the value is in the array, remove the value and return the array
  return currentArrayOfValues
    .slice(0, index)
    .concat(currentArrayOfValues.slice(index + 1))
}

export const handleCheck = (e, field, form, props) => {
  if (props.readonly) { return null }
  const { dependents, copyto, copyfrom } = props
  if (dependents) { dependents.forEach(dependent => { form.setFieldValue(dependent, null, false) }) }

  const value = getValueForCheckbox(getIn(form.values, field.name), e.target.checked, props.type, props.value)

  form.setFieldValue(field.name, value).then(() =>
    form.setFieldTouched(field.name, true, true)
  )
  if (copyto) {
    if (!copyfrom) {
      copyto.forEach(copy => { form.setFieldValue(copy, value, false) })
    } else if (value) {
      copyto.forEach(copy => { form.setFieldValue(copy, getIn(form, `values.${copyfrom}`), false) })
    }
  }
  if (props.linkedfield) {
    const domain = props.domain
    const fictitious_email = value ? [ form.values.cell_number, domain ].join('@') : ''
    form.setFieldValue(props.linkedfield, fictitious_email, false)
  }

  return e
}

const CheckInput = ({ field, form, label, id, ...props }) => {
  const error = form.errors[field.name]
  let classes = `checkinput form-group ${props.classes ? props.classes : field.name}`
  if (props.inlinetip) { classes += ' inlinetip' }
  const el = useRef({ checked: !!field.value }) // Need to set initial state here
  return (
    <React.Fragment>
      <div id={id} className={classes}>
        <div className="forminput">
          {props.prefix}
          <label className={classNames('checkcontainer', { 'input-group-prefix': props.prefix, 'input-group-suffix': props.suffix || (field.name === 'on_show' && form.values.id), switch: props.switch, readonly: props.readonly })}>
            <div>
              <input
                id={field.name}
                className={getIn(el.current, 'checked') ? 'on' : 'off'}
                ref={el}
                {...field}
                onChange={e => handleCheck(e, field, form, props)}
                disabled={props.disabled || props.readonly ? 'disabled' : ''}
                type={props.type || 'checkbox'}
              />

              {props.switch ? (
                <span className="slider round"><span className="onoff"></span></span>
              ) : (
                <span className={`checkmark ${field.name}`}>
                  {getIn(el.current, 'checked') ? <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg> : null}
                </span>
              )
              }
            </div>

            {props.help &&
              <span className="help-text">
                {props.help.map((component, hid) => (<HelpText key={`ht-${field.name}-${hid}`} {...component} />))}
              </span>
            }
          </label>
          <Label className="formlabel" htmlFor={field.name}>{label}</Label>
          {props.suffix}

          { (field.name === 'on_show' && form.values.id && form.initialValues.on_show_events && form.initialValues.on_show_events.length > 0) ? (
            <OnShowHistoryButton
              form={form}
              modelname={props.modelname}
              modelid={form.values.id}
            />
          ) : null}
        </div>
      </div>

      {error && // Fix for errors on mobi being squashed up
        <div id={`${id}-error`} className={`${classes} error`}>
          <ErrorMessage component="div" className="error" name={field.name} />
        </div>
      }
    </React.Fragment>
  )
}

CheckInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
  action: PropTypes.string,
  help: PropTypes.array,
  modelname: PropTypes.string,
  id: PropTypes.string.isRequired,
  inlinetip: PropTypes.bool,
  switch: PropTypes.bool,
  defaultvalue: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array
  ]),
  readonly: PropTypes.bool,
  classes: PropTypes.string,
  prefix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  linkedfield: PropTypes.string,
  type: PropTypes.string
}

export default CheckInput
