/* eslint-disable new-cap */
import { LOCATION_CHANGE } from 'redux-first-history'
import { Map, fromJS, List, getIn } from 'immutable'

import config from '../config/config.json'
import { merger } from '../utils'


export default (newstate = fromJS(config.defaultState.cache), action) => {
  switch (action.type) {
    case 'UNSELECT_AGENT':
      return fromJS(config.defaultState.cache)

    case 'CREATE_PORTAL_SUCCESS':
      if (action.cache) {
        return merger(newstate, fromJS(action.cache))
      }
      return newstate

    case 'UPDATE_MODEL_SUCCESS':
    case 'UPDATE_HOME_PAGE_SUCCESS':
      return merger(newstate, fromJS(action.cache))

    case 'FETCH_ONE_SUCCESS':
      Object.keys(action.delta).forEach(k => {
        newstate = newstate.has(k) ? newstate : newstate.set(k, Map({}))
        Object.keys(action.delta[k]).forEach(id => {
          if (newstate.hasIn([ k, id ])) {
            if (!newstate.getIn([ k, id ]).equals(fromJS(action.delta[k][id]))) {
              newstate = newstate.setIn([ k, id ], fromJS(action.delta[k][id]))
            }
          } else {
            newstate = newstate.setIn([ k, id ], fromJS(action.delta[k][id]))
          }
        })
      })
      return newstate

    case 'FETCH_MANY_SUCCESS':
      // We need the merge function to ensure lists are not concat'ed
      if (!action.select) {
        if (action.modellist) {
          Object.keys(action.delta).forEach(k => {
            if (!newstate.get(k)) {
              newstate = newstate.set(k, Map({}))
            }
            Object.keys(action.delta[k]).forEach(id => {
              newstate = newstate.setIn([ k, id ], fromJS(action.delta[k][id]))
            })
          })
        } else {
          newstate = merger(newstate, fromJS(action.delta))
        }
        return newstate
      }
      return newstate

    case LOCATION_CHANGE: {
      const { location } = action.payload
      const path_parts = location.pathname.split('/')
      let modelname = getIn(path_parts, [ 3 ], '')
      const path_action = getIn(path_parts, [ 4 ], '')

      // don't do anything, we're not changing modules
      if (!path_parts.includes('secure')) { return newstate }
      if (path_parts.includes('secure') && path_parts.length === 3 && !modelname) {
        modelname = 'dashboard'
      }
      if (path_action) { return newstate }

      const keep = [ modelname ]
      if (!keep.includes('settings')) {
        keep.push('settings')
      }
      if (!keep.includes('globalportals')) {
        keep.push('globalportals')
      }
      if (!keep.includes('portals')) {
        keep.push('portals')
      }

      // clear cache of all keys except the "global" ones
      // and the ones needed for the current view
      if (modelname) {
        newstate.keySeq().toArray().forEach(key => {
          if (!keep.includes(key)) {
            newstate = newstate.set(key, Map({}))
          }
        })
      }
      return newstate
    }

    case 'FETCH_ACTIVITY_SUCCESS': {
      if (!newstate.has(action.modelname)) {
        newstate = newstate.set(action.modelname, Map())
      }
      if (!newstate.hasIn([ `${action.modelname}`, `${action.id}` ])) {
        newstate = newstate.setIn([ `${action.modelname}`, `${action.id}` ], fromJS({ id: action.id }))
      }
      const activity_results = Array.isArray(action.body) ? action.body : action.body.results
      let model = newstate.getIn([ `${action.modelname}`, `${action.id}` ])
      if (!model.has('activity')) {
        model = model.set('activity', fromJS(activity_results))
      }
      const current = model.get('activity')
      activity_results.forEach(activity => {
        const exists = current.findIndex(c => c.get('id') === activity.id)
        if (exists !== -1) {
          model = model.setIn([ 'activity', exists ], fromJS(activity))
        } else {
          model = model.updateIn([ 'activity' ], a => a.push(fromJS(activity)))
        }
      })
      const sorted = model.get('activity').sort((a, b) => {
        if (a.created < b.created) { return -1 }
        if (b.created < a.created) { return 1 }
        // b.created === a.created
        return 0
      })
      model = model.set('activity', fromJS(sorted))
      return newstate.setIn([ `${action.modelname}`, `${action.id}` ], model)
    }

    case 'FETCH_NOTES_SUCCESS': {
      let model = newstate.getIn([ `${action.modelname}`, `${action.id}` ])
      if (model.has('notes')) {
        model = model.mergeDeepIn('notes', fromJS(action.body))
      } else {
        model = model.setIn([ 'notes' ], fromJS(action.body))
      }
      return newstate.setIn([ `${action.modelname}`, `${action.id}` ], model)
    }

    case 'FETCH_GLOBAL_PORTALS_SUCCESS':
      if (!newstate.hasIn([ 'settings', `${action.site}`, 'portals' ])) {
        newstate = newstate.setIn([ 'settings', `${action.site}`, 'portals' ], Map({}))
      }
      return newstate.setIn([ 'settings', `${action.site}`, 'portals', 'global' ], List(fromJS(action.portals)))

    case 'FETCH_AGENCY_PORTALS_SUCCESS':
      if (!newstate.hasIn([ 'settings', `${action.site}`, 'portals' ])) {
        newstate = newstate.setIn([ 'settings', `${action.site}`, 'portals' ], Map({}))
      }
      return newstate.setIn([ 'settings', `${action.site}`, 'portals', 'agency' ], fromJS(action.portals))

    case 'FETCH_BRANCH_PORTALS_SUCCESS':
      if (!newstate.hasIn([ 'settings', `${action.site}`, 'portals' ])) {
        newstate = newstate.setIn([ 'settings', `${action.site}`, 'portals' ], Map({}))
      }
      return newstate.setIn([ 'settings', `${action.site}`, 'portals', 'branch' ], fromJS(action.portals))

    case 'FETCH_PORTAL_LOGS':
      return newstate.setIn([ action.modelname, action.modelid, 'portallogs' ], List())

    case 'SELECT_AGENT_SUCCESS': {
      const { agent } = action
      if (!newstate.getIn([ 'settings', `${agent.site.id}` ])) {
        newstate = newstate.setIn([ 'settings', `${agent.site.id}` ], fromJS(agent.site))
      } else {
        newstate = newstate.setIn([ 'settings', `${agent.site.id}`, 'portals' ], fromJS(agent.site.portals))
      }
      if (newstate.getIn([ 'settings', `${agent.site.id}`, 'portals', 'global' ])) {
        let globalportals = Map()
        newstate.getIn([ 'settings', `${agent.site.id}`, 'portals', 'global' ]).forEach(p => {
          globalportals = globalportals.set(p.get('id'), p)
        })
        newstate = newstate.set('globalportals', globalportals)
        let portals = Map()
        const agencyportals = newstate.getIn([ 'settings', `${agent.site.id}`, 'portals', 'agency' ])
        if (agencyportals) {
          newstate.getIn([ 'settings', `${agent.site.id}`, 'portals', 'agency' ]).forEach(p => {
            const global = newstate.getIn([ 'globalportals', p.get('portal') ])
            p = p.setIn([ 'meta', 'portal' ], global)
            portals = portals.set(p.get('id'), p)
          })
        }
        newstate = newstate.set('portals', portals)
      } else {
        newstate.setIn([ 'settings', `${agent.site.id}` ], Map({ portals: { global: {}, agency: {} } }))
      }
      return newstate
    }

    case 'FETCH_PORTAL_LOGS_SUCCESS':
      return newstate.setIn([ action.modelname, action.modelid, 'portallogs', action.portalid ], action.body)

    case 'FETCH_PORTALS_SUCCESS':
      return newstate.mergeDeepIn([ 'portals' ], action.delta)

    case 'UPDATE_BRANCH_PORTAL_CONFIG_SUCCESS':
      return newstate.mergeDeepIn([ 'portals', action.pid, 'branch', action.bidx ], action.body)

    case 'CREATE_BRANCH_PORTAL_CONFIG_SUCCESS': {
      let configs = newstate.getIn([ 'portals', action.body.bidx, 'branch' ])
      configs = configs.push(action.body)
      return newstate.setIn([ 'portals', action.body.bidx, 'branch' ], configs)
    }

    case 'CREATE_PORTAL_CONFIG_SUCCESS':
      return newstate.setIn([ 'portals', action.body.portal ], fromJS(action.body.portal))

    case 'ALERT_AGENTPROPERTYLEAD_SUCCESS': {
      let matchkey = null
      if (action.body.residential) {
        matchkey = `residential${action.body.residential}matches`
      } else if (action.body.commercial) {
        matchkey = `commercial${action.body.commercial}matches`
      } else if (action.body.holiday) {
        matchkey = `holiday${action.body.holiday}matches`
      } else if (action.body.project) {
        matchkey = `project${action.body.project}matches`
      }
      return newstate.setIn([ `${matchkey}`, `${action.body.profile}`, 'can_send_alert' ], false)
    }

    case 'FETCH_ACTIVITY_HISTORY_SUCCESS': {
      let model = newstate.getIn([ action.modelname, `${action.id}` ])
      if (model.getIn([ 'meta', 'history' ])) {
        model = model.setIn([ 'meta', 'history', 'on_show' ], fromJS(action.data))
      } else {
        model = model.setIn([ 'meta', 'history' ], fromJS({ on_show: action.data }))
      }
      newstate = newstate.setIn([ action.modelname, `${action.id}` ], model)
      return newstate
    }

    case 'CACHE_DELTA':
      // We need the merge function to ensure lists are not concat'ed
      if (!action.select) {
        newstate = merger(newstate, fromJS(action.delta))
        return newstate
      }
      return newstate

    case 'DO_LOGOUT':
    case 'TOKE_ERROR': { // Remove token from store and return default state
      newstate = fromJS(config.defaultState.cache)
      return newstate
    }

    default:
      return newstate
  }
}
