/* eslint-disable new-cap */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Label, Legend, Pie, PieChart, Surface, Symbols, Text } from 'recharts'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { CONFIG, MINUSER } from '../../selectors'
import { valueFormat, getTextWidth, breakpoint } from '../../utils'
import Card from '../common/Card'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import Loader from '../common/Loader'
import { useBreakPoint } from '../../hooks/useBreakPoint'


const RADIAN = Math.PI / 180

const MarketingEmailEventSummaryWidget = props => {
  const showActions = useBreakPoint(breakpoint)

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const value = (percent * 100).toFixed(0)
    let scale = radius / 99.6
    const width = getTextWidth(value, `${12 * scale}px Poppins`)
    const max = (radius - 5) * 2
    if (width > max) {
      const offset = max / width
      scale *= offset
    }
    if (scale < 0.6) {
      return null
    }
    if (value > 0) {
      return (
        <Text x={x} y={y} fontFamily="Poppins" fontSize={12 * scale} fill="#ffffff" textAnchor="middle" verticalAnchor='middle'>
          {`${value}%`}
        </Text>
      )
    }
    return null
  }

  const renderCustomizedLegend = ({ payload, chartWidth, chartHeight, layout }) => (
    <div className="customized-legend-vertical" style={{ width: layout === 'vertical' ? chartWidth - chartHeight : chartWidth }}>
      {
        payload.map((entry, eid) => {
          const { payload: data, color } = entry
          if (data.label === 'No Leads Found') {
            return null
          }
          return (
            <span key={`overlay-${data.label}-${eid}`} className="legend-item">
              <Surface width={10} height={10} viewBox={{
                x: 0,
                y: 0,
                width: 10,
                height: 10
              }}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
              </Surface>
              <span className="legend-label">{data.label}</span>
              <span className="legend-count">{valueFormat('number', data.value)} ({data.sublabel}%)</span>
            </span>
          )
        })
      }
    </div>
  )

  return (
    <Card
      id="marketing-email-event-summary-widget"
      classes="grid-col-1 marketing-email-pie-graph"
      bodyclass="stats-card no-top-padding"
      background
      header={
        <h3>Marketing Email Activity</h3>
      }
      body={props.loading ? (
        <div className="empty flex-container" style={{ height: 240 }}><Loader inline className="large" /></div>
      ) : (
        <ResponsiveContainer width={'100%'} height={300} debounce={1} legendWrap={!showActions}>
          <PieChart cx="50%" cy="50%" margin={0}>
            <Legend wrapperStyle={{ width: showActions ? null : '100%', height: showActions ? '100%' : null, bottom: showActions ? null : 1 }} iconType="circle" iconSize={9} layout={showActions ? 'vertical' : 'horizontal'} verticalAlign={showActions ? 'top' : 'bottom'} align="right" content={renderCustomizedLegend} data={props.statistics} />
            <Pie
              angleAxisId={0}
              minAngle={1}
              background={{ fill: '#ffffff' }}
              clockWise
              startAngle={90}
              endAngle={-270}
              innerRadius="66%"
              outerRadius="100%"
              isAnimationActive={false}
              data={props.statistics}
              labelLine={false}
              strokeWidth={0}
              label={renderCustomizedLabel}
              dataKey="value"
            >
              <Label fontFamily='Poppins' position="center" content={test => {
                if (!test.viewBox.innerRadius) { return null }
                let scale = test.viewBox.innerRadius / 79.2
                const total = props.statistics.map(d => d.value).reduce((a, b) => a + b, 0)
                const width = getTextWidth(valueFormat('number', total), `${34 * scale}px Poppins`)
                const max = (test.viewBox.innerRadius - 5) * 2
                if (width > max) {
                  const offset = max / width
                  scale *= offset
                }
                return <Text fill='#ffffff' fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">{valueFormat('number', total)}</Text>
              }}></Label>
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
    />
  )
}

MarketingEmailEventSummaryWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  status_field: PropTypes.object,
  model: PropTypes.object,
  loading: PropTypes.bool,
  statistics: PropTypes.array
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  const config = CONFIG(state, 'marketing-emails')
  const status_field = config.get('statusField')
  return ({
    user,
    status_field
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(
  MarketingEmailEventSummaryWidget)
)
