import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, Suspense } from 'react'

import ModelActions from './common/ModelActions'
import widgets from './widgets'


const Dash = ({ actions, ui, settings }) => {
  const [ offset, setOffset ] = useState(0)
  const tab_el = useRef()
  useEffect(() => {
    if (tab_el.current) {
      const new_offset = tab_el.current.getBoundingClientRect().top + tab_el.current.getBoundingClientRect().height
      if (offset !== new_offset) {
        setOffset(new_offset)
      }
    }
  }, [ tab_el.current ])
  useEffect(() => {
    window.setTimeout(() => {
      actions.checkVersion()
    }, 3000)
  }, [])
  return (
    <div className="content main-dashboard" id="content">
      <div ref={tab_el} className="viewhead">
        <ModelActions
          route="list"
          actions={actions}
          modelname="dashboard"
          ui={ui}
        />
        <div className="grid-container grid-3">
        </div>
      </div>
      <div className="view container-fluid">
        <div className="viewcontent">
          <div className="grid-container grid-1">
            <div className="grid-container grid-2">
              <Suspense fallback="">
                <widgets.ActiveListingsWidget delay={500} />
                <widgets.ExpiringSoonWidget delay={500} />
              </Suspense>
            </div>
            <div className="grid-container grid-1">
              <Suspense fallback="">
                <widgets.ListingQualityWidget delay={750} />
              </Suspense>
            </div>
            {/* <div className="grid-container grid-1">
              <widgets.ListingValueWidget delay={1000} />
            </div> */}
            {settings.deals_addon ? (
              <>
                <div className="grid-container grid-1">
                  <Suspense fallback="">
                    <widgets.SalesMadeWidget delay={750} />
                  </Suspense>
                </div>
                <div className="grid-container grid-1">
                  <Suspense fallback="">
                    <widgets.ExpectedNettCommissionWidget delay={750} />
                  </Suspense>
                </div>
                <div className="grid-container grid-3">
                  <Suspense fallback="">
                    <widgets.PastSalesPerformanceWidget delay={750} />
                  </Suspense>
                  <Suspense fallback="">
                    <widgets.LifetimeSalesPerformanceWidget delay={750} />
                  </Suspense>
                </div>
              </>
            ) : null}
            <div className="grid-container grid-2">
              <Suspense fallback="">
                <widgets.LeadSourceWidget delay={2000} />
                <widgets.StockWidget delay={2000} />
              </Suspense>
            </div>
            <div className="grid-container grid-2">
              <Suspense fallback="">
                <widgets.LatestLeadsWidget delay={3000} />
                <widgets.ExpiringListingsWidget delay={3000} />
              </Suspense>
            </div>
            <div className="grid-container grid-1">
              <Suspense fallback="">
                {![ 'ae' ].includes(settings.region) ? (
                  <widgets.SyndicationWidget delay={4000} />
                ) : null}
                {[ 'ae' ].includes(settings.region) ? (
                  <widgets.UAESyndicationWidget delay={4000} />
                ) : null}
              </Suspense>
            </div>
            <div className="grid-container grid-2">
              <Suspense fallback="">
                {/* <widgets.NotificationsWidget delay={5000} /> */}
                <widgets.NotesWidget delay={5000} />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Dash.propTypes = {
  actions: PropTypes.object,
  id: PropTypes.string,
  ui: PropTypes.object,
  settings: PropTypes.object
}

export default Dash
