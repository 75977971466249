import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'

import { handleSubmitError, useCustomCompareMemo } from '../utils'
import { viewing_interaction } from '../validate/viewing-interaction'
import { toggleWideSidebar } from '../actions'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'


const ViewingFeedbackSidebar = props => {
  const { communication, config, actions, match, cache } = props
  const { fields, modelname, fieldgroups } = config
  const [ initVals, setInitVals ] = useState({})
  const form = useRef()

  useEffect(() => {
    const newInitvals = {}
    if (communication) {
      fields.forEach(field => {
        newInitvals[field.name] = getIn(communication.meta.communication, field.name)
        if (field.name === 'fields') {
          newInitvals[field.name] = JSON.stringify(newInitvals[field.name]) || '[]'
        }
      })
      setInitVals(newInitvals)
    }
    return () => {
      actions.toggleWideSidebar()
    }
  }, [ useCustomCompareMemo(communication) ])

  const handleSubmit = (values, formikBag) => {
    formikBag.setSubmitting(true)
    const clean = { ...values }
    Object.keys(values).forEach(k => {
      const field = fields.find(f => f.name === k)
      if (field && field.input === 'Float') { clean[k] = clean[k] ? parseFloat(clean[k]).toFixed(1) : clean[k] }
      if (field && field.name === 'fields') {
        if (getIn(clean, k)) {
          clean[k] = JSON.parse(getIn(clean, k))
        } else {
          clean[k] = []
        }
      }
      if (clean[k] === null) {
        delete clean[k]
      }
    })

    return new Promise((resolve, reject) => actions.createLeadInteraction({
      modelid: communication.lead,
      values,
      resolve,
      reject
    })).then(() => {
      formikBag.resetForm({})
      formikBag.setSubmitting(false)
      toggleWideSidebar()
    }).catch(e => {
      handleSubmitError(e, formikBag, form.current)
      formikBag.setSubmitting(false)
    }).finally(() => {
      formikBag.setSubmitting(false)
    })
  }

  return (
    <div id="template-creator-sidebar" className="wide-sidebar template-creator-sidebar">
      <div className="wide-sidebar-container">
        <Formik
          initialValues={initVals}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={viewing_interaction}
        >{ formik => {
            form.current = formik
            return (
              <CustomForm
                render={() => (
                  <div className="wide-sidebar-pane">
                    <div className="wide-sidebar-heading">
                      <h4>Add Viewing Feedback</h4>
                      <Button
                        type="button"
                        icon="#icon24-X-Large"
                        className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                        onClick={() => { actions.toggleWideSidebar() }}
                      />
                    </div>
                    <Scrollbar
                      style={{ height: 'calc(100vh - 218px)' }}
                      renderView={({ style, ...rest }) => <div {...rest} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                    >
                      <div className="wide-sidebar-content">
                        { Object.keys(fieldgroups).map((group, gidx) => (
                          <FieldGroup
                            key={`fs-${gidx}`}
                            groupname={group}
                            group={fieldgroups[group]}
                            gidx={gidx}
                            classes={fieldgroups[group].classes}
                            fields={fields.filter(field => field.group === group).map(f => {
                              const newfield = merge({}, f)
                              if (newfield.createnew) { newfield.createnew = false }
                              if (newfield.twin) { newfield.readonly = true }
                              return newfield
                            })}
                            cache={cache}
                            modelname={modelname}
                            match={match}
                            columns
                            render={({ renderFieldGroup, hidden }) => {
                              if (hidden) { return null }
                              return (
                                <fieldset className="editgroup">
                                  <h5>{group}</h5>
                                  {renderFieldGroup(group)}
                                </fieldset>
                              )
                            }}
                          />
                        ))}
                        <div className="wide-sidebar-footer">
                          <Button
                            type="button" // This cannot be submit otherwise sibling form is submitted
                            onClick={formik.submitForm}
                            disabled={formik.isSubmitting}
                            className="btn btn-primary"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Scrollbar>
                  </div>
                )}
              />
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

ViewingFeedbackSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  autofill: PropTypes.object,
  match: PropTypes.object,
  communication: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  settings: PropTypes.object,
  list: PropTypes.object,
  sidebar: PropTypes.string,
  selectContact: PropTypes.func,
  toggleLookup: PropTypes.func
}

export default ViewingFeedbackSidebar
