import * as yup from 'yup'

import { decimalPlaces } from '../utils'
import messages from './messages.json'


yup.addMethod(yup.mixed, 'float', function (min, max) { // Make sure we get 1-digit floats, or NaN
  // eslint-disable-next-line
  return this.transform(function (value) {
    return (value !== undefined && String(value).trim() !== '') ? Number(value).toFixed(1) : NaN
  }).test(function (value) {
    if (min !== undefined && Number(value) < min) {
      return new yup.ValidationError(`Enter a valid number greater than ${min}`, value, this.path)
    }
    if (max !== undefined && Number(value) > max) {
      return new yup.ValidationError(`Enter a valid number less than ${max}`, value, this.path)
    }
    return true
  })
})

yup.addMethod(yup.mixed, 'step', function (step = 0.1) { // Make sure value is a multiple of 'step' - can be any number
  return this.test(function (value) {
    if (value && isNaN(value)) { return new yup.ValidationError(messages.number, value, this.path) }
    const places = decimalPlaces(step)
    const multiplier = Math.pow(10, places)
    const inv = 1.0 / step
    if (value && ((value * multiplier) % (step * multiplier) !== 0)) {
      const min = (Math.floor(value * inv) / inv).toFixed(1)
      const max = (Math.ceil(value * inv) / inv).toFixed(1)
      return new yup.ValidationError(`Enter a valid number. The two nearest numbers are ${min} and ${max}`, value, this.path)
    }
    return true
  })
})

const isValidStep = yup.mixed().float(0, 995.5).step(0.5)

export const residential_listing = yup.object({
  status: yup.string().when([ 'listing_type' ], {
    is: listing_type => listing_type === 'To Let',
    then: yup.string().oneOf([
      'Active',
      'Rented',
      'Archived',
      'Pending',
      'Valuation'
    ], 'Cannot use status type Sold, with listing type To Let').nullable(),
    otherwise: yup.string().nullable()
  }).required(messages.required),
  listing_type: yup.string().typeError('Please select a listing type').required(messages.required),
  branch: yup.number().strict().typeError('Please select a branch').typeError(messages.invalid).positive().integer().required(messages.required).nullable(),
  team: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent: yup.number().typeError(messages.invalid).positive().integer().required(messages.required).nullable(),
  agent_2: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent_3: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  agent_4: yup.number().typeError(messages.invalid).positive().integer().nullable(),
  price: yup.number().when('status', (status, schema) => (
    status !== 'Valuation' ? yup.number().moreThan(-1, messages.invalid).typeError(messages.invalid).required(messages.required) : schema.nullable())
  ),
  sold_price: yup.number().when('status', (status, schema) => (
    [ 'Sold', 'Rented' ].includes(status) ? yup.number().moreThan(-1, messages.invalid).typeError(messages.invalid).required(messages.required) : schema.nullable())
  ),
  pricing_lease_type: yup.string().when('listing_type', (listing_type, schema) => (
    listing_type === 'To Let' ? yup.string().trim().oneOf([
      'Renewal',
      'New',
      null
    ], messages.invalid).nullable() : schema.nullable()
  )),
  municipal_value: yup.number().moreThan(-1).typeError(messages.invalid)
    .transform(cv => (isNaN(cv) ? null : cv)).nullable(),
  distressed: yup.boolean().nullable(),
  bank_repossessed: yup.boolean().nullable(),
  bank_name: yup.string().trim().when(
    [ 'bank_repossessed', 'distressed' ], {
      is: (bank_repossessed, distressed) => bank_repossessed || distressed,
      then: yup.string().oneOf([
        'Absa Bank',
        'Nedbank',
        'Standard Bank',
        'FNB',
        'SA Home Loans'
      ], messages.invalid).required(messages.required),
      otherwise: yup.string().nullable()
    }
  ),
  public_tender: yup.boolean().nullable(),
  private_seller: yup.boolean().nullable(),
  property_type: yup.string().trim().oneOf([
    'Apartment',
    'Apartment Block',
    'Bed & Breakfast',
    'Bungalow',
    'Compound',
    'Club',
    'Cluster',
    'Detached',
    'Duet',
    'Duplex',
    'Equestrian Property',
    'Farm',
    'Full Floor',
    'Flat',
    'Freehold',
    'Freestanding',
    'Garden Cottage',
    'Gated Estate',
    'Golf Estate',
    'Guest House',
    'Half Floor',
    'Hotel',
    'Hotel Room',
    'House',
    'Labour Camp',
    'Leaseback',
    'Lodge',
    'Maisonette',
    'Package Home',
    'Penthouse',
    'Retirement Unit',
    'Room',
    'Sectional Title',
    'Semi Detached',
    'Simplex',
    'Small Holding',
    'Studio Apartment',
    'Townhouse',
    'Vacant Land',
    'Villa'
  ], messages.invalid).required(messages.required),
  owners: yup.array().of(yup.number().typeError(messages.invalid).nullable()
    .positive(messages.positive).integer().nullable()).nullable(),
  description: yup.string().when('status', (status, schema) => (status !== 'Valuation' ? schema.required(messages.required) : schema.nullable())),
  land_size_measurement_type: yup.string().trim().transform(cv => (cv === 'Hectares (Ha)' ? 'Hectares' : cv)).oneOf([
    'Square Metres',
    'Hectares',
    'Acres',
    'Square Feet',
    null
  ], messages.invalid).nullable(),
  land_size: yup.number().moreThan(-1).typeError(messages.invalid).when('property_type', (property_type, schema) => (
    property_type === 'Vacant Land' ? schema.required(messages.required) : schema.transform(cv => (isNaN(cv) ? undefined : cv)).nullable()
  )),
  floor_size_measurement_type: yup.string().trim().oneOf([
    'Square Metres',
    'Square Feet',
    null
  ], messages.invalid).nullable(),
  floor_size: yup.number().typeError(messages.invalid).min(0).transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  floor_number: yup.number().typeError(messages.number).integer().positive(messages.positive).nullable(),
  property_title: yup.string().trim().oneOf([
    'Sectional Title',
    'Freehold',
    'Share Block',
    'Leasehold',
    'Life Rights',
    null
  ], messages.invalid).nullable(),
  rates_period: yup.string().trim().oneOf([
    'Per Month',
    'Per Year',
    null
  ], messages.invalid).nullable(),
  payment_frequency: yup.string().when([ 'listing_type', 'status' ], {
    is: (listing_type, status) => listing_type === 'To Let' && status === 'Active',
    then: yup.string().oneOf([
      null,
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12'
    ], messages.invalid).nullable(),
    otherwise: yup.string().nullable()
  }),
  escalation: yup.string().trim().oneOf([
    'Negotiable',
    'Specify %',
    null
  ], messages.invalid).nullable(),
  annual_escalation: yup.number().when('escalation', (escalation, schema) => (
    escalation === 'Specify %' ? (
      yup.number().positive().required(messages.required)
    ) : schema.nullable()
  )),
  available_from: yup.string().trim().oneOf([
    'Immediately',
    'Negotiable',
    'Specific Date',
    null
  ], messages.invalid).nullable(),
  marketing_heading: yup.string().max(100).nullable(),
  location: yup.number().typeError(messages.invalid).positive().integer().required(messages.required),
  building_name: yup.string().max(60).nullable(),
  street_number: yup.string().max(10).nullable().required(messages.required),
  street_name: yup.string().max(100).nullable().required(messages.required),
  erf_number: yup.string().nullable(),
  lightstone_id: yup.number(messages.number).integer().positive(messages.positive).typeError('Please enter a valid lightstone ID').nullable(),
  map_to_option: yup.string().trim().oneOf([
    'Address',
    'Suburb'
  ], messages.invalid),
  project: yup.number().typeError('Please select a valid option').positive().integer().nullable(),
  bedrooms: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  bathrooms: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  study: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  lounges: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  dining_rooms: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  kitchen: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  laundry: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  furnished: yup.boolean().nullable(),
  patio: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  balcony: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  garages: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  carports: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  flatlet: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  domestic_accommodation: yup.number().typeError('Please enter a valid number').when([ 'property_type', 'status' ], (property_type, status, schema) => {
    if (property_type !== 'Vacant Land') {
      return status !== 'Valuation' ? isValidStep.required(messages.required) : isValidStep.nullable()
    }
    return schema.transform(() => undefined).nullable()
  }),
  pool: yup.mixed().transform(value => !!value).nullable(),
  security: yup.boolean().nullable(),
  sea_views: yup.boolean().nullable(),
  pets_allowed: yup.boolean().nullable(),
  monthly_rates: yup.number().positive().transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  monthly_levy: yup.number().positive().transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  sectional_title_levy: yup.number().positive().transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  special_levy: yup.number().positive().transform(cv => (isNaN(cv) ? undefined : cv)).nullable(),
  enable_offers: yup.boolean().nullable(),
  offer_status: yup.string().when('enable_offers', (enable_offers, schema) => (
    enable_offers ? (
      yup.string().oneOf([
        'Accepting Offers',
        'Offer Pending',
        'Offer Accepted'
      ], messages.invalid).required(messages.required).nullable()
    ) : schema.nullable()
  )),
  offers_from: yup.number().when('enable_offers', (enable_offers, schema) => (
    enable_offers ? (
      yup.number().integer().positive().required(messages.required)
        .transform(cv => (isNaN(cv) ? undefined : cv)).nullable()
    ) : schema.nullable()
  )),
  offers_increment: yup.number().when('enable_offers', (enable_offers, schema) => (
    enable_offers ? (
      yup.number().integer().positive().required(messages.required)
        .transform(cv => (isNaN(cv) ? undefined : cv)).nullable()
    ) : schema.nullable()
  )),
  on_show: yup.boolean().nullable(),
  on_show_directions: yup.string().when('on_show', (on_show, schema) => (
    on_show ? schema.nullable().required(messages.required) : schema.nullable()
  )),
  on_show_events: yup.array().when('on_show', {
    is: true,
    then: yup.array().of(yup.object().shape({
      on_show_date: yup.string().nullable(),
      on_show_start_time: yup.string().required(messages.required).nullable(),
      on_show_end_time: yup.string().required(messages.required).nullable()
    })),
    otherwise: yup.array().nullable()
  }),
  auction: yup.boolean().nullable(),
  auction_lots: yup.number().typeError('Please enter a valid number').when('auction', (auction, schema) => (
    auction ? (
      yup.number().integer(messages.integer).positive().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  auction_date: yup.mixed().when('auction', (auction, schema) => (
    auction ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  auction_start_time: yup.mixed().when('auction', (auction, schema) => (
    auction ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  auction_end_time: yup.mixed().when('auction', (auction, schema) => (
    auction ? (
      yup.string().required(messages.required).nullable()
    ) : schema.nullable()
  )),
  auction_venue: yup.string().nullable(),
  matterport_id: yup.string().max(100).nullable(),
  virtual_tour: yup.string().url(messages.url).max(150).nullable(),
  external_link_name: yup.string().max(20).nullable(),
  external_link_url: yup.string().url(messages.url).max(200).nullable(),
  viewing_contact_person: yup.string().max(50).nullable(),
  viewing_contact_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  tenant_rental: yup.number(messages.number).typeError(messages.invalid).positive(messages.positive).nullable(),
  lease_period: yup.string().when([ 'listing_type', 'status' ], {
    is: (listing_type, status) => listing_type === 'To Let' && status === 'Active',
    then: yup.string().trim().required(messages.required).oneOf([
      '1 month',
      '2 months',
      '3 months',
      '6 months',
      '9 months',
      '12 months',
      '24 months',
      '36 months',
      '48 months',
      '60 months',
      '10 year plus',
      '15 year plus',
      '25 year plus',
      '35 year plus',
      'Negotiable',
      null
    ], messages.invalid).nullable(),
    otherwise: yup.string().nullable()
  }),
  tenant_notice_period: yup.string().max(50).nullable(),
  quick_sell_ref: yup.string().max(30).nullable(),
  mandate_commission_split: yup.string().max(15).nullable(),
  mandate_commission_percentage: yup.string().max(20).nullable(),
  mandate_type: yup.string().when('listing_type', (listing_type, schema) => (
    listing_type === 'For Sale' ? schema.trim().typeError(messages.invalid).oneOf([
      'Sole',
      'Open',
      'Company Listing',
      'Shared',
      'Expired',
      'MLS',
      'Referral',
      'Auction',
      'PLN',
      'Exclusive',
      null
    ], messages.invalid).nullable() : schema.nullable()
  )),
  mandate_start_date: yup.string().when('mandate_type', {
    is: mandate_type => [ 'Sole', 'Exclusive' ].includes(mandate_type),
    then: yup.string().typeError(messages.invalid).required(messages.required),
    otherwise: yup.string().nullable()
  }),
  mandate_end_date: yup.string().when([ 'mandate_type' ], {
    is: mandate_type => [ 'Sole', 'Exclusive' ].includes(mandate_type),
    then: yup.string().typeError(messages.invalid).required(messages.required),
    otherwise: yup.string().nullable() }),
  tenant_lease_ends: yup.string().when([ 'mandate_type', 'tenant' ], {
    is: (mandate_type, tenant) => mandate_type === 'Sole' && tenant,
    then: yup.string().trim().typeError(messages.invalid).required(messages.required),
    otherwise: yup.string().nullable() }),
  on_market_since: yup.string().when([ 'mandate_type' ], {
    is: mandate_type => mandate_type === 'Sole',
    then: yup.string().trim().required(messages.required),
    otherwise: yup.string().nullable() }),
  flooring: yup.array(yup.string().oneOf([
    'Carpet',
    'Cement',
    'Marble',
    'Parquet',
    'Slasto',
    'Slate',
    'Tiles',
    'Wood',
    'Laminate',
    'Polished',
    'Vinyl'
  ], messages.invalid)).nullable(),
  exterior: yup.array(yup.string().oneOf([
    'Block',
    'Brick',
    'Clinker',
    'Concrete',
    'Face Brick',
    'Iron',
    'Nutec',
    'Plaster',
    'Stone',
    'Tile',
    'Vacant Land',
    'Wood',
    'Zincalume'
  ], messages.invalid)).nullable(),
  roof: yup.array(yup.string().oneOf([
    'Aluminium',
    'Asbestos',
    'Concrete',
    'Iron',
    'Slate',
    'Thatch',
    'Tile',
    'Vacant Land',
    'Colour Steel'
  ], messages.invalid)).nullable(),
  garden_type: yup.array(yup.string().oneOf([
    'Above Road',
    'Communal',
    'Indigenous',
    'Landscaped',
    'Level',
    'Rocky',
    'Sloped',
    'Steep',
    'Backyard Access',
    'Manicured',
    'Tidy',
    'Overgrown',
    'Greenhouse',
    'Terraced'
  ], messages.invalid)).nullable(),
  store_room: yup.string().max(30).nullable(),
  walling: yup.array(yup.string().oneOf([
    'Chicken Wire',
    'Electric',
    'Face Brick',
    'Fully Fenced',
    'Fully Walled',
    'Palisade',
    'Partly Fenced',
    'Partly Walled',
    'Precast',
    'Vacant Land',
    'Open',
    'Electric Fence'
  ], messages.invalid)).nullable(),
  share_block_number: yup.string().max(15).nullable(),
  sectional_scheme_name: yup.string().max(128).nullable(),
  section_number: yup.string().max(100).nullable(),
  section_plan_number: yup.string().max(15).nullable(),
  per_quota: yup.string().max(15).nullable(),
  body_corp_name: yup.string().max(50).nullable(),
  body_corp_chairman: yup.string().max(50).nullable(),
  body_corp_telephone_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  supervisor_name: yup.string().max(50).nullable(),
  supervisor_telephone_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  managing_agent: yup.string().max(50).nullable(),
  managing_agent_telephone_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  open_hour_agent: yup.string().max(50).nullable(),
  open_hour_agent_telephone_number: yup.string().max(20).matches(/^[.\d() +-]+$/,
    { message: messages.phone, excludeEmptyString: true }).nullable(),
  bond_bank: yup.string().max(80).nullable(),
  bond_bank_branch: yup.string().max(80).nullable(),
  bond_bank_account_number: yup.string().max(80).nullable(),
  bond_bank_balance: yup.string().max(30).nullable(),
  portals: yup.object().nullable()
}
// The below has been removed as it completely screws up the DeedsLookupSidebar when a selection is made
// ).test( // Cyclicly dependant fields can only be validated after all other fields.
//   'unit_number',
//   schema => {
//     const { unit_number, complex_name } = schema
//     if (unit_number && unit_number.trim() &&
//       (!complex_name || !(complex_name && complex_name.trim()))) { // If unit_number is present and complex_name is not, throw an error
//       return new yup.ValidationError(messages.required, complex_name, 'complex_name')
//     }
//     if (complex_name && complex_name.trim() &&
//       (!unit_number || !(unit_number && unit_number.trim()))) {
//       return new yup.ValidationError(messages.required, unit_number, 'unit_number') // If complex_name is present and unit_number is not, throw an error
//     }
//     return true
//   }
).test(
  'external_link',
  schema => {
    const { external_link_name, external_link_url } = schema
    if (!external_link_name && !external_link_url) { return true }
    if (external_link_name && external_link_name.trim() &&
        external_link_url && external_link_url.trim()) { return true }

    if (external_link_url && external_link_url.trim() &&
      (!external_link_name || !(external_link_name && external_link_name.trim()))) {
      return new yup.ValidationError(messages.required, external_link_name, 'external_link_name')
    }
    return new yup.ValidationError(messages.required, external_link_url, 'external_link_url')
  }
).test(
  {
    message: 'Available from date is required',
    test: schema => {
      const { available_from, listing_type } = schema
      if (listing_type === 'To Let' && !available_from) {
        return new yup.ValidationError(messages.required, available_from, 'available_from')
      }
      return true
    }
  }
).test(
  {
    message: 'Occupation date is required',
    test: schema => {
      const { available_from, listing_type, occupation_date } = schema
      if (listing_type === 'To Let' && available_from === 'Specific Date' && !occupation_date) {
        return new yup.ValidationError(messages.required, occupation_date, 'occupation_date')
      }
      return true
    } }
).test(
  'on_show', // On show requires at least 1 of 3 fields be completed
  schema => {
    const {
      on_show,
      on_show_directions,
      on_show_events
    } = schema
    if (on_show) {
      if (!on_show_directions) {
        return new yup.ValidationError(messages.required, on_show_directions, 'on_show_directions')
      }
      if (!on_show_events || !on_show_events.length) {
        return new yup.ValidationError(messages.required, on_show_events, 'on_show_events')
      }
      on_show_events.forEach((e, eidx) => {
        const {
          on_show_date,
          on_show_start_time,
          on_show_end_time
        } = schema.on_show_events[eidx]
        if (!on_show_date) {
          return new yup.ValidationError(messages.required, on_show_date, 'on_show_date')
        }
        if (!on_show_start_time) {
          return new yup.ValidationError(messages.required, on_show_start_time, 'on_show_start_time')
        }
        if (!on_show_end_time) {
          return new yup.ValidationError(messages.required, on_show_end_time, 'on_show_end_time')
        }
        return null
      })
    }
    return true
  }
).test(
  'display_on_website', // On show requires at least 1 of 3 fields be completed
  schema => {
    const {
      listing_images,
      display_on_website
    } = schema
    if (display_on_website) {
      if (!listing_images || (listing_images && !listing_images.length)) {
        return new yup.ValidationError('Listings require at least one image to display on websites.', display_on_website, 'display_on_website')
      }
    }
    return true
  }
)

export const residential = () => residential_listing
