import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'

import DetailsList from '../common/DetailsList'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import RecipientsManager from '../../containers/RecipientsManager'
import MarketingEmailEventSummaryWidget from '../widgets/MarketingEmailEventSummaryWidget'
import MarketingEmailEventDetailWidget from '../widgets/MarketingEmailEventDetailWidget'
import Tab from '../common/tabs/Tab'
import { Button } from '../ui/Button'
import Loader from '../common/Loader'
import Card from '../common/Card'
import Activity from '../common/Activity'
import { capitalize } from '../../utils'
import { useBreakPoint } from '../../hooks/useBreakPoint'


const MarketingEmail = props => {
  const {
    model,
    config,
    cache,
    actions,
    user,
    routeConfig
  } = props

  const [ sigma, setSigma ] = useState({})
  const [ daily, setDaily ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ dimensions, setDimensions ] = useState({ width: '100%', height: 'auto' })
  const [ view, setView ] = useState('Desktop')
  const [ scale, setScale ] = useState(1)
  const [ src, setSrc ] = useState('')

  const showActions = useBreakPoint()

  const preview = useRef()
  const resize = useRef()

  const abortController = useRef(new AbortController())

  const viewWidth = (view === 'Mobi') ? 360 : 600

  const keys = {
    delivered: '#73C677',
    unsubscribe: '#70859E',
    open: '#0CA6C8',
    click: '#10294D',
    spamreport: '#459948',
    bounce: '#FC495D',
    dropped: '#006DDC'
  }

  const checkSize = () => {
    clearTimeout(resize.current)
    resize.current = setTimeout(() => {
      const obj = preview?.current
      if (
        obj &&
        dimensions.width !== obj.contentWindow.document.body.scrollWidth
        || dimensions.height !== obj.contentWindow.document.body.scrollHeight
      ) {
        setDimensions({
          width: obj.contentWindow.document.body.scrollWidth,
          height: Math.max(
            document.body.scrollHeight,
            obj.contentWindow.document.body.scrollHeight + 15
          )
        })
      }
      setScale(view === 'Mobi' ? viewWidth / 600 : 1)
    }, 150)
  }

  const fetchStatsEvents = () => {
    setLoading(true)
    new Promise((resolve, reject) => actions.fetchMarketingEmailStats({
      params: { id: model.id },
      signal: abortController.current.signal,
      resolve,
      reject
    })).then(r => {
      const data = []
      const stats = r.sigma
      setDaily(r.daily)
      const total = Object.keys(stats).map(s => stats[s]).reduce((a, b) => a + b, 0)
      Object.keys(stats).forEach(event => {
        const percent = Math.round(stats[event] / total * 100)
        let formatted_event = event
        if (!event.endsWith('ed')) {
          if (event.endsWith('e')) {
            formatted_event += 'd'
          } else {
            formatted_event += 'ed'
          }
        }
        if (event === 'spamreport') { formatted_event = 'Spam'}
        const d = {
          value: stats[event],
          label: `${capitalize(formatted_event)}`,
          fill: keys[event],
          sublabel: percent
        }
        data.push(d)
      })
      setSigma(data)
    }).catch(e => {
      // eslint-disable-next-line no-console
      console.error(e)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (preview.current) { checkSize() }
  }, [ view ])

  useEffect(() => {
    fetchStatsEvents()

    new Promise((resolve, reject) => {
      const { variables, ...rest } = model
      const params = {
        modelname: config.modelname,
        ...rest,
        ...variables,
        quiet: true,
        endpoint: {
          write: '/mail/api/v1/email-templates/preview/?preview=True'
        },
        resolve,
        reject
      }
      return actions.updateTemplatePreview(params)
    }).then(r => {
      setSrc(r)
    }).catch(e => {
      console.error(e)
    })
    return () => {
      abortController.current.abort()
    }
  }, [])

  let framewidth = !showActions ? dimensions.width : '100%'
  if (view === 'Mobi') {
    framewidth = 600
  }

  return (
    <HorizontalTabs
      config={routeConfig}
      location={props.location}
      match={props.match}
      model={model}
      defaultTab="details"
      user={{ permissions: user.permissions, agent: user.agent }}
    >

      {model.status === 'Sent' ? (
        <Tab tab="dashboard" label="Dashboard" noScroll>
          <div className="grid-container grid-1">
            <MarketingEmailEventSummaryWidget
              statistics={sigma}
              loading={loading}
            />
          </div>
          <MarketingEmailEventDetailWidget
            statistics={daily}
            loading={loading}
          />
        </Tab>
      ) : null }

      <Tab tab="details" label="Details" noScroll>
        <DetailsList {...props} />
      </Tab>

      <Tab tab="recipients" label="Recipients">
        <RecipientsManager
          location={props.location}
          match={props.match}
          model={model}
          user={user}
        />
      </Tab>

      <Tab tab="preview" label="Preview">
        <Card
          background={true}
          header={
            <h3>Preview</h3>
          }
          bodyclass="no-padding"
          body={
            <div className='previewcontainer' style={{ width: '100%' }}>
              <nav id='previewcontainer-nav' className='previewcontainer-nav'>
                {showActions ? (
                  <div className="nav-group">
                    <Button icon="#icon24-Website" title="Desktop" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={() => setView('Desktop')} />
                    <Button icon="#icon24-Mobile" title="Mobi" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' onClick={() => setView('Mobi', () => {
                      this.checkSize()
                    })} />
                  </div>
                ) : null }
              </nav>
              <div className='previewcontainer-content' style={{
                width: view === 'Mobi' ? 360 : '100%',
                height: dimensions.height * (view === 'Mobi' ? viewWidth / 600 : 1),
                overflow: 'hidden'
              }}>
                {loading ? <Loader onError={() => {}} throwViewError={() => {}} /> : null}
                <iframe
                  key={`iframe-${config.model}`}
                  ref={preview}
                  scrolling={'no'}
                  onLoad={() => {
                    const obj = preview.current
                    setLoading(false)
                    setDimensions({
                      width: obj.contentWindow.document.body.scrollWidth,
                      height: Math.max(
                        document.body.scrollHeight,
                        obj.contentWindow.document.body.scrollHeight + 15
                      )
                    })
                  }}
                  style={{
                    height: dimensions.height,
                    width: framewidth,
                    transformOrigin: 'top left',
                    transform: `scale(${scale})`
                  }}
                  srcDoc={src}
                />
              </div>
            </div>
          }
        />
      </Tab>


      <Tab tab="activity" label="Activity">
        <Activity
          model={model}
          cache={cache}
          config={config}
          events={model.activity}
          user={user}
          settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
          agents={cache && cache.agents ? cache.agents : {}}
          fetchActivity={actions.fetchActivity}
          fetchOne={actions.fetchOne}
          fetchMany={actions.fetchMany}
        />
      </Tab>

    </HorizontalTabs>

  )
}

MarketingEmail.propTypes = {
  listingid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  routeConfig: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  settings: PropTypes.object
}

export default MarketingEmail
