export const KEYS = [
  'rfs_ac_value',
  'rfs_pe_value',
  'rfs_va_value'
]

export const KEY_LABELS = [
  'Valuation',
  'For Sale',
  'To Let',
  'Pending',
  'Sold',
  'Rented'
]

export const MANDATE_TYPES = [
  'Sole',
  'Open',
  'Company Listing',
  'Shared',
  'Expired',
  'Exclusive',
  'MLS',
  'PLN',
  'Referral',
  'Auction'
]

export const MANDATE_TYPES_OPTIONS = [ { label: 'Mandate Type', value: '' }, ...MANDATE_TYPES.map(m => ({ value: m, label: m })) ]
