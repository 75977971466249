import { eachDayOfInterval, endOfDay, endOfWeek, endOfMonth, isWithinInterval, startOfDay, startOfWeek, startOfMonth, eachWeekOfInterval, eachMonthOfInterval } from 'date-fns'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { components } from 'react-select'
import { Tooltip, Legend, CartesianGrid, XAxis, YAxis, Bar } from 'recharts'

import { generateAddress, valueFormat, getRandomColor, textToDate, hasAddons, hasPermission, parseURL, breakpoint } from '../../utils'
import BarGraph, { longestLabelLength } from '../ui/graphs/BarGraph'
import date_options from '../../config/date-options.json'
import { Button } from '../ui/Button'
import ViewingFeedbackSidebar from '../../containers/ViewingFeedbackSidebar'
import WideSidebar from '../ui/sidebar/WideSidebar'
import InlineSelect from './forms/inputs/InlineSelect'
import Loader from './Loader'
import Card from './Card'
import SimpleTable from './simpletable/SimpleTable'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const CustomizedAxisTick = ({ x, y, payload }) => {
  if (payload.value === 'auto') { return payload.value }
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {valueFormat('day', new Date(payload.value).toString())}
      </text>
    </g>
  )
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object
}

const ViewingFeedback = ({ user, model, actions, config, addons, configs, app, match }) => {
  const option = date_options.find(o => o.value === 'LAST_30_DAYS')
  const period = option.value
  const { start, end, days } = textToDate(option.value)
  const [ periodState, setPeriodState ] = useState({
    period,
    current: valueFormat('shortdate', start.toString()),
    limit: valueFormat('shortdate', end.toString()),
    days
  })
  const [ showActions, setShowActions ] = useState(breakpoint.matches)
  const [ periodData, setPeriodData ] = useState({ yes: 0, no: 0, maybe: 0, dates: [] })
  const [ upcoming, setUpcoming ] = useState(0)
  const [ loading, setLoading ] = useState(true)
  const [ communication, setCommunication ] = useState()

  const toggleActions = e => {
    if (e.matches && !showActions) {
      setShowActions(true)
    } else if (e.matches !== undefined && showActions) {
      setShowActions(false)
    }
  }

  const getTicks = (startDate, endDate, num) => {
    let ticks = eachMonthOfInterval({ start: startDate, end: endDate })
    if (num < 1) {
      ticks = eachDayOfInterval({ start: startDate, end: endDate })
    } else if (Math.ceil(num) < 10) {
      ticks = eachWeekOfInterval({ start: startDate, end: endDate })
    }
    return ticks.map(t => t.getTime())
  }

  const fillTicksData = (_ticks, data, num) => {
    const ticks = [ ..._ticks ]
    const filled = ticks.map(currentTick => {
      const tick_data = { date: currentTick, created: new Date(currentTick) }
      const label = 'Viewings'
      const total = data.filter(it => {
        let startOfPeriod = startOfMonth(currentTick)
        let endOfPeriod = endOfMonth(currentTick)
        if (num < 1) {
          startOfPeriod = startOfDay(currentTick)
          endOfPeriod = endOfDay(currentTick)
        } else if (Math.ceil(num) < 10) {
          startOfPeriod = startOfWeek(currentTick)
          endOfPeriod = endOfWeek(currentTick)
        }
        return isWithinInterval(new Date(it.Date), { start: startOfPeriod, end: endOfPeriod })
      }).map(it => it.Viewings).reduce((prevValue, currentValue) => prevValue + parseFloat(currentValue), 0)
      tick_data[label] = parseInt(total, 10)
      return tick_data
    })
    return filled
  }

  const getDate = (date, day_count) => {
    if (day_count <= 31) {
      return valueFormat('day', date)
    } else if (day_count <= 93) {
      return valueFormat('daymonth', date)
    }
    return day_count >= 180 ? valueFormat('shortmonthyear', date) : valueFormat('shortdate', date)
  }

  const toggleFeedback = () => {
    actions.toggleWideSidebar('show-viewing-feedback')
  }

  const renderCustomizedLegend = () => {
    const total = periodData.yes + periodData.no + periodData.maybe
    return (
      <div className="customized-legend">
        <span className="legend-total">
          <span className="legend-count">{total}</span>
          <span className="legend-label">Viewings</span>
        </span>
        <span className="legend-total">
          <span className="legend-count">{upcoming}</span>
          <span className="legend-label">Upcoming</span>
        </span>
      </div>
    )
  }

  useEffect(() => {
    breakpoint.addEventListener('change', toggleActions)

    new Promise((resolve, reject) => actions.fetchViewingFeedback({
      modelname: config.modelname,
      action: 'viewing-summary',
      modelid: model.id,
      params: {
        viewing__viewing_date__gte: periodState.current,
        viewing__viewing_date__lte: periodState.limit
      },
      resolve,
      reject
    })).then(r => {
      const dates = Object.keys(r.dates).map(day => ({ Date: day, Viewings: r.dates[day] }))
      setPeriodData({ ...r, dates })
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    }).finally(() => {
      setLoading(false)
    })

    return () => {
      breakpoint.removeEventListener('change', toggleActions)
    }
  }, [ periodState.period ])

  const dataKeys = [ 'Viewings' ]
  const num = (periodState.days / 365) * 12
  const ticks = getTicks(
    new Date(periodState.current),
    new Date(periodState.limit),
    num
  )
  const filledData = fillTicksData(ticks, periodData.dates, num)

  let seller_feedback_button
  if (hasAddons([ 'seller_feedback_report_addon' ], addons) && [ 'residential', 'commercial' ].includes(model.model)) {
    if (
      model.listing_type === 'For Sale'
      && hasPermission([ `listings_${model.model}_for_sale_generate_seller_feedback_report` ], user.permissions)
      && (hasPermission([ `listings_${model.model}_for_sale_update` ], user.permissions)
      || (
        hasPermission([ `listings_${model.model}_for_sale_update_own` ], user.permissions)
        && [ model.agent, model.agent_2, model.agent_3, model.agent_4 ].includes(user.agent.id))
      )
    ) {
      seller_feedback_button = <Button className="btn btn-subtle" component={NavLink} to='./seller-feedback'>Edit Report</Button>
    } else if (
      model.listing_type === 'To Let'
      && hasPermission([ `listings_${model.model}_to_let_generate_seller_feedback_report` ], user.permissions)
      && (hasPermission([ `listings_${model.model}_to_let_update` ], user.permissions)
      || (
        hasPermission([ `listings_${model.model}_to_let_update_own` ], user.permissions)
        && [ model.agent, model.agent_2, model.agent_3, model.agent_4 ].includes(user.agent.id))
      )
    ) {
      seller_feedback_button = <Button className="btn btn-subtle" component={NavLink} to='./landlord-feedback'>Edit Report</Button>
    } else {
      const url_pattern = configs['seller-feedback'].endpoint.public
      const url = parseURL(url_pattern, model, user)
      let service
      if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'e2e') {
        service = app.reports.stage
      } else if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production') {
        service = app.reports.live
      } else {
        service = app.reports.dev
      }
      seller_feedback_button = <Button className="btn btn-subtle" target="_blank" component={'a'} href={`${service}${url}`}>View Report</Button>
    }
  }
  return (
    <div className="grid-container grid-4 grid-rows">
      <Card
        classes="grid-col-3 grid-row-3"
        bodyclass="bar-graph viewing-feedback-graph"
        background
        header={
          <>
            <h3>Viewings</h3>
            <div className="details-section-buttons min-flex tablemeta">
              <div className="filter-date-range">
                <InlineSelect
                  id="period"
                  name="period"
                  className="inline-select"
                  classNamePrefix="inline"
                  defaultValue={date_options.find(o => o.value === 'LAST_30_DAYS')}
                  selectedValue={periodState.period}
                  options={date_options.filter(o => !o.value.includes('NEXT') && !o.value.includes('TOMORROW'))}
                  onChange={e => {
                    const newPeriod = e.value
                    const { start: newStart, end: newEnd, days: newDays } = textToDate(newPeriod)
                    const current = valueFormat('shortdate', newStart.toString())
                    const limit = valueFormat('shortdate', newEnd.toString())
                    setPeriodState({ period: newPeriod, current, limit, days: newDays })
                  }}
                  components={{
                    Option: CustomOption
                  }}
                />
              </div>
              {seller_feedback_button}
            </div>
          </>
        }
        body={
          !loading ? (
            <div className="stats">
              <BarGraph data={filledData} height={180} dataKeys={dataKeys}>{() => (
                <>
                  <Legend wrapperStyle={{ width: showActions ? 'auto' : '100%', height: showActions ? '100%' : null, top: 0, right: 0 }} iconType="circle" iconSize={9} layout={showActions ? 'vertical' : 'horizontal'} verticalAlign={'top'} align="right" content={renderCustomizedLegend} />
                  <CartesianGrid vertical={false} stroke="#F3F5F8" />
                  <XAxis axisLine={false} dataKey="date" tickFormatter={date => getDate(date, periodState.days)} />
                  <YAxis allowDecimals={false} width={longestLabelLength(filledData, dataKeys)} axisLine={false} tick={{ stroke: '#B2C2D4', strokeWidth: 1 }} tickLine={{ stroke: 'none', strokeWidth: 1 }} />
                  <Tooltip cursor={{ fill: '#FAFBFD' }} formatter={value => valueFormat('number', value)} labelFormatter={getDate} />
                  {dataKeys.map((key, idx) => (
                    <Bar key={`bar-${idx}`} barSize={10} dataKey={key} fill={getRandomColor(1)} radius={[ 10, 10, 0, 0 ]} />
                  ))}
                </>
              )}
              </BarGraph>
            </div>
          ) : (
            <div className="empty flex-container" style={{ height: 180 }}><Loader inline className="large" /></div>
          )
        }
      />
      <Card
        classes="grid-col-1"
        bodyclass="stats-card-mini viewing-feedback-card"
        background
        body={
          <div className="flex-container justify-between">
            <div><svg viewBox='0 0 32 32'><use href="/images/icons-24.svg#icon24-VeryHappy" /></svg> Interested</div><span>{periodData.yes}</span>
          </div>
        }
      />
      <Card
        classes="grid-col-1"
        bodyclass="stats-card-mini viewing-feedback-card"
        background
        body={
          <div className="flex-container justify-between">
            <div><svg viewBox='0 0 32 32'><use href="/images/icons-24.svg#icon24-Happy" /></svg> Possibly Interested</div><span>{periodData.maybe}</span>
          </div>
        }
      />
      <Card
        classes="grid-col-1"
        bodyclass="stats-card-mini viewing-feedback-card"
        background
        body={
          <div className="flex-container justify-between">
            <div><svg viewBox='0 0 32 32'><use href="/images/icons-24.svg#icon24-Sad" /></svg> Not Interested</div><span>{periodData.no}</span>
          </div>
        }
      />
      <Card
        classes="grid-col-4 maxcard"
        bodyclass="viewing-feedback-list no-top-padding"
        background
        header={
          <h3>Viewings awaiting feedback</h3>
        }
        body={
          <SimpleTable
            paginated
            config={{
              endpoint: {
                read: '/mashup/api/v1/leads/pending/'
              }
            }}
            action={(params, resolve, reject) => actions.fetchViewingFeedback({
              modelname: config.modelname,
              action: 'pending',
              modelid: model.id,
              resolve,
              reject,
              ...params
            })}
            header={[
              {
                name: 'meta.communication.viewing_date',
                label: 'Viewing Date',
                format: 'date'
              },
              {
                name: [ 'meta.communication.viewing_start_time', 'meta.communication.viewing_end_time' ],
                label: 'Viewing Time',
                format: 'timeinterval'
              },
              {
                name: 'meta.communication.viewing_type',
                label: 'Viewing Type'
              },
              {
                name: [ 'meta.lead.meta.agent.first_name', 'meta.lead.meta.agent.last_name' ],
                label: 'Agent',
                link: '/secure/:site/agents/:meta.lead.agent/details'
              },
              {
                name: [ 'meta.contact.first_name', 'meta.contact.last_name' ],
                label: 'Contact',
                link: '/secure/:site/contacts/:contact/details'
              }
            ]}
            user={user}
            params={{
              limit: 100,
              meta_fields: [ 'lead', 'contact' ]
            }}
            parser={data => {
              setUpcoming(data.count)
              const newData = {
                options: data.results.map(l => ({
                  ...l,
                  address: generateAddress(l)
                })),
                hasMore: !!data.next
              }
              return newData
            }}
            rowActions={(row, data) => (
              <>
                <Button className="btn btn-small btn-grey" component={Button} type="button" onClick={() => {
                  setCommunication(data)
                  toggleFeedback()
                }}>Add Feedback</Button>
                <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${getIn(data, 'site')}/${config.modelname}/${model.id}/leads/${getIn(data, 'lead')}`} />
              </>
            )}
          />
        }
      />
      <Card
        classes="grid-col-4 maxcard"
        bodyclass="viewing-feedback-list no-top-padding"
        background
        header={
          <h3>Interested</h3>
        }
        body={
          <SimpleTable
            paginated
            config={{
              endpoint: {
                read: '/mashup/api/v1/leads/viewing-feedback/'
              }
            }}
            action={(params, resolve, reject) => actions.fetchViewingFeedback({
              modelname: config.modelname,
              action: 'viewing-feedback',
              modelid: model.id,
              resolve,
              reject,
              ...params
            })}
            header={[
              {
                name: 'meta.viewing.viewing_date',
                label: 'Viewing Date',
                format: 'date'
              },
              {
                name: [ 'meta.lead.meta.agent.first_name', 'meta.lead.meta.agent.last_name' ],
                label: 'Agent',
                link: '/secure/:site/agents/:meta.lead.agent/details'
              },
              {
                name: [ 'meta.viewing.feedback_layout', 'meta.viewing.feedback_location', 'meta.viewing.feedback_price', 'meta.viewing.feedback_size', 'meta.viewing.feedback_other' ],
                label: 'Concerns'
              },
              {
                name: [ 'meta.contact.first_name', 'meta.contact.last_name' ],
                label: 'Contact',
                link: '/secure/:site/contacts/:contact/details'
              },
              {
                name: 'meta.viewing.feedback',
                label: 'Feedback'
              }
            ]}
            user={user}
            params={{
              limit: 100,
              viewing__interested: 'Yes',
              meta_fields: [ 'lead', 'contact' ]
            }}
            parser={data => {
              const newData = {
                options: data.results.map(l => ({
                  ...l,
                  address: generateAddress(l)
                })),
                hasMore: !!data.next
              }
              return newData
            }}
            rowActions={(row, data) => (
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${getIn(data, 'site')}/${config.modelname}/${model.id}/leads/${getIn(data, 'lead')}`} />
            )}
          />
        }
      />
      <Card
        classes="grid-col-4 maxcard"
        bodyclass="viewing-feedback-list no-top-padding"
        background
        header={
          <h3>Possibly Interested</h3>
        }
        body={
          <SimpleTable
            paginated
            config={{
              endpoint: {
                read: '/mashup/api/v1/leads/viewing-feedback/'
              }
            }}
            action={(params, resolve, reject) => actions.fetchViewingFeedback({
              modelname: config.modelname,
              action: 'viewing-feedback',
              modelid: model.id,
              resolve,
              reject,
              ...params
            })}
            header={[
              {
                name: 'meta.viewing.viewing_date',
                label: 'Viewing Date',
                format: 'date'
              },
              {
                name: [ 'meta.lead.meta.agent.first_name', 'meta.lead.meta.agent.last_name' ],
                label: 'Agent',
                link: '/secure/:site/agents/:meta.lead.agent/details'
              },
              {
                name: [ 'meta.viewing.feedback_layout', 'meta.viewing.feedback_location', 'meta.viewing.feedback_price', 'meta.viewing.feedback_size', 'meta.viewing.feedback_other' ],
                label: 'Concerns'
              },
              {
                name: [ 'meta.contact.first_name', 'meta.contact.last_name' ],
                label: 'Contact',
                link: '/secure/:site/contacts/:contact/details'
              },
              {
                name: 'meta.viewing.feedback',
                label: 'Feedback'
              }
            ]}
            user={user}
            params={{
              limit: 100,
              viewing__interested: 'Maybe',
              meta_fields: [ 'lead', 'contact' ]
            }}
            parser={data => {
              const newData = {
                options: data.results.map(l => ({
                  ...l,
                  address: generateAddress(l)
                })),
                hasMore: !!data.next
              }
              return newData
            }}
            rowActions={(row, data) => (
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${getIn(data, 'site')}/${config.modelname}/${model.id}/leads/${getIn(data, 'lead')}`} />
            )}
          />
        }
      />
      <Card
        classes="grid-col-4 maxcard"
        bodyclass="viewing-feedback-list no-top-padding"
        background
        header={
          <h3>Not Interested</h3>
        }
        body={
          <SimpleTable
            paginated
            config={{
              endpoint: {
                read: '/mashup/api/v1/leads/viewing-feedback/'
              }
            }}
            action={(params, resolve, reject) => actions.fetchViewingFeedback({
              modelname: config.modelname,
              action: 'viewing-feedback',
              modelid: model.id,
              resolve,
              reject,
              ...params
            })}
            header={[
              {
                name: 'meta.viewing.viewing_date',
                label: 'Viewing Date',
                format: 'date'
              },
              {
                name: [ 'meta.lead.meta.agent.first_name', 'meta.lead.meta.agent.last_name' ],
                label: 'Agent',
                link: '/secure/:site/agents/:meta.lead.agent/details'
              },
              {
                name: [ 'meta.viewing.feedback_layout', 'meta.viewing.feedback_location', 'meta.viewing.feedback_price', 'meta.viewing.feedback_size', 'meta.viewing.feedback_other' ],
                label: 'Concerns'
              },
              {
                name: [ 'meta.contact.first_name', 'meta.contact.last_name' ],
                label: 'Contact',
                link: '/secure/:site/contacts/:contact/details'
              },
              {
                name: 'meta.viewing.feedback',
                label: 'Feedback'
              }
            ]}
            user={user}
            params={{
              limit: 100,
              viewing__interested: 'No',
              meta_fields: [ 'lead', 'contact' ]
            }}
            parser={data => {
              const newData = {
                options: data.results.map(l => ({
                  ...l,
                  address: generateAddress(l)
                })),
                hasMore: !!data.next
              }
              return newData
            }}
            rowActions={(row, data) => (
              <Button icon="#icon16-EyeOpen" className="btn btn-icon-16 btn-icon-only btn-none" component={NavLink} to={`/secure/${getIn(data, 'site')}/${config.modelname}/${model.id}/leads/${getIn(data, 'lead')}`} />
            )}
          />
        }
      />
      <WideSidebar sidebar={'show-viewing-feedback'}>
        <ViewingFeedbackSidebar
          key="show-viewing-feedback-edit"
          toggleLookup={toggleFeedback}
          communication={communication}
          config={{
            modelname: 'interaction',
            endpoint: {},
            fieldgroups: {
              'Log Viewing': {}
            },
            fields: [
              {
                name: 'interested',
                label: 'Interested',
                input: 'Select',
                options: [
                  { label: 'Yes', value: 'Yes' },
                  { label: 'Maybe', value: 'Maybe' },
                  { label: 'No', value: 'No' }
                ],
                edit: true,
                group: 'Log Viewing',
                required: true
              },
              {
                name: 'viewing_concerns',
                label: 'Concerns',
                edit: true,
                group: 'Log Viewing',
                input: 'CheckGroup',
                usenames: true,
                usevalues: false,
                unique: false,
                options: [
                  {
                    value: 'Price',
                    label: 'Price',
                    name: 'feedback_price'
                  },
                  {
                    value: 'Location',
                    label: 'Location',
                    name: 'feedback_location'
                  },
                  {
                    value: 'Size',
                    label: 'Size',
                    name: 'feedback_size'
                  },
                  {
                    value: 'Layout',
                    label: 'Layout',
                    name: 'feedback_layout'
                  },
                  {
                    value: 'Other',
                    label: 'Other',
                    name: 'feedback_other_check'
                  }
                ]
              },
              {
                name: 'feedback_other',
                label: 'Other',
                edit: [
                  [
                    {
                      field: 'feedback_other_check',
                      condition: {
                        value: true
                      }
                    }
                  ]
                ],
                required: [
                  [
                    {
                      field: 'feedback_other_check',
                      condition: {
                        value: true
                      }
                    }
                  ]
                ],
                input: 'TextArea'
              },
              {
                name: 'viewing_date',
                label: 'Viewing Date',
                input: 'Date',
                format: 'date',
                edit: true,
                readonly: true,
                group: 'Log Viewing',
                required: true
              },
              {
                name: 'feedback',
                label: 'Notes / Feedback',
                edit: true,
                group: 'Log Viewing',
                required: true,
                input: 'TextArea'
              },
              {
                label: 'Tags',
                name: 'tags',
                plural: 'tags',
                singular: 'interaction',
                edit: true,
                group: 'Log Viewing',
                bulkedit: true,
                multi: true,
                required: false,
                orderkey: 'label',
                extraparams: 'order_by=label',
                modelname: 'tags',
                optionlabel: 'label',
                input: 'AsyncCreateSelect',
                orderable: true,
                labelformat: {
                  head: [ 'label' ],
                  tags: [ 'level' ]
                },
                additional_data: {
                  level: 'User'
                }
              },
              {
                name: 'archive_lead',
                label: 'Archive Lead',
                edit: [
                  [
                    {
                      field: 'interested',
                      condition: {
                        value: 'No'
                      }
                    }
                  ]
                ],
                input: 'Check',
                cols: 'lg-2'
              }
            ]
          }}
          match={match}
        />
      </WideSidebar>
    </div>
  )
}

ViewingFeedback.propTypes = {
  currency: PropTypes.string,
  model: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  settings: PropTypes.object,
  cache: PropTypes.object,
  recent_match_count: PropTypes.number,
  older_match_count: PropTypes.number,
  loading_matches: PropTypes.bool,
  unhighlightMatch: PropTypes.func,
  highlightMatch: PropTypes.func,
  alertAgentPropertyLead: PropTypes.func,
  fetchProfileMatches: PropTypes.func,
  fetchRecentMatches: PropTypes.func,
  fetchOldMatches: PropTypes.func,
  fetchHighlights: PropTypes.func,
  addons: PropTypes.array,
  app: PropTypes.object,
  fetchMatches: PropTypes.func
}

export default ViewingFeedback
