/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, createModel, updateModel, notifyUser, exportData } from '../actions'
import { CONFIG, CACHE, SIDEBAR } from '../selectors'
import ReportEditSidebar from '../components/ReportEditSidebar'


function mapStateToProps(state, ownProps) {
  const modelconfig = CONFIG(state, ownProps.match?.params?.model)
  return {
    sidebar: SIDEBAR(state),
    cache: CACHE(state),
    config: CONFIG(state, 'reports'),
    modelconfig
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    exportData,
    notifyUser,
    toggleWideSidebar,
    createModel,
    updateModel
  }, dispatch)
})

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(ReportEditSidebar))
