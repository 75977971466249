/* eslint-disable new-cap */
import { Formik, getIn, setIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import withImmutablePropsToJS from 'with-immutable-props-to-js'


import ModelActions from '../../components/common/ModelActions'
import NoteCreator from '../../components/common/NoteCreator'
import Step from '../../components/common/Step'
import { withStep } from '../../components/common/StepComponent'
import Agent from '../../components/singles/Agent'
import Asset from '../../components/singles/Asset'
import Application from '../../components/singles/Application'
import Article from '../../components/singles/Article'
import Branch from '../../components/singles/Branch'
import Franchise from '../../components/singles/Franchise'
import Commercial from '../../components/singles/Commercial'
import Contact from '../../components/singles/Contact'
import Content from '../../components/singles/Content'
import Deal from '../../components/singles/Deal'
import Document from '../../components/singles/Document'
import Domain from '../../components/singles/Domain'
import Group from '../../components/singles/Group'
import Holiday from '../../components/singles/Holiday'
import Lead from '../../components/singles/Lead'
import LocationProfile from '../../components/singles/LocationProfile'
import MarketingEmail from '../../components/singles/MarketingEmail'
import Profile from '../../components/singles/Profile'
import Project from '../../components/singles/Project'
import Residential from '../../components/singles/Residential'
import Subscriber from '../../components/singles/Subscriber'
import Syndication from '../../components/singles/Syndication'
import Team from '../../components/singles/Team'
import Referral from '../../components/singles/Referral'
import Offer from '../../components/singles/Offer'
import Newsletter from '../../components/singles/Newsletter'
import Insights from '../../components/singles/Insights'
import Card from '../../components/common/Card'
import notesconfig from '../../config/note.json'
import log from '../../logging'
import { history } from '../../store'
import { ADDONS, CACHE, CACHEDMODELID, CONFIGS, MODELALERTS, MODELLEADS, PORTALS, SELECTED, UI, MINUSER, SETTINGS, APP } from '../../selectors'
import { chunkArray, hasAddons, hasPermission, isInViewport, parseURL, slugify, sortBy, uniqueArray, breakpoint, isConditional } from '../../utils'
import Loader from '../../components/common/Loader'
import WideSidebar from '../../components/ui/sidebar/WideSidebar'
import CustomForm from '../../components/common/forms/CustomForm'
import { withCustomRouter } from '../../components/withCustomRouter'
import Slider from '../../components/common/Slider'
import CreditCheckSidebar from '../CreditCheckSidebar'


class ModelViewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fetching: true,
      note: null,
      scrollTop: null,
      selectedGroup: {},
      currentGroup: {},
      showActions: breakpoint.matches,
      show_note: false,
      offset: 0,
      initvals: {},
      mergeable: false
    }
    this.hashChanged = this.hashChanged.bind(this)
    this.getCurrentElement = this.getCurrentElement.bind(this)
    this.hashLinkScroll = this.hashLinkScroll.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this)
    this.renderList = this.renderList.bind(this)
    this.fetchInitial = this.fetchInitial.bind(this)
    this.toggleActions = this.toggleActions.bind(this)
    this.updateScroller = this.updateScroller.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.unsetNote = this.unsetNote.bind(this)
    this.isConditional = isConditional.bind(this)
    this.timers = {}
    this._is_mounted = true
    this.actions = {
      fetchLeadsBreakdown: this.fetchLeadsBreakdown.bind(this),
      fetchLeads: this.fetchLeads.bind(this),
      fetchProfiles: this.fetchProfiles.bind(this),
      fetchReferrals: this.fetchReferrals.bind(this),
      fetchReferralContactMatch: this.props.actions.fetchReferralContactMatch,
      fetchActivity: this.fetchActivity.bind(this),
      fetchFeedLogs: this.fetchFeedLogs.bind(this),
      fetchListings: this.fetchListings.bind(this),
      fetchValuations: this.fetchValuations.bind(this),
      fetchReferralListings: this.fetchReferralListings.bind(this),
      fetchSubscribers: this.fetchSubscribers.bind(this),
      fetchNotes: this.fetchNotes.bind(this),
      fetchMatches: this.fetchMatches.bind(this),
      fetchRecentMatches: this.fetchRecentMatches.bind(this),
      fetchOldMatches: this.fetchOldMatches.bind(this),
      fetchAlerts: this.fetchAlerts.bind(this),
      fetchDealFinancialStructure: this.props.actions.fetchDealFinancialStructure,
      updateDealFinancialStructure: this.props.actions.updateDealFinancialStructure,
      fetchNotifications: this.fetchNotifications.bind(this),
      fetchHighlights: this.fetchHighlights.bind(this),
      highlightMatch: this.highlightMatch.bind(this),
      unhighlightMatch: this.unhighlightMatch.bind(this),
      fetchProfileMatches: this.fetchProfileMatches.bind(this),
      fetchRelated: this.fetchRelated.bind(this),
      renderRelated: this.renderRelated.bind(this),
      parseMeta: this.parseMeta.bind(this),
      fetchListingHistory: this.props.actions.fetchListingHistory,
      exportData: this.props.actions.exportData,
      notifyUser: this.props.actions.notifyUser,
      createModel: this.props.actions.createModel,
      toggleWideSidebar: this.props.actions.toggleWideSidebar,
      toggleNoteCreator: this.toggleNoteCreator.bind(this),
      editNote: this.editNote.bind(this),
      fetchPortalLogs: this.props.actions.fetchPortalLogs,
      sendActivation: this.props.actions.sendActivation, // Resend user activation
      sendReset: this.props.actions.sendReset, // Agent single send reset
      syndicatePortalItem: this.props.actions.syndicatePortalItem,
      fetchOne: this.props.actions.fetchOne, // Used for related data such as notes
      fetchMany: this.props.actions.fetchMany, // User for search stuff like agents in branch
      updateModel: this.props.actions.updateModel, // Used for archiving leads
      alertAgentPropertyLead: this.props.actions.alertAgentPropertyLead, // Allows agent to alert contact manually
      downloadImages: this.props.actions.downloadImages,
      deleteModel: this.props.actions.deleteModel,
      registerRedirect: this.props.actions.registerRedirect,
      createLeadInteraction: this.props.actions.createLeadInteraction,
      updateLeadInteraction: this.props.actions.updateLeadInteraction,
      fetchViewingFeedback: this.props.actions.fetchViewingFeedback,
      fetchTemplateConfig: this.props.actions.fetchTemplateConfig,
      updateTemplatePreview: this.props.actions.updateTemplatePreview,
      autosaveCheck: this.props.actions.autosaveCheck,
      notifyReferralRequest: this.props.actions.notifyReferralRequest,
      renderList: this.renderList.bind(this),
      fetchAgentStatistics: this.props.actions.fetchAgentStatistics,
      fetchBranchStatistics: this.props.actions.fetchBranchStatistics,
      handleUpdate: this.handleUpdate.bind(this),
      setScroller: this.setScroller.bind(this),
      setInitVals: this.setInitVals.bind(this),
      fetchInitial: this.fetchInitial,
      emailTemplate: this.props.actions.emailTemplate,
      validateSendGridDomains: this.props.actions.validateSendGridDomains,
      fetchMarketingEmailStats: this.props.actions.fetchMarketingEmailStats
    }
  }

  componentDidMount() {
    breakpoint.addEventListener('change', this.toggleActions)
    this.props.actions.dismissNotice() // Remove any notices on mount
    this.root = document.getElementById('wrapper')
    if (this.props.model && !this.hasViewPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
      this.props.actions.registerRedirect(redirect)
    }
    this.fetchInitial()
    const options = {
      root: this.scroller,
      threshold: [ 0.0, 0.5, 1.0 ],
      rootMargin: '20px'
    }
    this.observer = new IntersectionObserver(() => {
      this.getCurrentElement()
    }, options)
  }

  componentDidUpdate(prevProps) {
    if (this.props.model && !this.hasViewPermission()) { // If we're not allowed to be here, redirect to designated redirect
      const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
      this.props.actions.registerRedirect(redirect)
    }
    if ((!this.props.model || (getIn(prevProps.model, 'id') !== this.props.model.id)) && !this.state.fetching) {
      const model = this.props.match.params.log ? `syndication${this.props.match.params.log}` : this.props.match.params.model
      new Promise((resolve, reject) => {
        this.setState({ fetching: true })
        this.props.actions.fetchOne(model, this.props.modelid, resolve, reject)
      }).then(() => {
        this.setState({ fetching: false })
        this.fetchRelated() // Fetch the related data
        const note_data = [ model, this.props.modelid ]
        this.fetchNotes({
          obj_model__and__obj_id__or: note_data,
          relations__related_model__and__relations__related_id__or: note_data,
          order_by: '-created',
          meta_fields: [ 'files' ]
        })
      }).catch(e => {
        log.error(e)
        this.setState({ fetching: false })
        // There was an error fetching data, take us back to the list view
        const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
        this.props.actions.registerRedirect(redirect)
      })
    }
    if (this.props.model && Object.keys(this.props.cache.settings).length > 1 && !this.hasViewPermission()) { // If we're not allowed to be here, redirect to designated redirect
      log.error(this.props.model)
      const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
      this.props.actions.registerRedirect(redirect)
    }
    if (this.props.match.params.tab !== prevProps.match.params.tab) {
      setTimeout(() => {
        this.forceUpdate()
      }, 100)
    }
    const tab_el = document.querySelector('.tab-content')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
    let mergeable = false
    if (this.props.selected?.length > 1 && this.props.routeConfig.view?.mergeable) {
      mergeable = this.isConditional(
        this.props.routeConfig.view,
        'mergeable',
        false,
        { values: { ...getIn(this.props.cache, this.props.config.modelname, {}) } },
        { ...this.props.user, selected: this.props.selected },
        this.props.cache[this.props.config.modelname]
      )
    }
    if (this.state.mergeable !== mergeable) { this.setState({ mergeable }) }
  }

  componentWillUnmount() {
    this._is_mounted = false
    breakpoint.removeEventListener('change', this.toggleActions)
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  initObserver(el) {
    this.observer.observe(el)
  }

  fetchInitial() {
    const model = this.props.match.params.log ? `syndication${this.props.match.params.log}` : this.props.match.params.model
    new Promise((resolve, reject) => { // Fetch the singleton data
      this.props.actions.fetchOne(model, this.props.modelid, resolve, reject, false, true)
    }).then(res => {
      if (this._is_mounted) {
        this.setState({ fetching: false, initvals: res[model][this.props.modelid] }, () => {
          this.fetchRelated() // Fetch the related data
        })
      }
    }).catch(e => {
      log.error(e)
      if (this._is_mounted) {
        this.setState({ fetching: false })
        // There was an error fetching data, take us back to the list view
        const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model || this.props.match.params)
        this.props.actions.registerRedirect(redirect)
      }
    })
    window.scrollTo(0, 0)
  }

  unsetNote() {
    this.setState({ note: null })
  }

  fetchRelated() { // Fetch related model data
    const { model, cache, configs, config } = this.props
    const relatedModels = config.fields.filter(f => getIn(f, 'modelname', getIn(f, 'inner.modelname')))
    // The below commented code is for FieldArray related data. This is
    // mostly returned in the meta key in the response so is not necessary
    // at the moment, but could be in future.
    // const faInputs = config.fields.filter(f => f.input === 'FieldArray')
    // faInputs.forEach(fai => { // Fetch related data in FieldArrays too
    //   const arrayFields = fai.fields.filter(f => f.modelname)
    //   arrayFields.forEach(af => { af.faname = fai.name })
    //   relatedModels = relatedModels.concat(arrayFields)
    // })
    for (const a of relatedModels) {
      let fetch = false
      const related_model = getIn(a, 'modelname', getIn(a, 'inner.modelname'))
      if (!getIn(configs, related_model)) { continue } // Skip if the config is incorrect
      // if (a.faname) {
      //   if (model[a.faname] && model.meta[a.faname]) {
      //     const fdata = model.meta[a.faname].map(d => d[a.name])
      //   }
      // } else {
      const fname = model[a.relatedField] ? a.relatedField : a.name
      const fdata = model[fname]
      // }
      if (fdata) { // Ensure correct config
        if (getIn(cache, related_model)) { // Data is already cached for this model
          if (Array.isArray(fdata)) {
            fetch = (fdata.filter(id => !(id in getIn(cache, related_model))).length > 0) // Field is an array and one of the values is not in cache
          } else if (!(fdata in getIn(cache, related_model))) { // Related field not in cache
            fetch = true
          }
        } else if (Array.isArray(fdata) && fdata.length) {
          fetch = true
        } else if (!Array.isArray(fdata)) {
          fetch = true
        }
      }

      if (fetch) { // The data is not cached and must be fetched
        if (Array.isArray(fdata) && fdata.length > 1) { // There is more than one to fetch, do search
          const groups = chunkArray(fdata.filter(p => p), 50) // Split array into groups of 50
          const metafields = getIn(configs, related_model).fields.filter(f => f.metafield)
            .map(m => (m.modelname ? m.modelname : m.name))
          while (groups.length) { // Loop over groups and fetchMany
            let group = groups.pop()
            // eslint-disable-next-line
            new Promise((resolve, reject) => {
              if (group.length && group[0].id) { group = group.map(g => g.id) }
              const params = { id__in: uniqueArray(group), meta_fields: metafields }
              const values = {
                modelname: related_model,
                all: true,
                params: { ...params, limit: group.length }
              }
              if (getIn(configs, related_model) && getIn(configs, related_model).endpoint.read) {
                return this.props.actions.fetchMany({ values, resolve, reject })
              }
              return model.meta[related_model]
            }).catch(e => log.error(e))
          }
        } else if (configs[related_model] && configs[related_model].endpoint.read && fdata) {
          if (config.modelname === 'deals' && [ 'residential', 'commercial' ].includes(related_model)) {
            if (model.model_type !== related_model) {
              continue
            }
          }
          this.props.actions.fetchOne(related_model, fdata)
        }
      }
    }
  }

  renderRelated(modelname, relatedField, fieldName, returnarray = false) {
    // Renders related field data from a foreign model by id
    let data = null
    const newdata = []
    const field = this.props.config.fields.find(f => f.name === relatedField)
    if (this.props.cache[modelname]) {
      if (Array.isArray(this.props.model[relatedField])) {
        this.props.model[relatedField].forEach(id => {
          if (this.props.cache[modelname][id]) {
            const cached_data = this.props.cache[modelname][id]
            if (Array.isArray(fieldName) && cached_data) {
              const oneof = fieldName.map(name => {
                if (cached_data[name]) { return cached_data[name] }
                return null
              }).filter(name => name)
              newdata.push(oneof.join(field.labelseparator || ' '))
              if (newdata.length) { data = newdata }
            } else {
              newdata.push(this.props.cache[modelname][id][fieldName])
              if (newdata.length && !returnarray) {
                data = newdata.join(', ')
              } else {
                data = newdata
              }
            }
          }
        })
      } else if (this.props.cache[modelname][this.props.model[relatedField]]) {
        if (Array.isArray(fieldName)) {
          const id = this.props.cache[modelname][this.props.model[relatedField]]
          if (id) {
            fieldName.forEach(name => {
              if (id[name]) {
                newdata.push(id[name])
              }
            })
            if (newdata.length) { data = newdata }
          }
        } else {
          data = this.props.cache[modelname][this.props.model[relatedField]][fieldName]
        }
      }
    }
    return data
  }

  parseMeta(model, metaField) {
    let newdata = []
    const meta = getIn(model.meta, metaField.name)
    if (Array.isArray(metaField.optionlabel)) {
      if (meta) {
        if (Array.isArray(meta)) {
          newdata = meta.map(m => {
            const d = []
            metaField.optionlabel.forEach(name => {
              if (getIn(m, name)) {
                d.push(m[name])
              }
            })
            if (!d.length && metaField.name.includes('.')) {
              const subfields = metaField.name.split('.')
              let submeta = getIn(model.meta[subfields[0]].meta, subfields[1])
              if (submeta && Array.isArray(submeta)) { // Nested array metas ie. referring branch manager
                submeta = submeta.find(i => i.id === m)
                metaField.optionlabel.forEach(name => {
                  if (getIn(submeta, name)) {
                    d.push(submeta[name])
                  }
                })
              }
            }
            if (d.length) {
              return d.join(metaField.labelseparator || ' ')
            }
            return null
          })
        } else {
          metaField.optionlabel.forEach(name => {
            if (getIn(meta, name)) {
              newdata.push(meta[name])
            }
          })
          if (!newdata.length && metaField.name.includes('.')) {
            const subfields = metaField.name.split('.')
            const submeta = getIn(model.meta[subfields[0]].meta, subfields[1])
            if (submeta) { // Nested metas ie. 1:1 mapping ie. leads manager
              metaField.optionlabel.forEach(name => {
                if (getIn(submeta, name)) {
                  newdata.push(submeta[name])
                }
              })
            }
          }
          if (newdata.length) {
            newdata = newdata.join(metaField.labelseparator || ' ')
          }
        }
      }
    } else if (Array.isArray(meta)) {
      newdata = meta.map(m => {
        const d = []
        if (getIn(m, metaField.optionlabel)) {
          d.push(m[metaField.optionlabel])
        }
        if (d.length) {
          return d.join(metaField.labelseparator || ' ')
        }
        return null
      }).join(', ')
    } else if (getIn(model.meta, `${metaField.name}.${metaField.optionlabel}`)) {
      newdata = model.meta[metaField.name][metaField.optionlabel]
    }
    return newdata
  }

  // Listings and contacts
  fetchAlerts({ params = {}, resolve, reject }) {
    return this.props.actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'alerts',
        params
      },
      resolve,
      reject
    })
  }

  fetchNotifications({ params = {}, resolve, reject }) {
    return this.props.actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'agentnotifications',
        params: {
          meta_fields: [ 'agent' ],
          ...params
        }
      },
      resolve,
      reject
    })
  }

  fetchRecentMatches({ params = {}, resolve = null, reject = null }) {
    return this.props.actions.fetchMatches({
      modelname: this.props.config.modelname,
      suffix: 'recent/',
      id: this.props.modelid,
      resolve,
      reject,
      params
    })
  }

  fetchOldMatches({ params = {}, resolve = null, reject = null }) {
    return this.props.actions.fetchMatches({
      modelname: this.props.config.modelname,
      suffix: 'old/',
      id: this.props.modelid,
      resolve,
      reject,
      params
    })
  }

  fetchMatches({ params, resolve, reject }) {
    return this.props.actions.fetchMatches({
      noloader: true,
      modelname: this.props.config.modelname,
      id: this.props.modelid,
      resolve,
      reject,
      params
    })
  }

  fetchHighlights({ params = {}, resolve = null, reject = null }) {
    const values = {
      modelname: this.props.config.modelname,
      id: this.props.modelid,
      resolve,
      reject,
      params
    }
    return this.props.actions.fetchHighlights(values)
  }

  highlightMatch({ match, resolve, reject }) {
    this.props.actions.highlightMatch({
      match,
      modelid: this.props.modelid,
      modelname: this.props.config.modelname,
      resolve,
      reject
    })
  }

  unhighlightMatch({ match, resolve, reject }) {
    this.props.actions.unhighlightMatch({
      match,
      modelid: this.props.modelid,
      modelname: this.props.config.modelname,
      resolve,
      reject
    })
  }

  // Branches / Teams / Agents
  fetchLeadsBreakdown(data = {}) {
    this.props.actions.getLeadsBreakdown(data)
  }

  // Contacts
  fetchProfiles({ params = {}, resolve, reject }) {
    this.props.actions.fetchMany({
      values: {
        noloader: true,
        modelname: 'profiles',
        params: {
          contact: this.props.modelid,
          ...params
        }
      },
      resolve,
      reject
    })
  }

  // Contacts
  fetchProfileMatches(id, resolve = null, reject = null) {
    this.props.actions.fetchProfileMatches(
      id,
      resolve,
      reject
    )
  }

  fetchLeads({ params, resolve, reject }) {
    this.props.actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'leads',
        params: {
          ...params
        }
      },
      resolve,
      reject
    })
  }

  fetchReferrals({ params = {}, resolve, reject }) {
    this.props.actions.fetchMany({
      values: {
        noloader: true,
        modelname: 'referrals',
        params: {
          contact: this.props.modelid,
          ...params
        }
      },
      resolve,
      reject
    })
  }

  fetchReferralListings({ params = {}, resolve, reject }) {
    const promises = [
      new Promise((res, rej) => {
        this.props.actions.fetchMany({
          noloader: true,
          values: {
            modelname: 'residential',
            params: {
              owners__overlap__or: [ this.props.model.created_contact ],
              tenant__or: this.props.model.created_contact,
              property_sold_rented_to__overlap__or: this.props.model.created_contact,
              meta_fields: [ 'agent', 'branch' ],
              ...params
            }
          },
          resolve: res,
          reject: rej
        })
      }),
      new Promise((res, rej) => {
        this.props.actions.fetchMany({
          noloader: true,
          values: {
            modelname: 'commercial',
            params: {
              owners__overlap__or: [ this.props.model.created_contact ],
              tenant__or: this.props.model.created_contact,
              property_sold_rented_to__overlap__or: this.props.model.created_contact,
              meta_fields: [ 'agent' ],
              ...params
            }
          },
          resolve: res,
          reject: rej
        })
      })
    ]
    Promise.allSettled(promises).then(results => {
      try {
        let combined = []
        let hasMore = null
        results.forEach(r => {
          if (r.hasMore) { hasMore = r.hasMore }
          if (r.value && r.value.options) {
            combined = combined.concat(r.value.options)
          }
        })
        resolve({ listings: combined, hasMore })
      } catch (e) {
        reject(e)
      }
    })
  }

  fetchListings({ params = {}, resolve, reject }) {
    const promises = [
      new Promise((res, rej) => {
        this.props.actions.fetchMany({
          noloader: true,
          values: {
            modelname: 'residential',
            params: {
              owners__overlap__or: [ this.props.modelid ],
              tenant__or: this.props.modelid,
              meta_fields: [ 'agent', 'branch' ],
              ...params
            }
          },
          resolve: res,
          reject: rej
        })
      }),
      new Promise((res, rej) => {
        this.props.actions.fetchMany({
          noloader: true,
          values: {
            modelname: 'commercial',
            params: {
              owners__overlap__or: [ this.props.modelid ],
              tenant__or: this.props.modelid,
              meta_fields: [ 'agent' ],
              ...params
            }
          },
          resolve: res,
          reject: rej
        })
      })
    ]
    Promise.allSettled(promises).then(results => {
      try {
        let combined = []
        let hasMore = null
        results.forEach(r => {
          if (r.hasMore) {
            hasMore = r.hasMore
          }
          if (r.value) {
            combined = [ ...combined, ...r.value.options ]
          }
        })
        resolve({ options: combined, hasMore })
      } catch (e) {
        reject(e)
      }
    })
  }

  // Listings and contacts
  fetchValuations({ params = {}, resolve, reject }) {
    return this.props.actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'valuations',
        params
      },
      resolve,
      reject
    })
  }

  fetchSubscribers({ params = {}, resolve, reject }) {
    this.props.actions.fetchMany({
      values: {
        noloader: true,
        modelname: 'subscribers',
        endpoint: {
          read: '/mashup/api/v1/mailing-list'
        },
        searchkey: '',
        params: {
          contact: this.props.modelid,
          ...params
        }
      },
      resolve,
      reject
    })
  }

  // All models
  fetchActivity(params = {}, resolve = null, reject = null) {
    const { modelname, id, ...other_params } = params
    const values = {
      modelname: modelname ? modelname : this.props.config.servicename,
      id: id ? id : this.props.modelid,
      resolve,
      reject,
      params: other_params
    }
    this.props.actions.fetchActivity(values)
  }

  // All models
  fetchFeedLogs({ params = {}, resolve = null, reject = null }) {
    const { modelname, portal, listing_id, ...other_params } = params
    const values = {
      modelname,
      portal,
      listing_id,
      resolve,
      reject,
      params: other_params
    }
    this.props.actions.fetchFeedLogs(values)
  }

  fetchNotes(params = {}) {
    if (this.props.config.modelname !== 'groups') {
      const values = {
        modelname: 'notes',
        modellist: true,
        noloader: true,
        params
      }
      this.props.actions.fetchMany({ values })
    }
  }

  switchComponent(model = false) {
    const s = {}
    switch (model) {
      case 'agents':
        s.component = Agent
        break
      case 'applications':
        s.component = Application
        break
      case 'articles':
        s.component = Article
        break
      case 'assets':
        s.component = Asset
        break
      case 'franchises':
        s.component = Franchise
        break
      case 'branches':
        s.component = Branch
        break
      case 'cms':
        s.component = Content
        break
      case 'commercial':
        s.component = Commercial
        break
      case 'contacts':
        s.component = Contact
        break
      case 'projects':
        s.component = Project
        break
      case 'groups':
        s.component = Group
        break
      case 'holiday':
        s.component = Holiday
        break
      case 'leads':
        s.component = Lead
        break
      case 'location-profiles':
        s.component = LocationProfile
        break
      case 'profiles':
        s.component = Profile
        break
      case 'residential':
        s.component = Residential
        break
      case 'subscribers':
        s.component = Subscriber
        break
      case 'teams':
        s.component = Team
        break
      case 'referrals':
        s.component = Referral
        break
      case 'offers':
        s.component = Offer
        break
      case 'newsletters':
        s.component = Newsletter
        break
      case 'insights':
        s.component = Insights
        break
      case 'documents':
        s.component = Document
        break
      case 'domains':
        s.component = Domain
        break
      case 'deals':
        s.component = Deal
        break
      case 'marketing-emails':
        s.component = MarketingEmail
        break
      case 'syndicationresidential':
      case 'syndicationcommercial':
      case 'syndicationholiday':
      case 'syndicationprojects':
      case 'syndicationbranches':
      case 'syndicationagents':
        s.component = Syndication
        break
      default: {
        const redirect = parseURL(this.props.routeConfig.view.redirect, this.props.model)
        const search = new URLSearchParams(getIn(this.props, 'model.params', getIn(this.props, 'config.params', ''))).toString()
        this.props.actions.registerRedirect({ pathname: redirect, search })
        s.component = () => React.createElement('div', {}, 'Not Found')
      }
    }
    return s
  }

  hasViewPermission() {
    const { user, model, config, addons } = this.props
    if (config.addons) { return hasAddons(config.addons, addons) } // Entire module is disabled
    const requiredPermissions = this.props.routeConfig.view.permissions
    if (this.props.user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true }
    const hasViewOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_view_own'))
    if (model && hasPermission(hasViewOwnPermissions, this.props.user.permissions)) {
      switch (config.modelname) {
        case 'residential':
        case 'commercial':
        case 'projects':
        case 'holiday':
          if ([ model.agent, model.agent_2, model.agent_3, model.agent_4 ].indexOf(user.agent.id) === -1) {
            return false
          }
          return true
        case 'users':
          if (model.agent !== user.agent.id) { // Agent doesn't match
            return false
          }
          return true
        case 'leads':
          if (model.agent === user.agent.id) { // Agent is lead agent
            return true
          }
          if (!model.agent && hasPermission([ 'leads_view_unassigned' ], this.props.user.permissions)) {
            return true
          }
          if (
            hasPermission([ 'leads_contacts_associated_agents_view' ], this.props.user.permissions) &&
            model.meta.contact.associated_agents &&
            model.meta.contact.associated_agents.includes(user.agent.id)
          ) {
            // User is allowed to view associated contacts leads
            return true
          }
          // Agent not lead agent
          return false
        case 'contacts':
          if (!model.introduction_agent && hasPermission([ 'contacts_view_unassigned' ], this.props.user.permissions)) {
            return true
          }
          if (
            hasPermission([ 'contacts_associated_agents_view' ], this.props.user.permissions) &&
            model.associated_agents &&
            model.associated_agents.includes(user.agent.id)
          ) { // User is allowed to view associated contacts
            return true
          } else if (model.introduction_agent && model.introduction_agent === user.agent.id) {
            return true
          }
          return false
        case 'subscribers':
          if (
            hasPermission([ 'mailing_list_contacts_associated_agents_view' ], this.props.user.permissions) &&
            model.meta.contact.associated_agents &&
            model.meta.contact.associated_agents.includes(user.agent.id)
          ) { // User is allowed to view associated contacts subscribers
            return true
          } else if (model.meta.contact && model.meta.contact.introduction_agent === user.agent.id) {
            return true
          }
          return false
        case 'profiles':
          if (!model.agent && hasPermission([ 'profiles_view_unassigned' ], this.props.user.permissions)) {
            return true
          }
          if (
            hasPermission([ 'profiles_contacts_associated_agents_view' ], this.props.user.permissions) &&
            model.meta.contact.associated_agents &&
            model.meta.contact.associated_agents.includes(user.agent.id)
          ) { // User is allowed to view associated contacts profiles
            return true
          } else if (
            (model.meta.contact && model.meta.contact.introduction_agent === user.agent.id)
            || (model.agent === user.agent.id)
          ) {
            return true
          }
          return false
        default:
          return true
      }
    }
    const hasViewAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_view'))
    if (hasPermission(hasViewAllPermissions, user.permissions)) { return true }
    if (hasPermission(requiredPermissions, user.permissions)) { return true } // Implicit permissions
    return false
  }

  toggleNoteCreator(show) {
    this.setState({
      note: null,
      comment: null,
      associations: null,
      show_note: (show === null) ? !this.state.show_note : show
    })
  }

  editNote(note, comment = false, associations = false) {
    this.setState({ note, comment, associations }, () => {
      this.props.actions.toggleWideSidebar('show-notes-sidebar')
    })
  }

  renderList(exclude_groups) {
    const { config } = this.props
    return (
      <Slider
        closed={!this.state.showActions && !this.state.showJump}
        classes={`jumplist${this.state.sticky ? ' sticky' : ''}`}
        id={'slider-jumplist-groups'}
        childref={this.sliderref}
      >
        <div className="jumplist-inner" ref={el => { this.sliderref = el }}>
          {Object.keys(config.fieldgroups).filter(g => !exclude_groups.includes(g)).map((group, gidx) => {
            const el = document.getElementById(`${slugify(group)}`)
            if (!el) { return null }
            this.initObserver(document.querySelector(`#${slugify(group)}`))
            return (
              <NavLink
                key={`jl-${gidx}`}
                aria-current="step"
                isActive={() => slugify(group) === this.state.currentGroup.id}
                to={`#${slugify(group)}`}
                onClick={() => {
                  const element = this.scroller.querySelector(`#${slugify(group)}`)
                  this.scrollTo(element)
                  this.setState({ showJump: false })
                }}
              >
                {group}
              </NavLink>
            )
          })}
        </div>
      </Slider>
    )
  }

  toggleActions(e) {
    if (e.matches && !this.state.showActions) {
      this.setState({ showActions: true })
    } else if (e.matches !== undefined && this.state.showActions) {
      this.setState({ showActions: false })
    }
  }

  hashChanged() {
    this.hashLinkScroll()
  }

  getCurrentElement() {
    if (document.querySelectorAll('.card')) {
      clearTimeout(this.timers.currentGroup)
      this.timers.currentGroup = setTimeout(() => {
        let cards = document.querySelectorAll('.card')
        cards = Array.prototype.slice.call(cards)
        const visible = cards.filter(c => isInViewport(c))
        const currentAvailable = sortBy(cards.map(c => ({ id: c.getAttribute('id'), label: c.querySelector('h3') ? c.querySelector('h3').textContent : null, top: c.getBoundingClientRect().top, height: c.getBoundingClientRect().height })), 'top')
        const currentVisible = sortBy(visible.map(c => ({ id: c.getAttribute('id'), label: c.querySelector('h3') ? c.querySelector('h3').textContent : null, top: c.getBoundingClientRect().top, height: c.getBoundingClientRect().height })), 'top')
        let current
        if (this.state.selectedGroup && this.state.selectedGroup.id) {
          current = currentVisible.find(c => c.id === this.state.selectedGroup.id)
        }
        if (!current) {
          current = currentAvailable.filter(c => c.top + c.height >= (208 + (!this.state.showActions ? 65 : 0)))[0]
        }
        if (current && current.id !== getIn(this.state.currentGroup, 'id')) {
          this.setState({ currentGroup: current }, () => {
            clearTimeout(this.timers.push)
            this.timers.push = setTimeout(() => {
              history.replace({ hash: current.id })
            }, 300)
          })
        }
      }, 70)
    }
  }

  updateScroller() {
    const { hash } = window.location
    if (this.scroller) {
      this.scroller.scrollTop(0)
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      this.scrollTo(element)
      this.setState()
    } else {
      setTimeout(this.updateScroller, 1000)
    }
  }

  hashLinkScroll() {
    const { hash } = window.location
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(this.updateScroller, 1000)
    } else {
      window.scrollTo(0, 0)
    }
  }

  scrollTo(el) {
    if (!el) { return }
    const scrollTop = el.offsetTop - (!this.state.showActions ? 48 : 10)
    const currentGroup = { id: el.getAttribute('id'), label: el.querySelector('h3').textContent, top: el.getBoundingClientRect().top, height: el.getBoundingClientRect().height }
    if (
      scrollTop !== this.state.scrollTop
      || this.state.selectedGroup !== currentGroup
    ) {
      this.setState({ scrollTop, selectedGroup: currentGroup }, () => {
        this.scroller.scrollTo({
          top: scrollTop,
          behavior: 'smooth'
        })
      })
    }
  }

  handleSpringUpdate(spring) {
    const val = spring.getCurrentValue()
    if (this.scroller) {
      this.scroller.scrollTop(val)
    }
  }

  handleUpdate() {
    const { tab } = this.props.match.params
    if (tab === 'details') {
      this.getCurrentElement()
    }
  }

  handleSubmit(values, actions) {
    const { config } = this.props
    let valid = {
      id: this.state.initvals.id,
      endpoint: config.endpoint,
      modelname: config.modelname
    }
    config.fields.filter(field => field.viewinput).map(field => field.name).forEach(fname => {
      if (getIn(values, fname) || getIn(values, fname) === null) {
        valid = setIn(valid, fname, getIn(values, fname))
      }
    })

    return new Promise((resolve, reject) => {
      this.props.actions.updateModel({ values: valid, autosaved: false, resolve, reject })
    }).then(() => {
      this.fetchInitial()
      actions.setSubmitting(false)
    }).catch(e => {
      console.error(e)
    })
  }

  setScroller(el) {
    this.scroller = el
  }

  setInitVals(values) {
    this.setState({
      initvals: values
    })
  }

  render() {
    if (!this.props.settings) { return null }
    if (!this.props.model && !this.state.fetching) { return null }
    if (this.state.fetching) { return (<Loader />) }
    const single = this.switchComponent(this.props.config.modelname)
    const p = { ...this.props, actions: this.actions }
    return (
      <Formik
        initialValues={{
          ...this.state.initvals,
          endpoint: p.config.endpoint,
          modelname: p.config.modelname
        }}
        // validationSchema={getIn(validate, `${user.agent.site.region}.${config.modelname}`, getIn(validate, `default.${config.modelname}`))}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={this.handleSubmit}
      >{ formik => {
          this.form = formik
          return (
            <CustomForm
              model={this.props.model ? true : false}
              id="content"
              className="content"
              autosave={true}
              actions={{ autosaveCheck: p.actions.autosaveCheck, updateModel: p.actions.updateModel }}
              mode="view"
              modelname={p.config.modelname}
              userid={p.user.agent.id}
              modelid={p.model.id}
              onChange={ (changes, fmk, form) => {
                if (Object.keys(this.form.touched).length > 0) { // Only fire when touched
                  changes.forEach(changedfield => {
                    if (
                      getIn(this.form.touched, changedfield) &&
                      ![ undefined ].includes(getIn(this.form.values, changedfield))
                    ) {
                      p.config.fields.map(f => {
                        if (f.name === changedfield && f.onchange) {
                          return f
                        }
                        if (changedfield.match(/\.\d+\./gi)) {
                          const parts = changedfield.split(/\.\d+\./gi)
                          if (f.name === parts[0] && f.fields) {
                            const field = f.fields.find(fe => fe.name === parts[1])
                            if (field) {
                              return {
                                ...field,
                                name: changedfield
                              }
                            }
                          }
                        }
                        return null
                      }).filter(cb => cb).forEach(cb => {
                        if (p.actions[cb.onchange]) {
                          p.actions[cb.onchange](cb, this.form, form) // Perform the required action
                        }
                      })
                    }
                  })
                }
              } }
              render={ () => (
                <>
                  <div className="viewhead details">
                    <div className="action-bar">
                      <ModelActions
                        modelname={p.config.modelname}
                        singleton={true}
                        singular={p.config.singular}
                        modelid={p.model.id}
                        form={formik}
                      />
                      { p.selected && p.selected.length > 1 && document.querySelector('.stepper') &&
                      ReactDOM.createPortal(
                        <Card
                          background
                          body={
                            <Step
                              stepPage={this.props.steps.stepPage}
                              next={this.props.steps.next}
                              previous={this.props.steps.previous}
                              selected={this.props.steps.selected}
                              modelid={this.props.model.id}
                              mergeable={this.state.mergeable}
                            />
                          }
                        />,
                        document.querySelector('.stepper')
                      )
                      }
                    </div>
                  </div>
                  <div className={`view details ${p.config.modelname}`}>
                    <div className="viewcontent">
                      <single.component
                        {...p}
                        currentGroup={this.state.currentGroup}
                        form={formik}
                        mergeable={this.mergeable}
                      />
                    </div>
                    <WideSidebar sidebar="show-notes-sidebar">
                      <NoteCreator
                        associations={p.config.associations || []}
                        actions={{
                          toggleNoteCreator: this.toggleNoteCreator,
                          toggleWideSidebar: p.actions.toggleWideSidebar,
                          createModel: p.actions.createModel,
                          updateModel: p.actions.updateModel,
                          fetchOne: p.actions.fetchOne,
                          notifyUser: p.actions.notifyUser,
                          unsetNote: this.unsetNote
                        }}
                        note={this.state.note}
                        comment={this.state.comment}
                        modelid={p.model.id}
                        match={this.props.match}
                        modelconfig={p.config}
                        config={notesconfig.config}
                      />
                    </WideSidebar>
                    <WideSidebar sidebar="show-credit-check">
                      <CreditCheckSidebar
                        actions={{
                          toggleCreditCheck: () => { p.actions.toggleWideSidebar('show-credit-check') },
                          toggleWideSidebar: p.actions.toggleWideSidebar
                        }}
                        modelconfig={p.config}
                      />
                    </WideSidebar>
                  </div>
                </>
              )}
            />
          )
        }}
      </Formik>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  let model = false
  const modelname = ownProps.match.params.log ? `syndication${ownProps.match.params.log}` : ownProps.match.params.model
  let leads = Map({})
  let alerts = Map({})

  const configs = CONFIGS(state)
  const cache = CACHE(state)
  const selected = SELECTED(state, modelname)
  const ui = UI(state)
  const user = MINUSER(state)
  const portals = PORTALS(state)
  const addons = ADDONS(state)
  const app = APP(state)


  const minuser = Map({
    id: user.get('id'),
    agent: user.get('agent'),
    selected: user.get('selected'),
    permissions: user.get('permissions')
  })

  if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) {
    leads = MODELLEADS(state)
    alerts = MODELALERTS(state)
  }
  if ([ 'profiles', 'contacts' ].includes(modelname)) {
    alerts = MODELALERTS(state)
  }
  const tab = ownProps.match.params.tab || 'toolbox'
  const profiletype = ownProps.match.params.profiletype || 'buyer'
  if (modelname && cache) {
    model = CACHEDMODELID(state, modelname, ownProps.match.params.id)
  }

  const settings = SETTINGS(state)

  return {
    modelid: parseInt(ownProps.match.params.id, 10),
    selected,
    model,
    leads,
    alerts,
    tab,
    profiletype,
    config: configs.get(modelname),
    configs: configs,
    cache,
    user: minuser,
    ui,
    portals,
    settings,
    addons,
    app
  }
}

ModelViewContainer.propTypes = {
  routing: PropTypes.object,
  ui: PropTypes.object,
  modelid: PropTypes.number,
  selected: PropTypes.array,
  model: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  match: PropTypes.object,
  leads: PropTypes.object,
  tab: PropTypes.string,
  className: PropTypes.string,
  profiletype: PropTypes.string,
  configs: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  portals: PropTypes.object,
  steps: PropTypes.object,
  settings: PropTypes.object,
  routeConfig: PropTypes.object,
  addons: PropTypes.array,
  app: PropTypes.object
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { routeConfig, actions, match } = ownProps
  return ({ ...stateProps, routeConfig, actions, match })
}

export default withCustomRouter(
  connect(mapStateToProps, null, mergeProps)(withImmutablePropsToJS(withStep(ModelViewContainer)))
)
