import PropTypes from 'prop-types'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { valueFormat } from '../../utils'


const sitelabel = (website, microsite, formatted) => {
  if (website && formatted !== 'No') {
    if (microsite) {return 'View on Microsite' }
    return 'View on Website'
  }
  if (typeof formatted === 'string' && (formatted.startsWith('http') || formatted.startsWith('https'))) {
    return 'Visit Link'
  }
  return !website && formatted
}

const MetaDetail = (
  { label, value, format, className, classes, field, url, render, url_print = false }) => {
  if (render) {
    return (
      <div className={`meta-detail${className ? ` ${className}` : ''}`}>
        <label className="heavy">{label}</label>
        <div className={`col ${`${field && field.name}`}${classes ? `${classes}` : ''}`}>
          {render({ label, value, format, className, classes, field, url, render, url_print })}
        </div>
      </div>
    )
  }
  try {
    const formatted = valueFormat(format, value, field)

    let Link = NavLink
    let atag = false
    const website = label.toLowerCase().includes('microsite') || label.toLowerCase().includes('website')
    const microsite = label.toLowerCase().includes('microsite')
    if (url && typeof url === 'string' && (url.startsWith('http://') || url.startsWith('https://'))) {
      Link = 'a'
      atag = true
    }
    if (url === 'null') { return null }
    if (!formatted) { return null }
    if (url && typeof url === 'string' && url.startsWith('null') && field.modelname) {
      url = `/secure/${value.site}/${field.modelname}/${value.id}/details`
    }
    if (url) {
      return (value || [ 'yesno', 'number' ].includes(format)) ? (
        <div className={`meta-detail${className ? ` ${className}` : ''}`}>
          <label className="heavy">{label}</label>
          <div className={`col ${`${field && field.name}`}${classes ? `${classes}` : ''}`}>
            {website && formatted }
            {Array.isArray(url) ? (
              url.map((u, idx) => (
                <React.Fragment
                  key={`${field ? field.name : 'meta-detail'}-link-${idx}`}
                >
                  <Link
                    className="has-link"
                    to={!atag ? u : undefined}
                    href={atag ? u : undefined}
                    target={atag ? '_blank' : '_self'}
                  >
                    {valueFormat(format, value[idx], field)}
                  </Link>
                  {idx !== url.length - 1 && <span>,&nbsp;</span>}
                </React.Fragment>
              ))
            ) : (
              <Link
                className={`${website ? 'microsite' : ''} has-link` }
                key={`${field ? field.name : 'meta-detail'}-link`}
                to={!atag ? url : undefined}
                href={atag ? url : undefined}
                target={atag ? '_blank' : '_self'}
              >
                {sitelabel(website, microsite, formatted)}
              </Link>
            )}
          </div>
        </div>
      ) : null
    }
    return (value || [ 'yesno', 'number', 'choice', 'referral-contact' ].includes(format)) ? (
      <div className={`meta-detail${className ? ` ${className}` : ''}`}>
        <label className="heavy">{label}</label>
        {url_print && format === 'yesno' && formatted === 'Yes' ?
          <Link
            className={`col has-link ${`${field && field.name ? field.name : ''}`}${classes ? `${classes}` : ''}`}
            to={!atag ? url_print : undefined}
            href={atag ? url_print : undefined}
            target={atag ? '_blank' : '_self'}
            key={`${field ? field.name : 'meta-detail'}-link`}
          >
            {formatted}
          </Link> :
          <div className={`col ${`${field && field.name ? field.name : ''}`}${classes ? `${classes}` : ''}`}>{formatted}</div>
        }
      </div>
    ) : null
  } catch (e) {
    console.error(e)
    return null
  }
}

MetaDetail.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool
  ]),
  format: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  classes: PropTypes.array,
  field: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  url: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool
  ]),
  url_print: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool
  ]),
  render: PropTypes.func,
  model: PropTypes.object,
  modelname: PropTypes.string
}

export default MetaDetail
