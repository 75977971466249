import PropTypes from 'prop-types'
// import { getIn } from 'formik'
import React, { useState, useEffect } from 'react'

import ModelActions from './ModelActions'
import Card from './Card'
// import Search from './search/Search'


const ModelChoice = props => {
  // eslint-disable-next-line no-unused-vars
  const [ redirect, setRedirect ] = useState(false)
  const [ offset, setOffset ] = useState(0)

  useEffect(() => {
    props.actions.selectNone()
    window.scrollTo(0, 0)
    window.setTimeout(() => {
      props.actions.checkVersion()
    }, 3000)
    return () => { }
  }, [])

  useEffect(() => {
    const tab_el = document.querySelector('.viewhead')
    if (tab_el) {
      const newoffset = tab_el.getBoundingClientRect().bottom
      if (offset !== newoffset) { setOffset(newoffset) }
    }
  })

  const redirectSchema = schema => {
    setRedirect(schema) // Fired on submit via the ContextMenu
  }

  const selectRedirect = (location, key) => {
    if (props.match.params.model === 'email-templates') {
      props.actions?.registerRedirect(`/secure/${props.user.agent.site.id}/${props.cache[props.modelname][key].template_type}s/configure?template=${props.cache[props.modelname][key].name}`)
    }
    if (props.match.params.model === 'brand-templates') {
      props.actions?.registerRedirect(`/secure/${props.user.agent.site.id}/${props.cache[props.modelname][key].template_type}s/add?template=${props.cache[props.modelname][key].name}`)
    }
  }

  // const { config, configs, advanced, model, modelname, tableconfig,
  //   ui, user, actions, models, cache, selected } = props
  const { config, model, modelname, ui, user, actions, cache } = props
  if ((!config || !model) && ui.awaited) { return null }
  // Fix the race condition when hot reloading
  if (!user.preferences[modelname]) { return null }
  return (
    <div className={props.className} id={props.id}>
      <div className="viewhead">
        <ModelActions
          actions={actions}
          ui={ui}
          redirectSchema={redirectSchema}
          modelname={modelname}
        />
      </div>
      <div className="view container-fluid">
        <div className="viewcontent">
          <div className="grid-container grid-5 modelchoice">
            {cache[modelname] && model ? (
              model.index.map(key => { // Loop over model items
                if (cache[modelname][key] && cache[modelname][key].template_type === config.templatetype) {
                  return (
                    <Card
                      background={true}
                      classes="grid-col-1 choice"
                      key={`choice-${cache[modelname][key].id}`}
                      body={
                        <div
                          onClick={ e => selectRedirect(e, key) }
                          className="preview"
                        >
                          {cache[modelname][key].meta?.preview?.file ? (
                            <img
                              alt={cache[modelname][key].display_name}
                              src={cache[modelname][key].meta.preview.file}
                              // width={240}
                            />
                          ) : (
                            <div className="img-placeholder"></div>
                          )}
                          <h3>{cache[modelname][key].display_name}</h3>
                        </div>
                      }
                    />
                  )
                }
                return null
              })
            ) : null }
          </div>
        </div>
      </div>
    </div>
  )
}

ModelChoice.propTypes = {
  advanced: PropTypes.bool,
  cache: PropTypes.object,
  model: PropTypes.object,
  models: PropTypes.object,
  selected: PropTypes.array,
  tableconfig: PropTypes.object,
  config: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  log: PropTypes.string,
  configs: PropTypes.object,
  toggleAdvanced: PropTypes.func,
  findStatus: PropTypes.func,
  findDomain: PropTypes.func,
  handleReset: PropTypes.func,
  resetPage: PropTypes.func,
  actions: PropTypes.object,
  meta_cache: PropTypes.object,
  match: PropTypes.object,
  modelname: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
}

export default ModelChoice
