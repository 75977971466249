/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import PropTypes from 'prop-types'
import { Formik, Field, getIn } from 'formik'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import { isEqual } from 'date-fns'
import { CONFIG, MINUSER, MODELROUTE, CACHEDMODELID, CURRENCY } from '../../selectors'
import CustomForm from '../common/forms/CustomForm'
import TextInput from '../common/forms/inputs/Text'
import { Button } from '../ui/Button'
import { sortBy, updateSearchParms, valueFormat, useCustomCompareMemo } from '../../utils'
import QueryBuilder from '../common/QueryBuilder'
import ModelActions from '../common/ModelActions'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import ReportEditSidebar from '../../containers/ReportEditSidebar'
import WideSidebar from '../ui/sidebar/WideSidebar'
import Card from '../common/Card'
import SimpleTable from '../common/simpletable/SimpleTable'
import Tab from '../common/tabs/Tab'
import TableManager from '../../containers/TableManager'
import { toggleWideSidebar, fetchOne, exportData, toggleManager, updateModel, createModel, fetchMany } from '../../actions'
import Loader from '../common/Loader'


const fetchReport = ({ actions, templatename, id, modelname, overrides }) => {
  new Promise((resolve, reject) => actions.fetchOne('reports', id, resolve, reject)).then(r => {
    const report = r.reports[id]
    actions.setReport(report)
    new Promise((resolve, reject) => actions.fetchOne('templates', r.reports[id].template, resolve, reject)).then(t => {
      const template = t.templates[r.reports[id].template]
      actions.setTemplate(template)
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        const params = {
          params: {
            ...report.report_filters,
            ...overrides
          },
          modelname,
          args: {
            action: 'report',
            template: templatename
          },
          label: report.name,
          noalert: true,
          callback: actions.setData,
          resolve,
          reject
        }
        return actions.exportData(params)
      })
    })
  })
}

const arg_mapping = {
  'agent-sales-report': 'agent_id',
  'branch-sales-report': 'branch_id'
}

const ReportTable = props => {
  const { match, config, reports_config, location, user, actions, sidebar, currency } = props

  const qs = new QueryBuilder(location.search)
  const [ report, setReport ] = useState()
  const [ categories, setCategories ] = useState([])
  const [ category, setCategory ] = useState(match.params.tab)
  const [ search, setSearch ] = useState(qs.getAllArgs(false))
  const [ template, setTemplate ] = useState()
  const [ editTemplate, setEditTemplate ] = useState(qs.getAllArgs(false))
  const [ data, setData ] = useState()
  const [ preferences, setPreferences ] = useState([])
  const [ availablePreferences, setAvailablePreferences ] = useState({ reports: [] })
  const overrides = { ...search }
  if (match.params.arg1 && match.params.arg2) {
    overrides[arg_mapping[match.params.arg1]] = match.params.arg2
  }
  useEffect(() => {
    setData(null)
    fetchReport({
      actions: {
        fetchOne: actions.fetchOne,
        exportData: actions.exportData,
        setReport,
        setTemplate,
        setData
      },
      templatename: match.params.arg1 ? `${match.params.arg1}.json` : `${match.params.report}.json`,
      id: match.params.id,
      modelname: config.modelname,
      overrides
    })
  }, [ useCustomCompareMemo(match.params) ])

  useEffect(() => {
    if (editTemplate?.id) {
      actions.toggleWideSidebar('show-report-edit')
    } else {
      fetchReport({
        actions: {
          fetchOne: actions.fetchOne,
          exportData: actions.exportData,
          setReport,
          setTemplate,
          setData
        },
        templatename: match.params.arg1 ? `${match.params.arg1}.json` : `${match.params.report}.json`,
        id: match.params.id,
        modelname: config.modelname,
        overrides
      })
    }
  }, [ useCustomCompareMemo(editTemplate) ])

  const fetchPreferences = () => {
    new Promise((resolve, reject) => actions.fetchMany({
      values: {
        modelname: 'reports',
        endpoint: {
          read: `/reports/api/v1/report-templates/${match.params.id}/preferences/`
        },
        conflict: true
      },
      resolve,
      reject
    })).then(r => {
      const active = r.options.find(o => o.active) || { id: 0, name: 'Default', column_preferences: [ ...data.response.tableconfig ] } || {}
      setPreferences(active)
      setAvailablePreferences({ reports: [ { id: 0, name: 'Default', column_preferences: [ ...data.response.tableconfig ] }, ...r.options ] })
    })
  }

  useEffect(() => {
    if (!data?.response?.tableconfig) { return }
    fetchPreferences()
  }, useCustomCompareMemo([ data?.response?.tableconfig || [] ]))
  if (!report || !template) { return null }
  return (
    <>
      <Formik
        initialValues={{
          ...search
        }}
        enableReinitialize={true}
        onSubmit={(values, form) => {
          updateSearchParms('term', values.term)
          form.setTouched({})
          form.setSubmitting(false)
        }}
      >{formik => (
          <div className="content">
            <div className="viewhead details">
              <div className="action-bar">
                <ModelActions
                  touched={formik.touched}
                  errors={formik.errors}
                  handleSubmit={e => {
                    e.preventDefault()
                  }}
                  actions={{
                    toggleSidebar: () => {
                      actions.toggleWideSidebar('show-report-edit')
                    }
                  }}
                  isSubmitting={formik.isSubmitting}
                  form={formik}
                  extras={{
                    list: null,
                    'list-reports': {
                      ...config.modelactions.list,
                      className: 'list btn-none',
                      label: 'Back To Reports',
                      link: '/secure/:site/deals/reports'
                    },
                    'export-reports': {
                      label: 'Export (XLSX)',
                      action: 'exportData',
                      className: 'btn-red btn-round',
                      fields: true,
                      conditions: {
                        rightclick: {
                          show: false
                        }
                      },
                      args: {
                        action: 'report',
                        template: match.params.arg1 ? `${match.params.arg1}.xlsx` : `${match.params.report}.xlsx`,
                        fields: true,
                        filters: {
                          ...report.report_filters,
                          ...overrides
                        },
                        userfields: preferences?.column_preferences
                      },
                      routes: [
                        'reports'
                      ]
                    }
                  }}
                  modelname={config.modelname}
                  statusmsg={formik.status ? formik.status.msg : false}
                />
              </div>
            </div>
            <div className='view container-fluid'>
              <div className='viewcontent'>
                <div>
                  <Card
                    header={<h3>{report.name}</h3>}
                    background
                    body={
                      <>
                        <div className="report-table-criteria">
                          <div className="report-table-criteria-list">
                            {valueFormat('criteria', report, { fields: template.fields })}
                          </div>
                          <div className="report-table-criteria-buttons">
                            <Button id="edit_report" className="btn btn-primary" onClick={() => (editTemplate?.id === report.id ? setEditTemplate(null) : setEditTemplate(report))} title="Edit Report" type="button">Edit Report</Button>
                          </div>
                        </div>
                        {data?.response?.fields ? (
                          <div className="tablemeta">
                            <div className="page-tools">
                              <div className="table-manager">
                                <Button icon="#icon16-Columns" className="btn btn-subtle btn-icon-16 btn-table-manager" onClick={actions.toggleManager} />
                                <TableManager
                                  match={match}
                                  modelname="reports"
                                  config={data?.response || {}}
                                  preferences={preferences}
                                  availablePreferences={availablePreferences}
                                  actions={{
                                    updateTableConfig: (modelname, new_preferences) => {
                                      setPreferences({
                                        ...preferences,
                                        column_preferences: new_preferences
                                      })
                                    },
                                    addTableField: ({ values, resolve, reject }) => {
                                      const { fields } = data.response
                                      if (!fields) { return }
                                      const field = fields.find(f => JSON.stringify(f.name) === `"${values.field}"`)
                                      if (!field) {
                                        reject()
                                      }
                                      setPreferences({
                                        ...preferences,
                                        column_preferences: [ ...preferences.column_preferences, field ]
                                      })
                                      resolve()
                                    },
                                    removeTableField: field_name => {
                                      const { fields } = data.response
                                      if (!fields) { return }
                                      const field = fields.find(f => JSON.stringify(f.name) === field_name)
                                      setPreferences({
                                        ...preferences,
                                        column_preferences: [
                                          ...preferences.column_preferences.filter(f => !isEqual(f, field))
                                        ]
                                      })
                                    },
                                    createPreference: ({ values, resolve, reject }) => {
                                      new Promise((res, rej) => actions.createModel({
                                        values: {
                                          column_preferences: values.data,
                                          name: values.name,
                                          modelname: 'reports',
                                          endpoint: {
                                            write: `/reports/api/v1/report-templates/${match.params.id}/preferences/`
                                          }
                                        },
                                        resolve: res,
                                        reject: rej
                                      })).then(r => {
                                        fetchPreferences()
                                        resolve(r)
                                      }).catch(e => {
                                        reject(e)
                                      })
                                    },
                                    updatePreference: ({ resolve, reject, ...values }) => {
                                      // eslint-disable-next-line no-new
                                      new Promise((res, rej) => actions.updateModel({
                                        values: {
                                          ...values,
                                          modelname: 'reports',
                                          endpoint: {
                                            write: `/reports/api/v1/report-templates/${match.params.id}/preferences/`
                                          }
                                        },
                                        resolve: res,
                                        reject: rej
                                      })).then(resp => {
                                        if (resp) {
                                          setPreferences(availablePreferences.reports.find(p => p.id === resp))
                                        } else {
                                          setPreferences({ id: 0, name: 'Default', column_preferences: [ ...data.response.tableconfig ] })
                                        }
                                        resolve(resp)
                                      }).catch(e => {
                                        reject(e)
                                      })
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    }
                  />
                  <Card
                    header={null}
                    background
                    body={
                      <div className="report-table-wrapper">
                        {data?.status !== 200 ? (
                          <Loader inline />
                        ) : null}
                        {data?.status === 200 ? (
                          <SimpleTable
                            orderable
                            config={{
                              ...config,
                              doubleclick: data.response.doubleclick,
                              fields: [
                                ...config.fields,
                                ...preferences?.column_preferences || []
                              ]
                            }}
                            summerize_footer={data.response.summerize_footer}
                            action={null}
                            params={null}
                            parser={results => {
                              results.options = data.response.data.map(r => {
                                r.rid = match.params.id
                                return r
                              })
                              if (data.response?.summerize_footer) {
                                const final_row = { ...results.options[0] }
                                const total_rows = data.response?.tableconfig?.filter(f => f.total)
                                  .map(f => f.name) || []
                                Object.keys(final_row).forEach(k => {
                                  if (!total_rows.includes(k)) {
                                    final_row[k] = null
                                  } else {
                                    final_row[k] = results.options.reduce((prevVal, nextVal) => (
                                      prevVal + parseFloat(nextVal[k])
                                    ), 0)
                                  }
                                })
                                results.final_row = final_row
                              }
                              results.hasMore = false
                              return results
                            }}
                            match={match}
                            currency={currency}
                            data={data.response.data}
                            header={preferences?.column_preferences || []}
                            user={user}
                          />
                        ) : null}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <WideSidebar sidebar={'show-report-edit'}>
        <ReportEditSidebar
          modelname={config.servicename}
          templates={[ template ]}
          report={editTemplate}
          sidebar={'show-report-edit'}
          setEditTemplate={setEditTemplate}
          setReport={setReport}
          match={match}
        />
      </WideSidebar>
    </>
  )
}

ReportTable.propTypes = {
  location: PropTypes.object,
  config: PropTypes.object,
  reports_config: PropTypes.object,
  user: PropTypes.object,
  routeConfig: PropTypes.object,
  match: PropTypes.object,
  currency: PropTypes.string,
  sidebar: PropTypes.string,
  actions: PropTypes.object
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  const { model, id } = ownProps.match.params
  const user = MINUSER(state)
  const config = CONFIG(state, model)
  const reports_config = CONFIG(state, 'reports')
  const routeConfig = MODELROUTE(state, model)
  const report = CACHEDMODELID(state, model, id)
  const currency = CURRENCY(state)

  return {
    user,
    config,
    report,
    reports_config,
    routeConfig,
    currency
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    exportData,
    fetchOne,
    fetchMany,
    createModel,
    updateModel,
    toggleWideSidebar,
    toggleManager
  }, dispatch)
})

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(ReportTable))

