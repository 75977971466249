import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import CustomForm from '../forms/CustomForm'

import { logEvent, updateSearchParms } from '../../../utils'


const PageJump = props => {
  const [ page, setPage ] = useState(1)
  const [ pages, setPages ] = useState(1)

  const calculatePages = () => {
    if (props.params) {
      const { offset, limit } = props.params
      const { count } = props
      let newpage = parseInt(page, 10)
      const newpages = Math.ceil(count / limit)
      if (offset && limit) {
        newpage = Math.floor(offset / limit) + 1
      } else {
        newpage = 1
      }
      if (newpage !== page || newpages !== pages) {
        setPage(newpage)
        setPages(newpages)
      }
    }
  }

  const goToPage = values => {
    if (props.params) {
      const { count } = props
      const offset = (Math.max(values.page, 1) - 1) * props.params.limit
      if (offset <= count) {
        updateSearchParms('offset', offset)
        logEvent('PAGE_JUMP', { modelname: props.modelname, page: values.page })
      }
    }
  }

  const isEnter = e => {
    if (e.keyCode === 13) { // fire goToPage on enter
      return goToPage({ page: e.target.value })
    } // continue typing
    return true
  }

  useEffect(() => {
    calculatePages()
  }, [ props ])

  if (props.count) {
    return (
      <div className="pagejump">
        <Formik
          enableReinitialize={true}
          onSubmit={goToPage}
          initialValues={{
            page: page
          }}
        >{ () => (
            <CustomForm
              component="div"
              render={() => (
                <>
                  <span>Page</span>
                  <div className="form-group page">
                    <div className="forminput">
                      <Field
                        component="input"
                        id="page"
                        name="page"
                        type="number"
                        className="form-control form-control-lg page-jump"
                        onKeyDown={isEnter}
                        min={1}
                      />
                    </div>
                  </div>
                  <span>of {pages}</span>
                </>
              )}
            />
          )}
        </Formik>
      </div>
    )
  }
  return null
}

PageJump.propTypes = {
  params: PropTypes.object,
  count: PropTypes.number,
  modelname: PropTypes.string,
  endpoint: PropTypes.object
}

export default PageJump
