/* eslint-disable no-process-env */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'

import { slideUp } from '../utils'
import GlobalAdd from '../containers/GlobalAdd'
import { useBreakPoint } from '../hooks/useBreakPoint'
import Crumb from './common/Crumb'
import { Button } from './ui/Button'
import Nav from './ui/sidebar/Nav'
import UserHeader from './UserHeader'


const Header = ({ user, actions, addons, ui, toggleNav, nav }) => {
  const hideNavItem = () => {
    for (const item of document.getElementsByClassName('subnavitem')) { slideUp(item) }
  }
  const showAlert = useBreakPoint()
  const [ unread, setUnread ] = useState(0)
  const [ open, setOpen ] = useState(false)
  const chatHolder = useRef(document.querySelector('#hubspot-chat-holder'))

  const updateUnreadCount = payload => {
    setUnread(payload.unreadCount)
  }
  const toggleChat = (state = null) => {
    if ([ true, false ].includes(state)) {
      setOpen(state)
    } else if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }
  const closeChat = () => {
    setOpen(false)
  }

  useEffect(() => {
    window.addEventListener('hs:close', closeChat)
    return () => {
      window.removeEventListener('hs:close', closeChat)
    }
  })

  useEffect(() => {
    if (chatHolder.current) {
      if (open) {
        chatHolder.current.style.visibility = 'visible'
        window.HubSpotConversations?.widget.refresh()
        window.HubSpotConversations?.widget.open()
      } else {
        chatHolder.current.style.visibility = 'hidden'
      }
    }
  }, [ open ])

  useEffect(() => {
    chatHolder.current = document.querySelector('#hubspot-chat-holder')
    window.HubSpotConversations?.on('unreadConversationCountChanged', updateUnreadCount)
    window.HubSpotConversations?.on('widgetClosed', closeChat)
    return () => {
      window.HubSpotConversations?.off('unreadConversationCountChanged', updateUnreadCount)
      window.HubSpotConversations?.off('widgetClosed', closeChat)
    }
  }, [])

  const toggleNavWrap = () => {
    setOpen(false)
    toggleNav()
    if (nav === 'mini') { hideNavItem() }
  }

  const { alerts } = user
  const activealerts = alerts.length
  if (!user.agent) { return null }
  return (
    <>
      <div id="header" className="headerwrap">
        { user.agent &&
        <>
          <div id="main-header" className="main-header">
            <div role="button" className="navbtn" onClick={toggleNavWrap}>
              <div>
                <svg className="open" viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-Menu" /></svg>
                <svg className="close" viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-X-Large" /></svg>
              </div>
            </div>
            {showAlert ? (
              <>
                <div className="alertshead">
                  <Button icon="#icon24-Bell" component='div' onClick={e => {
                    actions.toggleAlerts(e)
                    setOpen(false)
                  }} className="action navitem btn btn-text btn-icon-24 alerts-head">
                    <div className="badge">{activealerts}</div>
                  </Button>
                </div>
                <div className="chatshead">
                  <Button icon="#icon24-Chat" component='div' onClick={toggleChat} className="action navitem btn btn-text btn-icon-24 alerts-head">
                    <div className="badge">{unread}</div>
                  </Button>
                </div>
              </>
            ) : null}
            <div className="userhead">
              <UserHeader
                ws={ui.ws}
                setChatOpen={toggleChat}
                activealerts={activealerts}
                showAlert={!showAlert}
                unread={unread}
                toggleAlerts={actions.toggleAlerts}
                user={user}
                actions={actions}
                addons={addons}
              />
            </div>
            <div className="globaladd">
              <GlobalAdd setChatOpen={toggleChat} />
            </div>
            <div className="breadcrumbs">
              <Crumb />
            </div>
          </div>
        </>
        }
      </div>
      <Nav />
    </>
  )
}

Header.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object,
  settings: PropTypes.object,
  ui: PropTypes.object,
  nav: PropTypes.object,
  match: PropTypes.object,
  addons: PropTypes.array,
  toggleAlerts: PropTypes.func,
  toggleNav: PropTypes.func
}

export default Header

// Header.whyDidYouRender = true
