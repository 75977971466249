import classNames from 'classnames'
import { Formik, getIn } from 'formik'
import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import PropTypes from 'prop-types'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import merge from 'deepmerge'
import 'whatwg-fetch'

import log from '../../../logging'
import { capitalize, hasAddons, hasPermission, isConditional, parseURL, sortBy } from '../../../utils'
import { Button } from '../../ui/Button'
import ChallengeModal from '../modals/ChallengeModal'
import QueryBuilder from '../QueryBuilder'
import { useBreakPoint } from '../../../hooks/useBreakPoint'
import ContextButton from './ContextButton'


const ContextMenu = props => {
  const { user, model, modelname, match, extras, cache, form, config, ui,
    portals, dropdownHover, route: forcedRoute, selected, addons, app, settings } = props

  let { modelid } = props
  const { id } = match.params
  if (!modelid) { modelid = parseInt(id, 10) }

  const downloadManager = useRef(new DownloadManager({
    disableCreateObjectURL: false
  }))

  const dropdownPositionCallback = el => {
    if (!el) { return false }
    const box = el.getBoundingClientRect()
    const parent = el.offsetParent
    const newbox = {
      x: box.x,
      y: box.y,
      bottom: box.bottom,
      top: box.top,
      left: box.left,
      right: box.right
    }
    const cursorX = newbox.x
    const cursorY = parent ? parent.getBoundingClientRect().top + window.scrollY + box.height + 10 : 0
    const updatedStyles = { top: cursorY, left: cursorX, position: 'absolute', display: 'block', minWidth: box.width }
    return updatedStyles
  }

  const [ note, setNote ] = useState(false)
  const [ backTo, setBackTo ] = useState(history.length)
  const showActions = useBreakPoint()

  const root = useRef(document.getElementById('wrapper'))

  const toggleNoteCreator = useCallback(() => {
    props.actions.toggleWideSidebar('show-notes-sidebar')
    setNote(!note)
  }, [ note ])

  const toggleVacancyPro = () => {
    props.actions.toggleWideSidebar('show-vacancy-sidebar')
  }

  const toggleCreditCheck = () => {
    props.actions.toggleWideSidebar('show-credit-check')
  }

  const [ challenge, setChallenge ] = useState({
    challenge: false,
    action: null
  })

  const [ actions, setActions ] = useState({
    ...props.actions,
    dropdownPositionCallback: props.dropdownPositionCallback || dropdownPositionCallback,
    refreshList: () => {
      let matchmodel = props.match.params.model
      const { log: logname } = props.match.params // For syndication logs
      if (logname) { matchmodel = `${matchmodel}${logname}`}
      const { params } = props.model
      props.actions.selectNone() // Clear selected models
      const refresh = new Promise((resolve, reject) => {
        const qs = new QueryBuilder(props.location.search)
        const values = {
          modelname: matchmodel,
          endpoint: props.config.endpoint,
          params: { ...params, ...qs.getAllArgs(), offset: 0 },
          modellist: true
        }
        props.actions.fetchMany({ values, resolve, reject })
      })
      return refresh
    },
    submitForm: btn => {
      const btnform = btn.form
      let isDisabled = false
      if (props.form) {
        if (getIn(btn, 'redirect') && props.redirectSchema) { props.redirectSchema(btn.redirect) }
        if (props.handleSubmit) {
          return props.handleSubmit(btn)
        }
        props.form.submitForm()
        isDisabled = true
      } else if (props.handleSubmit) {
        if (getIn(btn, 'redirect') && props.redirectSchema) { props.redirectSchema(btn.redirect) }
        return props.handleSubmit()
      }
      if (!btnform.isValid && Object.keys(btnform.errors).length) {
        const errs = Object.keys(btnform.errors)
        let { touched } = btnform
        const { errors } = btnform
        touched = { ...touched }
        Object.keys(errors).forEach(f => { // ensure the errors display by marking the fields as touched
          if (!Object.keys(touched).includes(f)) {
            touched[f] = true
          }
        })
        const errorfields = sortBy(errs.map(err => ({
          name: err,
          ordinal: props.config.fields.findIndex(c => c.name === err)
        })).filter(e => e.ordinal !== -1), 'ordinal')
        const firsterr = errorfields.length ? errorfields[0].name : errs[0]
        const raw = {}
        raw[firsterr] = errors[firsterr] && Array.isArray(errors[firsterr]) ? errors[firsterr] : [ errors[firsterr] ]
        // find field that's visibile
        const errfield = props.config.fields.find(c => c.name === firsterr && isConditional(c, 'edit', false, btnform))
        if (!errfield) {
          props.actions.notifyUser({
            title: 'Invalid data',
            body: 'The form data is not valid',
            type: 'error'
          })
          log.error(`Error field not found: ${JSON.stringify(errs)}`)
        }
        return true
      }
      if (btnform.isSubmitting) { isDisabled = true }
      if (!isDisabled) {
        if (getIn(btn, 'redirect') && props.redirectSchema) { props.redirectSchema(btn.redirect) }
        btnform.handleSubmit(btn)
      }
      return true
    },
    deleteModels: () => {
      setChallenge({ challenge: true, action: 'deleteModels' })
    },
    mergeModels: () => {
      setChallenge({ challenge: true, action: 'mergeModels' })
    },
    downloadDocuments: values => {
      values.filename = `documents_${values.selected ? values.selected.length : 1}_${new Date().getTime()}.zip`
      new Promise((resolve, reject) => props.actions.downloadImages({ values, resolve, reject })).then(r => {
        const blob = new Blob([ r ], { type: 'application/zip' })
        const url = window.URL.createObjectURL(blob)

        const downloadByUrl = () => {
          downloadManager.current.downloadUrl(url, values.filename)
        }
        try {
          downloadManager.current.download(blob, url, values.filename)
        } catch (e) {
          downloadByUrl()
        }
      }).catch(e => {
        console.error(e)
      })
    },
    downloadDocument: values => {
      const selectedvalues = getIn(values.selected, '0')
      const file = getIn(props.cache, selectedvalues)
      if (!file) { return }
      fetch(file.meta.document.file).then(r => r.blob()).then(r => {
        const blob = new Blob([ r ], { type: file.meta.document.content_type })
        const url = window.URL.createObjectURL(blob)
        const filename = file.meta.document.caption ? file.meta.document.caption : file.meta.document.file.split('/').pop()
        const downloadByUrl = () => {
          downloadManager.current.downloadUrl(url, filename)
        }
        try {
          downloadManager.current.download(blob, url, filename)
        } catch (e) {
          console.error(e)
          downloadByUrl()
        }
      }).catch(e => {
        console.error(e)
      })
    },
    toggleNoteCreator: toggleNoteCreator,
    toggleVacancyPro: toggleVacancyPro,
    toggleCreditCheck: toggleCreditCheck,
    goBack: () => {
      if ([ 'modules' ].includes(getIn(props, 'match.params.model'))) {
        const module_model = getIn(props.cache, getIn(props, 'match.params.id'), null)
        if (module_model.parent && module_model.status !== 'Published') {
          props.actions.registerRedirect(`/secure/${getIn(props, 'match.params.site')}/${getIn(props, 'match.params.model')}/${model.parent}/edit/versions/${model.id}`)
        }
      } else {
        const goBacks = backTo - history.length - 1
        props.history.go(goBacks)
      }
    },
    createListing: () => {
      if (props.match.params.model === 'referrals') {
        let selected_model = false
        if (props.selected.length) {
          selected_model = getIn(props.cache, props.selected[0])
        } else if (props.form) { // Handle unsaturated page refresh ie. straight to ModelView
          selected_model = props.form.values
        }
        if (selected_model) {
          let model_type = 'residential'
          if (selected_model.seller_listing_type.includes('Commercial')) { model_type = 'commercial' }
          props.actions.registerRedirect(`/secure/${getIn(props, 'match.params.site')}/${model_type}/add?model=referrals&id=${selected_model.id}`)
        } else {
          props.actions.registerRedirect(`/secure/${getIn(props, 'match.params.site')}/residential/add`)
        }
      }
    },
    createValuation: () => {
      if (props.match.params.model === 'referrals') {
        let selected_model = false
        if (props.selected.length) {
          selected_model = getIn(props.cache, props.selected[0])
        } else if (props.form) { // Handle unsaturated page refresh ie. straight to ModelView
          selected_model = props.form.values
        }
        if (selected_model) {
          props.actions.registerRedirect(`/secure/${getIn(props, 'match.params.site')}/valuations/add?model=referrals&id=${selected_model.id}`)
        } else {
          props.actions.registerRedirect(`/secure/${getIn(props, 'match.params.site')}/valuations/add`)
        }
      }
    },
    unassignPrimaryAgent: () => {
      setChallenge({ challenge: true, action: 'unassignPrimaryAgent' })
    }
  })


  const checkBranches = () => {
    const has_selected = selected.length ? selected : [ modelid ]
    if (user.permissions.includes('apply_to_all_branches') || user.permissions.includes('is_prop_data_user')) {
      return true
    }
    const allowed_records = has_selected.map(sel => {
      const m = cache ? cache[sel] : null
      if (!m) { return null }
      const all_here = user.agent.branches.filter(b => b === sel)
      return all_here.some(perm => perm) ? m.id : null
    })
    if (allowed_records.filter(l => l).length === has_selected.length) {
      return true
    }
    return false
  }

  const isButtonVisible = (button, buttonaction) => {
    let v = null
    let route = match.params.action
    if (!route && id) {
      route = 'view'
    }
    if (!route && match.params.model) {
      route = 'list'
    }
    if (!route && forcedRoute) {
      route = forcedRoute
    }
    let { model: matchmodel } = match.params
    const { log: logname } = match.params
    if (logname) { matchmodel = `${matchmodel}${logname}`} // Syndication logs
    if (button.conditions) {
      if (button.conditions.permissions) { // check non-record specific perms
        let context = selected.length ? cache[selected[0]] : false
        if (modelid && !context) {
          context = getIn(cache, modelid)
        }
        v = hasPermission(
          button.conditions.permissions,
          user.permissions,
          context,
          user.agent.id,
          button.conditions.permission_key,
          button.conditions.preferOwn
        )
      }

      if (v && button.conditions.own) { // check record specific perms for branches
        v = checkBranches()
      }

      if (
        user.permissions.includes('apply_to_all_branches') &&
        [
          'branches',
          'agents',
          'teams',
          'residential',
          'commerical',
          'holiday',
          'projects',
          'contacts',
          'profiles',
          'leads',
          'subscribers',
          'referrals',
          'syndication'
        ].includes(matchmodel)
      ) { v = true }

      if (user.permissions.includes('is_prop_data_user')) {
        if (button.conditions && button.conditions.permissions && button.conditions.permissions.includes('!is_prop_data_user')) {
          v = false
        } else {
          v = true
        }
      }

      if (v || v === null) { // passed perms, or not perms specific
        // Count goes first as it is least important and may conflict with the other conditions like selected
        if (button.conditions.count) { // Are there items in the model at all?
          const model_count = model && Array.isArray(model.index) ? model.index.length : 0
          if (button.conditions.count.hasOwnProperty('min') && button.conditions.count.hasOwnProperty('max')) {
            v = (model_count >= button.conditions.count.min && model_count <= button.conditions.count.max)
          } else if (button.conditions.count.hasOwnProperty('min')) {
            v = (model_count >= button.conditions.count.min)
          } else if (button.conditions.count.hasOwnProperty('max')) {
            v = (model_count <= button.conditions.count.max)
          }
        }

        if (button.conditions.selected) { // Are there items selected in this model?
          let selected_count = selected.length
          if (!selected_count && id) { selected_count = 1 }
          if (!selected_count && modelid) { selected_count = 1 }
          if (button.conditions.selected.hasOwnProperty('min') && button.conditions.selected.hasOwnProperty('max')) {
            v = (selected_count >= button.conditions.selected.min && selected_count <= button.conditions.selected.max)
          } else if (button.conditions.selected.hasOwnProperty('min')) {
            v = (selected_count >= button.conditions.selected.min)
          } else if (button.conditions.selected.hasOwnProperty('max')) {
            v = (selected_count <= button.conditions.selected.max)
          }
        }

        if (button.conditions.rightclick) { // Is this a right click context menu
          if (dropdownHover) { v = button.conditions.rightclick.show }
        }

        if (button.conditions.portal) { // Is the portal configured?
          v = false
          if (cache[modelid] && cache[modelid].meta && cache[modelid].meta.portals) {
            const pidx = Object.keys(portals).find(pk => portals[pk].meta.portal.slug === button.conditions.portal)
            if (pidx) {
              const pcfg = cache[modelid].meta.portals.find(p => p.portal === portals[pidx].portal)
              if (pcfg && pcfg.active) { v = true }
            }
          }
        }
        if (v && button.conditions.active !== undefined && selected) { // Does this button only apply to a specific status
          v = selected.every(item => cache[item].active === !!button.conditions.active)
        }
        if (v && button.conditions.status !== undefined && selected) { // Does this button only apply to a specific status
          v = selected.every(item => cache[item].status === button.conditions.status)
        }

        const visibility = getIn(button, 'conditions.visible')
        if (visibility && Array.isArray(visibility)) { // Visibility based on model data value
          let has_selected = selected
          if ((!Array.isArray(has_selected.length) || !has_selected.length) && modelid) {
            has_selected = [ modelid ]
          }
          if (button.routes && button.routes.includes('add') && !modelid) {
            has_selected = [ true ]
          }

          // handle addon checks when no listings selected
          if (!has_selected.length && button.conditions.selected) { // Are there items selected in this model?
            let selected_count = has_selected.length
            if (!selected_count && id) { selected_count = 1 }
            if (!selected_count && modelid) { selected_count = 1 }
            if (button.conditions.selected.hasOwnProperty('min') && button.conditions.selected.hasOwnProperty('max')) {
              has_selected = [
                (selected_count >= button.conditions.selected.min
                && selected_count <= button.conditions.selected.max)
              ]
            } else if (button.conditions.selected.hasOwnProperty('min')) {
              has_selected = [ (selected_count >= button.conditions.selected.min) ]
            } else if (button.conditions.selected.hasOwnProperty('max')) {
              has_selected = [ (selected_count <= button.conditions.selected.max) ]
            }
          }

          if (!has_selected.length && button.conditions.count) { // Are there items in the model at all?
            const model_count = model && Array.isArray(model.index) ? model.index.length : 0
            if (button.conditions.count.hasOwnProperty('min') && button.conditions.count.hasOwnProperty('max')) {
              const has_enough = (
                model_count >= button.conditions.count.min
                && model_count <= button.conditions.count.max
              )
              has_selected = [ has_enough ]
            } else if (button.conditions.count.hasOwnProperty('min')) {
              has_selected = [ (model_count >= button.conditions.count.min) ]
            } else if (button.conditions.count.hasOwnProperty('max')) {
              has_selected = [ (model_count <= button.conditions.count.max) ]
            }
          }
          let can_see = false
          if (has_selected.length) {
            can_see = has_selected.map(item => isConditional(
              button.conditions,
              'visible',
              false,
              { values: { ...getIn(cache, item, {}), ...form?.values }, touched: form ? { ...form.touched } : {} },
              { ...user, selected: selected }
            ))
          } else if (form) {
            can_see = isConditional(
              button.conditions,
              'visible',
              false,
              form,
              user
            )
            can_see = [ can_see ]
          } else {
            can_see = [ true ]
          }
          v = (v || v === null) && can_see.length && can_see.every(item => item === true) ? true : false
        }
      }

      if (v === null) { // None of the above checks failed
        v = true
      }
    } else {
      v = true // Show the button if there are no conditions
    }
    if (button.routes && !button.routes.includes(route) && v) {
      v = false
    }
    if (buttonaction === 'list' && match.params.tab) {
      if (!showActions) {
        v = false
      }
    }

    return v
  }

  useEffect(() => {
    setBackTo(history.length)
  }, [ history.length ])

  useEffect(() => {
    setActions({
      ...actions,
      goBack: () => {
        if ([ 'modules' ].includes(getIn(props, 'match.params.model'))) {
          const module_model = getIn(props.cache, getIn(props, 'match.params.id'), null)
          if (module_model.parent && module_model.status !== 'Published') {
            props.actions.registerRedirect(`/secure/${getIn(props, 'match.params.site')}/${getIn(props, 'match.params.model')}/${module_model.parent}/edit/versions/${module_model.id}`)
          }
        } else {
          const goBacks = backTo - history.length - 1
          props.history.go(goBacks)
        }
      }
    })
  }, [ backTo ])

  const inner = []

  if (modelname) {
    const modelactions = extras ? merge(config.modelactions, extras) : config.modelactions
    if (modelactions instanceof Object) {
      if (form && modelactions.save) { // This button / menu relates to a form
        let isDisabled = false
        if (form.isSubmitting || Object.keys(form.touched).length === 0) { isDisabled = true }
        let button = { label: 'Save', action: 'submitForm', icon: isDisabled ? 'lock' : 'check' }
        if (modelactions.save) { button = { ...button, ...modelactions.save } }
        if (button.redirect && modelactions.save && typeof modelactions.save.redirect === 'object') {
          if (props.match.params.action === 'add') {
            button.redirect = modelactions.save.redirect.add
          } else {
            button.redirect = modelactions.save.redirect.edit
          }
        }
        const cancel = { label: 'Cancel', action: 'goBack', className: 'btn-grey-50 btn-round' }
        modelactions.save = button
        modelactions.cancel = cancel
      }
      for (const action in modelactions) { // Loop over actions in config
        if (modelactions[action]) {
          const button = modelactions[action]
          let visible = false
          let addon = true
          if (button.conditions && button.conditions.addons) { // Does this action require any active addons?
            addon = hasAddons(addons, button.conditions.addons)
          }
          visible = isButtonVisible(button, action)
          if (visible && addon) { // Show the button if there are no false conditions and if there is permission
            let link = ''
            if (button.link) { // This button links to a model view
              if (modelid) {
                if (action === 'list') { // Add the default list params
                  const search = new URLSearchParams(getIn(props, 'model.params', getIn(props, 'config.params', ''))).toString()
                  link = `${button.link}?${search}`
                } else {
                  link = parseURL(button.link, cache[modelid]) // Singleton
                }
              } else if (button.scope === 'selected') {
                link = parseURL(button.link, { selected }) // List with selection
              } else if (selected.length) {
                link = parseURL(button.link, cache[selected[0]]) // List with selection
              } else if (action === 'list') { // Add the default list params
                const search = new URLSearchParams(getIn(props, 'model.params', getIn(props, 'config.params', ''))).toString()
                link = `${button.link}?${search}`
              } else {
                link = button.link
              }
            }
            let el = (
              <ContextButton
                action={action}
                match={props.match}
                model={model}
                modelname={modelname}
                cache={cache}
                portals={portals}
                modelid={modelid}
                className={action}
                selected={selected}
                app={app}
                key={`${props.parent}-${action}`}
                button={button}
                parent={props.parent}
                form={form}
                user={user}
                website_url={settings.website_url}
                link={link}
                dropdownHover={props.dropdownHover}
                stickyProps={props.stickyProps}
                isButtonVisible={isButtonVisible}
                actions={actions}
              >{button.label}</ContextButton>
            )
            if (button.render) {
              el = button.render({ key: `${props.parent}-${action}`, context: props, actions: actions, button })
            }
            inner.push(el)
          }
        }
      }
    }
  }

  if (root.current && challenge.challenge && (selected.length || modelid)) {
    inner.push(
      ReactDOM.createPortal(
        <Formik
          initialValues={{
            record_ids: selected || [ modelid ],
            modelid
          }}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={true}
        >{ formik => (
            <ChallengeModal
              action={challenge.action}
              isLoading={ui?.isLoading}
              challenges={[
                {
                  text: (
                    <span>
                      You&apos;re about to delete
                      {formik.values.record_ids.length > 1 ? <><span>{formik.values.record_ids.length.toString()}</span> {config.plural}</> : ` a ${config.singular} `}.
                      Please confirm your action by typing the number of {config.plural} you are about
                      to edit in the field below.
                    </span>
                  ),
                  value: () => {
                    if (formik.values.record_ids.length === 1 || !formik.values.record_ids.length) {
                      return '1'
                    }
                    return formik.values.record_ids.length.toString()
                  },
                  action: 'deleteModels'
                },
                {
                  text: (
                    <span>
                      You&apos;re about to delete
                      {formik.values.record_ids.length > 1 ? <><span>{formik.values.record_ids.length.toString()}</span> {config.plural}</> : ` a ${config.singular} `}.
                      Please confirm your action by typing <code>DELETE</code> in the field below.
                    </span>
                  ),
                  value: () => 'DELETE',
                  action: 'deleteModels'
                },
                {
                  text: (
                    <span>
                      You are about to merge {formik.values.record_ids.length.toString()} profiles.
                      Your currently viewed profile will be enriched to form the primary profile.
                      All other selected profiles will be archived. <strong>This action cannot be undone.</strong>
                      <br /><br />
                      Confirm your action by typing the number of profiles you are about to merge
                      in the field below. Please be patient while the profiles are merged.
                    </span>
                  ),
                  value: () => formik.values.record_ids.length.toString(),
                  action: 'mergeModels'
                },
                {
                  text: (
                    <span>
                      You&apos;re about to edit
                      <span> {formik.values.record_ids.length.toString()} </span> {config.plural}.
                      Please confirm your action by typing <code>EDIT</code> in the field below.
                    </span>
                  ),
                  value: () => 'EDIT',
                  action: 'unassignPrimaryAgent'
                }
              ]}
              preCheck={modelname === 'agents' ? challenge_el => {
                new Promise((resolve, reject) => {
                  props.actions.checkDelete({
                    values: {
                      selected: formik.values.record_ids,
                      modelname
                    },
                    resolve,
                    reject
                  })
                }).then(() => {
                  challenge_el.setState({ checks_passed: true })
                }).catch(e => {
                  if (Array.isArray(e)) {
                    const el = challenge_el.el.querySelector('.challenge-text')
                    e.forEach((error, ind) => {
                      if (ind === 0) {
                        el.innerHTML = `You cannot delete this agent as there are still records assigned to them.
                        Please assign this data to another agent and try again.<br /><br /><a target="_blank" href=${error.url}>${capitalize(error.count)}</a><br />`
                      } else {
                        el.insertAdjacentHTML('beforeend', `<a target="_blank" href=${error.url}>${capitalize(error.count)}</a><br />`)
                      }
                    })
                    const fg = challenge_el.el.querySelector('.form-group')
                    fg.innerHTML = ''
                  }
                  challenge_el.setState({ checks_passed: false })
                })
              } : null}
              visible={challenge.challenge}
              buttons={(({ valid, value }) => (
                <div className="modal-buttons">
                  <Button className={classNames('btn', 'btn-primary', { disabled: !valid })} type="submit" onClick={valid ? () => {
                    formik.setFieldTouched('challenge', true, false)
                    if (valid) {
                      new Promise((resolve, reject) => {
                        if (challenge.action === 'deleteModels') {
                          return (
                            actions.deleteModel({
                              resolve,
                              reject,
                              values: {
                                modelname,
                                selected: formik.values.record_ids.length ?
                                  formik.values.record_ids : [ formik.values.modelid ]
                              }
                            })
                          )
                        } else if (challenge.action === 'mergeModels') {
                          return (
                            actions.mergeModel({
                              resolve,
                              reject,
                              values: {
                                modelname,
                                record_ids: formik.values.record_ids.filter(i => i !== formik.values.modelid),
                                primary_id: formik.values.modelid
                              }
                            })
                          )
                        } else if (challenge.action === 'unassignPrimaryAgent') {
                          const introduction_agents = formik.values.record_ids.map(
                            c => cache[c].introduction_agent).filter(c => c)
                          return (
                            actions.bulkEditModel({
                              resolve,
                              reject,
                              values: {
                                modelname,
                                record_ids: formik.values.record_ids.filter(i => i !== formik.values.modelid),
                                associated_agents: introduction_agents,
                                introduction_agent: null
                              }
                            })
                          )
                        }
                        return null
                      }).then(() => {
                        setChallenge({ challenge: false, action: null })
                        actions.refreshList()
                        if (modelid) {
                          // if on details view, go back to list view
                          const list = document.querySelector('.navitem.list')
                          if (list) { list.click() }
                        }
                      }).catch(() => {
                        setChallenge({ challenge: false, action: null })
                      })
                    } else {
                      formik.setFieldError('challenge', value ? 'This field is invalid' : 'This field is required')
                    }
                  } : null}>Confirm</Button>
                  <Button className="btn btn-white" type="submit" onClick={() => {
                    setChallenge({ challenge: false, action: null })
                    formik.setFieldError('challenge', null)
                  }}>Cancel</Button>
                </div>
              ))}
            />
          )}
        </Formik>, root.current)
    )
  }

  return inner
}

ContextMenu.propTypes = {
  modelid: PropTypes.number,
  actions: PropTypes.object,
  user: PropTypes.object.isRequired,
  stickyProps: PropTypes.object,
  config: PropTypes.object.isRequired,
  model: PropTypes.object,
  modelname: PropTypes.string,
  addons: PropTypes.array,
  cache: PropTypes.object,
  settings: PropTypes.object,
  form: PropTypes.object,
  portals: PropTypes.object,
  ui: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  extras: PropTypes.object,
  app: PropTypes.object,
  parent: PropTypes.node,
  redirectSchema: PropTypes.func,
  dropdownHover: PropTypes.func,
  dropdownPositionCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  route: PropTypes.string,
  selected: PropTypes.array
}

export default ContextMenu
