/* eslint-disable new-cap */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany, notifyUser } from '../actions'
import { CONFIG, USER } from '../selectors'
import RecipientsManager from '../components/common/forms/inputs/RecipientsManager'


function mapStateToProps(state, ownProps) {
  const config = ownProps.modelname === 'brand-assets' ? CONFIG(state, 'brand-recipients') : CONFIG(state, 'email-recipients')
  const user = USER(state)
  return {
    config,
    user
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    fetchMany,
    notifyUser
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(RecipientsManager))
