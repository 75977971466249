import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import { DownloadManager } from 'pdfjs-dist/web/pdf_viewer.js'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'
import { Document, Page, pdfjs } from 'react-pdf'

import log from '../logging'
import { hasAddons, hasPermission, overwriteMerge, parseURL, uniqueArray } from '../utils'
import validate from '../validate'
import Loader from './common/Loader'
import Card from './common/Card'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import ModelActions from './common/ModelActions'
import QueryBuilder from './common/QueryBuilder'
import { Button } from './ui/Button'


pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const BrandAssetGenerator = props => {
  const qs = new QueryBuilder(props.location.search)
  const downloadManager = new DownloadManager({
    disableCreateObjectURL: false
  })
  const [ initvals, setInitvals ] = useState(false)
  const [ pageNumber, setPageNumber ] = useState(1)
  const [ page, setPage ] = useState(1)
  const [ numPages, setNumPages ] = useState(0)
  const [ document, setDocument ] = useState(null)
  const [ template_id, setTemplate_id ] = useState(qs.getParam('template'))
  const [ template_config, setTemplate_config ] = useState(null)
  const [ url, setUrl ] = useState('')
  const [ status, setStatus ] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [ width, setWidth ] = useState(null)
  const [ height, setHeight ] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [ redirect, setRedirect ] = useState()

  let form = useRef()
  let el = useRef()

  const hasPerm = () => {
    const { model, user, config, addons } = props
    if (config.addons) {
      if (!hasAddons(config.addons, addons)) { return false } // Entire module is disabled
    }
    const requiredPermissions = getIn(props.routeConfig, 'add.permissions', getIn(props.routeConfig, 'edit.permissions'))
    if (user.permissions.includes('is_prop_data_user')) { return true }
    if (!requiredPermissions) { return true } // No permissions needed
    const hasEditOwnPermissions = requiredPermissions.filter(perm => perm.endsWith('_view_own'))
    const hasEditAllPermissions = requiredPermissions.filter(perm => perm.endsWith('_view'))
    if (props.model && props.modelid) {
      if (hasPermission(hasEditAllPermissions, user.permissions)) { return true }
      if (hasPermission(hasEditOwnPermissions, user.permissions)) {
        if (model.agent !== user.agent.id) { return false }
        return true
      }
    }
    if (hasPermission(requiredPermissions, user.permissions)) { return true } // Implicit permissions
    return false
  }

  const fetchTemplateConfig = () => {
    if (!template_id) { return }
    new Promise((resolve, reject) => props.actions.fetchTemplateConfig({
      params: {
        template_type: 'brand-assets',
        template: template_id
      },
      resolve,
      reject
    })
    ).then(r => {
      setTemplate_config({ ...r })
    }).catch(e => console.error(e))
  }

  const initModel = () => { // Initialise model data for formik
    const { model, config } = props
    const variables = model ? model.variables : {}
    const i = { ...variables }
    i.template = template_id
    i.modelname = config.modelname
    i.name = model ? model.name : null
    if (template_config) {
      Object.keys(i).forEach(f => {
        const field = template_config.fields?.find(fe => fe.name === f)
        if (!field) { return }
        if (field && field.modelname) {
          if (field.extraparams) { // Used for form submission
            field.params = parseURL(
              field.extraparams,
              variables,
              props.user ? props.user.agent : false,
              props.user ? props.cache.settings[props.user.agent.site.id] : false,
              props.cache,
              0, // index
              field.extraparamslock
            )
          }
          if (field.params) {
            const q = new QueryBuilder(field.params)
            const params = q.getAllArgs()
            params[`${field.optionvalue ? field.optionvalue : 'id'}__in`] = Array.isArray(i[f]) ? i[f] : [ i[f] ]
            const vals = {
              modelname: field.modelname,
              params
            }
            props.actions.fetchMany({ values: vals })
          }
        }
      })
      return i
    }
    return {}
  }

  async function downloadImage() {
    const filename = `${template_config.display_name}.png`
    try {
      return await document.getPage(page).then(p => {
        const scale = '1.5'
        const viewport = p.getViewport({
          scale: scale
        })
        const canvas = document.createElement('canvas')
        const canvasContext = canvas.getContext('2d')
        canvas.height = viewport.height || viewport.viewBox[3] /* viewport.height is NaN */
        canvas.width = viewport.width || viewport.viewBox[2] /* viewport.width is also NaN */
        p.render({
          canvasContext, viewport
        }).promise.then(() => {
          canvas.toBlob(blob => {
            downloadManager.download(blob, canvas.toDataURL(), filename)
          })
        })
      }).catch(e => {
        console.error(e)
      })
    } catch (e) {
      console.error(e)
    }
    return null
  }

  const redirectSchema = schema => { setRedirect(schema) }

  const addVerb = (bag, group, gidx) => {
    const { fields } = props.config
    const template_fields = getIn(template_config, 'fields', []) || []
    const inferred_fields = template_fields.map(f => {
      if (typeof f === 'string') {
        return fields.find(field => field.name === f)
      }
      const real_field = fields.find(field => isEqual(field.name, f.name))
      if (real_field) {
        return real_field
      }
      return f
    })
    const all_fields = uniqueArray(merge([], [
      ...fields,
      ...inferred_fields
    ], { arrayMerge: overwriteMerge }).map(f => f), 'name')

    if (all_fields) {
      const brochure_fields = all_fields.map(field => {
        const rest = template_config ? template_config.fields?.find(f => getIn(f, 'name') === field.name) || {} : {}
        const groupprops = {
          ...field,
          ...rest,
          cols: 'lg-12',
          required: field.name === 'template' || field.required,
          quality: false
        }
        return groupprops
      })

      return <FieldGroup
        form={bag}
        card={true}
        groupname={group}
        key={gidx}
        match={props.match}
        modelid={props.modelid}
        config={{
          fields: brochure_fields
        }}
        fields={brochure_fields}
      />
    }
    return null
  }

  const onDocumentLoadSuccess = doc => {
    doc.getPage(1).then(p => {
      const viewport = p.getViewport()
      return { width: viewport.viewBox[2], height: viewport.viewBox[3] }
    }).then(({ w, h }) => {
      setDocument(doc)
      setNumPages(doc.numPages)
      // setPage(1)
      // setPageNumber(1)
      setWidth(w)
      setHeight((el.scrollWidth * (h / w)) + 25)
    })
  }

  const goToPrevPage = () => {
    const newpage = parseInt(pageNumber, 10) - 1
    if (newpage > 0) {
      setPageNumber(newpage)
      setPage(newpage)
    }
  }

  const goToNextPage = () => {
    const newpage = parseInt(pageNumber, 10) + 1
    if (newpage <= numPages) {
      setPageNumber(newpage)
      setPage(newpage)
    }
  }

  const goToPage = p => {
    if (p <= numPages && p > 0) {
      setPageNumber(p)
    }
  }

  const isEnter = e => {
    if (e.keyCode === 13) { // fire goToPage on enter
      return goToPage(e.target.value)
    } // continue typing
    return true
  }

  const handleSubmit = (values, actions) => {
    const valid = merge({}, values)
    return new Promise((resolve, reject) => {
      if (valid.id) {
        props.actions.updateModel({ values: valid, resolve, reject })
      } else {
        props.actions.createModel({ values: valid, resolve, reject })
      }
    }).then(r => {
      actions.setSubmitting(false)
      actions.setTouched({})
      props.actions.registerRedirect(`/secure/${props.match.params.site}/${props.config.modelname}/${r.id}`)
    }).catch(e => {
      form.handleSubmitError(e, actions, this.form)
    })
  }

  const previewAsset = () => {
    const { actions } = props
    new Promise((resolve, reject) => {
      form.setSubmitting(true)
      const { template, ...cleanvalues } = form.values
      const params = {
        params: cleanvalues,
        modelname: props.modelname,
        args: {
          action: 'brand-asset',
          template
        },
        label: 'Brand Asset',
        noalert: true,
        callback: setStatus,
        resolve,
        reject
      }
      setUrl('')
      return actions.exportData(params)
    }).then(r => {
      form.setSubmitting(false)
      const u = r.response.file
      form.setSubmitting(false)
      setUrl(u)
    }).catch(e => {
      if (Object.keys(e).includes('template')) {
        form.setErrors(e.response)
      }
      form.setSubmitting(false)
      log.error(e)
    })
  }

  useEffect(() => {
    if (!hasPerm()) { // If we're not allowed to be here, redirect to designated redirect
      const loc = parseURL(getIn(props.routeConfig, 'add.redirect', getIn(props.routeConfig, 'edit.redirect')), props.model)
      props.registerRedirect(loc)
    }
    fetchTemplateConfig()
  }, [])

  useEffect(() => {
    fetchTemplateConfig()
  }, [ template_id ])

  useEffect(() => {
    if (template_config) {
      setInitvals(initModel())
    }
  }, [ template_config ])

  useEffect(() => {
    if (props.modelid) {
      new Promise((resolve, reject) => {
        props.fetchOne(props.modelname, props.modelid, resolve, reject, true)
      }).then(r => {
        setTemplate_id(r[props.modelname][props.modelid].template)
      })
    }
  }, [ props.modelid ])

  const { config, ui } = props

  return (
    <Formik
      validationSchema={validate.brochure_generator}
      validateOnChange={true}
      initialValues={{
        ...initvals,
        model: config.servicename || props.modelname
      }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >{formik => {
        form = formik
        return (
          <div id="content" className="content">
            <div className="viewhead details">
              <div className="action-bar">
                <ModelActions
                  touched={formik.touched}
                  errors={formik.errors}
                  isSubmitting={formik.isSubmitting}
                  redirectSchema={redirectSchema}
                  form={formik}
                  modelname={config.modelname}
                  actions={{
                    downloadImage
                  }}
                  extras={{
                    downloadpng: {
                      label: 'Download PNG',
                      menu: null,
                      redirect: null,
                      icon: '#icon16-Download',
                      action: 'downloadImage',
                      className: 'btn-round btn-red',
                      routes: [ 'add', 'edit' ],
                      conditions: {
                        visible: [
                          [
                            {
                              field: 'status',
                              condition: {
                                value: 'Finalised'
                              }
                            }
                          ]
                        ]
                      }
                    },
                    downloadpdf: {
                      label: 'Download PDF',
                      menu: null,
                      redirect: null,
                      icon: '#icon16-Download',
                      action: 'download',
                      className: 'btn-round btn-red',
                      routes: [ 'add', 'edit' ],
                      conditions: {
                        visible: [
                          [
                            {
                              field: 'status',
                              condition: {
                                value: 'Finalised'
                              }
                            }
                          ]
                        ]
                      }
                    }
                  }}
                  statusmsg={formik.status ? formik.status.msg : false}
                />
              </div>
            </div>
            <div className={`view details brochures ${config.modelname}`}>
              <div className="viewcontent">
                <div className="brochure-generator">
                  <div className='brochure-form'>
                    <CustomForm
                      ui={ui}
                      model={props.model ? true : false}
                      setContextForm={props.actions.setContextForm}
                      render={() => (
                        <div className='row'>
                          <div className='col'>
                            {
                              Object.keys(config.fieldgroups).map((group, gidx) => addVerb(formik, group, gidx))
                            }
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div className='brochure-preview'>
                    <Card
                      background={true}
                      header={
                        <h3>Brand Asset Preview</h3>
                      }
                      body={
                        <div className='pdfcontainer'>
                          {url ? (
                            <nav id='pdfcontainer-nav' className='pdfcontainer-nav'>
                              <div className="nav-group">
                                <div className="pagejump">
                                  <div className="form-group page">
                                      Page
                                    <div className="forminput">
                                      <input
                                        // component="input"
                                        type="number"
                                        className="form-control form-control-lg page-jump"
                                        min={1}
                                        value={page}
                                        onKeyDown={isEnter}
                                        onChange={e => setPage(e.target.value)}
                                      />
                                    </div>
                                      /  {numPages}
                                  </div>
                                </div>
                                <Button icon="#icon16-ChevronLeft" title="Previous Page" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' disabled={page <= 1} onClick={goToPrevPage} />
                                <Button icon="#icon16-ChevronRight" title="Next Page" className="btn btn-subtle btn-icon-16 btn-icon-only" type='button' disabled={page === numPages} onClick={goToNextPage} />
                              </div>
                              <Button icon="#icon16-EyeOpen" title="Preview" className="btn btn-primary btn-icon-16 btn-icon-left" type='button' onClick={formik.handleSubmit}>{(formik.dirty && Object.keys(formik.touched).length) ? 'Update Preview' : 'Preview'}</Button>
                            </nav>
                          ) : null }
                          <div ref={elem => { if (el) { el = elem } }} className='react-pdf react-pdf__Highlighting' style={{ height: height }}>
                            {formik.isSubmitting ? (
                              <div className="text-center">
                                <Loader inline className="large" onError={() => {}} throwViewError={() => {}} />
                                <div className="message">{status ? status.response.detail : null}</div>
                              </div>
                            ) : (
                              <Document
                                file={url}
                                loading={<Loader inline className="large" onError={() => {}} throwViewError={() => {}} />}
                                onLoadSuccess={onDocumentLoadSuccess}
                                // onMouseDown={onMouseDown}
                                // onScroll={onMouseMove}
                                noData={(
                                  <div className="no-pdf">
                                    <Button icon="#icon16-EyeOpen" title="Preview" className="btn btn-primary btn-icon-16 btn-icon-left" type='button' onClick={previewAsset}>Preview Brand Asset</Button>
                                  </div>
                                )}
                              >
                                <Page
                                  pageNumber={parseInt(pageNumber, 10)}
                                  renderTextLayer={false}
                                  // scale={scale}
                                />
                              </Document>
                            )}
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } }
    </Formik>
  )
}

BrandAssetGenerator.propTypes = {
  model: PropTypes.object,
  location: PropTypes.object,
  fetchOne: PropTypes.func,
  modelname: PropTypes.string,
  modelid: PropTypes.string,
  routeConfig: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  configs: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  ui: PropTypes.object,
  user: PropTypes.object,
  addons: PropTypes.array,
  registerRedirect: PropTypes.func
}

export default BrandAssetGenerator
