/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { CONFIGS, SETTINGS, MINUSER } from '../../selectors'
import { title, valueFormat, uniqueArray, debounce } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import { Button } from '../ui/Button'
import { Scrollbar } from '../ui/Scrollbars'
import SingleUser from '../common/SingleUser'
import Loader from '../common/Loader'
import Tag from '../common/Tag'
import InlineAgentSelect from './inputs/InlineAgentSelect'


const notestatuses = [
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Complete', label: 'Complete' },
  { value: 'Proceed', label: 'Proceed' },
  { value: 'Scheduled', label: 'Scheduled' }
]

const notetypes = [
  { value: 'agents', label: 'Agents' },
  { value: 'branches', label: 'Branches' },
  { value: 'commercial', label: 'Commercial Listings' },
  { value: 'contacts', label: 'Contacts' },
  { value: 'holiday', label: 'Holiday Listings' },
  { value: 'leads', label: 'Leads' },
  { value: 'profiles', label: 'Profiles' },
  { value: 'projects', label: 'Project Listings' },
  { value: 'referrals', label: 'Referrals' },
  { value: 'residential', label: 'Residential Listings' },
  { value: 'subscribers', label: 'Subscribers' },
  { value: 'teams', label: 'Teams' }
]

const NotesWidget = ({ user, configs, obj_model, obj_id, actions }) => {
  const abortController = useRef(new AbortController())

  const allagents = [ { first_name: 'All', last_name: 'Agents', id: '' } ]
  if (user.agent.id) {
    allagents.push({ first_name: user.agent.first_name, last_name: user.agent.last_name, id: user.agent.id })
  }

  const [ agentId, setAgentId ] = useState(user.agent.id ? user.agent.id : '')
  const [ agents, setAgents ] = useState(allagents)
  const [ notetype, setNoteType ] = useState('')
  const [ notestatus, setNoteStatus ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ data, setData ] = useState([])

  const getText = note => {
    let content
    try {
      const parser = new DOMParser()
      const dom = parser.parseFromString(note.content, 'text/html')
      content = dom.documentElement.textContent.replace(/[\n\r\t]/gi, '')
    } catch (e) {
      content = ''
    }
    return content
  }

  const filterNotes = useCallback(() => {
    setLoading(true)
    const params = {
      limit: 50,
      order_by: '-created',
      meta_fields: 'relations,files',
      category__not: 'Comment',
      status: notestatus,
      agent_id: agentId,
      no_count: 1
    }
    const endpoint = {
      read: `/notes/api/v1/notes${notetype ? `/${notetype}` : ''}`
    }
    if (obj_id && obj_model) {
      params.obj_model = obj_model
      params.obj_id = obj_id
      endpoint.read = `/notes/api/v1/notes/${obj_model === 'project' ? 'projects' : obj_model}/${obj_id}`
    }
    new Promise((resolve, reject) => actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'notes',
        endpoint,
        params,
        signal: abortController.current.signal
      },
      resolve,
      reject
    })).then(r => {
      setData(r.options.map(d => {
        d.text = getText(d)
        return d
      }))
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    }).finally(() => {
      setLoading(false)
    })
  })

  useEffect(() => () => {
    abortController.current.abort()
  }, [])

  useEffect(() => {
    debounce(filterNotes, 300)()
  }, [ notetype, notestatus, agentId ])


  const height = obj_id ? 200 : 340

  return (
    <Card
      id="notes-widget"
      classes={obj_id ? 'grid-col-2' : 'grid-col-1'}
      bodyclass="stats-card no-top-padding notes-widget"
      background
      header={
        <>
          <h3>Notes / Actions</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <div className="filter-branch">
              {!obj_id && !obj_model ? (
                <InlineSelect
                  id="note_type"
                  name="note_type"
                  className="inline-select"
                  classNamePrefix="inline"
                  options={[ { label: 'All Types', value: '' }, ...notetypes ]}
                  defaultValue={{ label: 'All Types', value: '' }}
                  selectedValue={notetype}
                  onChange={e => setNoteType(e.value)}
                />
              ) : null}
            </div>
            <div className="filter-status">
              <InlineSelect
                id="note_status"
                name="note_status"
                className="inline-select"
                classNamePrefix="inline"
                options={[ { label: 'All Statuses', value: '' }, ...notestatuses ]}
                defaultValue={{ label: 'All Statuses', value: '' }}
                selectedValue={notestatus}
                onChange={e => setNoteStatus(e.value)}
              />
            </div>
            <InlineAgentSelect
              user={user}
              actions={actions}
              selectedValue={agentId}
              onLoad={e => { setAgents(uniqueArray([ ...allagents, ...e ], 'id'))} }
              onChange={e => {
                setAgentId(e.value)
              }}
            />
          </div>
        </>
      }
      body={loading ? (
        <div className="empty flex-container" style={{ height: height }}><Loader inline className="large" /></div>
      ) : (
        <Scrollbar style={{ height: height }}>
          {data.length ? (
            data.map(note => {
              const origin = note.relations.find(rel => rel.id === note.origin)
              if (!origin) { return null }
              const agent = getIn(agents, note.agent_id)
              const config = getIn(configs, origin.related_model)
              let related_config
              if (notetype && origin.related_model !== notetype) {
                related_config = getIn(configs, notetype)
              }
              return (
                <div key={`note-${note.id}`} className="notes-summary">
                  <div className="notes-summary-top">
                    <div className="notes-summary-creator">
                      {note.agent_id !== null && agent &&
                        <div className="agents">
                          <SingleUser small={true} model={agent} />
                          <div className="noteauthor">
                            {note.user_id === user.id ? 'You' : `${agent.first_name} ${agent.last_name}`}
                          </div>
                        </div>
                      }
                      {note.user_id !== null && !note.agent_id &&
                        <div className="agents" title="System User">
                          <div className='avatar avatar-md'>
                            <div className="thumbimg">
                              <svg viewBox="0 0 24 24">
                                <use href="/images/icons-24.svg#icon24-Cog" />
                              </svg>
                            </div>
                          </div>
                          <div className="noteauthor">
                            System User
                          </div>
                        </div>
                      }
                    </div>


                    <div className="notes-summary-date">
                      <div>Created {valueFormat('datetime', note.created)}</div>
                      {note.note_date ? (
                        <div>Due {valueFormat('datetime', note.note_date)}</div>
                      ) : null }
                    </div>
                  </div>
                  <Button component={NavLink} to={`/secure/${origin.related_model}/${origin.related_id}/notes/${note.id}`} className="notes-summary-body">
                    {note.text ? note.text : ''}
                  </Button>
                  <div className="notes-summary-bottom">
                    <div className="notes-summary-tag">{title(config ? config.plural : '')}</div>
                    {related_config ? <div className="notes-summary-tag-related">{title(related_config.plural)}</div> : ''}
                    {note.status &&
                      <Tag className={note.status} key={`notes-${note.status}`} value={`${note.status === 'Scheduled' && (new Date()) > (new Date(`${note.note_date}T${note.start}`)) ? 'Overdue' : note.status}`} />
                    }
                  </div>
                </div>
              )
            })
          ) : 'No notes found'}
        </Scrollbar>
      )}
    />
  )
}


NotesWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  configs: PropTypes.object,
  settings: PropTypes.object,
  obj_model: PropTypes.string,
  obj_id: PropTypes.number
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  return ({
    user,
    settings: SETTINGS(state),
    configs: CONFIGS(state)
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(NotesWidget))
