
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { getDisplayName } from '../../utils'


export function withDelay(WidgetComponent) {
  try {
    return class DelayedComponent extends WidgetComponent {
      static displayName = `DelayedComponent(${getDisplayName(WidgetComponent)})`

      constructor(props) {
        super(props)
        this.state = {
          visible: false
        }
        this.timer = null
      }

      componentDidMount() {
        const { delay } = this.props
        this.timer = setTimeout(() => {
          this.setState({ visible: true })
        }, delay || 0)
      }

      componentWillUnmount() {
        clearTimeout(this.timer)
      }

      render() {
        if (!this.state.visible) { return null }
        return <WidgetComponent {...this.props} />
      }
    }
  } catch (e) {
    const FCWithDelay = ({ delay, ...props }) => {
      const [ visible, setVisible ] = useState(false)
      const timer = useRef()

      useEffect(() => {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
          setVisible(true)
        }, delay || 0)
        return () => {
          clearTimeout(timer.current)
        }
      }, [ delay ])

      if (!visible) { return null }
      return <WidgetComponent {...props} />
    }
    FCWithDelay.propTypes = {
      delay: PropTypes.number
    }
    FCWithDelay.displayName = `DelayedComponent(${getDisplayName(WidgetComponent)})`
    return FCWithDelay
  }
}

export default withDelay
