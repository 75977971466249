/* eslint-disable new-cap */
import { getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { components } from 'react-select'
import { Label, Legend, Pie, PieChart, Surface, Symbols, Text } from 'recharts'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany, fetchListingCounts } from '../../actions'
import { CONFIG, MINUSER } from '../../selectors'
import { getRandomColor, title, valueFormat, getTextWidth } from '../../utils'
import Card from '../common/Card'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import Loader from '../common/Loader'
import { useBreakPoint } from '../../hooks/useBreakPoint'
import withDelay from './withDelay'
import InlineBranchSelect from './inputs/InlineBranchSelect'
import InlineAgentSelect from './inputs/InlineAgentSelect'
import InlineListingTypeSelect from './inputs/InlineListingTypeSelect'


const RADIAN = Math.PI / 180
const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option
    {...props}
  >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const StockWidget = ({ user, actions, status_fields, branches }) => {
  const [ branchId, setBranchId ] = useState(branches ? branches[0] : '')
  const [ agentId, setAgentId ] = useState(user.agent.id || '')
  const [ listingModel, setListingModel ] = useState('')
  const showActions = useBreakPoint()
  const [ loading, setLoading ] = useState(false)
  const [ { data, modelname, listing_type }, setData ] = useState({ data: [], modelname: '', listing_type: '' })
  const abortController = useRef(new AbortController())

  const filterListings = useCallback(() => {
    if (!listingModel) { return }
    let modelName = listingModel
    let listingType = null
    try {
      const re = /(?<modelname>.*)_(?<listing_type>for_sale|to_let)/g
      const groups = re.exec(listingModel).groups
      modelName = groups.modelname
      listingType = groups.listing_type
    } catch (e) {
      console.error(e)
    }
    if (!modelName) { return }
    setLoading(true)

    const statuses_needed = getIn(status_fields, modelName, { options: [] }).options.map(o => o.value).sort()

    new Promise((resolve, reject) => actions.fetchListingCounts({
      values: {
        params: {
          model: modelName === 'projects' ? 'project' : modelName,
          branch__in: branchId ? [ branchId ] : branches,
          listing_type__in: listingType ? title(listingType.replace('_', ' ')) : '',
          agents__in: agentId,
          order_by: '-created'
        },
        signal: abortController.current.signal
      },
      resolve,
      reject
    })).then(r => {
      setData({
        data: statuses_needed.map((status, sid) => ({
          label: status,
          value: r[status],
          fill: getRandomColor(sid)
        })),
        modelname: modelName,
        listing_type: listingType
      })
    }).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [ branchId, agentId, listingModel ])

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const value = (percent * 100).toFixed(0)
    let scale = radius / 99.6
    const width = getTextWidth(value, `${12 * scale}px Poppins`)
    const max = (radius - 5) * 2
    if (width > max) {
      const offset = max / width
      scale *= offset
    }
    if (scale < 0.6) {
      return null
    }
    if (value > 3) {
      return (
        <Text x={x} y={y} fontFamily="Poppins" fontSize={12 * scale} fill="white" textAnchor="middle" verticalAnchor='middle'>
          {`${value}%`}
        </Text>
      )
    }
    return null
  }

  const renderCustomizedLegend = useCallback(({ payload: all_data, chartWidth, chartHeight, layout }) => (
    <div className="customized-legend-vertical" style={{ width: layout === 'vertical' ? chartWidth - chartHeight : chartWidth }}>
      {
        all_data.map((entry, eid) => {
          const { payload, color } = entry
          if (payload.label === 'No Leads Found') {
            return null
          }
          return (
            <span key={`overlay-${payload.label}-${eid}`} className="legend-item">
              <Surface width={10} height={10} viewBox={{
                x: 0,
                y: 0,
                width: 10,
                height: 10
              }}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
              </Surface>
              <span className="legend-label"><NavLink to={`/secure/${user.agent.site.id}/${modelname}/?status__in=${payload.label}&listing_type__in=${listing_type ? title(listing_type.replace('_', ' ')) : ''}${branchId ? `&branch__in=${branchId}` : ''}${!branchId && branches ? `&branch__in=${branches.join(',')}` : ''}${agentId ? `&agent__in=${agentId}` : ''}&order_by=-created`}>{payload.label}</NavLink></span>
              <span className="legend-count">{valueFormat('number', payload.value)}</span>
            </span>
          )
        })
      }
    </div>
  ), [ modelname, listing_type ])

  useEffect(() => {
    filterListings()
  }, [ branchId, agentId, listingModel ])

  useEffect(() => () => {
    abortController.current.abort()
  }, [])

  return (
    <Card
      id="stock-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding"
      background
      header={
        <>
          <h3>Stock</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <InlineBranchSelect
              branches={branches}
              user={user}
              actions={actions}
              selectedValue={branchId}
              onChange={e => {
                setBranchId(e.value)
                setAgentId('')
              }}
            />
            <InlineAgentSelect
              user={user}
              actions={actions}
              branchId={branchId}
              selectedValue={agentId}
              onChange={e => {
                setAgentId(e.value)
              }}
            />
            <InlineListingTypeSelect
              user={user}
              selectedValue={listingModel}
              onChange={e => {
                setListingModel(e.value)
              }}
            />
          </div>
        </>
      }
      body={
        !loading ? (
          <ResponsiveContainer width={'100%'} height={240} debounce={1} legendWrap={!showActions}>
            <PieChart cx="50%" cy="50%" margin={0}>
              <Legend wrapperStyle={{ width: showActions ? null : '100%', height: showActions ? '100%' : null, bottom: showActions ? null : 1 }} iconType="circle" iconSize={9} layout={showActions ? 'vertical' : 'horizontal'} verticalAlign={showActions ? 'top' : 'bottom'} align="right" content={renderCustomizedLegend} data={data} />
              <Pie
                startAngle={90}
                endAngle={-270}
                data={[ { value: 100, label: 'No Leads Found' } ]} dataKey="value" innerRadius="66%" outerRadius="100%" fill="#F3F5F8" isAnimationActive={false} label={false}
              >
                {!data.length ?
                  <Label fontFamily='Poppins' position="center" content={test => {
                    if (!test.viewBox.innerRadius) { return null }
                    let scale = test.viewBox.innerRadius / 79.2
                    const width = getTextWidth('0', `${34 * scale}px Poppins`)
                    const max = (test.viewBox.innerRadius - 5) * 2
                    if (width > max) {
                      const offset = max / width
                      scale *= offset
                    }
                    return <Text fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">0</Text>
                  }}></Label> : null}
              </Pie>
              <Pie
                angleAxisId={0}
                minAngle={1}
                background={{ fill: '#ffffff' }}
                clockWise
                startAngle={90}
                endAngle={-270}
                innerRadius="66%"
                outerRadius="100%"
                isAnimationActive={false}
                data={data}
                labelLine={false}
                strokeWidth={0}
                label={renderCustomizedLabel}
                dataKey="value"
              >
                <Label fontFamily='Poppins' position="center" content={test => {
                  if (!test.viewBox.innerRadius) { return null }
                  let scale = test.viewBox.innerRadius / 79.2
                  const total = data.map(d => d.value).reduce((a, b) => a + b, 0)
                  const width = getTextWidth(valueFormat('number', total), `${34 * scale}px Poppins`)
                  const max = (test.viewBox.innerRadius - 5) * 2
                  if (width > max) {
                    const offset = max / width
                    scale *= offset
                  }
                  return <Text fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">{valueFormat('number', total)}</Text>
                }}></Label>
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        ) : <div className="empty flex-container" style={{ height: 340 }}><Loader inline className="large" /></div>
      }
    />
  )
}

StockWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  status_fields: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  const status_fields = Map({
    residential: CONFIG(state, 'residential').get('fields')?.find(f => f.get('name') === 'status'),
    commercial: CONFIG(state, 'commercial').get('fields')?.find(f => f.get('name') === 'status'),
    projects: CONFIG(state, 'projects').get('fields')?.find(f => f.get('name') === 'status'),
    holiday: CONFIG(state, 'holiday').get('fields')?.find(f => f.get('name') === 'status')
  })

  return ({
    user,
    status_fields
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany, fetchListingCounts }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(StockWidget)))
