/* eslint-disable no-console */
import {
  APIProvider,
  Map,
  Marker,
  MapControl,
  ControlPosition,
  useMap,
  useMapsLibrary
} from '@vis.gl/react-google-maps'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import isEqual from 'react-fast-compare'
import Select, { components } from 'react-select'

import country_bounds from '../../../../config/country-bounds.json'
import db from '../../../../db'
import { debounce } from '../../../../utils'
import { Button } from '../../../ui/Button'
import Tip from '../Tip'


const MapToButton = props => {
  const { location, clickHandler } = props
  return (
    <div className={`map-button ${location}`}>
      <Button type="button" className="btn btn-primary" onClick={() => clickHandler()}>{props.children}</Button>
    </div>
  )
}

MapToButton.propTypes = {
  location: PropTypes.string,
  clickHandler: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const libraries = [ 'places' ]


const boldMatchCharacters = ({ sentence = '', characters = '' }) => {
  const regEx = new RegExp(characters, 'gi')
  return sentence.replace(regEx, '<b>$&</b>')
}

const CustomOption = props => {
  const label = props.data.__isNew__ ? `Search ${props.data.value}`
    : boldMatchCharacters({
      sentence: getIn(props, 'data.label'),
      characters: props.selectProps.inputValue
    })
  return <components.Option
    {...props}
  >
    <div className="search-option" dangerouslySetInnerHTML={{ __html: label }} />
  </components.Option>
}

CustomOption.propTypes = {
  selectProps: PropTypes.object,
  data: PropTypes.object
}

const MapComponent = ({ cb, center, ...props }) => {
  const { google } = window
  const map = useMap()

  useEffect(() => {
    if (!map) {return}
    if (!cb) {return}
    map.fitBounds(cb)
    map.setCenter(center)
    const listener = google.maps.event.addListener(map, 'idle', () => {
      if (map.getZoom() > 16) {
        map.setZoom(16)
      }
      google.maps.event.removeListener(listener)
    })
  }, [ map ])
  if (!google) { return null }
  return <Map
    {...props}
    center={center ? center : cb?.getCenter()}
  />
}

MapComponent.propTypes = {
  cb: PropTypes.any,
  center: PropTypes.any,
  defaultBounds: PropTypes.object
}

// This is an example of the classic "Place Autocomplete" widget.
// https://developers.google.com/maps/documentation/javascript/place-autocomplete
export const PlaceAutocompleteClassic = ({ onPlaceSelect, address, searchAddress, setSearchAddress }) => {
  const { google } = window

  const [ inputValue, setInputValue ] = useState('')
  const [ options, setOptions ] = useState([])
  const [ value, setValue ] = useState()
  const [ menuIsOpen, setMenuIsOpen ] = useState(false)
  const selectRef = useRef()

  const places = useMapsLibrary('places')

  const map = useMap()


  const loadOptions = useCallback(debounce((inputVal, callback) => {
    const service = new places.PlacesService(document.createElement('div'))

    service.findPlaceFromQuery({ query: inputVal, locationBias: 'IP_BIAS', fields: [ 'name', 'geometry' ] }, async (results, status) => {
      if (status === places.PlacesServiceStatus.REQUEST_DENIED) {
        const { AutocompleteSessionToken, AutocompleteSuggestion } = await google.maps.importLibrary('places')
        const token = new AutocompleteSessionToken()
        const request = {
          input: inputVal,
          language: 'en-US',
          sessionToken: token
        }
        const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions(request)
        const suggestedPlaces = suggestions.map(o => ({
          value: o.placePrediction,
          label: o.placePrediction.text.toString()
        }))
        callback(suggestedPlaces)
        setMenuIsOpen(true)
      } else {
        const suggestedPlaces = results.map(o => ({
          value: o,
          label: o.name
        }))
        callback(suggestedPlaces)
      }
    })
  }, 300), [])

  useEffect(() => {
    if (searchAddress) {
      setInputValue(address)
      setSearchAddress(false)
      loadOptions(address, setOptions)
    }
  }, [ address, searchAddress ])

  if (!google) { return null }
  if (!map) { return null }

  function handleInputChange(val, { action }) {
    if (action === 'input-change') {
      setInputValue(val)
      if (val) {
        loadOptions(val, setOptions)
      }
    } else if (action === 'set-value' && !val) {
      setMenuIsOpen(false)
    }
    if (action === 'input-blur') {
      setMenuIsOpen(false)
    }
  }

  return (
    <div className="map-search forminput not-required">
      <Select
        key={`map-search-${address.length}`}
        ref={selectRef}
        placeholder={value || 'Search...'} // when blurred & value == "" this shows
        value="" // always show placeholder
        className={'react-select react-select-autocomplete'}
        classNamePrefix="react-select"
        controlShouldRenderValue={false}
        onChange={async v => {
          if (v.value.toPlace) {
            const place = v.value.toPlace() // Get first predicted place.
            await place.fetchFields({
              fields: [ 'displayName', 'location', 'formattedAddress', 'addressComponents' ]
            })
            onPlaceSelect({
              name: place.displayName,
              address_components: place.addressComponents,
              geometry: { location: place.location }
            }, map)
            setValue(place.formattedAddress)
            setInputValue('')
          } else {
            setValue(v.value.name)
            onPlaceSelect(v.value, map)
          }
        }}
        filterOption={() => true}
        options={options}
        openMenuOnFocus={false}
        openMenuOnClick={false}
        blurInputOnSelect={true}
        onInputChange={handleInputChange}
        noOptionsMessage={() => null}
        inputValue={inputValue}
        menuIsOpen={menuIsOpen}
        isSearchable
        unstyled
        components={{
          IndicatorsContainer: () => null,
          Option: CustomOption
        }}
      />
    </div>
  )
}

PlaceAutocompleteClassic.propTypes = {
  onPlaceSelect: PropTypes.func,
  address: PropTypes.string,
  searchAddress: PropTypes.bool,
  setSearchAddress: PropTypes.func
}

const MapInput = props => {
  const { field, form, addressField, id, settings } = props

  const [ address, setAddress ] = useState('')
  const [ searchAddress, setSearchAddress ] = useState(false)
  const [ place, setPlace ] = useState(null)
  const [ value, setValue ] = useState({ lat: getIn(form, `values.${props.coordinateFields[0]}`), lng: getIn(form, `values.${props.coordinateFields[1]}`) })

  const [ latInput, lngInput ] = [ props.coordinateFields[0], props.coordinateFields[1] ]

  const mapToAddress = (no_street = false, no_country = false) => {
    if (!settings.gmaps_key) { return } // Bail if no key
    const mapping_option = form.values.mapping_option
    let newAddress = form.values.physical_address ? form.values.physical_address.split('\n').join(', ') : ''
    if (!newAddress) {
      if (form.values.location) { // Lisitng addresses are split into multiple fields
        db.suburbs.get(form.values.location).then(v => {
          if (v) {
            newAddress = [
              [ form.values.unit_number, form.values.complex_name ].join(' '),
              [ form.values.street_number, form.values.street_name ].join(' ')
            ]
            let country = [
              v.province,
              v.country
            ]
            if (no_street || mapping_option === 'Suburb') {
              newAddress = []
            }
            if (no_country) {
              country = []
            }
            newAddress.push(v.suburb)
            newAddress.push(v.area)
            newAddress = [ ...newAddress, ...country ]
            newAddress = newAddress.filter(str => str && str.toString().trim()).join(', ')
            setAddress(newAddress)
          }
        })
      } else if (form.values.area && !form.values.suburb) { // Area profiles are split into multiple fields
        db.areas.get(form.values.area).then(v => {
          if (v) {
            newAddress.push(v.area)
            newAddress.push(v.province)
            newAddress.push(v.country)
            newAddress = newAddress.filter(str => str && str.trim()).join(', ')
            setAddress(newAddress)
          }
        })
      } else if (form.values.area && form.values.suburb) { // Area profiles are split into multiple fields
        db.suburbs.get(form.values.suburb).then(v => {
          if (v) {
            newAddress.push(v.suburb)
            newAddress.push(v.area)
            newAddress.push(v.province)
            newAddress.push(v.country)
            newAddress = newAddress.filter(str => str && str.trim()).join(', ')
            setAddress(newAddress)
          }
        })
      }
    }
  }

  const onPlacesChanged = (newPlace, map) => {
    const { google } = window
    if (!google) { return }
    if (!newPlace) { return }
    const lat = newPlace.geometry.location.lat()
    const lng = newPlace.geometry.location.lng()
    form.setFieldValue(props.coordinateFields[0], lat)
    form.setFieldValue(props.coordinateFields[1], lng)
    form.setFieldValue(field.name, { lat, lng })
    setValue({ lat, lng })
    setPlace(newPlace)
    const marker_bounds = new google.maps.LatLngBounds()
    marker_bounds.extend({ lat, lng })
    map.fitBounds(marker_bounds)
  }

  const copyToAddress = () => {
    const newAddress = {
      unit_number: '',
      complex_name: '',
      street_number: '',
      street_name: '',
      suburb: '',
      area: '',
      province: '',
      country: ''
    }
    if (place) {
      Object.keys(place.address_components).forEach(part => {
        const types = place.address_components[part].types
        if (typeof addressField === 'object') {
          const partValue = place.address_components[part].long_name || place.address_components[part].longText
          if (types.includes('country')) {
            if (addressField.country) {
              form.setFieldValue(addressField.country, partValue).then(() => {
                form.setFieldTouched(addressField.country, true)
              })
            }
          }
          if (types.includes('administrative_area_level_1')) {
            if (addressField.province) {
              form.setFieldValue(addressField.province, partValue).then(() => {
                form.setFieldTouched(addressField.province, true)
              })
            }
          }
          if (types.includes('administrative_area_level_2')) {
            if (addressField.area) {
              form.setFieldValue(addressField.area, partValue).then(() => {
                form.setFieldTouched(addressField.area, true)
              })
            }
          }
          if (types.includes('administrative_area_level_3')) {
            if (addressField.suburb) {
              form.setFieldValue(addressField.suburb, partValue).then(() => {
                form.setFieldTouched(addressField.suburb, true)
              })
            }
          }
          if (types.includes('route')) {
            if (addressField.street_name) {
              form.setFieldValue(addressField.street_name, partValue).then(() => {
                form.setFieldTouched(addressField.street_name, true)
              })
            }
          }
          if (types.includes('street_number')) {
            if (addressField.street_number) {
              form.setFieldValue(addressField.street_number, partValue).then(() => {
                form.setFieldTouched(addressField.street_number, true)
              })
            }
          }
        } else {
          Object.keys(newAddress).forEach(k => {
            switch (k) {
              case 'country': {
                const component = place.address_components.filter(c => c.types.includes('country'))[0]
                if (component) {
                  newAddress.country = component.long_name
                }
                break
              }
              case 'province': {
                const component = place.address_components.filter(c => c.types.includes('administrative_area_level_1'))[0]
                if (component) {
                  newAddress.province = component.long_name
                }
                break
              }
              case 'area': {
                const component = place.address_components.filter(c => c.types.includes('administrative_area_level_2'))[0]
                if (component) {
                  newAddress.area = component.long_name
                }
                break
              }
              case 'suburb': {
                const component = place.address_components.filter(c => c.types.includes('administrative_area_level_3'))[0]
                if (component) {
                  newAddress.suburb = component.long_name
                }
                break
              }
              case 'street_name': {
                const component = place.address_components.filter(c => c.types.includes('route'))[0]
                if (component) {
                  newAddress.street_name = component.long_name
                }
                break
              }
              case 'street_number': {
                const component = place.address_components.filter(c => c.types.includes('street_number'))[0]
                if (component) {
                  newAddress.street_number = component.long_name
                }
                break
              }
              default:
                break
            }
          })
        }
      })
      if (typeof addressField !== 'object') {
        const val = [
          [ newAddress.unit_number, newAddress.complex_name ].join(' '),
          [ newAddress.street_number, newAddress.street_name ].join(' '),
          newAddress.suburb,
          newAddress.area,
          newAddress.province,
          newAddress.country
        ].filter(str => str && str.trim())
        form.setFieldValue(addressField, val.join(',\r\n'))
      }
    }
  }

  useEffect(() => {
    if (settings) {
      const country_code = settings.region.toUpperCase()
      switch (country_code) {
        case 'AE':
          mapToAddress(true)
          break
        default:
          break
      }
    }
  }, [ form.values.location ])

  useEffect(() => {
    if (!isEqual(field.value, value)) {
      form.setFieldValue(latInput, value.lat)
      form.setFieldValue(lngInput, value.lng)
      form.setFieldValue(field.name, value)
    }
  }, [ value ])

  useEffect(() => {
    if (form.values.mapping_option === 'Suburb' && settings.gmaps_key) {
      mapToAddress(true)
    } else {
      mapToAddress(false, false)
    }
  }, [ form.values.mapping_option ])

  const lat = !isNaN(form.values[latInput]) ? Number(form.values[latInput]) : null
  const lng = !isNaN(form.values[lngInput]) ? Number(form.values[lngInput]) : null

  const { google } = window
  const cb = useRef()
  const [ center, setCenter ] = useState()
  const [ markerCenter, setMarkerCenter ] = useState({ lat, lng })

  let country_code = settings.region.toUpperCase()
  if (country_code === 'DEFAULT') {
    country_code = 'ZA'
  }
  const bounds = country_bounds[country_code].bounds

  useEffect(() => {
    if (!google || !google.maps || !google.maps.LatLng) { return }
    const sw = new google.maps.LatLng({ lat: bounds.south, lng: bounds.west })
    const ne = new google.maps.LatLng({ lat: bounds.north, lng: bounds.east })
    cb.current = new google.maps.LatLngBounds()
    cb.current.extend(sw)
    cb.current.extend(ne)
    const country_center = cb.current.getCenter()
    setCenter({
      lat: isNaN(markerCenter.lat) ? country_center.lat() : Number(markerCenter.lat),
      lng: isNaN(markerCenter.lng) ? country_center.lng() : Number(markerCenter.lng)
    })
    mapToAddress()
  }, [ google ])

  useEffect(() => {
    setMarkerCenter({
      lat: Number(form.values[latInput]),
      lng: Number(form.values[lngInput])
    })
  }, [ form.values[latInput], form.values[lngInput] ])


  const onMarkerDragEnd = coord => {
    const { latLng } = coord
    const latValue = latLng.lat()
    const lngValue = latLng.lng()
    setMarkerCenter({ lat: Number(latValue), lng: Number(lngValue) })
    form.setFieldValue(latInput, latValue)
    form.setFieldValue(lngInput, lngValue)
    form.setFieldValue(field.name, { lat: latValue, lng: lngValue })
  }

  if (!settings.gmaps_key) {
    return (
      <Tip hot heading="Error" text="No Google Maps key found. Please add one under your site settings." />
    )
  }
  return (
    <div id={id} className="google-map form-control">
      <APIProvider apiKey={settings.gmaps_key} libraries={libraries}>
        <MapComponent
          defaultBounds={bounds}
          cb={cb.current}
          center={center}
          fullscreenControl={false}
          zoomControl={true}
          streetViewControl={false}
          mapTypeControl={false}
          disableDefaultUI={false}
          gestureHandling={'cooperative'}
          onClick={({ detail: { latLng } }) => {
            setValue(latLng)
          }}
          defaultZoom={(value.lat && value.lng) ? 14 : 5}
        >
          <MapControl position={ControlPosition.BLOCK_START_INLINE_START}>
            <PlaceAutocompleteClassic
              address={address}
              searchAddress={searchAddress}
              setSearchAddress={setSearchAddress}
              onPlaceSelect={onPlacesChanged}
            />
          </MapControl>
          {addressField ? (
            <MapControl position={ControlPosition.BLOCK_START_INLINE_START}>
              <div className="map-buttons">
                {address ? (
                  <MapToButton clickHandler={() => {
                    setSearchAddress(true)
                    mapToAddress()
                  }}>Map from address</MapToButton>
                ) : null}
                {place ? (
                  <MapToButton clickHandler={copyToAddress}>Copy to address</MapToButton>
                ) : null}
              </div>
            </MapControl>
          ) : null}
          {(markerCenter.lat && markerCenter.lng && google?.maps?.Point) &&
            <Marker
              position={markerCenter}
              draggable
              onDragEnd={onMarkerDragEnd}
              options={{
                icon: {
                  path: 'M17.5 0C7.83 0 0 7.76 0 17.33C0 20.41 0.84 23.29 2.26 25.8C2.49 26.21 2.74 26.62 3.01 27.02L17.5 52L31.99 27.02C32.21 26.69 32.41 26.34 32.609 26L32.74 25.8C34.16 23.29 35 20.41 35 17.33C35 7.76 27.16 0 17.5 0Z',
                  strokeWidth: 0,
                  fillOpacity: 1,
                  fillColor: '#FC495D',
                  strokeColor: 'transparent',
                  anchor: new google.maps.Point(17.5, 52),
                  size: new google.maps.Size(35, 52),
                  scaledSize: new google.maps.Size(32, 47.5)
                }
              }}
            />
          }
        </MapComponent>
      </APIProvider>
    </div>
  )
}

MapInput.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.object,
  classes: PropTypes.string,
  form: PropTypes.object,
  settings: PropTypes.object,
  coordinateFields: PropTypes.array,
  addressField: PropTypes.oneOfType([
    PropTypes.shape({
      unit_number: PropTypes.string,
      complex_name: PropTypes.string,
      street_number: PropTypes.string,
      street_name: PropTypes.string,
      suburb: PropTypes.string,
      area: PropTypes.string,
      province: PropTypes.string,
      country: PropTypes.string
    }),
    PropTypes.string
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default MapInput
