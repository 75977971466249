import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useCustomCompareMemo } from '../utils'


const isDOMReady = window && window.document && window.document.createElement

const ProductFruits = props => {
  const scriptElement = useRef()

  const setUserConfig = reactProps => {
    const {
      projectCode,
      language,
      username,
      email,
      firstname,
      lastname,
      signUpAt,
      role
    } = reactProps

    if (!window.productFruits || !window.productFruits.identifyUser) {
      window.productFruitsUser = {
        username,
        email,
        firstname,
        lastname,
        signUpAt,
        role
      }
    } else {
      window.productFruits.identifyUser({
        username,
        email,
        firstname,
        lastname,
        signUpAt,
        role
      })
    }

    window.productFruits = window.productFruits || {}

    const fireLanguageChangedEvent = window.productFruits.language && window.productFruits.language !== language

    window.productFruits.language = language
    window.productFruits.code = projectCode

    if (fireLanguageChangedEvent) {
      document.dispatchEvent(new CustomEvent('pf:language_changed'))
    }
  }

  useEffect(() => {
    const { projectCode, language } = props

    if (!projectCode || !language || !isDOMReady) {
      // PF - dom is not ready
      // eslint-disable-next-line no-console
      console.info('PF - dom is not ready, projectCode is not set or language is not set')
      return
    }
    if (!window.productFruits) {
      setUserConfig(props);
      ((w, d, u, c) => {
        const a = d.getElementsByTagName('head')[0]
        const r = d.createElement('script'); r.async = 1
        r.src = `${u}?c=${c}`
        scriptElement.current = r
        a.appendChild(r)
      })(window, document, 'https://app.productfruits.com/static/script.js', projectCode)
    }
    if (window.productFruitsUnmounted && window.productFruitsInit) {
      window.productFruitsInit()
      delete window.productFruitsUnmounted
    }
    // eslint-disable-next-line consistent-return
    return () => {
      if (isDOMReady && window.productFruits && window.productFruits.services) {
        window.productFruits.services.destroy()
        document.body.style.cssText = ''
        delete window.productFruits
        delete window.productFruitsUser
        window.productFruitsUnmounted = true
        if (scriptElement.current) { scriptElement.current.remove() }
      }
    }
  }, [])

  useEffect(() => {
    if (!isDOMReady) {
      // eslint-disable-next-line no-console
      console.info('PF - dom is not ready')
      return
    }
    setUserConfig(props)
  }, [ useCustomCompareMemo(props) ])

  return false
}

ProductFruits.propTypes = {
  projectCode: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
}

export default ProductFruits
