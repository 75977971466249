import classNames from 'classnames'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { getIn } from 'formik'

import HelpText from './../../HelpText'
import Label from './Label'


const isValidValue = val => ![ undefined, '' ].includes(val)

function checkStep(number, step) {
  const precision = (step.toString().includes('.')) ? step.toString().split('.').pop().length : 1
  const factor = Math.pow(10, precision)
  const value = number * factor
  const multi_step = step * factor
  return value % multi_step
}

const FloatInput = props => {
  const { value, name } = props.field
  const { defaultValue, label, className, classes, disabled, id, suffix, actions, field, form, step, max } = props
  const [ val, setVal ] = useState(value || null)
  const [ isFocused, setIsFocused ] = useState(false)

  useEffect(() => {
    if (defaultValue && defaultValue !== val) {
      setVal(defaultValue)
    }
  }, [ defaultValue ])

  useEffect(() => {
    let newValue = value
    let propsValue = props._value

    if (propsValue !== newValue && !isFocused) {
      setVal(newValue)
      return
    }
    if (!isValidValue(val) || isNaN(val)) { // is empty, undefined or not a number (null is allowed)
      props.form.setFieldValue(name, null, false)
      setVal(null)
    } else if (
      val !== value && // has changed
        parseFloat(val) !== parseFloat(value) && // has changed
        (!isNaN(val) || val === null) // is a valid number or null
    ) {
      props.form.setFieldValue(name, val, false)
      newValue = val
      propsValue = val
    }
    if (val !== newValue && !isFocused) {
      if (isValidValue(newValue) || (isValidValue(val) && isValidValue(newValue))) {
        setVal(newValue)
      } else if (!isValidValue(newValue)) {
        setVal(null)
      }
    }
  }, [ val, value, props._value, isFocused ])

  return (
    <div id={id} className={`form-group ${name} ${classes}`}>
      {label &&
      <Label htmlFor={name}>
        {label}
      </Label>
      }
      <div className={classNames('forminput', 'float', className)}>
        <NumberFormat
          className={classNames('form-control  form-control-lg', className)}
          decimalScale={step !== 1 ? 2 : 0}
          thousandSeparator={' '}
          value={val}
          name={`${name}formatted`}
          isAllowed={(max || step) ? values => {
            const { floatValue } = values
            let valid = true
            if (max && floatValue > max) {
              valid = false
            }
            if (step && checkStep(floatValue, step) !== 0) {
              valid = false
            }
            if ([ null, undefined, '' ].includes(floatValue)) {
              valid = true
            }
            return valid
          } : undefined}
          placeholder={props.placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false)
            form.setFieldTouched(name, true).then(() => {
              if (props.onBlur && actions && getIn(actions, props.onBlur)) {
                actions[props.onBlur]({ field: field, form: form })
              }
            })
          }}
          id={`${name}-formatted`}
          onValueChange={values => {
            if (!isValidValue(values.value)) { values.value = null }
            if (parseFloat(val) !== parseFloat(values.value) && !isNaN(values.value)) {
              setVal(values.value)
            }
          } }
          allowNegative={false}
          isNumericString
          allowEmptyFormatting={false}
          type='tel'
          disabled={disabled}
        />

        <input
          id={name}
          name={name}
          type="hidden"
          value={[ null, undefined, '' ].includes(value) ? '' : value}
        />
        {suffix}
        {props.help &&
          <span className="help-text">
            {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
          </span>
        }
        <ErrorMessage name={name} render={msg => <div className="error">{msg.replace(name, label)}</div> } />
      </div>
    </div>
  )
}

FloatInput.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  defaultValue: PropTypes.number,
  _value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  max: PropTypes.number,
  actions: PropTypes.object,
  help: PropTypes.array,
  onBlur: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]),
  _areas: PropTypes.bool,
  _suburbs: PropTypes.bool,
  protected: PropTypes.bool,
  required: PropTypes.bool,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default FloatInput
