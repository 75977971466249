import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import AsyncInlineSelect from '../../common/forms/inputs/AsyncInlineSelect'
import { hasPermission, uniqueArray } from '../../../utils'


const all_agents = { first_name: 'All', last_name: 'Agents', id: '' }

const InlineAgentSelect = ({ actions, user, branchId, selectedValue, onChange, onLoad }) => {
  let agent_branches = []
  if (!hasPermission([
    'apply_to_all_branches'
  ], user.permissions, null, user.agent.id)) {
    agent_branches = [ ...user.agent.branches ]
  }

  const [ agents, setAgents ] = useState([ all_agents ])
  useEffect(() => {
    setAgents([ all_agents ])
  }, [ branchId ])
  return (
    <div className="filter-agent">
      <AsyncInlineSelect
        id="agent_id"
        name="agent_id"
        className="inline-select"
        classNamePrefix="inline"
        defaultValue={user.agent.id ? {
          first_name: user.agent.first_name,
          last_name: user.agent.last_name,
          id: user.agent.id
        } : all_agents}
        options={agents}
        form={{ values: { branches__overlap: branchId ? [ branchId ] : agent_branches } }}
        modelname="agents"
        labelseparator=" "
        fetchMany={actions.fetchMany}
        optionlabel={[ 'first_name', 'last_name' ]}
        noclear
        watch={[ 'branches__overlap' ]}
        params={{
          branches__overlap: branchId ? [ branchId ] : agent_branches,
          active: 1,
          order_by: 'first_name,last_name',
          fields: 'id,first_name,last_name,statistics'
        }}
        field={{ value: selectedValue }}
        onLoad={e => {
          setAgents(uniqueArray([ ...agents, ...e ], 'id'))
          if (onLoad) {
            onLoad(e)
          }
        }}
        onChange={onChange}
      />
    </div>
  )
}

InlineAgentSelect.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  branchId: PropTypes.any,
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
  onLoad: PropTypes.func
}

export default InlineAgentSelect
