import PropTypes from 'prop-types'
import React from 'react'
import { PieChart as PieGraph, Pie, Tooltip, Legend, Cell } from 'recharts'
import { ResponsiveContainer } from './ResizeContainer'


const renderColorfulLegendText = value => <span style={{ color: '#5A6F89' }}>{value}</span>

const PieChart = ({ data, legend, width }) => (
  <>
    <ResponsiveContainer width={width ? width : '100%'} height={250} minWidth="0" maxWidth={250}>
      <PieGraph>
        <Pie data={data} dataKey="value" minAngle={3} innerRadius={'50%'} outerRadius={'100%'} fill="#82ca9d" stroke="none">
          {
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.colour}/>
            ))
          }
        </Pie>
        <Tooltip />
        {!legend && <Legend iconType="circle" iconSize={9} wrapperStyle={{ fontSize: 11 }} formatter={renderColorfulLegendText} />}
      </PieGraph>
    </ResponsiveContainer>
    {legend ? legend(data) : null}
  </>
)
PieChart.propTypes = {
  data: PropTypes.array,
  legend: PropTypes.func,
  width: PropTypes.number
}
export default PieChart
