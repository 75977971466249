import PropTypes from 'prop-types'
import React, { Fragment, useState, useEffect } from 'react'
import { Formik, Field } from 'formik'
import ReactDOM from 'react-dom'
import { NavLink } from 'react-router-dom'

import { buildLambdaURI, hasAddons, hasPermission } from '../utils'
import FocusTrap from './common/focus-trap/FocusTrap'
import { Button } from './ui/Button'
import CustomForm from './common/forms/CustomForm'
import Select from './common/forms/inputs/Select'


const UserHeader = ({
  user, user: { agent },
  actions,
  addons,
  activealerts,
  ws,
  toggleAlerts,
  showAlert,
  setChatOpen,
  unread
}) => {
  const [ isOpen, setOpen ] = useState(false)
  const [ isDropdown, setDropdown ] = useState(false)
  const [ options, setOptions ] = useState([])
  const [ lightstone_branches ] = useState(
    agent.meta ? agent.meta.branches.map(b => b.lightstone_api_key).filter(i => i) : []
  )

  // eslint-disable-next-line no-unused-vars
  const [ default_home_page, setDefaultHomePage ] = useState(agent.default_home_page)

  useEffect(() => {
    const links = []
    Array.from(document.querySelectorAll('.main-menu > .menu-item')).forEach(group => {
      Array.from(group.querySelectorAll(':scope > .inner-item')).forEach(top_level => {
        if (top_level.tagName === 'A') {
          const href = top_level.getAttribute('href').replace(/\/secure\/\d+/gi, '')
          const label = top_level.querySelector(':scope > .menu-item-content').innerHTML
          links.push({ value: href ? href : '/', label })
        } else {
          const label = top_level.querySelector(':scope > .menu-item-content').innerHTML
          const sub_options = Array.from(top_level.nextSibling.querySelectorAll('.menu-item > .inner-item')).map(sub_level => {
            const href = sub_level.getAttribute('href').replace(/\/secure\/\d+/gi, '')
            const sublabel = sub_level.querySelector(':scope > .menu-item-content').innerHTML
            return { value: href ? href : '/', label: sublabel }
          })
          links.push({ label, options: sub_options })
        }
      })
    })
    setOptions(links)
  }, [])

  const handleClose = e => {
    if (e) { e.stopPropagation() }
    setOpen(false)
  }

  useEffect(() => {
    if (!agent.id || default_home_page === agent.default_home_page) {
      return
    }
    new Promise((resolve, reject) => {
      actions.updateHomePage({
        values: {
          endpoint: `/users/api/v1/agents/${user.agent.id}/home-page/`,
          modelname: 'agents',
          default_home_page
        },
        resolve,
        reject
      })
    }).then(() => {
      setDefaultHomePage(default_home_page)
    }).catch(() => {})
  }, [ default_home_page ])

  const setVisibility = e => {
    e.stopPropagation()
    if (!isOpen) {
      setChatOpen(false)
      setOpen(true)
    } else {
      setOpen(false)
    }
  }
  const handleClick = e => {
    const inputEl = isDropdown ? isDropdown.querySelector('.forminput') : null
    if (inputEl && inputEl.contains(e.target)) {
      return false
    }
    if (isDropdown) {
      handleClose(e)
    } else {
      setVisibility(e)
    }
    return true
  }

  const prepareLogout = () => {
    actions.doLogout()
  }

  const root = document.getElementById('header')
  if (!user) { return null }
  let cropped = false
  let initials = null
  if (agent) {
    const image = agent.meta && agent.meta.image ? agent.meta.image.file : agent.image_url
    if (image) {
      const parms = {
        cx: agent.profile_picture_coord_x,
        cy: agent.profile_picture_coord_y,
        cw: agent.profile_picture_width,
        ch: agent.profile_picture_height,
        w: 90,
        h: 90
      }
      cropped = buildLambdaURI(image, parms)
    }
    if (agent.first_name || agent.last_name) {
      initials = `${agent.first_name.substring(0, 1)}${agent.last_name.substring(0, 1)}`
    }
  }

  return (
    <div className="user-toggle" onClick={handleClick}>
      <div className="user-details">
        <div className="user-name">{user.agent.first_name} {user.agent.last_name}</div>
        <div className="site-name">{user.agent.site.website_name}</div>
      </div>
      <div className="user-photo">
        <div className="avatar avatar-md">
          {cropped ? (
            <div
              title={`${user.agent.first_name} ${user.agent.last_name} (${ws ? 'Connected' : 'Disconnected'})`}
              className="thumbimg"
            >
              <img alt={`${user.agent.first_name} ${user.agent.last_name} (${ws ? 'Connected' : 'Disconnected'})`} src={cropped} />
            </div>
          ) : (
            <div className="thumbimg initials">
              <svg viewBox="0 0 96 96">
                <circle cx="50%" cy="50%" r="50%" />
                <text textAnchor="middle" x="50%" y="50%" dy="0.35em" fontSize="32" fontFamily="Open Sans">{initials}</text>
              </svg>
            </div>
          )}
        </div>
        <div className={`ws-status ${ws ? 'connected' : 'disconnected'}`} />
      </div>
      {isOpen &&
        ReactDOM.createPortal(<FocusTrap
          forwardRef={el => setDropdown(el)}
          onExit={handleClose}
          className="user-menu-cover"
          dialog={true}
        >
          <div id="user-menu" className="user-menu">
            <div className="menu-icons">
              {showAlert ? (
                <>
                  <Button icon="#icon24-Bell" component='div' onClick={toggleAlerts} className="action navitem btn btn-text btn-icon-24 alerts-head">
                    <div className="badge">{activealerts}</div>
                  </Button>
                  <Button icon="#icon24-Chat" component='div' onClick={setChatOpen} className="action navitem btn btn-text btn-icon-24 alerts-head">
                    <div className="badge">{unread}</div>
                  </Button>
                </>
              ) : null}
              <Button icon="#icon24-X-Large" component='div' onClick={handleClose} className="action navitem profile btn btn-text btn-icon-24 menu-close" />
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={{ default_home_page }}
              onSubmit={() => {}}
            >{ formik => (
                <CustomForm
                  component="div"
                  form={formik}
                  render={() => (
                    <Field
                      component={Select}
                      name="default_home_page"
                      id="default_home_page"
                      label="Default Home Page"
                      onChange={e => {
                        setDefaultHomePage(e.value)
                      }}
                      noclear
                      options={options}
                    />
                  )}
                />
              )}
            </Formik>
            { user.agents.length > 1 &&
            <Button icon="#icon24-User" component='div' onClick={actions.unselectAgent} className="action navitem profile btn btn-text btn-icon-24">
              <span className="label">Switch Account</span>
            </Button>
            }
            {(hasPermission([ 'users_update_scheduling' ], user.permissions) && hasAddons(addons, [ 'scheduling_addon' ]) && !user.permissions.is_prop_data_user) ? (
              <>
                <Button
                  icon="#icon24-Calendar"
                  component={NavLink}
                  to={`/secure/${user.agent.site.id}/agents/${user.agent.id}/calendar`}
                  className="action navitem profile btn btn-text btn-icon-24"
                  key="site-settings-calendar">
                  <span className="label">My Calendar</span>
                </Button>
                <Button
                  icon="#icon24-Cog"
                  component={NavLink}
                  to={`/secure/${user.agent.site.id}/agents/${user.agent.id}/availability`}
                  className="action navitem profile btn btn-text btn-icon-24"
                  key="site-settings-availability">
                  <span className="label">Manage Availability</span>
                </Button>
              </>
            ) : null}
            {
              [ 'is_prop_data_user',
                'super_access',
                'settings_update',
                'settings_view',
                'tags_own_add',
                'tags_branch_add',
                'tags_agency_add',
                'tags_own_update',
                'tags_branch_update',
                'tags_agency_update',
                'tags_own_delete',
                'tags_branch_delete',
                'tags_agency_delete',
                'snippets_add',
                'snippets_update',
                'snippets_delete'
              ].some(perm => user.permissions.includes(perm)) ? (
                  <Button
                    icon="#icon24-Cog"
                    component={NavLink}
                    to={`/secure/settings/${user.agent.site.id}/edit`}
                    className="action navitem profile btn btn-text btn-icon-24"
                    key="site-settings-usernav">
                    <span className="label">Site Settings</span>
                  </Button>
                ) : null}
            <>
              {hasAddons(addons, [ 'portals_addon' ]) && user.permissions.includes('is_prop_data_user') &&
                <Button icon="#icon24-Cog" component={NavLink} to={`/secure/${user.agent.site.id}/portals`} className="action navitem profile btn btn-text btn-icon-24" key="portal-settings-usernav">
                  <span className="label">Portal Settings</span>
                </Button>
              }
              {user.permissions.includes('is_prop_data_user') &&
                <Button icon="#icon24-Cog" component={NavLink} to={`/secure/${user.agent.site.id}/domains`} className="action navitem profile btn btn-text btn-icon-24" key="domain-settings-usernav">
                  <span className="label">Domain Settings</span>
                </Button>
              }
              {!user.permissions.includes('is_prop_data_user') && !lightstone_branches.length &&
                <Button icon="#icon24-Squares" component={NavLink} to={`/secure/${user.agent.site.id}/integrations`} className="action navitem profile btn btn-text btn-icon-24" key="domain-settings-usernav">
                  <span className="label">Integrations</span>
                </Button>
              }
            </>
            <Button icon="#icon24-Signout" component='div' onClick={prepareLogout} className="action navitem profile btn btn-text btn-icon-24">
              <span className="label">Sign Out</span>
            </Button>
          </div>
        </FocusTrap>, root)
      }
    </div>
  )
}


UserHeader.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object,
  setChatOpen: PropTypes.func,
  ws: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
  showAlert: PropTypes.bool,
  toggleAlerts: PropTypes.func,
  addons: PropTypes.array,
  activealerts: PropTypes.number,
  unread: PropTypes.number
}

export default UserHeader

// UserHeader.whyDidYouRender = true
