import { getIn } from 'formik'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import { hasPermission, slugify, uniqueArray, valueFormat } from '../../utils'
import { Button } from '../ui/Button'
import FieldGroup from './forms/FieldGroup'
import Card from './Card'


const DealSteps = props => {
  const { model, config, form, actions, user } = props
  const { fieldgroups } = config
  const ignore_groups = Object.keys(fieldgroups).filter(group => ![ 'Miscellaneous' ].includes(group))
  ignore_groups.push('Commission Details')
  const suspensive_conditions = getIn(model, 'suspensive_conditions')
  let other_fields = config.fields.filter(f => f.group && !ignore_groups.includes(f.group))
  const suspensive_field = config.fields.find(f => f.name === 'suspensive_conditions')
  const bond_fields = suspensive_field.fields.filter(f => Array.isArray(f.edit) && getIn(f.edit, '0.0.condition.value') === 'Subject to bond' && f.name !== 'notes')
  const sale_fields = suspensive_field.fields.filter(f => Array.isArray(f.edit) && getIn(f.edit, '0.0.condition.value') === 'Subject to sale' && f.name !== 'notes')
  const other_groups = uniqueArray(other_fields.map(f => f.group))
  return other_groups.map((groupname, gidx) => {
    if (groupname === 'Conditions met') {
      const fidx = other_fields.findIndex(f => f.group === groupname)
      const pre_suspensive = [ ...other_fields.slice(0, fidx) ]
      const post_suspensive = [ ...other_fields.slice(fidx) ]
      let suspensive = []
      suspensive_conditions.forEach((condition, cidx) => {
        suspensive.push({
          name: `suspensive_conditions.${cidx}.label`,
          group: groupname,
          aidx: cidx,
          aname: 'suspesive_conditions',
          label: condition.subject_to,
          input: 'SectionHeading',
          classes: cidx ? 'bordered' : null,
          edit: true,
          readonly: [
            [
              {
                condition: {
                  type: 'permissions',
                  permissions: [
                    '!deals_update',
                    '!deals_update_own'
                  ],
                  permission_key: {
                    agent: [
                      'deals_update_own'
                    ],
                    selling_agents: [
                      'deals_update_own'
                    ]
                  }
                }
              }
            ],
            [
              {
                condition: {
                  type: 'permissions',
                  permissions: [
                    'deals_update',
                    'deals_update_own'
                  ],
                  permission_key: {
                    agent: [
                      'deals_update_own'
                    ],
                    selling_agents: [
                      'deals_update_own'
                    ]
                  }
                }
              },
              {
                field: 'status',
                condition: {
                  type: 'contains',
                  value: [ 'Final', 'Closed', 'Cancelled', 'Deleted' ]
                }
              }
            ]
          ],
          cols: 'lg-12'
        })
        suspensive.push({
          name: `suspensive_conditions.${cidx}.date_met`,
          group: groupname,
          aidx: cidx,
          aname: 'suspesive_conditions',
          label: 'Date',
          input: 'Date',
          format: 'date',
          edit: true,
          disabled: [
            [
              {
                condition: {
                  type: 'permissions',
                  permissions: [
                    '!deals_update',
                    '!deals_update_own',
                    '!is_prop_data_user'
                  ],
                  permission_key: {
                    agent: [
                      'deals_update_own'
                    ],
                    selling_agents: [
                      'deals_update_own'
                    ]
                  }
                }
              }
            ],
            [
              {
                condition: {
                  type: 'permissions',
                  permissions: [
                    'deals_update',
                    'deals_update_own'
                  ],
                  permission_key: {
                    agent: [
                      'deals_update_own'
                    ],
                    selling_agents: [
                      'deals_update_own'
                    ]
                  }
                }
              },
              {
                field: 'status',
                condition: {
                  type: 'contains',
                  value: [ 'Final', 'Closed', 'Cancelled', 'Deleted' ]
                }
              }
            ]
          ],
          cols: 'lg-6'
        })
        switch (condition.subject_to) {
          case 'Subject to bond':
            suspensive = [
              ...suspensive,
              ...bond_fields.map(f => ({
                ...f,
                name: `suspensive_conditions.${cidx}.${f.name}`,
                group: groupname,
                aidx: cidx,
                aname: 'suspesive_conditions',
                disabled: f.name === 'expiry' ? true : f.disabled,
                required: false
              }))
            ]
            break
          case 'Subject to sale':
            suspensive = [
              ...suspensive,
              ...sale_fields.map(f => ({
                ...f,
                name: `suspensive_conditions.${cidx}.${f.name}`,
                group: groupname,
                aidx: cidx,
                aname: 'suspesive_conditions',
                disabled: f.name === 'expiry' ? true : f.disabled,
                required: false
              }))
            ]
            break
          default:
            break
        }
        suspensive.push({
          name: `suspensive_conditions.${cidx}.notes`,
          group: groupname,
          aidx: cidx,
          aname: 'suspesive_conditions',
          label: 'Notes',
          edit: true,
          disabled: [
            [
              {
                condition: {
                  type: 'permissions',
                  permissions: [
                    '!deals_update',
                    '!deals_update_own'
                  ],
                  permission_key: {
                    agent: [
                      'deals_update_own'
                    ],
                    selling_agents: [
                      'deals_update_own'
                    ]
                  }
                }
              }
            ],
            [
              {
                condition: {
                  type: 'permissions',
                  permissions: [
                    'deals_update',
                    'deals_update_own'
                  ],
                  permission_key: {
                    agent: [
                      'deals_update_own'
                    ],
                    selling_agents: [
                      'deals_update_own'
                    ]
                  }
                }
              },
              {
                field: 'status',
                condition: {
                  type: 'contains',
                  value: [ 'Final', 'Closed', 'Cancelled', 'Deleted' ]
                }
              }
            ]
          ],
          input: 'TextArea'
        })
      })
      other_fields = [ ...pre_suspensive, ...suspensive, ...post_suspensive ]
    }

    // eslint-disable-next-line no-unused-vars
    const [ canEdit, setCanEdit ] = useState(hasPermission([ 'deals_update' ], user.permissions))
    const isSeller = hasPermission([ 'deals_update_own' ], user.permissions) && (model.selling_agents.includes(user.agent.id) || model.agent === user.agent.id)
    // eslint-disable-next-line no-unused-vars
    const [ isSuper, setIsSuper ] = useState(hasPermission([ 'deals_update_super' ], user.permissions))
    // eslint-disable-next-line no-unused-vars
    const [ isDealFinal, setIsDealFinal ] = useState([ 'Final', 'Closed', 'Cancelled', 'Deleted' ].includes(model.status))
    return (<FieldGroup
      key={`fs-${gidx}`}
      groupname={groupname}
      gidx={gidx}
      fields={other_fields}
      form={form}
      card
      modelname={config.modelname}
      modelid={model.id}
      columns
      render={({ renderFieldGroup, group }) => (
        <Card
          key={`fgc-${gidx}`}
          id={slugify(groupname)}
          background={true}
          collapsable={false}
          header={
            <h3>{groupname}</h3>
          }
          body={
            <div
              className="editgroup"
            >
              { renderFieldGroup(group) }
              <div className="input-group">
                <div className="submit-button">
                  {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                    <>
                      <Button type="button" className="btn btn-primary" onClick={form.submitForm}>Submit</Button>
                      {groupname === 'Sale agreement' ? (
                        <div className="submit-button">
                          <Button component={NavLink} to={`/secure/${model.site}/deals/${model.id}/letter`} className="btn btn-primary">Generate Letter of Undertaking</Button>
                        </div>
                      ) : null}
                      {groupname === 'Instruction sent' ? (
                        <div className="submit-button">
                          <Button type="button" onClick={() => {
                            new Promise((resolve, reject) => {
                              actions.emailTemplate({
                                resolve,
                                reject,
                                values: {
                                  template: 'conveyancing-instruction',
                                  deal: model.id
                                }
                              })
                            }).then(() => actions.updateModel({
                              values: {
                                modelname: config.modelname,
                                id: model.id,
                                transfer_instruction_type: 'Email',
                                transfer_instruction_status: 'Instruction sent',
                                instruction_sent_date: valueFormat('shortdate', new Date())
                              }
                            })).then(() => {
                              actions.fetchInitial()
                            })
                          }} className="btn btn-primary">Send Instruction</Button>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          }
        />
      )}
    />)
  })
}

DealSteps.propTypes = {
  config: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  model: PropTypes.object
}

export default DealSteps
