import { useEffect, useCallback, useState } from 'react'
import { breakpoint } from '../utils'


export const useBreakPoint = () => {
  const [ desktop, setDesktop ] = useState(breakpoint.matches)
  const toggleDesktop = useCallback(e => {
    if (e.matches && !desktop) {
      setDesktop(true)
    } else if (e.matches !== undefined && desktop) {
      setDesktop(false)
    }
  }, [ desktop ])

  useEffect(() => {
    breakpoint.addEventListener('change', toggleDesktop)
    return () => {
      breakpoint.removeEventListener('change', toggleDesktop)
    }
  }, [])
  return desktop
}
