import merge from 'deepmerge'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import classNames from 'classnames'

import Loader from '../Loader'
import { overwriteMerge, slugify, valueFormat, debounce } from '../../../utils'
import { Button } from '../../ui/Button'
import Body from './Body'
import Head from './Head'


class SimpleTable extends React.Component {
  constructor(props) {
    super(props)
    this.triggerKeyboardShift = this.triggerKeyboardShift.bind(this)
    this.selectOne = this.selectOne.bind(this)
    this.toggleOrder = this.toggleOrder.bind(this)
    this.toggleSelectAll = this.toggleSelectAll.bind(this)
    this.calculateGrouping = this.calculateGrouping.bind(this)
    this.calculateRows = this.calculateRows.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.resetPage = this.resetPage.bind(this)
    this.refreshPage = this.refreshPage.bind(this)
    this.parseData = this.parseData.bind(this)
    this.loadMore = this.loadMore.bind(this)
    this.bindClass = this.bindClass.bind(this)
    this.state = {
      columns: [],
      rows: [],
      shifting: false,
      lastSelected: false,
      selectedAll: false,
      selected: [],
      params: null,
      data: null,
      index: [],
      loading: false
    }
    this.AbortController = new AbortController()
    this.unselectAll = this.unselectAll.bind(this)
    this._is_mounted = true
  }

  componentDidMount() {
    window.addEventListener('keydown', this.triggerKeyboardShift)
    window.addEventListener('keyup', this.triggerKeyboardShift)
    if (this.props.action) {
      this.setState({ params: this.props.params }, this.fetchData)
    } else if (this.props.data) {
      this.fetchData(this.props.data)
    }
    if (this.props.header) {
      const columns = []
      const rows = []
      this.props.header.map(column => this.calculateGrouping(columns, column))
      if (!isEqual(columns, this.state.columns)) {
        this.calculateRows(this.props.header, 1, rows)
        this.setState({ columns, rows })
      }
    }
    if (this.props.orderByDefault) {
      this.setState({ orderby: this.props.orderByDefault })
    }
    this.bindClass()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.header) {
      const columns = []
      const rows = []
      this.props.header.map(column => this.calculateGrouping(columns, column))
      if (!isEqual(columns, this.state.columns)) {
        this.calculateRows(this.props.header, 1, rows)
        this.setState({ columns, rows })
      }
    }
    if (!isEqual(prevState.selected, this.state.selected) && this.props.onSelect) {
      const { data, selected } = this.state
      this.props.onSelect({ data, selected, state: this.unselectAll })
    }
    if (!isEqual(prevProps.params, this.props.params) && this.props.action) {
      this.setState({ params: this.props.params }, this.fetchData)
    }
    if (this.props.data && !isEqual(prevProps.data, this.props.data)) {
      this.fetchData(this.props.data)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.triggerKeyboardShift)
    window.removeEventListener('keyup', this.triggerKeyboardShift)
    this._is_mounted = false
    this.AbortController.abort()
  }

  unselectAll() {
    this.setState({ selected: [], lastSelected: false, selectedAll: false })
  }

  triggerKeyboardShift(e) {
    if (e.key === 'Shift') {
      if (e.type === 'keydown') { this.setState({ shifting: true }) } else { this.setState({ shifting: false }) }
    }
  }

  bindClass() {
    return this.props.getClass ? this.props.getClass(this) : null
  }

  parseData(data) {
    if (this.props.parser) {
      data = this.props.parser(data, this.state.data)
    }
    return { data: data.options, hasMore: data.hasMore, final_row: data.final_row, loading: false }
  }

  fetchData(data) {
    this.setState({ loading: true, data: null })
    if (data) {
      this.setState(this.parseData(data))
    } else if (this.props.action) {
      debounce(() => {
        new Promise((resolve, reject) => {
          this.props.action({ params: this.state.params, signal: this.AbortController.signal, resolve, reject })
        }).then(this.parseData).then(results => {
          const index = results.data ? results.data.map(r => r.id) : []
          if (this._is_mounted) {
            this.setState({ ...results, index, loading: false })
          }
        }).catch(e => {
          if (e.status !== 408) {
            console.error(e)
          }
        })
      }, 300)()
    } else if (this.props.data) {
      if (this._is_mounted) {
        this.setState(this.parseData({ options: this.props.data }))
      }
    }
  }

  loadMore() {
    this.setState({ loading: true })
    const { data } = this.state
    if (this.props.action) {
      new Promise((resolve, reject) => {
        this.props.action({
          params: { ...this.state.params, offset: data.length },
          signal: this.AbortController.signal,
          resolve,
          reject
        })
      }).then(this.parseData).then(results => {
        const { data: newData, hasMore, final_row } = results
        const updated = merge(data, newData)
        const index = data.options ? data.options.map(r => r.id) : []
        if (this._is_mounted) {
          this.setState({ data: updated, index, hasMore, loading: false, final_row })
        }
      })
    }
  }

  resetPage() {
    this.setState({ data: null })
  }

  refreshPage(data) {
    this.resetPage()
    this.fetchData(data)
  }

  selectOne(data) { // Shift or single click select?
    let selected = [ ...this.state.selected ]
    if (this.state.shifting && this.state.lastSelected) {
      const lastidx = this.state.index.findIndex(a => a === this.state.lastSelected)
      const selectedidx = this.state.index.findIndex(a => a === data.id)
      let implied = []
      if (lastidx < selectedidx) { // Goes both ways!
        implied = this.state.index.slice(lastidx, selectedidx)
      } else {
        implied = this.state.index.slice(selectedidx, lastidx)
      }
      selected = implied
      selected.push(data.id)
      this.setState({ lastSelected: data.id, selected, selectedAll: false })
    } else {
      if (selected.includes(data.id)) {
        selected = selected.filter(a => a !== data.id)
      } else {
        selected.push(data.id)
      }
      this.setState({ lastSelected: data.id, selected, selectedAll: false })
    }
  }

  toggleOrder(col, e) {
    const el = e.target.closest('th')
    const { params } = this.state
    let key = col.name
    // Fields which reference other models need to be ordered by their key
    let field = this.props.config.fields.find(f => isEqual(f.name, col.name))
    if (!field) {
      field = col
    }
    if (field && field.modelname && field.orderkey) {
      key = `${key}__${field.orderkey}`
    } else if (field.container && field.container === 'stats') {
      key = `statistics__${key}`
    }
    if (Array.isArray(col.name)) { key = col.name[0] }
    if (field && field.orderby) {
      key = field.orderby
    }
    if (el.classList.contains('orderedby')) {
      if (!el.classList.contains('reversed')) {
        if (Array.isArray(field.orderby)) {
          key = field.orderby.map(k => `-${k}`).join(',')
        } else {
          key = `-${key}`
        }
        el.classList.add('reversed')
      } else {
        el.classList.remove('reversed')
      }
    } else {
      this.resetPage()
      el.classList.add('orderedby')
    }
    this.setState({ params: { ...params, order_by: key } }, this.fetchData)
    // Change order by
  }

  toggleSelectAll(e) {
    const t = e.target
    const head = document.querySelectorAll('table.datatable thead input[type="checkbox"]')[0]
    head.checked = t.checked
    const selected = [ ...this.state.index ]
    if (t.checked) {
      this.setState({ selectedAll: true, selected })
    } else {
      this.setState({ selectedAll: false, selected: [] })
    }
  }

  calculateGrouping(columns, object, groupArray = []) {
    if (Array.isArray(object)) { return }
    const column = merge({}, object, { arrayMerge: overwriteMerge })
    let groupingArray = groupArray || []
    if (column.children && Array.isArray(column.children) && column.children.length) {
      groupingArray = groupingArray.push(column.label)
      const children = column.children.map(c => this.calculateGrouping(columns, c, groupingArray))
      column.children = children
    }
    column.nesting = groupingArray
    columns.push(column)
    // eslint-disable-next-line consistent-return
    return column
  }

  calculateRows(columns, count = 1, rows) {
    columns.forEach(col => {
      const column = merge({}, col, { arrayMerge: overwriteMerge })
      if (!rows[count - 1]) {
        rows[count - 1] = []
      }
      rows[count - 1].push(column)
      if (column.children) {
        this.calculateRows(column.children, count + 1, rows)
      }
    })
    return count
  }

  render() {
    return (
      <>
        <div className="listbody">
          <div className="datatablewrap">
            <div className="datatablewrap-inner">
              <table cellPadding="0" cellSpacing="0" border="0" className={`datatable ${this.props.config.modelname}tbl`}>

                <Head
                  modelname={this.props.config.modelname}
                  params={this.state.params}
                  orderby={this.state.orderby}
                  selectAll={this.selectAll}
                  selectedAll={this.state.selectedAll}
                  selectable={this.props.selectable}
                  columns={this.state.columns}
                  rows={this.state.rows}
                  rowActions={this.props.rowActions}
                  toggleSelectAll={this.toggleSelectAll}
                  toggleOrder={this.toggleOrder}
                  sortable={this.props.sortable}
                />

                <Body
                  user={this.props.user}
                  config={this.props.config}
                  selected={this.state.selected}
                  columns={this.state.columns}
                  data={this.state.data}
                  loading={this.state.loading}
                  updateModel={this.props.updateModel}
                  rowActions={this.props.rowActions}
                  tableconfig={this.props.header}
                  selectOne={this.selectOne}
                  doubleClick={!!this.props.config.doubleclick}
                  match={this.props.match}
                  selectable={this.props.selectable}
                  currency={this.props.currency}
                  sortable={this.props.sortable}
                  dragEnded={this.props.dragEnded}
                  refreshPage={this.refreshPage}
                />

                {this.props.summerize_footer && this.state.final_row ? (
                  <tfoot>
                    <tr>
                      {this.props.header.map((col, cidx) => {
                        if (!col.total) {return <th key={`foot-${col.name}`}><div className="heading-wrapper"></div></th>}
                        return (
                          <th
                            key={`foot-c${cidx}`}
                            className={classNames(col.classes, `column-${slugify(col.name)}`, { grouper: !!col.children })}
                          >
                            <div className="heading-wrapper">
                              {valueFormat(
                                col.format,
                                this.state.final_row[col.name], { currency: this.props.currency }
                              )}
                            </div>
                          </th>
                        )
                      })}
                    </tr>
                  </tfoot>
                ) : null}

              </table>
              <div style={{ clear: 'both' }} />
            </div>
          </div>
        </div>
        {(this.state.hasMore && this.props.paginated) ? (
          <div className="list-actions">
            {this.state.loading ? (
              <Loader inline />
            ) : (
              <Button
                onClick={this.loadMore}
                className="btn btn-grey"
              >
                Load More
              </Button>
            )}
          </div>
        ) : null}
      </>
    )
  }
}

SimpleTable.propTypes = {
  data: PropTypes.array,
  header: PropTypes.array,
  selectable: PropTypes.bool,
  rowActions: PropTypes.func,
  config: PropTypes.object,
  match: PropTypes.object,
  sortable: PropTypes.bool,
  paginated: PropTypes.bool,
  currency: PropTypes.string,
  user: PropTypes.object,
  action: PropTypes.func,
  parser: PropTypes.func,
  getClass: PropTypes.func,
  onSelect: PropTypes.func,
  updateModel: PropTypes.func,
  orderByDefault: PropTypes.string,
  dragEnded: PropTypes.func,
  params: PropTypes.object,
  summerize_footer: PropTypes.bool,
  final_row: PropTypes.object
}

export default SimpleTable

// SimpleTable.whyDidYouRender = true
