/* eslint-disable new-cap */
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Label, Legend, Pie, PieChart, Surface, Symbols, Text } from 'recharts'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany, getLeadsBreakdown } from '../../actions'
import { CONFIG, MINUSER } from '../../selectors'
import { getRandomColor, sortBy, textToDate, valueFormat, getTextWidth, breakpoint } from '../../utils'
import Card from '../common/Card'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import Loader from '../common/Loader'
import { useBreakPoint } from '../../hooks/useBreakPoint'
import withDelay from './withDelay'
import InlineAgentSelect from './inputs/InlineAgentSelect'
import InlineBranchSelect from './inputs/InlineBranchSelect'
import InlinePeriodSelect from './inputs/InlinePeriodSelect'


const RADIAN = Math.PI / 180

const dateFilter = dates => dates.filter(o => !o.value.includes('NEXT') && !o.value.includes('TOMORROW') && !o.value.includes('TODAY') && !o.value.includes('YESTERDAY'))

const { start: defaultStart, end: defaultEnd, days: defaultDays } = textToDate('LAST_30_DAYS')

const LeadSourceWidget = ({ status_field, user, model, actions, branches }) => {
  const [ branchId, setBranchId ] = useState(branches ? branches[0] : '')
  const [ agentId, setAgentId ] = useState(user.agent.id || '')
  const [ status, setStatus ] = useState('Active')
  const [ statuses ] = useState([ { value: status_field.options.map(o => o.value).join(','), label: 'All Statuses' }, ...status_field.options ])
  const [ loading, setLoading ] = useState(false)
  const [ statistics, setStatistics ] = useState([])
  const showActions = useBreakPoint(breakpoint)
  const [ { start: current, end: limit, period }, setPeriodData ] = useState({ start: valueFormat('shortdate', defaultStart.toString()), end: valueFormat('shortdate', defaultEnd.toString()), days: defaultDays, period: 'LAST_30_DAYS' })
  const abortController = useRef(new AbortController())

  const filterLeads = useCallback(() => {
    let params = {
      contact__branch: branchId,
      contact_agents__in: agentId
    }
    if (!branchId && branches) {
      params = {
        contact__branch__in: branches
      }
    }
    if (model) {
      params = {
        [model.model]: model.id
      }
    }
    setLoading(true)
    new Promise((resolve, reject) => actions.getLeadsBreakdown({
      fields: [ 'leads' ],
      subsets: [ 'leads.source_counts' ],
      params: {
        status__in: status,
        created__date__gte: current,
        created__date__lte: limit,
        ...params
      },
      signal: abortController.current.signal,
      resolve,
      reject
    })).then(r => {
      const stats = sortBy(Object.keys(r.leads.source_counts)
        .filter(k => r.leads.source_counts[k])
        .map(k => ({ label: k, value: r.leads.source_counts[k] })), 'value').reverse()
      const total = stats.map(stat => stat.value).reduce((a, b) => a + b, 0)
      const top_5 = stats.filter(stat => stat.label !== 'Other').slice(0, 5)
        .map((stat, sid) => ({ ...stat, fill: getRandomColor(sid) }))
      const top_5_total = top_5.map(stat => stat.value).reduce((a, b) => a + b, 0)
      const other_totals = total - top_5_total
      let data = top_5
      if (other_totals) {
        data = [ ...top_5, {
          value: other_totals,
          label: 'Other',
          fill: getRandomColor(5)
        } ]
      }
      setStatistics(data)
    }).catch(() => {
    }).finally(() => {
      setLoading(false)
    })
  }, [ agentId, branchId, status, current, limit ])

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const value = (percent * 100).toFixed(0)
    let scale = radius / 99.6
    const width = getTextWidth(value, `${12 * scale}px Poppins`)
    const max = (radius - 5) * 2
    if (width > max) {
      const offset = max / width
      scale *= offset
    }
    if (scale < 0.6) {
      return null
    }
    if (value > 0) {
      return (
        <Text x={x} y={y} fontFamily="Poppins" fontSize={12 * scale} fill="white" textAnchor="middle" verticalAnchor='middle'>
          {`${value}%`}
        </Text>
      )
    }
    return null
  }

  const renderCustomizedLegend = ({ payload, chartWidth, chartHeight, layout }) => (
    <div className="customized-legend-vertical" style={{ width: layout === 'vertical' ? chartWidth - chartHeight : chartWidth }}>
      {
        payload.map((entry, eid) => {
          const { payload: data, color } = entry
          if (data.label === 'No Leads Found') {
            return null
          }
          return (
            <span key={`overlay-${data.label}-${eid}`} className="legend-item">
              <Surface width={10} height={10} viewBox={{
                x: 0,
                y: 0,
                width: 10,
                height: 10
              }}>
                <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
              </Surface>
              <span className="legend-label"><NavLink to={`/secure/${user.agent.site.id}/leads/?${agentId ? `contact_agents__in=${agentId}` : ''}&created__date__gte=${current}&created__date__lte=${limit}&status__in=${status}&${data.label === 'Other' ? `source__in__not=${statistics.filter(s => s.label !== 'Other').map(s => s.label)}` : `source=${data.label}`}`}>{data.label}</NavLink></span>
              <span className="legend-count">{valueFormat('number', data.value)}</span>
            </span>
          )
        })
      }
    </div>
  )

  useEffect(() => {
    filterLeads()
  }, [ agentId, branchId, status, period ])

  useEffect(() => () => {
    abortController.current.abort()
  }, [])

  return (
    <Card
      id="lead-source-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding"
      background
      header={
        <>
          <h3>Lead Source</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <InlineBranchSelect
              branches={branches}
              user={user}
              actions={actions}
              selectedValue={branchId}
              onChange={e => {
                setBranchId(e.value)
                setAgentId('')
              }}
            />
            <InlineAgentSelect
              user={user}
              actions={actions}
              branchId={branchId}
              selectedValue={agentId}
              onChange={e => {
                setAgentId(e.value)
              }}
            />
            <div className="filter-status">
              <InlineSelect
                id="lead-status"
                name="lead-status"
                className="inline-select"
                classNamePrefix="inline"
                options={statuses}
                selectedValue={status}
                onChange={e => {
                  setStatus(e.value)
                }}
              />
            </div>
            <InlinePeriodSelect
              optionFilter={dateFilter}
              selectedValue={period}
              onChange={setPeriodData}
            />
          </div>
        </>
      }
      body={loading ? (
        <div className="empty flex-container" style={{ height: 240 }}><Loader inline className="large" /></div>
      ) : (
        <ResponsiveContainer width={'100%'} height={240} debounce={1} legendWrap={!showActions}>
          <PieChart cx="50%" cy="50%" margin={0}>
            <Legend wrapperStyle={{ width: showActions ? null : '100%', height: showActions ? '100%' : null, bottom: showActions ? null : 1 }} iconType="circle" iconSize={9} layout={showActions ? 'vertical' : 'horizontal'} verticalAlign={showActions ? 'top' : 'bottom'} align="right" content={renderCustomizedLegend} data={statistics} />
            <Pie
              startAngle={90}
              endAngle={-270}
              data={[ { value: 100, label: 'No Leads Found' } ]} dataKey="value" innerRadius="66%" outerRadius="100%" fill="#F3F5F8" isAnimationActive={false} label={false}
            >
              {!statistics.length ?
                <Label fontFamily='Poppins' position="center" content={test => {
                  if (!test.viewBox.innerRadius) { return null }
                  let scale = test.viewBox.innerRadius / 79.2
                  const width = getTextWidth('0', `${34 * scale}px Poppins`)
                  const max = (test.viewBox.innerRadius - 5) * 2
                  if (width > max) {
                    const offset = max / width
                    scale *= offset
                  }
                  return <Text fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">0</Text>
                }}></Label> : null}
            </Pie>
            <Pie
              angleAxisId={0}
              minAngle={1}
              background={{ fill: '#ffffff' }}
              clockWise
              startAngle={90}
              endAngle={-270}
              innerRadius="66%"
              outerRadius="100%"
              isAnimationActive={false}
              data={statistics}
              labelLine={false}
              strokeWidth={0}
              label={renderCustomizedLabel}
              dataKey="value"
            >
              <Label fontFamily='Poppins' position="center" content={test => {
                if (!test.viewBox.innerRadius) { return null }
                let scale = test.viewBox.innerRadius / 79.2
                const total = statistics.map(d => d.value).reduce((a, b) => a + b, 0)
                const width = getTextWidth(valueFormat('number', total), `${34 * scale}px Poppins`)
                const max = (test.viewBox.innerRadius - 5) * 2
                if (width > max) {
                  const offset = max / width
                  scale *= offset
                }
                return <Text fontFamily='Poppins' fontSize={34 * scale} dx={test.viewBox.cx} dy={test.viewBox.cy} width={test.viewBox.innerRadius} textAnchor="middle" verticalAnchor="middle">{valueFormat('number', total)}</Text>
              }}></Label>
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )}
    />
  )
}

LeadSourceWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  status_field: PropTypes.object,
  model: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  const config = CONFIG(state, 'leads')
  const status_field = config.get('statusField')
  return ({
    user,
    status_field
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany, getLeadsBreakdown }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(LeadSourceWidget)))
