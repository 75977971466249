/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import merge from 'deepmerge'

import { CONFIG, MINUSER, MODELROUTE, CACHEDMODELID, CURRENCY, CACHE } from '../../selectors'
import { sortBy, groupBy, getRandomColor, valueFormat, useCustomCompareMemo, debounce } from '../../utils'
import QueryBuilder from '../common/QueryBuilder'
import ModelActions from '../common/ModelActions'
import Card from '../common/Card'
import { toggleWideSidebar, fetchOne, exportData, toggleManager, updateModel, createModel, fetchMany } from '../../actions'
import Loader from '../common/Loader'

import PieChart from '../ui/graphs/PieChart'
import StackedBarGraph from '../ui/graphs/StackedBarGraph'
import MetaDetail from '../common/MetaDetail'
import db from '../../db'


const getSourcesStat = async (data, state_key) => {
  if (!Array.isArray(data)) {
    data = Object.keys(data).map(k => {
      const newObj = {}
      newObj[k] = data[k]
      return newObj
    })
  }
  const total = data.map(source => source[Object.keys(source).pop()]).reduce((a, b) => a + b, 0)
  const top_9 = data.filter(source => !Object.keys(source).includes('Other')).slice(0, 9)

  let top_9_total = 0
  top_9.forEach(area => {
    const area_id = Object.keys(area).pop()
    top_9_total += area[area_id]
  })
  const slices = []
  top_9.forEach(source => {
    const source_key = Object.keys(source).pop()
    const count = source[source_key]
    if (count) {
      slices.push({
        name: `${source_key}`,
        value: count,
        percentage: count / total * 100,
        colour: getRandomColor(slices.length)
      })
    }
  })

  const other_totals = total - top_9_total
  if (other_totals) {
    slices.push({
      name: 'Other',
      value: other_totals,
      percentage: other_totals / total * 100,
      colour: getRandomColor(slices.length)
    })
  }
  const state = {}
  state[`${state_key}`] = slices
  return state
}

const getCountryStat = async (data, state_key) => {
  if (!Array.isArray(data)) {
    data = Object.keys(data).map(k => {
      const newObj = {}
      newObj[k] = data[k]
      return newObj
    })
  }
  const ids = []
  const total = data.map(s => {
    const aid = Object.keys(s).pop()
    ids.push(parseInt(aid, 10))
    return s[Object.keys(s).pop()]
  }).reduce((a, b) => a + b, 0)

  const options = await db.areas
    .where('id').anyOf(ids).toArray()

  const countries = groupBy(options, 'country')
  let country_data = Object.keys(countries).map(country => {
    const areas = countries[country]
    const count = areas
      .map(a => data.find(d => Object.keys(d).pop() === String(a.id))[String(a.id)])
      .reduce((a, b) => a + b, 0)
    const cdata = {}
    cdata[`${country}`] = count
    return cdata
  })
  country_data = sortBy(country_data, null, (a, b) => {
    const A = a[Object.keys(a)]
    const B = b[Object.keys(b)]
    if (A < B) { return -1 }
    if (A > B) { return 1 } // names must be equal
    return 0
  })
  country_data.reverse()

  const top_9 = country_data.slice(0, 9)

  let top_9_total = 0
  const slices = []
  top_9.forEach(area => {
    const area_id = Object.keys(area).pop()
    top_9_total += area[area_id]
  })
  top_9.forEach(source => {
    const source_key = Object.keys(source).pop()
    const count = source[source_key]
    slices.push({
      name: `${source_key}`,
      value: count,
      percentage: count / total * 100,
      colour: getRandomColor(slices.length)
    })
  })
  const other_totals = total - top_9_total
  if (other_totals) {
    slices.push({
      name: 'Other',
      value: other_totals,
      percentage: other_totals / total * 100,
      colour: getRandomColor(slices.length)
    })
  }
  const state = {}
  state[`${state_key}`] = slices
  return state
}

const getProvinceStat = async (data, state_key) => {
  if (!Array.isArray(data)) {
    data = Object.keys(data).map(k => {
      const newObj = {}
      newObj[k] = data[k]
      return newObj
    })
  }
  const ids = []
  const total = data.map(s => {
    const aid = Object.keys(s).pop()
    ids.push(parseInt(aid, 10))
    return s[Object.keys(s).pop()]
  }).reduce((a, b) => a + b, 0)

  const options = await db.areas
    .where('id').anyOf(ids).toArray()

  const provinces = groupBy(options, 'province')
  let province_data = Object.keys(provinces).map(province => {
    const areas = provinces[province]
    const count = areas
      .map(a => data.find(d => Object.keys(d).pop() === String(a.id))[String(a.id)])
      .reduce((a, b) => a + b, 0)
    const cdata = {}
    cdata[`${province}`] = count
    return cdata
  })
  province_data = sortBy(province_data, null, (a, b) => {
    const A = a[Object.keys(a)]
    const B = b[Object.keys(b)]
    if (A < B) { return -1 }
    if (A > B) { return 1 } // names must be equal
    return 0
  })
  province_data.reverse()

  const top_9 = province_data.slice(0, 9)

  let top_9_total = 0
  const slices = []
  top_9.forEach(area => {
    const area_id = Object.keys(area).pop()
    top_9_total += area[area_id]
  })
  top_9.forEach(source => {
    const source_key = Object.keys(source).pop()
    const count = source[source_key]
    slices.push({
      name: `${source_key}`,
      value: count,
      percentage: count / total * 100,
      colour: getRandomColor(slices.length)
    })
  })

  const other_totals = total - top_9_total
  if (other_totals) {
    slices.push({
      name: 'Other',
      value: other_totals,
      percentage: other_totals / total * 100,
      colour: getRandomColor(slices.length)
    })
  }
  const state = {}
  state[`${state_key}`] = slices
  return state
}

const getAreaStat = async (data, state_key) => {
  if (!Array.isArray(data)) {
    data = Object.keys(data).map(k => {
      const newObj = {}
      newObj[k] = data[k]
      return newObj
    })
  }
  const ids = []
  const total = data.map(s => {
    const aid = Object.keys(s).pop()
    ids.push(parseInt(aid, 10))
    return s[Object.keys(s).pop()]
  }).reduce((a, b) => a + b, 0)

  const options = await db.areas
    .where('id').anyOf(ids).toArray()
  const area_groups = groupBy(options, 'area')
  let area_data = Object.keys(area_groups).map(area => {
    const areas = area_groups[area]
    const count = areas
      .map(a => data.find(d => Object.keys(d).pop() === String(a.id))[String(a.id)])
      .reduce((a, b) => a + b, 0)
    const cdata = {}
    cdata[`${area}`] = count
    return cdata
  })
  area_data = sortBy(area_data, null, (a, b) => {
    const A = a[Object.keys(a)]
    const B = b[Object.keys(b)]
    if (A < B) { return -1 }
    if (A > B) { return 1 } // names must be equal
    return 0
  })
  area_data.reverse()

  const top_9 = area_data.slice(0, 9)

  let top_9_total = 0
  const slices = []
  top_9.forEach(area => {
    const area_id = Object.keys(area).pop()
    top_9_total += area[area_id]
  })
  top_9.forEach(source => {
    const source_key = Object.keys(source).pop()
    const count = source[source_key]
    slices.push({
      name: `${source_key}`,
      value: count,
      percentage: count / total * 100,
      colour: getRandomColor(slices.length)
    })
  })

  const other_totals = total - top_9_total
  if (other_totals) {
    slices.push({
      name: 'Other',
      value: other_totals,
      percentage: other_totals / total * 100,
      colour: getRandomColor(slices.length)
    })
  }
  const state = {}
  state[`${state_key}`] = slices
  const areas = {}
  options.forEach(a => { areas[a.id] = a })
  state.areas = areas
  return state
}

const getSuburbStat = async (data, state_key) => {
  if (!Array.isArray(data)) {
    data = Object.keys(data).map(k => {
      const newObj = {}
      newObj[k] = data[k]
      return newObj
    })
  }
  const ids = []
  const total = data.map(s => {
    const aid = Object.keys(s).pop()
    ids.push(parseInt(aid, 10))
    return s[Object.keys(s).pop()]
  }).reduce((a, b) => a + b, 0)

  const options = await db.suburbs
    .where('id').anyOf(ids).toArray()

  const suburb_groups = groupBy(options, 'suburb')
  let area_data = Object.keys(suburb_groups).map(suburb => {
    const areas = suburb_groups[suburb]
    const count = areas
      .map(a => data.find(d => Object.keys(d).pop() === String(a.id))[String(a.id)])
      .reduce((a, b) => a + b, 0)
    const cdata = {}
    cdata[`${suburb}`] = count
    return cdata
  })
  area_data = sortBy(area_data, null, (a, b) => {
    const A = a[Object.keys(a)]
    const B = b[Object.keys(b)]
    if (A < B) { return -1 }
    if (A > B) { return 1 } // names must be equal
    return 0
  })
  area_data.reverse()

  const top_9 = area_data.slice(0, 9)

  let top_9_total = 0
  const slices = []
  top_9.forEach(area => {
    const area_id = Object.keys(area).pop()
    top_9_total += area[area_id]
  })
  top_9.forEach(source => {
    const source_key = Object.keys(source).pop()
    const count = source[source_key]
    slices.push({
      name: `${source_key}`,
      value: count,
      percentage: count / total * 100,
      colour: getRandomColor(slices.length)
    })
  })

  const other_totals = total - top_9_total
  if (other_totals) {
    slices.push({
      name: 'Other',
      value: other_totals,
      percentage: other_totals / total * 100,
      colour: getRandomColor(slices.length)
    })
  }
  const state = {}
  state[`${state_key}`] = slices
  const suburbs = {}
  options.forEach(a => { suburbs[a.id] = a })
  state.suburbs = suburbs
  return state
}

const getListingTypeStat = async (data, state_key, type) => {
  if (!Array.isArray(data)) {
    data = Object.keys(data).map(k => {
      const newObj = {}
      newObj[k] = data[k]
      return newObj
    })
  }
  const slices = []
  const total = data.map(s => s[Object.keys(s).pop()]).reduce((a, b) => a + b, 0)
  const top_9 = data.slice(0, 9)
  let top_9_total = 0
  top_9.forEach(area => {
    const area_id = Object.keys(area).pop()
    top_9_total += area[area_id]
  })
  top_9.forEach(source => {
    const source_key = Object.keys(source).pop()
    const count = source[source_key]
    let label = source_key
    if (source_key === 'residential' && type === 'For Sale') {
      label = 'Residential For Sale'
    } else if (source_key === 'commercial' && type === 'For Sale') {
      label = 'Commercial For Sale'
    }
    slices.push({
      name: `${label}`,
      value: count,
      percentage: count / total * 100,
      colour: getRandomColor(slices.length)
    })
  })

  const other_totals = total - top_9_total
  if (other_totals) {
    slices.push({
      name: 'Other',
      value: other_totals,
      percentage: other_totals / total * 100,
      colour: getRandomColor(slices.length)
    })
  }
  const state = {}
  state[`${state_key}`] = slices
  return state
}

const getPropertyTypeStat = async (data, state_key) => {
  if (!Array.isArray(data)) {
    data = Object.keys(data).map(k => {
      const newObj = {}
      newObj[k] = data[k]
      return newObj
    })
  }
  const total = data.map(source => source[Object.keys(source).pop()]).reduce((a, b) => a + b, 0)
  const top_9 = data.slice(0, 9)

  let top_9_total = 0
  top_9.forEach(area => {
    const area_id = Object.keys(area).pop()
    top_9_total += area[area_id]
  })
  const slices = []
  top_9.forEach(source => {
    const source_key = Object.keys(source).pop()
    const count = source[source_key]
    slices.push({
      name: `${source_key}`,
      value: count,
      percentage: count / total * 100,
      colour: getRandomColor(slices.length)
    })
  })

  const other_totals = total - top_9_total
  if (other_totals) {
    slices.push({
      name: 'Other',
      value: other_totals,
      percentage: other_totals / total * 100,
      colour: getRandomColor(slices.length)
    })
  }
  const state = {}
  state[`${state_key}`] = slices
  return state
}

const fetchReport = ({ actions, templatename, id, modelname, overrides }) => {
  new Promise((resolve, reject) => actions.fetchOne('reports', id, resolve, reject)).then(r => {
    const report = r.reports[id]
    actions.setReport(report)
    new Promise((resolve, reject) => actions.fetchOne('templates', r.reports[id].template, resolve, reject)).then(t => {
      const template = t.templates[r.reports[id].template]
      actions.setTemplate(template)
      // eslint-disable-next-line no-new
      new Promise((resolve, reject) => {
        const params = {
          params: {
            ...report.report_filters,
            ...overrides
          },
          modelname,
          args: {
            action: 'report',
            template: templatename
          },
          label: report.name,
          noalert: true,
          callback: actions.setData,
          resolve,
          reject
        }
        return actions.exportData(params)
      })
    })
  })
}

const LeadAnalysisReport = props => {
  const { match, config, cache, location, actions } = props
  const qs = new QueryBuilder(location.search)
  // eslint-disable-next-line no-unused-vars
  const [ template, setTemplate ] = useState()
  const [ report, setReport ] = useState()
  const [ search ] = useState(qs.getAllArgs(false))
  const [ data, setData ] = useState()
  const [ graphData, setGraphData ] = useState({})
  const overrides = { ...search }

  useEffect(() => {
    setData(null)
    debounce(() => {
      fetchReport({
        actions: {
          fetchOne: actions.fetchOne,
          exportData: actions.exportData,
          setReport,
          setTemplate,
          setData: async ({ response }) => {
            const { leads, buyer, tenant, seller, landlord } = response
            let state = {}
            if (leads) {
              state = merge(state, await getSourcesStat(leads.source_counts, 'lead_sources'))
              state = merge(state, await getCountryStat(leads.area_counts, 'lead_location_country'))
              state = merge(state, await getProvinceStat(leads.area_counts, 'lead_location_province'))
            }
            if (buyer) {
              state = merge(state, await getListingTypeStat(buyer.listing_types, 'buyer_listing_type', 'For Sale'))
              state = merge(state, await getProvinceStat(buyer.area_counts, 'buyer_province'))
              state = merge(state, await getAreaStat(buyer.area_counts, 'buyer_area'))
              state = merge(state, await getSuburbStat(buyer.suburb_counts, 'buyer_suburb'))
              state = merge(state, await getPropertyTypeStat(buyer.property_types, 'buyer_property_type'))
            }
            if (tenant) {
              state = merge(state, await getListingTypeStat(tenant.listing_types, 'tenant_listing_type', 'To Let'))
              state = merge(state, await getProvinceStat(tenant.area_counts, 'tenant_province'))
              state = merge(state, await getAreaStat(tenant.area_counts, 'tenant_area'))
              state = merge(state, await getSuburbStat(tenant.suburb_counts, 'tenant_suburb'))
              state = merge(state, await getPropertyTypeStat(tenant.property_types, 'tenant_property_type'))
            }
            if (seller) {
              state = merge(state, await getListingTypeStat(seller.listing_types, 'seller_listing_type', 'For Sale'))
              state = merge(state, await getProvinceStat(seller.area_counts, 'seller_province'))
              state = merge(state, await getAreaStat(seller.area_counts, 'seller_area'))
              state = merge(state, await getSuburbStat(seller.suburb_counts, 'seller_suburb'))
              state = merge(state, await getPropertyTypeStat(seller.property_types, 'seller_property_type'))
            }
            if (landlord) {
              state = merge(state, await getListingTypeStat(landlord.listing_types, 'landlord_listing_type', 'To Let'))
              state = merge(state, await getProvinceStat(landlord.area_counts, 'landlord_province'))
              state = merge(state, await getAreaStat(landlord.area_counts, 'landlord_area'))
              state = merge(state, await getSuburbStat(landlord.suburb_counts, 'landlord_suburb'))
              state = merge(state, await getPropertyTypeStat(landlord.property_types, 'landlord_property_type'))
            }
            setData(response)
            setGraphData(state)
          }
        },
        templatename: 'leads-analysis-report',
        id: match.params.id,
        modelname: config.modelname,
        overrides
      })
    }, 1000)()
  }, [ useCustomCompareMemo(match.params) ])


  const renderRelated = (reportField, modelname, modelField, relatedFieldName) => {
    // Renders related field data from a foreign model by id
    let related_data = false
    const newdata = []
    const field = config.fields.find(f => f.name === modelField)
    if (cache[modelname]) {
      if (Array.isArray(report.report_filters[reportField])) {
        report.report_filters[reportField].forEach(id => {
          if (cache[modelname][id]) {
            const cached_data = cache[modelname][id]
            if (Array.isArray(relatedFieldName) && cached_data) {
              const oneof = relatedFieldName.map(name => {
                if (cached_data[name]) { return cached_data[name] }
                return false
              }).filter(name => name)
              newdata.push(oneof.join(field.labelseparator || ' '))
              if (newdata.length) { related_data = newdata }
            } else {
              newdata.push(cache[modelname][id][relatedFieldName])
              if (newdata.length) { related_data = newdata.join(', ') }
            }
          }
        })
      } else if (cache[modelname][report.report_filters[reportField]]) {
        if (Array.isArray(relatedFieldName)) {
          const id = cache[modelname][report.report_filters[reportField]]
          if (id) {
            relatedFieldName.forEach(name => {
              if (id[name]) {
                newdata.push(id[name])
              }
            })
            if (newdata.length) { related_data = newdata }
          }
        } else {
          related_data = cache[modelname][report.report_filters[reportField]][relatedFieldName]
        }
      }
    } else if (graphData[modelname]) {
      if (Array.isArray(report.report_filters[reportField])) {
        report.report_filters[reportField].forEach(id => {
          if (graphData[modelname][id]) {
            const stated_data = graphData[modelname][id]
            if (Array.isArray(relatedFieldName) && stated_data) {
              const oneof = relatedFieldName.map(name => {
                if (stated_data[name]) { return stated_data[name] }
                return false
              }).filter(name => name)
              newdata.push(oneof.join(modelField.labelseparator || ' '))
              if (newdata.length) { related_data = newdata }
            } else {
              newdata.push(graphData[modelname][id][relatedFieldName])
              if (newdata.length) { related_data = newdata.join(', ') }
            }
          }
        })
      } else if (graphData[modelname][report.report_filters[reportField]]) {
        if (Array.isArray(relatedFieldName)) {
          const id = graphData[modelname][report.report_filters[reportField]]
          if (id) {
            relatedFieldName.forEach(name => {
              if (id[name]) {
                newdata.push(id[name])
              }
            })
            if (newdata.length) { related_data = newdata }
          }
        } else {
          related_data = graphData[modelname][report.report_filters[reportField]][relatedFieldName]
        }
      }
    }
    return related_data
  }

  const addCharts = () => {
    if (!Object.keys(data).length) { return null }
    return (
      <React.Fragment>
        {(graphData.lead_sources || (data.leads && data.leads.telephonic_count)) &&
        <Card
          header={
            <h3 className="piechart-section-heading">Lead Source</h3>
          }
          background
          body={
            <div className="piechart-columns">
              {graphData.lead_sources && graphData.lead_sources.length > 0 &&
                <div className="piechart-column">
                  <h4>Electronic Lead Sources</h4>
                  <div className="pie-chart">
                    <PieChart width={250} legend={chart_data => (
                      <div className="stacked-legend">
                        <ul className="recharts-default-legend">
                          {chart_data.map((entry, i) => {
                            const color = entry.colour
                            const className = classNames({
                              'recharts-legend-item': true,
                              [`legend-item-${i}`]: true,
                              inactive: entry.inactive
                            })
                            const viewBox = '0 0 19 2'
                            const svgStyle = { display: 'block', marginTop: 2, marginBottom: 2 }
                            return (
                              <li className={className} key={`legend-${i}`}>
                                <div className="stacked-legend-header">
                                  <strong>{entry.name}: </strong>
                                  <span>{entry.value}</span>
                                </div>
                                <svg width={19} height={2} viewBox={viewBox} style={svgStyle}>
                                  <rect
                                    stroke="none"
                                    fill={color}
                                    width={19}
                                    height={2}
                                    className="recharts-legend-icon"
                                  />
                                </svg>
                                <div className="stacked-legend-percentage">
                                  {valueFormat('number', entry.percentage)}%
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    )} data={graphData.lead_sources} />
                  </div>
                </div>
              }
              {data.leads.telephonic_count > 0 &&
                <div className="piechart-column">
                  <h4>YOUR PROP DATA WEBSITE<br />TELEPHONIC LEADS*</h4>
                  <span className="big-stat">{data.leads.telephonic_count}</span>
                  <span className="telephonic-note">* the above Telephonic Leads only pertain to your Prop Data Website and do not include any portal related telephonic leads from Property 24 or Private Property.</span>
                </div>
              }
            </div>
          }
        />
        }
        {(
          (graphData.lead_location_country && graphData.lead_location_country.length)
          || (graphData.lead_location_province && graphData.lead_location_province.length)
        ) ? (
            <Card
              header={
                <h3 className="piechart-section-heading">Lead Location</h3>
              }
              background
              body={
                <div className="piechart-columns">
                  {graphData.lead_location_country && graphData.lead_location_country.length > 0 &&
                  <div className="piechart-column">
                    <h4>Country</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.lead_location_country}
                    />
                  </div>
                  }
                  {graphData.lead_location_province && graphData.lead_location_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Province'}
                      data={graphData.lead_location_province}
                    />
                  </div>
                  }
                </div>
              }
            />
          ) : null}
        {(graphData.buyer_listing_type || graphData.buyer_province || graphData.buyer_area ||
          graphData.buyer_suburb || graphData.buyer_property_type) &&
          <Card
            header={
              <h3 className="piechart-section-heading">Buyers</h3>
            }
            background
            body={
              <div className="piechart-columns">
                {graphData.buyer_listing_type && graphData.buyer_listing_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Listing Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Listing Type'}
                      data={graphData.buyer_listing_type}
                    />
                  </div>
                }
                {graphData.buyer_province && graphData.buyer_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Province'}
                      data={graphData.buyer_province}
                    />
                  </div>
                }
                {graphData.buyer_area && graphData.buyer_area.length > 0 &&
                  <div className="piechart-column">
                    <h4>Area</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Area'}
                      data={graphData.buyer_area}
                    />
                  </div>
                }
                {graphData.buyer_suburb && graphData.buyer_suburb.length > 0 &&
                  <div className="piechart-column">
                    <h4>Suburb</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Suburb'}
                      data={graphData.buyer_suburb}
                    />
                  </div>
                }
                {graphData.buyer_property_type && graphData.buyer_property_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Property Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Property Type'}
                      data={graphData.buyer_property_type}
                    />
                  </div>
                }
              </div>
            }
          />
        }
        {(graphData.tenant_listing_type || graphData.tenant_province || graphData.tenant_area ||
          graphData.tenant_suburb || graphData.tenant_property_type) &&
          <Card
            header={
              <h3 className="piechart-section-heading">Tenants</h3>
            }
            background
            body={
              <div className="piechart-columns">
                {graphData.tenant_listing_type && graphData.tenant_listing_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Listing Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.tenant_listing_type}
                    />
                  </div>
                }
                {graphData.tenant_province && graphData.tenant_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.tenant_province}
                    />
                  </div>
                }
                {graphData.tenant_area && graphData.tenant_area.length > 0 &&
                  <div className="piechart-column">
                    <h4>Area</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.tenant_area}
                    />
                  </div>
                }
                {graphData.tenant_suburb && graphData.tenant_suburb.length > 0 &&
                  <div className="piechart-column">
                    <h4>Suburb</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.tenant_suburb}
                    />
                  </div>
                }
                {graphData.tenant_property_type && graphData.tenant_property_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Property Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.tenant_property_type}
                    />
                  </div>
                }
              </div>
            }
          />
        }
        {(graphData.seller_listing_type || graphData.seller_province || graphData.seller_area ||
          graphData.seller_suburb || graphData.seller_property_type) &&
          <Card
            header={
              <h3 className="piechart-section-heading">Sellers</h3>
            }
            background
            body={
              <div className="piechart-columns">
                {graphData.seller_listing_type && graphData.seller_listing_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Listing Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.seller_listing_type}
                    />
                  </div>
                }
                {graphData.seller_province && graphData.seller_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.seller_province}
                    />
                  </div>
                }
                {graphData.seller_area && graphData.seller_area.length > 0 &&
                  <div className="piechart-column">
                    <h4>Area</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.seller_area}
                    />
                  </div>
                }
                {graphData.seller_suburb && graphData.seller_suburb.length > 0 &&
                  <div className="piechart-column">
                    <h4>Suburb</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.seller_suburb}
                    />
                  </div>
                }
                {graphData.seller_property_type && graphData.seller_property_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Property Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.seller_property_type}
                    />
                  </div>
                }
              </div>
            }
          />
        }
        {(graphData.landlord_listing_type || graphData.landlord_province || graphData.landlord_area ||
          graphData.landlord_suburb || graphData.landlord_property_type) &&
          <Card
            header={
              <h3 className="piechart-section-heading">Landlords</h3>
            }
            background
            body={
              <div className="piechart-columns">
                {graphData.landlord_listing_type && graphData.landlord_listing_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Listing Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.landlord_listing_type}
                    />
                  </div>
                }
                {graphData.landlord_province && graphData.landlord_province.length > 0 &&
                  <div className="piechart-column">
                    <h4>Province</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.landlord_province}
                    />
                  </div>
                }
                {graphData.landlord_area && graphData.landlord_area.length > 0 &&
                  <div className="piechart-column">
                    <h4>Area</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.landlord_area}
                    />
                  </div>
                }
                {graphData.landlord_suburb && graphData.landlord_suburb.length > 0 &&
                  <div className="piechart-column">
                    <h4>Suburb</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.landlord_suburb}
                    />
                  </div>
                }
                {graphData.landlord_property_type && graphData.landlord_property_type.length > 0 &&
                  <div className="piechart-column">
                    <h4>Property Type</h4>
                    <StackedBarGraph
                      sort={true}
                      padding={{ x: 0, y: 0 }}
                      radius={92.5}
                      title={'Country'}
                      data={graphData.landlord_property_type}
                    />
                  </div>
                }
              </div>
            }
          />
        }
      </React.Fragment>
    )
  }
  // eslint-disable-next-line no-console
  console.log(data)
  return (
    <div id="content" className="content">
      <div className="viewhead details">
        <div className="action-bar">
          <ModelActions
            modelname={config.modelname}
            extras={{
              list: null,
              'list-reports': {
                ...config.modelactions.list,
                className: 'list btn-none',
                label: 'Back to Reports',
                link: `/secure/:site/${config.modelname}/reports`
              },
              save: {
                label: 'Generate Report',
                menu: null,
                redirect: null,
                icon: '#icon16-Download',
                action: 'submitForm',
                className: 'btn-round btn-red',
                routes: [ 'reports' ]
              }
            }}
          />
        </div>
      </div>
      <div className={`view details reports ${config.modelname}`}>
        <div className="viewcontent">
          <div className="report-form">
            <Card
              background={true}
              header={
                <h3>{report?.name}</h3>
              }
              body={data ? (
                <>
                  <div className="input-group">
                    <MetaDetail
                      label="Lead Status"
                      className="col-lg-12"
                      value={report.report_filters.status__in}
                    />
                    <MetaDetail
                      label="Lead Type"
                      className="col-lg-12"
                      value={report.report_filters.lead_type__in && report.report_filters.lead_type__in.map(val => config.fields.find(f => f.name === 'lead_type').options.find(o => o.value === val).label).join(', ')}
                    />
                    <MetaDetail
                      label="Date From"
                      format="date"
                      className="col-lg-12"
                      value={report.report_filters.created__date__gte}
                    />
                    <MetaDetail
                      label="Date To"
                      format="date"
                      className="col-lg-12"
                      value={report.report_filters.created__date__lte}
                    />
                    <MetaDetail
                      label="Branch(es)"
                      className="col-lg-12"
                      value={renderRelated('branch__in', 'branches', 'branch', 'name')}
                    />
                    <MetaDetail
                      label="User(s)"
                      className="col-lg-12"
                      value={renderRelated('agent__in', 'agents', 'agent', [ 'first_name', 'last_name' ], { labelseparator: ' ' })}
                    />
                    <MetaDetail
                      label="Area(s)"
                      className="col-lg-12"
                      value={renderRelated('profile__areas__overlap', 'areas', null, 'area')}
                    />
                    <MetaDetail
                      label="Suburb(s)"
                      className="col-lg-12"
                      value={renderRelated('profile__suburbs__overlap', 'suburbs', { labelseparator: ', ' }, [ 'suburb', 'area' ])}
                    />
                  </div>
                  {addCharts()}
                </>
              ) : (
                <Loader inline onError={() => {}} throwViewError={() => {}} />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

LeadAnalysisReport.propTypes = {
  location: PropTypes.object,
  config: PropTypes.object,
  reports_config: PropTypes.object,
  user: PropTypes.object,
  routeConfig: PropTypes.object,
  match: PropTypes.object,
  currency: PropTypes.string,
  sidebar: PropTypes.string,
  actions: PropTypes.object,
  cache: PropTypes.object
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  const { model, id } = ownProps.match.params
  const user = MINUSER(state)
  const config = CONFIG(state, model)
  const reports_config = CONFIG(state, 'reports')
  const routeConfig = MODELROUTE(state, model)
  const report = CACHEDMODELID(state, model, id)
  const currency = CURRENCY(state)
  const cache = CACHE(state)

  return {
    user,
    cache,
    config,
    report,
    reports_config,
    routeConfig,
    currency
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    exportData,
    fetchOne,
    fetchMany,
    createModel,
    updateModel,
    toggleWideSidebar,
    toggleManager
  }, dispatch)
})

export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(LeadAnalysisReport))
