/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import React from 'react'
import merge from 'deepmerge'
import { isEqual } from 'date-fns'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { isConditional, parseURL, slugify, sortBy, uniqueArray, buildOptionLabel, hasPermission, title, valueFormat, objectFieldList } from '../../utils'
import extras from '../../config/extras.json'
import ReferralAction from '../ReferralAction'
import ViewInput from '../ViewInput'
import MetaDetail from './MetaDetail'
import Card from './Card'
import ContactDetails from './ContactDetails'
import SimpleTable from './simpletable/SimpleTable'


const ExtrasField = ({ field, value, allportals }) => {
  let options = merge([], extras.options)
  let portals = []
  if (allportals && allportals.agency && allportals.global) {
    portals = allportals.agency.map(p => {
      const pconf = allportals.global.find(pc => pc.id === p.portal)
      return pconf
    })
    portals = sortBy(portals, 'id')
    portals = portals.map(po => po.slug)
  }
  options = options
    .map(o => {
      if (o.options) {
        o.options = [ ...o.options ]
          .map(so => {
            so.group = o.label
            return so
          })
          .filter(so => (so.portals ? so.portals.some(p => portals.includes(p)) : true))
      }
      if (o.portals) {
        o.portals = o.portals.filter(p => portals.includes(p))
      }
      return o
    })
    .filter(o => {
      if (o.portals) {
        return o.portals.some(p => portals.includes(p))
      }
      if (o.options) {
        return o.options.some(so => so.portals.some(p => portals.includes(p)))
      }
      return true
    })

  let vals = []
  if (value) {
    vals = value.split('\n')
  }
  if (vals.length) {
    return (
      <div className="meta-detail extras">
        <label className="heavy">{field.label}</label>
        <div className="col">
          {vals.map((v, idx) => {
            let extra_val = options.find(o => v === o.value)
            if (!extra_val) {
              options.filter(o => o.options).forEach(o => {
                extra_val = o.options.find(so => v === so.value)
              })
            }
            const tags = extra_val ? extra_val.portals.map(p => extras.legend[p]) : []
            return (
              <div key={`extras-${idx}`} className="extras-selected">
                <div className="extras-label">
                  {extra_val ? extra_val.label : v}
                </div>
                <div className="extras-tags">
                  {extra_val && extra_val.group ? <span className="extras-tag group-tag">{extra_val.group}</span> : null}
                  {tags.map((t, tidx) => (
                    <span className={`extras-tag ${t}`} key={`extras-tag-${tidx}`}>{t}</span>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  return null
}

const ObjectArrayFields = ({ field, model, allportals, available_languages, currency, modelname }) => {
  let url
  let values = field.metafield ? getIn(model.meta, field.name) : getIn(model, field.name)
  if (!values || !Array.isArray(values)) { return null }
  if (field.input === 'OnShowEvents') {
    values = sortBy(values, 'on_show_date').reverse()
  }
  const array_fields = values.map((v, vid) => {
    const portal = getIn(allportals, 'global') ? (
      allportals.global.find(p => p.id === v.portal)
    ) : null
    const inner = field.fields.map(f => {
      url = false
      let val = v.meta && v.meta[f.name] ? v.meta[f.name] : v[f.name]
      let label = `${f.label}`
      if ([ 'id', 'portal' ].includes(f.name)) { return null }
      if (field.name === 'portals' && portal) {
        label = `${portal.name} ${f.label}`
      }
      if (field.activitygroup) {
        if (f.name === field.activitygroup && field.input === 'SizeBreakdowns') {
          return <h4 key={`h4-sizebreakdowns-${vid}`} className="col-lg-12">{val}</h4>
        }
        if (f.name === field.activitygroup && field.input === 'LandUseBreakdowns') {
          return <h4 key={`h4-landusebreakdowns-${vid}`} className="col-lg-12">{val}</h4>
        }
      }
      if (field.input === 'TranslatableTextArea' && f.name === 'language') {
        return null
      }
      if (field.input === 'TranslatableTextArea' && f.name === 'description') {
        label = field.label.replace('Translated', `${getIn(available_languages.find(l => l.code === values[vid].language), 'name')}`)
      }
      if (f.format === 'currency') { f.currency = currency }
      if ([ null, undefined ].includes(val)) { return null }
      if (Array.isArray(val) && !val.length) { return null }
      if (typeof val === 'object') { // Most likely references a meta object
        const d = buildOptionLabel(f, val)
        val = d.label
        if (f.directLink) {
          url = parseURL(f.directLink, {
            id: v[f.name],
            site: model.site
          })
          if (Array.isArray(url) && url.length === 1) {
            url = url[0]
          }
        }
      }
      if ([ 'contact_whatsapp_link', 'whatsapp_link_only', 'tagged' ].includes(f.format)) {
        f.modelname = modelname
        val = model
      }
      if ([ 'tagged' ].includes(field.format)) {
        f.modelname = modelname
        val = getIn(model, 'meta.tags')
      }
      return (
        <MetaDetail
          className={f.detailCols ? `col-${f.detailCols}` : `${f.cols ? `col-${f.cols}` : 'col-12'}`}
          key={`g-o${vid}-${f.name}`}
          label={label}
          value={val}
          format={f.format}
          field={f}
          url={url}
        />
      )
    }).filter(node => node)
    const groups = []
    let current_group = []
    let count = 12
    inner.forEach((node, idx) => {
      const classes = node.props && node.props.className ? node.props.className : ''
      const regex = /col(-[a-z]+)?-(\d+)/ig
      const matches = regex.exec(classes)
      current_group.push(node)
      if (matches) {
        const col = parseInt(matches.pop(), 10)
        count -= col
      }
      if (count <= 0 || idx === inner.length - 1) {
        groups.push(<div key={`col-${idx}`} className='meta-group'>{current_group}</div>)
        current_group = []
        count = 12
      }
    })
    return groups.length ? groups : null
  }).filter(node => node)
  const array_groups = []
  array_fields.forEach((node, idx) => {
    array_groups.push(<div key={`col-${idx}`} className='meta-group array-group'>{node}</div>)
  })
  return array_groups.length ? array_groups : null
}

const ContactField = ({ field, model, actions, value, values, user, region, match, modelname }) => {
  let disable = false
  if (!values && getIn(model.meta, field.name)) { // Not allowed, but exists in model meta
    values = actions.parseMeta(model, field)
    disable = true
  }
  if (field.multi) {
    const inner = value?.map((contact, vid) => {
      let meta_values = []
      meta_values = actions.parseMeta(model, field)
      const meta = model.meta[field.name]
      const val = meta[vid]
      if (field.read && !isConditional(field, 'read', false, { values: model.meta[field.name][vid] }, user)) { disable = true }

      let can_edit = true
      if (field.edit && !isConditional({ ...field, edit: [
        [ {
          condition: {
            type: 'permissions',
            permissions: [
              'contacts_view',
              'contacts_view_own',
              'contacts_associated_agents_view'
            ],
            permission_key: {
              introduction_agent: [ 'contacts_view_own' ],
              associated_agents: [ 'contacts_associated_agents_view' ]
            }
          }
        } ]
      ] }, 'edit', false, { values: model.meta[field.name][vid] }, user)) {
        can_edit = false
      }
      if (meta_values.includes(val) && !values.includes(val)) {
        disable = true
      } else {
        disable = false
      }
      let label = field.label
      if (label === 'Link Contact') {
        label = 'Linked Contact'
      }
      if (typeof label === 'undefined' && getIn(field, 'suffix')) {
        label = `${getIn(field, 'suffix', '')} `
      }
      return <ContactDetails
        key={`g-o${vid}`}
        contact={val}
        label={`${label} ${vid + 1}`}
        actions={actions}
        disabled={disable}
        canEdit={can_edit}
        region={region}
        match={match}
        user={user}
        modelname={modelname}
        model={model}
      />
    }).map(node => node) || []
    const groups = []
    let current_group = []
    let count = 12
    inner.forEach((node, idx) => {
      const classes = node.props && node.props.className ? node.props.className : ''
      const regex = /col(-[a-z]+)?-(\d+)/ig
      const matches = regex.exec(classes)
      current_group.push(node)
      if (matches) {
        const col = parseInt(matches.pop(), 10)
        count -= col
      }
      if (count <= 0 || idx === inner.length - 1) {
        groups.push(<div key={`col-${idx}`} className='linked-contact meta-group col-lg-12'>{current_group}</div>)
        current_group = []
        count = 12
      }
    })
    return groups.length ? groups : null
  }
  let meta_values = []
  meta_values = actions.parseMeta(model, field)
  let val = model.meta[field.name]
  if (
    match.params.model === 'referrals' &&
    model.status === 'Accepted' &&
    (
      hasPermission([ 'is_prop_data_user' ], user.permissions) ||
      hasPermission([ 'apply_to_all_branches' ], user.permissions) ||
      user.agent.branches && user.agent.branches.includes(model.recipient_branch)
    )) {
    val = model.meta.created_contact
  }
  if (field.read && !this.isConditional(field, 'read', false, { values: model.meta[field.name] }, user)) { disable = true }
  if (meta_values.includes(val) && !values.includes(val)) {
    disable = true
  } else {
    disable = false
  }
  let label = field.label
  if (label === 'Link Contact') { label = 'Linked Contact' }
  return (
    <ContactDetails
      contact={val}
      label={label}
      actions={actions}
      canEdit={!disable}
      disabled={disable}
      region={region}
      match={match}
      user={user}
      model={model}
    />
  )
}

const FlatArrayFields = ({ field, model, value, values, modelname }) => {
  let url
  if (field.directLink) {
    if (model[field.name]) {
      url = model[field.name].map(v => parseURL(field.directLink, {
        id: v,
        site: model.site
      }))
    } else if (value) {
      url = value.map(v => parseURL(field.directLink, {
        id: v,
        site: model.site
      }))
    }
    if (Array.isArray(url) && url.length === 1) {
      url = url[0]
    }
  }

  if (objectFieldList.includes(field.format)) {
    return <MetaDetail
      key={`g-${field.name}`}
      className={field.detailCols ? `col-${field.detailCols}` : `${field.cols ? `col-${field.cols}` : 'col-lg-12'}`}
      label={field.label}
      value={values}
      format={field.format}
      field={field}
      url={url}
      model={model}
      modelname={modelname}
    />
  }

  if (values && field.labelseparator && Array.isArray(value) && !url) {
    if (!Array.isArray(values)) {
      values = [ values ]
    }
    if (!field.directLink) {
      if (!Array.isArray(field.optionlabel)) {
        value = values.join(field.labelseparator || ' ')
      } else {
        value = values.join(', ')
      }
    }
  } else {
    value = values
    if (values && typeof values[0] === 'object') {
      value = value.filter(val => val).map(val => buildOptionLabel(field, val).label)
    } else if (values && field.directLink && !Array.isArray(values) && isNaN(values)) {
      value = values.split(', ')
    }
  }
  return <MetaDetail
    key={`g-${field.name}`}
    className={field.detailCols ? `col-${field.detailCols}` : `${field.cols ? `col-${field.cols}` : 'col-lg-12'}`}
    label={field.label}
    value={value}
    format={field.format}
    field={field}
    url={url}
    model={model}
    modelname={modelname}
  />
}

const exclude_groups = [
  'Media',
  'Photos',
  'Documents',
  'Header Images',
  'Logo',
  'Floor Plans',
  'Videos',
  'Private Documents',
  'Branch Images',
  'Partners',
  'Profile Picture',
  'Website Documents',
  'Publish',
  'Google Mapping',
  'Seller Documents',
  'Buyer Documents',
  'Sale Agreement Documents',
  'SendGrid Email',
  'SendGrid Domains'
]

class DetailsList extends React.Component {
  constructor(props) {
    super(props)
    this.timers = {}
    this.state = {
      required: false,
      collapsed: false,
      redirect: false,
      scrollTop: null,
      initvals: false,
      showJump: false,
      selectedGroup: {},
      currentGroup: {},
      sticky: false,
      offset: 0,
      active_portals: Object.keys(props.portals).map(pk => getIn(props, `portals.${pk}.meta.portal.slug`)).filter(slug => slug)
    }
    this.isConditional = isConditional.bind(this)
  }

  componentDidUpdate() {
    const tab_el = document.querySelector('.tab-content')
    if (tab_el) {
      const offset = tab_el.getBoundingClientRect().top
      if (this.state.offset !== offset) {
        this.setState({ offset })
      }
    }
  }

  render() {
    const listingstats = [
      'rfs_ac',
      'rtl_ac',
      'cfs_ac',
      'ctl_ac',
      'rd',
      'cd',
      're',
      'ce',
      'h_ac'
    ]

    const {
      model,
      app,
      config,
      cache,
      actions,
      user,
      match,
      form
    } = this.props
    const selected_site = user.agent && user.agent.site ? user.agent.site.id : 0
    const sitestats = { ...getIn(cache, `settings.${selected_site}.meta.statistics`, {}) }
    const available_languages = getIn(cache, `settings.${selected_site}.meta.allowed_translations`, [])

    const { portals: allportals, region } = cache.settings[user.agent.site.id]

    listingstats.forEach(stat => {
      sitestats[`${stat}_avg`] = sitestats.active_branches ? Math.round(sitestats[stat] / sitestats.active_branches) : 0
    })
    return (
      <>
        {this.props.actions.renderList(exclude_groups)}
        <div ref={this.props.actions.setScroller} className="model-details">
          <div className="stepper"></div>

          { Object.keys(config.fieldgroups).filter(group => !exclude_groups.includes(group)).map((group, gidx) => {
            const fields = uniqueArray(
              config.fields.filter(field => {
                if (field.group === group) {
                  if (![ null, undefined ].includes(
                    getIn(model, field.name, getIn(model.meta, field.name))
                  )) {
                    return true
                  }
                  if (field.viewinput) {
                    if (this.isConditional(field, 'edit', false, { values: model }, user)) { return true }
                  }
                  if (
                    Array.isArray(field.name)
                    && field.name.map(n => getIn(model, n, getIn(model.meta, n)))
                      .filter(n => ![ null, undefined ].includes(n).length)
                  ) {
                    return true
                  }
                }
                return false
              }), // Input field in model view mode
              'name'
            )
            if (fields.length) {
              let heading = group
              const id = slugify(group)
              if (model.listing_type === 'To Let' && group === 'Seller / Landlord Details') {
                heading = 'Landlord Details'
              } else if (model.listing_type === 'For Sale' && group === 'Seller / Landlord Details') {
                heading = 'Seller Details'
              }
              const consent = getIn(model, 'meta.consent')
              if (group === 'Communication Preferences' && !consent) {
                return null
              }
              return (
                <Card
                  header={
                    <h3>{heading}</h3>
                  }
                  classes={this.props.currentGroup?.id === id ? 'active' : ''}
                  id={id}
                  background
                  key={`g-${gidx}`}
                  body={() => {
                    if (group === 'Communication Preferences') {
                      return (
                        <SimpleTable
                          user={user}
                          action={({ params, resolve, reject }) => {
                            if (params.id) {
                              return new Promise((res, rej) => {
                                actions.fetchActivity(params, res, rej)
                              }).then(results => {
                                const data = [
                                  {
                                    id: 'consent_messages',
                                    communication: 'SMS/Direct Messages',
                                    preference: consent.consent_messages,
                                    consent_given: consent.consent_messages_subscribe_date,
                                    consent_removed: consent.consent_messages_unsubscribe_date,
                                    agent: getIn(results.find(r => consent.consent_messages_subscribe_date && isEqual(new Date(getIn(r, 'diff.consent_messages_subscribe_date.1')), new Date(consent.consent_messages_subscribe_date))), 'agent')
                                  },
                                  {
                                    id: 'consent_emails',
                                    communication: 'Emails',
                                    preference: consent.consent_emails,
                                    consent_given: consent.consent_emails_subscribe_date,
                                    consent_removed: consent.consent_emails_unsubscribe_date,
                                    agent: getIn(results.find(r => consent.consent_emails_subscribe_date && isEqual(new Date(getIn(r, 'diff.consent_emails_subscribe_date.1')), new Date(consent.consent_emails_subscribe_date))), 'agent')
                                  },
                                  {
                                    id: 'consent_calls',
                                    communication: 'Phone Calls',
                                    preference: consent.consent_calls,
                                    consent_given: consent.consent_calls_subscribe_date,
                                    consent_removed: consent.consent_calls_unsubscribe_date,
                                    agent: getIn(results.find(r => consent.consent_calls_subscribe_date && isEqual(new Date(getIn(r, 'diff.consent_calls_subscribe_date.1')), new Date(consent.consent_calls_subscribe_date))), 'agent')
                                  },
                                  {
                                    id: 'consent_information',
                                    communication: 'Hold and Process Data',
                                    preference: consent.consent_information,
                                    consent_given: consent.subscribe_date,
                                    consent_removed: consent.unsubscribe_date,
                                    agent: getIn(results.find(r => consent.subscribe_date && isEqual(new Date(getIn(r, 'diff.subscribe_date.1')), new Date(consent.subscribe_date))), 'agent')
                                  }
                                ]
                                const agent_ids = data.filter(r => r.agent).map(r => r.agent)
                                if (agent_ids.length) {
                                  new Promise((res2, rej2) => {
                                    actions.fetchMany({
                                      values: {
                                        conflict: true,
                                        modelname: 'agents',
                                        params: {
                                          id__in: agent_ids,
                                          get_all: 1
                                        }
                                      },
                                      resolve: res2,
                                      reject: rej2
                                    })
                                  }).then(agents => {
                                    const agent_data = data.map(r => {
                                      const agent = agents.options.find(a => a.id === r.agent)
                                      let who = getIn(consent, r.id) ? `${model.first_name} ${model.last_name}` : null
                                      if (agent) {
                                        who = <NavLink className="has-link" to={`/secure/${model.site}/agents/${model.id}`}>{`${agent.first_name} ${agent.last_name}`}</NavLink>
                                      } else if (![ 'Website', 'Mobi Site', 'Microsite' ].includes(model.source) && r.id === 'consent_information') {
                                        who = model.source
                                      }
                                      return {
                                        ...r,
                                        agent: who
                                      }
                                    }).filter(f => f)
                                    resolve({ options: agent_data, hasMore: false })
                                  }).catch(e => {
                                    console.error(e)
                                    reject(e.toString())
                                  })
                                } else {
                                  resolve({
                                    options: data.map(r => {
                                      let who = getIn(consent, r.id) ? `${model.first_name} ${model.last_name}` : null
                                      if (![ 'Website', 'Mobi Site', 'Microsite' ].includes(model.source) && r.id === 'consent_information') {
                                        who = model.source
                                      }
                                      return {
                                        ...r,
                                        agent: who
                                      }
                                    }),
                                    hasMore: false
                                  })
                                }
                              }).catch(e => {
                                const data = [
                                  {
                                    id: 'consent_messages',
                                    communication: 'SMS/Direct Messages',
                                    preference: consent.consent_messages,
                                    consent_given: consent.consent_messages_subscribe_date,
                                    consent_removed: consent.consent_messages_unsubscribe_date
                                  },
                                  {
                                    id: 'consent_emails',
                                    communication: 'Emails',
                                    preference: consent.consent_emails,
                                    consent_given: consent.consent_emails_subscribe_date,
                                    consent_removed: consent.consent_emails_unsubscribe_date
                                  },
                                  {
                                    id: 'consent_calls',
                                    communication: 'Phone Calls',
                                    preference: consent.consent_calls,
                                    consent_given: consent.consent_calls_subscribe_date,
                                    consent_removed: consent.consent_calls_unsubscribe_date
                                  },
                                  {
                                    id: 'consent_information',
                                    communication: 'Hold and Process Data',
                                    preference: consent.consent_information,
                                    consent_given: consent.subscribe_date,
                                    consent_removed: consent.unsubscribe_date
                                  }
                                ]
                                console.error(e)
                                resolve({ options: data.map(r => ({ ...r, agent: null })), hasMore: false })
                              })
                            }
                            return reject('No ID supplied')
                          }}
                          params= {{
                            order_by: '-created',
                            modelname: 'contactconsent',
                            id: consent ? consent.id : null,
                            get_all: 1
                          }}
                          config={{
                            modelname: 'consent',
                            singular: 'communication preference',
                            plural: 'communication preferences'
                          }}
                          header={[
                            {
                              label: 'Communication',
                              name: 'communication'
                            },
                            {
                              label: 'Preference',
                              name: 'preference',
                              format: 'yesno'
                            },
                            {
                              label: 'Consent Given',
                              name: 'consent_given',
                              format: 'datetime'
                            },
                            {
                              label: 'Consent Removed',
                              name: 'consent_removed',
                              format: 'datetime'
                            },
                            {
                              label: 'Consent Confirmed By',
                              name: 'agent'
                            }
                          ]}
                        />
                      )
                    }

                    if ([ 'Referral Response', 'Referral Outcome' ].includes(group)) {
                      if ((
                        (hasPermission([ 'referral_can_approve' ], user.permissions) && group === 'Referral Response' && model.status === 'Pending') ||
                          (hasPermission([ 'referral_can_finalize' ], user.permissions) && group === 'Referral Outcome'))
                          && !model.referral_outcome
                          && !hasPermission([ 'is_prop_data_user' ], user.permissions)) {
                        return <ReferralAction
                          model={model}
                          user={user}
                          config={config}
                          groupname={group}
                          form={form}
                          gidx={gidx}
                        />
                      }
                    }

                    if (group === 'Application Outcome' && ![ 'Accepted', 'Declined', 'Sent', 'Expired' ].includes(model.status) && (
                      hasPermission([ 'applications_update' ], user.permissions)
                      || (hasPermission([ 'applications_update_own' ], user.permissions) && getIn(model, 'interaction.agent') === user.agent.id)
                    )) {
                      return <ViewInput
                        model={model}
                        user={user}
                        config={config}
                        groupname={group}
                        form={form}
                        gidx={gidx}
                      />
                    }

                    const group_fields = uniqueArray(config.fields.filter(field => {
                      if (field.group === group) {
                        if (field.input === 'Hidden' && ![ 'country_code', 'property_size_total_size', 'property_size_total_cost', 'land_use_total_size' ].includes(field.name)) { return false }
                        if (field.viewonly) { return true }
                        if (field.permissions && !hasPermission(field.permissions, user.permissions)) { return false }
                        if (![ null, undefined ].includes(field.edit) || Array.isArray(field.edit)) { // Escape hatch for conditional fields like POA / Price
                          if (!this.isConditional(field, 'edit', false, { values: model }, user)) { return false }
                        }
                        return true
                      }
                      return false
                    }), 'name').map((fe, fidx) => {
                      const field = merge({}, fe)
                      let value = getIn(model, field.name, getIn(model.meta, field.name))
                      if (!value && Array.isArray(field.name)) {
                        value = field.name.map(name => {
                          if ([ ' ', ', ' ].includes(name)) { return name }
                          if (getIn(model, name, getIn(model.meta, name))) {
                            return getIn(model, name, getIn(model.meta, name))
                          }
                          return null
                        }).filter(a => a)
                        if (![ null, undefined ].includes(field.labelseparator)) {
                          value = value.join(field.labelseparator)
                          if (field.title) { value = title(value) }
                        } else {
                          value = value.filter(a => a).join(' ')
                        }
                      }
                      let url = false
                      const disable = false
                      if (field.input === 'Extras') {
                        return <ExtrasField
                          key={`extras-list-${fidx}`}
                          field={field}
                          value={value}
                          allportals={allportals}
                        />
                      }

                      if ([ 'FieldArray', 'SizeBreakdowns', 'OnShowEvents', 'LandUseBreakdowns', 'TranslatableTextArea' ].includes(field.input) && value && value.length) {
                        return <ObjectArrayFields
                          key={`col-${gidx}`}
                          field={field}
                          model={model}
                          allportals={allportals}
                          available_languages={available_languages}
                          currency={cache.settings[user.agent.site.id].default_currency}
                          modelname={config.modelname}
                        />
                      }

                      if (field.input === 'ParkingRatio') {
                        const total_bays = [
                          'open_parking_bays',
                          'covered_parking_bays',
                          'basement_parking'
                        ].map(f => getIn(model, f) || 0)
                          .filter(a => a).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                        const avg_price_per_bay = [
                          'open_parkings_total_cost',
                          'covered_parkings_total_cost',
                          'basement_parking_total_cost'
                        ].map(f => getIn(model, f) || 0)
                          .filter(a => a).reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / total_bays
                        let val
                        if (getIn(model, 'parking_ratio_bays') && getIn(model, 'parking_ratio_per_meter')) {
                          val = `${valueFormat('number', getIn(model, 'parking_ratio_bays'))} bays per ${valueFormat('measurement_type', getIn(model, 'parking_ratio_per_meter'), { measurement_type: getIn(model, 'floor_size_measurement_type') })}`
                        }
                        return (
                          <>
                            {(total_bays || avg_price_per_bay) ? (
                              <div key={'cols-pr-totals'} className='meta-group bordered'>
                                <MetaDetail
                                  className={'col-6'}
                                  key={`f${fidx}-${field.name}-total`}
                                  label={'Total Bays'}
                                  value={total_bays}
                                  format={'number'}
                                  field={{
                                    name: 'total_bays'
                                  }}
                                />
                                <MetaDetail
                                  className={'col-6'}
                                  key={`f${fidx}-${field.name}-avg`}
                                  label={'Avg. Price Per Bay'}
                                  value={avg_price_per_bay}
                                  format={'currency'}
                                  field={{
                                    name: 'avg_price_per_bay',
                                    currency: cache.settings[user.agent.site.id].default_currency
                                  }}
                                />
                              </div>
                            ) : null}
                            {val ? (
                              <div key={'col-pr-ratio'} className='meta-group'>
                                <MetaDetail
                                  className={'col-12'}
                                  key={`f${fidx}-${field.name}`}
                                  label={field.label}
                                  value={val}
                                  format={field.format}
                                  field={field}
                                />
                              </div>
                            ) : null}
                          </>
                        )
                      }

                      if (field.input === 'SectionHeading') {
                        let display = false
                        config.fields.filter(f => {
                          if (f.section === field.section) {
                            if (getIn(model, f.name, getIn(model.meta, f.name))) { display = true }
                          }
                        })
                        if (display) {
                          return <h4 key='h4-display' className={classNames('col-lg-12', field.classes)}>{field.label}</h4>
                        }
                      }

                      if (field.name === 'coordinates' && model.map_x_position && model.map_y_position) {
                        value = `Lat: ${model.map_x_position}, Lng: ${model.map_y_position}`
                      }


                      if (getIn(field, 'modelname', getIn(field, 'inner.modelname')) && getIn(field, 'optionlabel', getIn(field, 'inner.optionlabel'))) {
                        let values = actions.renderRelated(getIn(field, 'modelname', getIn(field, 'inner.modelname')), field.name, getIn(field, 'optionlabel', getIn(field, 'inner.optionlabel')), field.multi || field.input === 'FieldList')

                        if (field.input === 'ContactLookup') {
                          return <ContactField
                            key={`contactlookup-${fidx}`}
                            field={field}
                            model={model}
                            actions={actions}
                            value={value}
                            values={values}
                            user={user}
                            region={region}
                            match={match}
                            modelname={config.modelname}
                          />
                        }
                        if (!values && getIn(model.meta, field.name)) { // Not allowed, but exists in model meta
                          values = actions.parseMeta(model, field.inner ? { ...field, ...field.inner } : field)
                        }
                        if (field.format === 'tagged') {
                          values = getIn(model.meta, field.name)
                        }
                        if (field.multi || field.input === 'FieldList') {
                          if (!value || !value.length) { return null }
                          return <FlatArrayFields
                            key={`list-${fidx}`}
                            field={field.inner ? { ...field, ...field.inner } : field}
                            model={model}
                            value={value}
                            values={values}
                            modelname={config.modelname}
                          />
                        }
                        if (field.directLink) {
                          url = parseURL(field.directLink, {
                            id: value,
                            site: model.site
                          })
                        }
                        if (values && field.labelseparator && Array.isArray(value) && !url) {
                          if (!Array.isArray(values)) {
                            values = [ values ]
                          }
                          if (!field.directLink) {
                            if (!Array.isArray(field.optionlabel)) {
                              value = values.join(field.labelseparator || ' ')
                            } else {
                              value = values.join(', ')
                            }
                          }
                        } else {
                          value = values
                          if (values && field.directLink && !Array.isArray(values)) {
                            value = values.toString().split(', ')
                          }
                        }
                      }
                      if (field.link && !url && !disable) { url = parseURL(field.link, model) }
                      if (field.format === 'link' && !url) {
                        url = value
                        if (url) {
                          value = 'Visit Link'
                        }
                      }
                      if (field.format === 'listing_popup') {
                        field.currency = cache.settings[user.agent.site.id].default_currency
                        value = model
                        if (getIn(model, 'meta.listing.web_ref')) {
                          value = getIn(model, 'meta.listing')
                        }
                        if (getIn(model, `${field.container}.listing.web_ref`)) {
                          value = getIn(model, 'meta.listing')
                        }
                      }
                      if (field.format === 'measurement_type') {
                        field.measurement_type = model[`${field.name}_measurement_type`]
                      }
                      if (field.name === 'land_size_measurement_type' && !model.land_size) {
                        value = null
                      }
                      if (field.name === 'floor_size_measurement_type' && !model.floor_size) {
                        value = null
                      }
                      if (field.format === 'currency') { field.currency = cache.settings[user.agent.site.id].default_currency }

                      if (field.format === 'domstring' && !value) {
                        value = null
                      }
                      if (field.format === 'absolute_url' && field.input === 'FileDropzone' && !field.multi) {
                        url = parseURL(getIn(field, 'link', `:meta.${field.name}.file`), model)
                        if (field.link_to === 'public_url') {
                          if (process.env.REACT_APP_ENV === 'staging') {
                            url = `${app.gateways.staging}/gallery/public-api/documents/${url}/`
                          } else if (process.env.REACT_APP_ENV === 'production') {
                            url = `${app.gateways.live}/gallery/public-api/documents/${url}/`
                          } else {
                            url = `${app.gateways.local}/gallery/public-api/documents/${url}/`
                          }
                        }
                        value = getIn(model, `meta.${field.name}.caption`, 'View File')
                      } else if (field.format === 'absolute_url') {
                        url = parseURL(field.link, model)
                        value = 'View Link'
                      }
                      if (field.format === 'stage') {
                        if (!Array.isArray(value) && typeof value !== 'object') {
                          value = model
                        }
                      }
                      if ([ 'contact_whatsapp_link', 'whatsapp_link_only' ].includes(field.format)) {
                        field.modelname = config.modelname
                        value = model
                      }
                      if ([ 'tagged' ].includes(field.format)) {
                        field.modelname = config.modelname
                        value = getIn(model, 'meta.tags')
                      }
                      if (field.multi) {
                        if (!value || (Array.isArray(value) && !value.length)) {
                          value = null
                        }
                      }
                      if (Array.isArray(value)) {
                        if (field.optionlabel && !field.modelname) {
                          value = value.map(o => getIn(o, field.optionlabel)).join(field.labelseparator || ', ')
                        } else {
                          value = value.join(field.labelseparator || ', ')
                        }
                      }
                      if ([ 'user' ].includes(field.name)) {
                        value = getIn(model, 'meta.user.email')
                      }
                      if ([ 'pending_user' ].includes(field.name) && getIn(model, 'meta.pending_user.email')) {
                        value = `${getIn(model, 'meta.pending_user.email')} (Pending)`
                      }
                      if ([ null, undefined, '' ].includes(value)) { return null }
                      if (Array.isArray(value) && !value.length) { return null }
                      return (
                        <MetaDetail
                          key={`g-${gidx}-f${fidx}-${field.name}`}
                          className={field.detailCols ? `col-${field.detailCols}` : `${field.cols ? `col-${field.cols}` : 'col-lg-12'}`}
                          label={field.label}
                          value={value}
                          format={field.format}
                          field={field}
                          url={url}
                          model={model}
                          modelname={config.modelname}
                        />
                      )
                    }).filter(node => node)
                    const groups = []
                    let current_group = []
                    let count = 12
                    group_fields.forEach((node, idx) => {
                      const classes = node.props && node.props.className ? node.props.className : ''
                      const regex = /col(-[a-z]+)?-(\d+)/ig
                      const matches = regex.exec(classes)
                      current_group.push(node)
                      if (matches) {
                        const col = parseInt(matches.pop(), 10)
                        count -= col
                      }
                      if (count <= 0 || idx === group_fields.length - 1) {
                        groups.push(<div key={`col-${gidx}-${idx}`} className='meta-group'>{current_group}</div>)
                        current_group = []
                        count = 12
                      }
                    })
                    return groups.length ? groups : null
                  }}
                />
              )
            }
            return null
          }) }
        </div>
      </>
    )
  }
}

DetailsList.propTypes = {
  model: PropTypes.object,
  app: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  currentGroup: PropTypes.object,
  portals: PropTypes.object,
  match: PropTypes.object,
  form: PropTypes.object
}

export default DetailsList
