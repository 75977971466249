import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import Card from '../../common/Card'
import { logEvent, debounce } from '../../../utils'
import { formikConnect } from '../forms/customFormikConnect'


const SnippetModal = props => {
  const { location, value, onSelect, editor, parentref, setSnippet, cursorpos, fetchMany } = props
  const [ snippets, setSnippets ] = useState([])
  const [ selected, setSelected ] = useState()
  const [ term, setTerm ] = useState('')

  const root = props.rootRef
  const el = useRef()
  const parentel = useRef()

  const searchSnippets = useCallback(() => {
    const values = {
      modelname: 'snippets',
      term: term,
      location: location,
      trigram: true,
      params: {
        location,
        meta_fields: [ 'area', 'suburb' ]
      }
    }
    new Promise((resolve, reject) => {
      fetchMany({ values, resolve, reject })
    }).then(r => {
      setSnippets(r.options)
      setSelected(null)
    })
  }, [ term ])

  const scrollParentToChild = (parent, child) => {
    child.focus()
    const parentRect = parent.getBoundingClientRect()
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth
    }
    const childRect = child.getBoundingClientRect()
    const isViewable = (childRect.top >= parentRect.top) &&
      (childRect.bottom <= parentRect.top + parentViewableArea.height)
    if (!isViewable) {
      const scrollTop = childRect.top - parentRect.top
      const scrollBot = childRect.bottom - parentRect.bottom
      if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
        parent.scrollTop += scrollTop
      } else {
        parent.scrollTop += scrollBot
      }
    }
  }

  const selectSnippet = (e, s) => {
    e.preventDefault()
    debounce(() => {
      logEvent('SELECTED_SNIPPET', { id: s.id })
      if (!editor) {
        if (parentref.current.selectionStart === parentref.current.selectionEnd) { // No selection
          const prefix = value.substring(0, cursorpos)
          const suffix = value.substring(cursorpos, value.length)
          onSelect(prefix + s.content + suffix)
        } else {
          const prefix = value.substring(0, parentref.current.selectionStart)
          const suffix = value.substring(parentref.current.selectionEnd, value.length)
          onSelect(prefix + s.content + suffix)
        }
        setSnippet(false)
        parentref.current.focus()
        if (root && Object.hasOwn(root, 'unmount')) { root.unmount() }
      } else {
        onSelect(s.content)
        if (root && Object.hasOwn(root, 'unmount')) { root.unmount() }
      }
    }, 50)()
  }

  const keyPress = (e, s) => {
    if (e.key === 'ArrowDown') {
      el.current?.blur()
      e.preventDefault()
      if (snippets.length) {
        if (selected || selected === 0) {
          if (selected !== snippets.length - 1) {
            const childel = document.getElementsByClassName('snippet-option')[selected + 1]
            scrollParentToChild(parentel.current, childel)
          }
        } else {
          const childel = document.getElementsByClassName('snippet-option')[0]
          scrollParentToChild(parentel.current, childel)
        }
      }
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (snippets.length) {
        if (selected && selected !== 0) {
          const childel = document.getElementsByClassName('snippet-option')[selected - 1]
          scrollParentToChild(parentel.current, childel)
        } else if (selected === 0) {
          el.current?.focus()
          const childel = document.getElementById('snippet-search')
          scrollParentToChild(parentel.current, childel)
        }
      }
    }
    if (e.key === 'Enter') {
      if (s) {
        selectSnippet(e, s)
      }
    }
    return e
  }

  useEffect(() => {
    el.current.focus()
  }, [ el.current ])

  const closeModal = e => {
    if (e.key === 'Escape') {
      setSnippet(false)
      try {
        root?.unmount()
      } catch (er) {
        console.error(er)
      }
      parentref.current.focus()
    }
  }

  useEffect(() => {
    parentel.current?.addEventListener('keydown', keyPress)
    window.addEventListener('keydown', closeModal)
    if (!snippets.length) {
      new Promise((resolve, reject) => {
        const values = {
          modelname: 'snippets',
          params: {
            location,
            meta_fields: [ 'area', 'suburb' ]
          }
        }
        props.fetchMany({ values, resolve, reject })
      }).then(r => {
        setSnippets(r.options)
      })
    }
    return () => {
      setSnippet(false)
      parentel.current?.removeEventListener('keydown', keyPress)
      window.removeEventListener('keydown', closeModal)
      try {
        root?.unmount()
      } catch (er) {
        console.error(er)
      }
    }
  }, [ ])

  return (
    <div className="snippet-container">
      <Card
        background={true}
        showtoggles={false}
        classes="snippet-card"
        innerRef={parentel}
        header={
          <div className="form-group">
            <div className="field">
              <div className="forminput">
                <input
                  id="snippet-search"
                  ref={el}
                  type="search"
                  autoFocus
                  autoComplete='off'
                  placeholder="Search for a snippet"
                  className="form-control input-group-suffix"
                  onInput={e => {
                    setTerm(e.target.value)
                  }}
                  onFocus={() => {
                    setSelected(null)
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      debounce(searchSnippets, 300)()
                    } else {
                      keyPress(e)
                    }
                  }}
                  value={term}
                />
                <button type="button" onClick={() => searchSnippets()} className="input-group-addon btn btn-icon-24 btn-none">
                  <svg viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-Search"></use></svg>
                </button>
              </div>
            </div>
          </div>
        }
        body={snippets.length ? snippets.map((s, i) =>
          <div
            tabIndex="0" // this allows key events to work on divs
            title={s.name}
            onKeyDown={e => keyPress(e, s)}
            onFocus={() => {
              setSelected(i)
            }}
            onClick={e => selectSnippet(e, s)}
            key={`snippet-${s.id}`}
            className={`snippet-option${(selected || selected === 0) && selected === i ? ' selected' : ''}`}>{s.name}</div>
        ) : <div className="snippet-option">No snippets found</div>
        }
      />
    </div>
  )
}

SnippetModal.propTypes = {
  name: PropTypes.string,
  setSnippet: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  fetchMany: PropTypes.func.isRequired,
  cache: PropTypes.object,
  position: PropTypes.number,
  location: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  rootRef: PropTypes.any,
  parentref: PropTypes.any,
  editor: PropTypes.bool,
  cursorpos: PropTypes.number
}

export default formikConnect(SnippetModal)
