import * as yup from 'yup'
import messages from './messages.json'


export const templates = yup.object({
  name: yup.string().required(messages.required).nullable(),
  display_name: yup.string().required(messages.required).nullable(),
  template_type: yup.string().required(messages.required).nullable(),
  model: yup.string().required(messages.required).nullable(),
  fields: yup.mixed().required(messages.required).nullable()
})
