import { lazy } from 'react'


export default {
  ActiveListingsWidget: lazy(() => import('./ActiveListingsWidget')),
  ExpiringListingsWidget: lazy(() => import('./ExpiringListingsWidget')),
  ExpiringSoonWidget: lazy(() => import('./ExpiringSoonWidget')),
  LatestLeadsWidget: lazy(() => import('./LatestLeadsWidget')),
  LeadSourceWidget: lazy(() => import('./LeadSourceWidget')),
  ListingQualityWidget: lazy(() => import('./ListingQualityWidget')),
  ListingValueWidget: lazy(() => import('./ListingValueWidget')),
  NewsletterWidget: lazy(() => import('./NewsletterWidget')),
  UAESyndicationWidget: lazy(() => import('./UAESyndicationWidget')),
  NotesWidget: lazy(() => import('./NotesWidget')),
  NotificationsWidget: lazy(() => import('./NotificationsWidget')),
  StockWidget: lazy(() => import('./StockWidget')),
  SyndicationWidget: lazy(() => import('./SyndicationWidget')),
  SalesMadeWidget: lazy(() => import('./SalesMadeWidget')),
  PastSalesPerformanceWidget: lazy(() => import('./PastSalesPerformanceWidget')),
  ExpectedNettCommissionWidget: lazy(() => import('./ExpectedNettCommissionWidget')),
  LifetimeSalesPerformanceWidget: lazy(() => import('./LifetimeSalesPerformanceWidget'))
}
