/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../ui/Button'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import ModelSelectSidebar from '../../../../containers/ModelSelectSidebar'
import Loader from '../../Loader'
import { generateAddress, valueFormat } from '../../../../utils'


const ListingLookup = props => {
  const {
    id,
    settings,
    classes,
    field,
    form,
    user,
    match,
    label,
    toggleWideSidebar,
    modelname,
    fetchMany,
    currency
  } = props
  const { name, value } = field

  const [ loading, setLoading ] = useState(false)
  const [ listings, setListings ] = useState([])

  // const deleteSelected = selected => {
  // }

  const addSelected = (m, selected) => {
    setLoading(true)
    let newlistings = field.value ? [ ...field.value ] : []
    selected.forEach(s => newlistings.push(s))
    newlistings = newlistings.filter((v, i) => newlistings.indexOf(v) === i)
    form.setFieldValue(name, newlistings)
    setLoading(false)
  }

  const resetSelected = () => {
    form.setFieldValue(name, [])
    setLoading(false)
  }

  const fetchListings = () => {
    setLoading(true)
    new Promise((resolve, reject) => {
      fetchMany({
        values: {
          modelname,
          params: {
            id__in: value,
            meta_fields: []
          }
        },
        resolve,
        reject
      })
    }).then(r => {
      setListings(r.options)
    }).catch(e => {
      console.error(e)
    }).finally((() => {
      setLoading(false)
    }))
  }

  const removeListing = i => {
    setLoading(true)
    let newlistings = [ ...field.value ]
    newlistings = newlistings.filter(v => v !== i)
    form.setFieldValue(name, newlistings)
    setLoading(false)
  }

  useEffect(() => {
    if (value) { fetchListings() }
  }, [])

  useEffect(() => {
    if (!value) { setListings([])} else { fetchListings() }
  }, [ value ])

  return (
    <div id={id} className="listings-lookup">
      {loading ? (
        <Loader inline={true} />
      ) : (
        <>
          {listings && Array.isArray(listings) ? (
            <div className="listings-items">
              {listings.map(listing => {
                const address = generateAddress(listing)
                return (
                  <div key={`listing-item-${listing.id}`} className="listing-lookup-listing-item">
                    <div className="listing-item-address">{address}</div>
                    <div className="listing-item-price">{valueFormat('currencyabbr', listing.price, { currency: currency })}</div>
                    <div className="listing-item-remove"><Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none-red" onClick={() => removeListing(listing.id)} title="Remove" type="button" /></div>
                  </div>
                )
              })}
            </div>
          ) : null }
          <div id={id} className={`form-group ${classes ? classes : ''}`}>
            <Button
              type="button"
              id={`${name}-contact-lookup-search-btn`}
              tabIndex="-1"
              icon="#icon16-Plus"
              onClick={() => toggleWideSidebar(`show-${name}-listings-search`) }
              className="btn btn-grey btn-icon-left btn-icon-16"
            >Advanced Search</Button>

            <WideSidebar sidebar={`show-${name}-listings-search`}>
              <ModelSelectSidebar
                label={label}
                field={field}
                modelname={modelname}
                user={user}
                key={`listings-search-${name}`}
                addSelected={addSelected}
                settings={settings}
                siblingform={form}
                match={match}
                multi={true}
              />
            </WideSidebar>
          </div>
        </>
      )}
    </div>
  )
}

ListingLookup.propTypes = {
  id: PropTypes.string.isRequired,
  toggleWideSidebar: PropTypes.func.isRequired,
  fetchOne: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  currency: PropTypes.string,
  modelname: PropTypes.string,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  cache: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  model: PropTypes.object,
  match: PropTypes.object,
  selected: PropTypes.array,
  config: PropTypes.object,
  settings: PropTypes.object,
  required: PropTypes.bool,
  error: PropTypes.object,
  user: PropTypes.object
}


export default ListingLookup
