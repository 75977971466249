import PropTypes from 'prop-types'
import React, { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Switch, matchPath, Redirect } from 'react-router'
import { getIn } from 'formik'
import isEqual from 'react-fast-compare'

import { supportsWebp, useCustomCompareMemo } from '../utils'
import Websocket from '../containers/Websocket'
import ProductFruits from './ProductFruits'
import QueryBuilder from './common/QueryBuilder'
import Loader from './common/Loader'
import { Button } from './ui/Button'


let product_code = null

if (process.env.REACT_APP_ENV === 'staging' || process.env.NODE_ENV === 'production') {
  product_code = process.env.REACT_APP_PRODUCT_FRUITS_PROJECT
}


window._hsq = window._hsq || []
// Create a hook to seperate out logic.
const useHubspotChat = (portalId, hubspotHolder) => {
  const [ hasLoaded, setHasLoaded ] = React.useState(false)

  useEffect(() => {
    let script
    if (hubspotHolder.current && !document.getElementById('hs-script-loader') &&
    process.env.REACT_APP_ENV === 'production') {
      window.hsConversationsSettings = {
        loadImmediately: true,
        inlineEmbedSelector: '#hubspot-chat',
        enableWidgetCookieBanner: true,
        disableAttachment: false
      }
      // Add event listener.
      window.hsConversationsOnReady = [ () => {
        setHasLoaded(true)
      } ]

      // Create script component.
      script = document.createElement('script')
      script.id = 'hs-script-loader'
      script.src = `https://js.hs-scripts.com/${portalId}.js`
      script.async = true

      document.body.appendChild(script)
    }
    if (!window.HubSpotConversations?.widget.status().loaded) {
      window.HubSpotConversations?.widget.load()
    }
    return () => {
      if (script) {
        document.body.removeChild(script)
      }
      window.hsConversationsOnReady = []
    }
  }, [ hubspotHolder.current ])
  return {
    hasLoaded
  }
}

const HubspotChat = ({ hub_id }) => {
  const hubspotHolder = useRef(null)
  useHubspotChat(hub_id, hubspotHolder)
  return (
    createPortal(
      <div ref={hubspotHolder} id="hubspot-chat-holder">
        <Button icon="#icon16-X-Large" className='btn btn-none btn-icon-16' onClick={() => {
          const event = new Event('hs:close')
          window.dispatchEvent(event)
        }} />
        <div id="hubspot-chat"></div>
      </div>, document.body
    )
  )
}
HubspotChat.propTypes = {
  agent: PropTypes.object,
  hub_id: PropTypes.string
}


const LoadUser = ({
  actions,
  location,
  redirect: propredirect,
  agent,
  statetoken,
  user,
  hub_id,
  path,
  children
}) => {
  /* This component facilitates the auto login of users */
  const [ redirect, setRedirect ] = useState()
  const [ loading, setLoading ] = useState(true)

  const load = (storedToken, storedAgent, site) => {
    if (
      storedToken && // We have a token
      [ null, undefined, '', 'null', 'undefined', false ].includes(statetoken) && // But there is no state auth yet
      ![ null, undefined, '', 'null', 'undefined', false ].includes(storedToken)) { // Load token from storage if we aren't authenticated
      new Promise((resolve, reject) => { // Get a token
        actions.loadToke({ token: storedToken, agent: storedAgent, site, redirect, resolve, reject }) // This also calls selectAgent on success
      }).catch(() => {
        setRedirect({ pathname: '/login' })
      }).finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      if (await supportsWebp()) {
        document.body.classList.add('webp')
      } else {
        document.body.classList.add('no-webp')
      }
    })()

    let storedtoken = localStorage.getItem('token')
    let storedAgent = localStorage.getItem('agent')
    let site
    const match = matchPath(location?.pathname, { path })
    if (match) { site = getIn(match, 'params.site') }
    const qs = new QueryBuilder(location.search)
    if (qs.getParam('token')) {
      storedtoken = qs.getParam('token')
      qs.removeParam('token')
      storedAgent = qs.getParam('agent')
      qs.removeParam('agent')
      setRedirect({ ...location, search: qs.url(true) })
      load(storedtoken, storedAgent, site)
    } else if ([ null, undefined, false, 'null' ].includes(storedtoken)) {
      actions.registerRedirect(location) // Go to where we need to be
      setRedirect({ pathname: '/login' })
      setLoading(false)
    } else if (![ '/login', '/forgot', '/reset' ].includes(location.pathname) && location.pathname.startsWith('/secure') && !redirect) {
      setRedirect(location)
      load(storedtoken, storedAgent, site)
    }
  }, [])

  useEffect(() => {
    if (!agent.token) {
      setRedirect({ pathname: '/login' })
    } else if (propredirect?.pathname === '/login' && redirect?.pathname !== '/login') {
      setRedirect(propredirect)
    } else if (redirect?.pathname === location.pathname) {
      setRedirect(null) // Already where we need to be
      setLoading(false)
    } else if (propredirect && !isEqual(redirect, propredirect)) {
      setRedirect(propredirect)
      actions.registerRedirect(propredirect) // Go to where we need to be
    }
    return () => { }
  }, [
    useCustomCompareMemo(propredirect),
    agent.token,
    location.pathname,
    useCustomCompareMemo(redirect)
  ])

  if (redirect && !loading) { return <Redirect to={redirect} /> }
  const userInfo = user && user.agent ? {
    username: user.id,
    email: user.agent.email,
    firstname: user.agent.first_name,
    lastname: user.agent.last_name,
    signUpAt: user.agent.created
  } : null
  return !loading ? (
    <>
      {(product_code && userInfo) ? <ProductFruits
        projectCode={product_code}
        language="en"
        {...userInfo}
      /> : null}
      <Websocket key='websocket' />
      <HubspotChat hub_id={hub_id} agent={agent} />
      <Switch>{children}</Switch>
    </>
  ) : <Loader noblock={true} />
}

LoadUser.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]).isRequired,
  location: PropTypes.object,
  auth: PropTypes.bool,
  actions: PropTypes.object,
  path: PropTypes.string,
  user: PropTypes.object,
  statetoken: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
  agent: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool
  ]),
  hub_id: PropTypes.string
}

export default React.memo(LoadUser)
