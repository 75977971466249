/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, getIn } from 'formik'
import { Editor as MonacoEditor } from '@monaco-editor/react'

import classNames from 'classnames'
import Loader from '../../Loader'
import Label from './Label'


class CodeEditorInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: getIn(props.form.values, props.field.name),
      source: false,
      customCSS: null,
      options: {
        selectOnLineNumbers: true
      }
    }
    this.editorDidMount = this.editorDidMount.bind(this)
    this.onChange = this.onChange.bind(this)
    this.editor = null
  }

  componentDidMount() {
    if (getIn(this.props.form.values, this.props.field.name) !== this.state.value) {
      this.setState({ value: getIn(this.props.form.values, this.props.field.name) })
    }
  }

  componentDidUpdate(prevProps) {
    if (!getIn(prevProps.form.values, this.props.field.name) && getIn(this.props.form.values, this.props.field.name)) {
      this.setState({ value: getIn(this.props.form.values, this.props.field.name) })
    }
    if (
      ![ null, undefined ].includes(getIn(this.props.form.values, this.props.field.name))
      && getIn(this.props.form.values, this.props.field.name) === ''
      && getIn(this.props.form.values, this.props.field.name) !== this.state.value
    ) {
      this.setState({ value: '' })
    }
  }

  editorWillMount(monaco) {
    monaco.languages.html.htmlDefaults.setOptions({
      ...monaco.languages.html.htmlDefaults.options,
      format: {
        ...monaco.languages.html.htmlDefaults.options.format,
        wrapAttributes: false,
        wrapLineLength: 0,
        tabSize: 2
      }
    })
  }

  editorDidMount(editor) {
    const { language } = this.props
    this.editor = editor
    const tab_content = document.querySelector('.horizontal-tabs-wrapper + div')
    if (tab_content) {
      this.setState({ height: tab_content.scrollHeight - 20 })
    }
    editor.getModel().updateOptions({ tabSize: 2 })
    if (language !== 'scss') {
      editor.getAction('editor.action.formatDocument').run()
    }
    editor.onDidBlurEditorText(() => {
      if (language !== 'scss') {
        editor.getAction('editor.action.formatDocument').run()
      }
    })
  }

  onChange(newValue) {
    if (newValue !== this.state.value) {
      this.setState({ value: newValue }, () => {
        this.props.form.setFieldValue(this.props.field.name, newValue).then(() => {
          this.props.form.setFieldTouched(this.props.field.name)
        })
      })
    }
  }

  render() {
    const { field, classes, id, label, language, height } = this.props
    return (
      <Suspense fallback={<Loader inline />}>
        <div id={id} className={classNames('form-group', field.name, classes, 'code-input')}>
          {label && <Label htmlFor={`input-${id}`} className="formlabel">{label}</Label>}
          <div className="forminput form-control monaco-editor">
            <MonacoEditor
              id={id}
              width="100%"
              height={height ? height : 'calc(-238px + 100vh)'}
              language={`${language}`}
              theme="vs-dark"
              value={this.state.value}
              options={this.state.options}
              onChange={this.onChange}
              beforeMount={this.editorWillMount}
              onMount={this.editorDidMount}
              editorWillUnmount={() => {}}
            />
          </div>
          <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
        </div>
      </Suspense>
    )
  }
}

CodeEditorInput.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object,
  modelname: PropTypes.string,
  placeholder: PropTypes.string,
  language: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  height: PropTypes.number,
  config: PropTypes.object,
  settings: PropTypes.object,
  classes: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  uploadFile: PropTypes.func
}

export default CodeEditorInput
