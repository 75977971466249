import React, { useEffect, useState, useRef } from 'react'

import PropTypes from 'prop-types'
import InlineSelect from '../../common/forms/inputs/InlineSelect'
import { buildOptionLabel, hasPermission } from '../../../utils'


const useAgentBranches = ({ actions, user, branches }) => {
  const abortController = useRef(new AbortController())
  const [ branchData, setBranchData ] = useState({ branchData: [], branchOptions: [] })
  useEffect(() => {
    let agent_branches = []
    if (!hasPermission([
      'apply_to_all_branches'
    ], user.permissions, null, user.agent.id)) {
      agent_branches = [ ...user.agent.branches ]
    }
    new Promise((resolve, reject) => actions.fetchMany({
      noloader: true,
      values: {
        modelname: 'branches',
        optionvalue: 'id',
        optionlabel: 'name',
        fields: [ 'id', 'name', 'statistics' ],
        active: 1,
        all: true,
        conflict: true,
        params: {
          id__in: branches ? [ ...agent_branches, ...branches ] : agent_branches,
          order_by: 'name',
          meta_fields: [ 'statistics' ]
        },
        signal: abortController.current.signal
      },
      resolve,
      reject
    })).then(r => {
      setBranchData({
        branchData: r.options,
        branchOptions: r.options.map(o => buildOptionLabel({ optionlabel: 'name' }, o))
      })
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
    })
    return () => {
      abortController.current.abort()
    }
  }, [])
  return branchData
}

const InlineBranchSelect = ({ actions, user, branches, selectedValue, onChange }) => {
  const { branchData, branchOptions } = useAgentBranches({ actions, user, branches })
  if (branches || !branchData.length) { return null }
  return (
    <div className="filter-branch">
      <InlineSelect
        id="branch_id"
        name="branch_id"
        className="inline-select"
        classNamePrefix="inline"
        options={[ { label: 'All Branches', value: '' }, ...branchOptions ]}
        defaultValue={{ label: 'All Branches', value: '' }}
        selectedValue={selectedValue}
        onChange={onChange}
      />
    </div>
  )
}

InlineBranchSelect.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  branches: PropTypes.array,
  selectedValue: PropTypes.any,
  onChange: PropTypes.func
}

export default InlineBranchSelect
