/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { getIn } from 'formik'
import { NavLink } from 'react-router-dom'

import log from '../../../logging'
import { Button } from '../../ui/Button'
import { valueFormat, parseURL, generateWebsiteLink } from '../../../utils'
import ContextDropdown from './ContextDropdown'


const ContextButton = props => {
  const [ open, setOpen ] = useState(false)
  const [ dropdownStyle, setDropdownStyle ] = useState({ display: 'none', position: 'absolute' })
  const [ generatedLink, setGeneratedLink ] = useState(null)

  const scrolling = useRef(null)
  const el = useRef(null)

  const handleOpen = () => { // Handles Open on mouseover event
    setOpen(true)
    setDropdownStyle(props.actions.dropdownPositionCallback(el.current))
  }

  const handleClose = () => { // Handles Close on mouselease event
    setOpen(false)
    setDropdownStyle(props.actions.dropdownPositionCallback(el.current))
  }

  const getButtonClasses = () => classNames('action', 'navitem', 'btn', { 'btn-icon-16': props.button.icon, 'btn-icon-left': props.button.icon }, props.className, props.button.className)

  const toggleMenu = () => { // Handles toggle on click event
    setOpen(!open)
    setDropdownStyle(props.actions.dropdownPositionCallback(el.current))
  }

  const scrollSubMenu = () => { // Handles toggle on click event
    scrolling.current = setTimeout(() => {
      if (el.current) {
        setDropdownStyle(props.actions.dropdownPositionCallback(el.current))
      }
    }, 100)
  }

  const generateMenuLink = (element, children, dropdownHover) => {
    const { id } = props.match.params
    const { form, user, actions, cache, model, portals, modelname, website_url, app, selected } = props
    let { modelid } = props
    let link = element.link
    let visible = false
    let m = getIn(cache, modelid)
    if (!m && selected.length) { m = cache[getIn(user, selected[0])] }
    visible = props.isButtonVisible(element)
    if (visible) {
      if (element.link) {
        if (element.portal) { // This relates to a portal link
          if (m.meta.portals && portals) { // Ensure selected model portal and global portals exist
            const portalidx = Object.keys(portals).find(pk => portals[pk].meta.portal.slug === element.portal)
            if (!portalidx) { return null }
            const portal = m.meta.portals.find(p => p.portal === portals[portalidx].portal)
            if (!portal) { return null }
            if (element.portal === 'hubspot') {
              const portal_config = user.agent.site.portals?.branch?.find(
                p => p.branch_id === m.meta.branch.id && p.portal === 25
              )
              if (portal_config) {
                portal.reference = false
                if (portal_config.credentials && portal_config.credentials.includes(':')) {
                  portal.reference = portal_config.credentials.split(':')[0]
                } else {
                  portal.reference = portal_config.reference
                }
              }
            }
            link = valueFormat(element.format, portal.reference, {
              portal: portal.meta.portal.slug,
              reference: portal.reference,
              location: m.meta.location ? m.meta.location : m.email,
              listing_type: m.listing_type
            })
          }
        } else if (props.action === 'list') { // Add the default list params
          const search = new URLSearchParams(getIn(props, 'model.params', getIn(props, 'config.params', ''))).toString()
          link = `${element.link}?${search}`
        } else if (element.scope === 'selected') {
          link = parseURL(element.link, { selected, site: user.agent.site.id }) // List with selection
        } else if (id) {
          link = parseURL(element.link, cache[id])
        } else if (modelid && !element.linkto) { // Right clicked on a table row for example
          link = parseURL(element.link, cache[modelid])
        } else if (selected.length) {
          link = parseURL(element.link, cache[selected[0]], user)
        }
        if (element.linkto) { // User for opening new tabs like view on website etc.
          switch (element.linkto) {
            case 'site':
              if ((!link || link === 'null') && element.link === ':meta.url.website') {
                if (m && !m.display_on_website) { return null } // Listing is not set to display
                if (!generatedLink) {
                  link = false
                  generateWebsiteLink(m, modelname, getIn(user, [ 'agent', 'site' ])).then(l => {
                    if (l) { setGeneratedLink(l) }
                  }).catch(e => log.error(e))
                } else {
                  link = valueFormat('url', `${website_url}${generatedLink}`)
                }
              } else {
                if (getIn(cache[modelid], element.link.substring(1))) { // Cater for branch, agent, team microsites as well as main website
                  link = getIn(cache[modelid], element.link.substring(1)) // Remove the colon from the link key and retrieve the value
                }
                if (link === 'null') {link = null}
                link = link && link.replace('./', '')
                if (link && !link.startsWith('http')) {
                  link = valueFormat('url', `${website_url}${link}`)
                }
              }

              break
            case 'facebook':
            case 'twitter':
            case 'linkedin':
            case 'whatsapp':
            case 'pinterest':
              link = link && link.replace('./', '')
              break
            case 'reports': {
              let service
              if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'e2e') {
                service = app.reports.stage
              } else if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production') {
                service = app.reports.live
              } else {
                service = app.reports.dev
              }
              link = `${service}${link}`
              break
            }
            default:
              break
          }
          if (link) {
            return (
              <Button
                component="a"
                className={getButtonClasses()}
                rel="noopener noreferrer"
                target="_blank"
                href={link}
                icon={element.icon}
                onMouseOver={e => {
                  if (dropdownHover && open) { handleClose(e) }
                  return e
                }}
              >{children}</Button>
            )
          }
          return null // Return nothing if no link
        }

        return ( // Otherwise return React Router Link
          <Button component={NavLink}
            target={element.target}
            to={parseURL(link, { site: user.agent.site.id })}
            className={getButtonClasses()}
            icon={element.icon}
            onMouseOver={e => {
              if (dropdownHover && open) { handleClose(e) }
              return e
            }}
          >{children}</Button>
        )
      } else if (element.action) { // Reports, exports etc.
        const action = actions[element.action]
        if (!modelid && id) {
          modelid = id
        } else if (!modelid && selected.length) {
          modelid = selected[0]
        }
        return (
          <Button component='div'
            type="button"
            onClick={e => {
              if (action) {
                action({
                  redirect: e.currentTarget.getAttribute('data-redirect'),
                  modelname,
                  id: modelid,
                  params: model.params,
                  args: element.args,
                  label: children,
                  site: user.agent.site.domain,
                  form: form,
                  fields: element.fields
                })
              }
            }}
            onMouseOver={e => {
              if (dropdownHover && open) { handleClose(e) }
              return e
            }}
            className={getButtonClasses()}
            icon={element.icon}
          >{children}</Button>
        )
      }
    }
    return null
  }


  const renderDropdownButton = () => {
    const { button, className, match, form, user, cache, model, portals, modelname,
      stickyProps, dropdownHover, isButtonVisible, modelid, website_url, selected, actions } = props

    return (
      <div
        className={`action has-submenu ${className || ''}`}
        onMouseOver={(e => {
          if (dropdownHover) { handleOpen() } // Only fire if this is a hoverDropdown
          return e
        })}
        onMouseLeave={(e => {
          if (dropdownHover) { handleClose() } // Only fire if this is a hoverDropdown
          return e
        })}
      >
        <Button
          icon={button.icon}
          component='div'
          forwardRef={elem => { el.current = elem }}
          className={`${getButtonClasses()}${open ? ' active' : ''}`}
          onClick={e => {
            if (el?.current.classList.contains('disabled')) { return }
            if (button.action && actions[button.action]) {
              actions[button.action]({ e, args: button.args, form })
            }
            if (button.menu) { toggleMenu(e) }
          }}
          data-redirect={button.redirect}
        >
          <span className="label">{props.children}</span>
          {!(props.button.className && props.button.className.includes('btn-round btn-red')) ? (
            <div
              className={`navicon icon submenu-icon${open ? ' active' : ''}`}
            >
              <svg viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-ChevronDown" /></svg>
            </div>
          ) : null}
        </Button>
        {open &&
          <ContextDropdown
            match={match}
            model={model}
            modelname={modelname}
            modelid={modelid}
            form={form}
            cache={cache}
            portals={portals}
            user={user}
            website_url={website_url}
            actions={actions}
            styles={stickyProps}
            selected={selected}
            menu={button.menu}
            dropdownHover={dropdownHover}
            handleClose={handleClose}
            dropdownStyles={dropdownStyle}
            className="action-submenu"
            isButtonVisible={isButtonVisible}
          />
        }
      </div>
    )
  }

  const renderLinkButton = () => {
    const { button, link, dropdownHover, children } = props
    return generateMenuLink(button, children, dropdownHover)
  }

  const renderButton = () => {
    const { button, form, dropdownHover, children, filters,
      match, modellist, user, model, disabled, selected, actions } = props
    return (
      /* Pass in the action event for the redirection schema,
      * any arguments from config as well as the
      * button label in order to populate the notifaction and alerts.
      */
      <Button
        component="div"
        icon={button.icon}
        className={getButtonClasses()}
        onClick={ e => {
          if (actions[button.action] && !disabled) {
            actions[button.action]({
              redirect: e.currentTarget.getAttribute('data-redirect'),
              modelname: match.params.model,
              id: match.params.id,
              args: button.args,
              label: button.label,
              modellist: modellist,
              params: filters ? { ...getIn(model, 'params', {}), ...filters } : getIn(model, 'params', {}),
              selected: selected.length ? selected : [ props.modelid ],
              form
            })
          }
        }}
        onMouseOver={e => {
          if (dropdownHover && open) { handleClose(e) }
          return e
        }}
        data-redirect={button.redirect}
      >
        <span className="label">{children}</span>
      </Button>
    )
  }

  useEffect(() => {
    if (!props.dropdownHover && el.current) { // Calculate position of dropdown on click
      setDropdownStyle(props.actions.dropdownPositionCallback(el.current))
    }
    return () => {
      window.removeEventListener('scroll', scrollSubMenu, true)
      window.removeEventListener('scroll', scrollSubMenu)
      clearTimeout(scrolling.current)
    }
  }, [])

  useEffect(() => {
    if (!props.dropdownHover && el.current) { // Calculate position of dropdown on click
      if (open) {
        window.addEventListener('scroll', scrollSubMenu, true)
      } else {
        window.removeEventListener('scroll', scrollSubMenu)
      }
    }
  }, [ props ])

  const { button } = props

  if (button.menu) {
    return renderDropdownButton()
  } else if (button.link) {
    return renderLinkButton()
  }
  return renderButton()
}

ContextButton.propTypes = {
  clickHandler: PropTypes.func,
  icon: PropTypes.string,
  link: PropTypes.string,
  modelname: PropTypes.string,
  menu: PropTypes.object,
  match: PropTypes.object,
  modelid: PropTypes.number,
  modellist: PropTypes.bool,
  website_url: PropTypes.string,
  action: PropTypes.string,
  user: PropTypes.object,
  actions: PropTypes.object,
  button: PropTypes.object,
  form: PropTypes.object,
  dropdownHover: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  filters: PropTypes.object,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  redirect: PropTypes.string,
  className: PropTypes.string,
  cache: PropTypes.object,
  portals: PropTypes.object,
  app: PropTypes.object,
  stickyProps: PropTypes.object,
  isButtonVisible: PropTypes.func,
  selected: PropTypes.array
}

export default ContextButton
