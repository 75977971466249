/* eslint-disable new-cap */
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import merge from 'deepmerge'

import Card from './common/Card'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import ModelActions from './common/ModelActions'
import Loader from './common/Loader'


const Integrations = props => {
  const [ loading, setLoading ] = useState(true)
  const [ init, setInit ] = useState(false)
  const [ initvals, setInitVals ] = useState({ lightstone: false, reos: false, flow: false })
  const abortController = useRef(new AbortController())
  let params
  let code
  let state
  if (props.location) {
    let { search } = props.location
    if (search && search.slice(0, 1) === '?') {
      search = search.substring(1)
    }
    params = new URLSearchParams(search)
    code = params.get('code')
    state = params.get('state')
  }
  const [ oauthcode, setOauthCode ] = useState(code)
  const [ oauthstate, setOauthState ] = useState(state)

  const fetchIntegrations = () => {
    const { actions } = props
    new Promise((resolve, reject) => {
      const p = {
        modelname: 'integrations',
        modellist: true,
        signal: abortController.current.signal,
        params: {
          agent: props.user.agent.id
        }
      }
      actions.fetchMany({ values: p, resolve, reject })
    }).then(r => {
      let newinitvals = {}
      if (typeof r.results === 'object') {
        Object.values(r.results).forEach(i => {
          if ([ 'lightstone', 'reos', 'flow' ].includes(i.provider)) {
            initvals[i.provider] = i.enabled
          }
        })
      }
      newinitvals = { ...initvals, ...newinitvals }
      setInit(true)
      setInitVals(newinitvals)
      setLoading(false)
    })
  }

  const toggleIntegration = (field, form) => {
    let lightstone_redirect_uri_prefix
    let lightstone_client_id
    if (process && process.env && process.env.REACT_APP_ENV === 'production') {
      lightstone_redirect_uri_prefix = ''
      lightstone_client_id = 'db69b10f-d017-4dcf-8ba6-701bf0a29764'
    } else if (process && process.env && process.env.REACT_APP_ENV === 'staging') {
      lightstone_redirect_uri_prefix = 'staging.'
      lightstone_client_id = '89c9d768-f98a-49b0-a7f4-4fcbca5af90f'
    } else { // Dev
      lightstone_redirect_uri_prefix = 'matt.'
      lightstone_client_id = '89c9d768-f98a-49b0-a7f4-4fcbca5af90f'
    }

    if (form.values[field] && form.touched[field]) { // Enablement
      if (field === 'lightstone') {
        setLoading(true)
        const redirect = `https://login.lightstone.co.za/lsgb2c.onmicrosoft.com/oauth2/v2.0/authorize/?p=b2c_1a_signupsigninlsg&client_id=${lightstone_client_id}&response_type=code&scope=offline_access+openid&redirect_uri=https://${lightstone_redirect_uri_prefix}manage.propdata.net&state=lightstone/${props.user.agent.site.id}/${props.user.agent.id}`
        window.location.replace(redirect)
      }
    }
  }

  useEffect(() => {
    if (oauthcode && oauthstate) {
      const { actions } = props
      new Promise((resolve, reject) => {
        const values = {
          modelname: 'integrations',
          code: oauthcode,
          state: oauthstate
        }
        actions.createModel({ values, resolve, reject })
      }).then(() => {
        const provider_parts = oauthstate.split('/')
        actions.registerRedirect(`/secure/${provider_parts[1]}/integrations`)
        setLoading(false)
      }).catch(e => {
        console.error(e)
      })
    }
    if (!init && !oauthcode) { fetchIntegrations() }
    return () => {
      abortController.current?.abort()
    }
  }, [])


  useEffect(() => {
    const { location } = props
    if (location.search && location.search === '') {
      setOauthCode(false)
      setOauthState(false)
      fetchIntegrations()
    }
  }, [ props.location.search ])

  const { actions, config, match, user } = props
  return !loading ? (
    <>
      <div id="content" className="content">
        <Formik
          initialValues={{ ...initvals }}
          onSubmit={values => {
            actions.updateModel({ values: { modelname: 'integrations', id: user.agent.id, ...values } })
          }}
        >{formik => (
            <CustomForm
              form={formik}
              onChange={(changes, form) => {
                toggleIntegration(changes[0], form)
              }}
              render={() => (
                <>
                  <div className="viewhead details">
                    <div className="action-bar">
                      <ModelActions
                        touched={formik.touched}
                        errors={formik.errors}
                        isSubmitting={formik.isSubmitting}
                        // redirectSchema={this.redirectSchema}
                        form={formik}
                        modelname={config.modelname}
                        statusmsg={formik.status ? formik.status.msg : false}
                      />
                    </div>
                  </div>
                  <div className="view action container-fluid integrations">
                    <div className="viewcontent">
                      { Object.keys(config.fieldgroups).map((group, gidx) => (
                        <FieldGroup
                          key={`fs-${gidx}`}
                          groupname={group}
                          group={config.fieldgroups[group]}
                          gidx={gidx}
                          classes={config.fieldgroups[group].classes}
                          fields={config.fields.filter(field => field.group === group).map(f => {
                            const newfield = merge({}, f)
                            if (newfield.createnew) { newfield.createnew = false }
                            if (newfield.twin) { newfield.readonly = true }
                            return newfield
                          })}
                          modelname={config.modelname}
                          match={match}
                          // required={this.state.required}
                          columns
                          // collapsed={this.state.collapsed}
                          render={({ renderFieldGroup, hidden }) => {
                            if (hidden) { return null }
                            return (
                              <div className="integrations-group">
                                <Card
                                  bodyclass="no-top-padding"
                                  collapsable
                                  background
                                  header={
                                    <>
                                      <h3>{group}</h3>
                                    </>
                                  }
                                  body={
                                    <>
                                      <div>
                                        <div className={`${group.toLowerCase()}-logo`}></div>
                                        {`${config.fieldgroups[group].payline}`}
                                      </div>
                                      <div>
                                        <fieldset className="editgroup">
                                          <div>Enable this integration</div>
                                          {renderFieldGroup(group)}
                                        </fieldset>
                                      </div>
                                    </>
                                  }
                                />
                              </div>
                            )
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )} // CustomForm render
            />
          )
          }
        </Formik>
      </div>
    </>
  ) : <Loader noblock={true} />
}

Integrations.propTypes = {
  model: PropTypes.object,
  modelname: PropTypes.string,
  routeConfig: PropTypes.object,
  actions: PropTypes.object,
  cache: PropTypes.object,
  configs: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  addons: PropTypes.array,
  location: PropTypes.object
}

export default Integrations
