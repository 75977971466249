/* eslint-disable no-unused-vars */
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'

import { logEvent, parseClasses, parseURL, updateSearchParms, updateAdditionalParams, useCustomCompareMemo } from '../../../utils'
import { Button } from '../../ui/Button'
import WatcherForm from '../forms/WatcherForm'
import CustomForm from '../forms/CustomForm'

import FieldGroup from '../forms/FieldGroup'


const AdvancedSearch = ({ config,
  model,
  actions,
  user,
  cache,
  match,
  handleReset,
  customform,
  toggleAdvanced,
  limit
}) => {
  const { advsearch } = config
  const [ initvals, setInitvals ] = useState({})
  const [ count, setCount ] = useState(0)
  const [ locationState, setLocationState ] = useState({ areas: null, locations: null })
  const form = useRef()

  useEffect(() => {
    const vals = {}
    const bool_list = []
    advsearch.forEach(group => {
      group.forEach(field => {
        const key = `${field.name}${field.verb ? `__${field.verb}` : ''}`
        if (Object.keys(model.params).includes(key)) {
          vals[key] = model.params[key]
        }
        if (field.valsasbools) {
          field.options.forEach(v => {
            if (Object.keys(model.params).includes(v.value)) {
              bool_list.push(v.value)
            }
          })
        }
      })
    })
    setInitvals(vals)
  }, [ useCustomCompareMemo(model.params) ])

  const updateLocations = values => {
    new Promise((resolve, reject) => {
      actions.fetchLocations({ values, resolve, reject })
    }).then(areas_suburbs => {
      // Only set areas if they haven't already been set
      const { locations, areas } = areas_suburbs
      const { locations: prevLocations, areas: prevAreas } = locationState
      if (!isEqual({ locations, areas }, { locations: prevLocations, areas: prevAreas })) {
        if (prevAreas) {
          // merge areas, don't replace
          areas_suburbs.areas = merge(prevAreas, areas)
        }
        setLocationState(areas_suburbs)
      }
    })
  }

  const renderButtons = formikProps => {
    const cleanedparams = { ...model.params }
    const defaultparams = { ...config.params }
    // Remove parameters that do not actually filter
    delete cleanedparams.meta_fields
    delete cleanedparams.order_by
    delete cleanedparams.order_by_related
    delete cleanedparams.offset
    delete cleanedparams.limit
    delete defaultparams.order_by
    delete defaultparams.limit
    return (
      <div className="search-buttons" key="adv-btns">
        { [ 'residential', 'commercial', 'holiday', 'projects' ].includes(config.modelname) && count > 0 &&
        <div className="adv-count">{count} {count === 1 ? config.singular : config.plural}</div>
        }
        <Button
          icon="#icon16-Search"
          type="button"
          disabled={formikProps.isSubmitting || ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(config.modelname) && !count) || !formikProps.dirty}
          className="btn btn-primary btn-icon-16 btn-icon-left"
          onClick={form.current.handleSubmit}
        >
          Search
        </Button>
        {formikProps.status && formikProps.status.success &&
        <Button type="button" onClick={e => {
          form.current.resetForm()
          handleReset(e)
        }} title="Reset filters" icon="#icon16-Refresh" className="btn btn-primary btn-icon-16 btn-icon-left">
          Reset Filters
        </Button>
        }
        {!isEqual(defaultparams, cleanedparams) &&
          <Button type="button" onClick={e => {
            formikProps.resetForm()
            handleReset(e)
          }} title="Reset filters" icon="#icon16-Refresh" className="btn btn-primary btn-icon-16 btn-icon-left">
            Reset Filters
          </Button>
        }
        <Button type="button" onClick={toggleAdvanced} className="btn btn-sublte">
          Close
        </Button>
      </div>
    )
  }

  const addVerb = formikProps => {
    if (advsearch) {
      return (
        <div className="search-fields">
          {advsearch.map((group, gidx) => {
            const fields = group.map(field => {
              const fieldProps = config.fields.find(f => f.name === field.name)
              let classes = field.classes ? parseClasses(field.classes, form.current.values) : ''
              classes = classes.split(' ')
              let cols = classes.find(c => c.includes('col-'))
              classes = classes.filter(c => !c.includes('col-'))
              cols = cols.replace('col-', '')
              const newProps = {
                ...fieldProps,
                ...field,
                group: `group-${gidx}`,
                name: `${field.name}${field.verb ? `__${field.verb}` : ''}`,
                classes,
                required: false,
                readOnly: false,
                quality: false,
                cols,
                match: match,
                id: field.id ? `field-${field.id}-${field.name}` : `field-${field.name}`
              }
              if (newProps.input?.endsWith('Notes')) {
                newProps.input = newProps.input.replace('Notes', '')
              }
              if (newProps.input === 'Extras') {
                newProps.hidelegend = true
              }
              if (newProps.input === 'LocationSelect') {
                if (newProps.extraparams && form.current) { // Used for form submission
                  newProps.params = parseURL(
                    newProps.extraparams,
                    form.current.values,
                    user ? user.agent : false,
                    user ? cache.settings[user.agent.site.id] : false
                  )
                }
                if ([ 'locations', 'suburbs' ].includes(newProps.modelname) && locationState.areas) {
                  newProps.params = parseURL(
                    `${newProps.extraparams}&id__in=${Object.keys(locationState.locations).join(',')}`,
                    { ...form.current.values, area__in: Object.keys(locationState.areas) },
                    user ? user.agent : false,
                    user ? cache.settings[user.agent.site.id] : false
                  )
                  newProps.force_filter = locationState.locations ? locationState.locations : null
                }
                if ([ 'areas' ].includes(newProps.modelname) && locationState.areas) {
                  newProps.params = parseURL(
                    `${newProps.extraparams}&id__in=${Object.keys(locationState.areas).join(',')}`,
                    { ...form.current.values },
                    user ? user.agent : false,
                    user ? cache.settings[user.agent.site.id] : false
                  )
                  newProps.force_filter = locationState.areas ? locationState.areas : null
                }
              }
              return newProps
            })
            return (
              <FieldGroup
                card={false}
                groupname={`group-${gidx}`}
                key={`group-${gidx}`}
                match={match}
                config={{
                  fields
                }}
                fields={fields}
                render={({ renderFieldGroup, hidden }) => {
                  if (hidden) { return null }
                  return renderFieldGroup({
                    fields
                  })
                }}
                buttons={() => {
                  if (gidx === (advsearch.length - 1)) { // Last item in last group
                    return renderButtons(formikProps)
                  }
                  return null
                }}
              />
            )
          })}
        </div>
      )
    }
    return null
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        limit,
        ...initvals
      }}
      onSubmit={(values, formikBag) => { // Remove null values
        const bool_fields = []
        Object.keys(values).forEach(vk => {
          if (values[vk] === null) { delete values[vk]} // Delete null values
          advsearch.forEach(group => {
            group.forEach(field => { // Remove explicit false values
              if (field.name === vk && field.explicit && values[vk] === false) { delete values[vk] }
              if (field.name === vk && field.valsasbools) { bool_fields.push(field.name) }
            })
          })
        })
        if ([ 'residential', 'commercial', 'projects', 'holiday' ].includes(config.modelname)) {
          if (values.agent__in__or) {
            [ 'agent__in__or', 'agent_2__in__or', 'agent_3__in__or', 'agent_4__in__or' ].forEach(v => {
              values[v] = values.agent__in__or
            })
          }
        }
        if ([ 'leads' ].includes(config.modelname)) {
          if (values.listing__area__in) {
            [ 'residential__area__in__or', 'commercial__area__in__or', 'holiday__area__in__or', 'project__area__in__or' ].forEach(v => {
              values[v] = values.listing__area__in
            })
          }
          if (values.listing__location__in) {
            [ 'residential__location__in__or', 'commercial__location__in__or', 'holiday__location__in__or', 'project__location__in__or' ].forEach(v => {
              values[v] = values.listing__location__in
            })
          }
        }
        let { ...params } = values
        config.search.key.forEach(k => { params[k] = false }) // Remove simple search term
        params = updateAdditionalParams(params, bool_fields)
        params.offset = 0
        updateSearchParms(params)
        logEvent('ADVANCED_SEARCH', { modelname: config.modelname, params: values })
        formikBag.setSubmitting(false)
      }}
    >{formik => {
        form.current = formik
        if (customform) {
          return (
            <CustomForm
              component="div"
              render={() => addVerb(formik)}
            />
          )
        }
        return (
          <WatcherForm
            model={model ? true : false}
            values={formik.values}
            formik={formik}
            config={config}
            updateCount={setCount}
            updateLocations={updateLocations}
            fetchMany={actions.fetchMany}
            render={() => addVerb(formik)}
          />
        )
      }
      }
    </Formik>
  )
}

AdvancedSearch.propTypes = {
  config: PropTypes.object,
  configs: PropTypes.object,
  addons: PropTypes.array,
  model: PropTypes.object,
  match: PropTypes.object,
  cache: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  limit: PropTypes.number,
  customform: PropTypes.bool,
  handleReset: PropTypes.func,
  toggleAdvanced: PropTypes.func
}

export default AdvancedSearch
