/* eslint-disable no-process-env */
import React from 'react'
import PropTypes from 'prop-types'

import { valueFormat, sortBy } from '../../utils'
import MetaDetail from './MetaDetail'
import Card from './Card'
import Tip from './forms/Tip'


class Publish extends React.Component {
  render() {
    const { model, portals } = this.props
    let active = []
    if (model.meta.portals) {
      model.meta.portals.filter(p => p.active === true).forEach(p => {
        const portal = Object.keys(portals.global).map(gp => portals.global[gp]).find(gp => gp.id === p.portal)
        let index = [ 'property24', 'private-property', 'gumtree' ].indexOf(portal.slug) // Always put these 3 portals first
        if (index === -1) {
          index = Object.keys(portals.global).length + portal.order // Put all other portals after
        }
        active.push({ portal: p.portal, index })
      })
    }
    active = sortBy(active, 'index')
    const inactive = portals.agency ? Object.keys(portals.agency).filter(p => {
      const portal_id = portals.agency[p].portal
      const global_portal = portals.global.find(gp => gp.id === portal_id && gp.public === true)
      if (!global_portal || !global_portal.model_types.includes(model.model)) { return false }
      return !active.map(ap => ap.portal).includes(portal_id) && !global_portal.inbound
    }) : []
    return (
      <div className="publish">
        <Card
          background
          header={
            <h3>Publication Settings</h3>
          }
          body={
            <div className="input-group publish-group">
              <MetaDetail
                className="col-lg-6"
                label="Website / Mobi Site"
                value={valueFormat('yesno', model.display_on_website)}
                url={model.meta.url.website}
              />
              {model.display_on_website && model.meta.url.branch_site_url ?
                <MetaDetail
                  className="col-lg-6"
                  label="Feed To Branch Microsite"
                  value={valueFormat('yesno', model.display_on_branch)}
                  url={model.meta.url.branch_site_url}
                /> : null}
              {model.display_on_website && model.meta.url.team_site_url ?
                <MetaDetail
                  className="col-lg-6"
                  label="Feed To Team Microsite"
                  value={valueFormat('yesno', model.display_on_team)}
                  url={model.meta.url.team_site_url}
                /> : null}
              {model.display_on_website && model.meta.url.agent_site_url ?
                <MetaDetail
                  className="col-lg-6"
                  label="Feed To Agent Microsite"
                  value={valueFormat('yesno', model.display_on_agent)}
                  url={model.meta.url.agent_site_url}
                /> : null }
              {model.display_on_website && model.meta.url.agent_2_site_url ?
                <MetaDetail
                  className="col-lg-6"
                  label="Feed To Agent 2 Microsite"
                  value={valueFormat('yesno', model.display_on_agent_2)}
                  url={model.meta.url.agent_2_site_url}
                /> : null }
              {model.display_on_website && model.meta.url.agent_3_site_url ?
                <MetaDetail
                  className="col-lg-6"
                  label="Feed To Agent 3 Microsite"
                  value={valueFormat('yesno', model.display_on_agent_3)}
                  url={model.meta.url.agent_3_site_url}
                /> : null }
              {model.display_on_website && model.meta.url.agent_4_site_url ?
                <MetaDetail
                  className="col-lg-6"
                  label="Feed To Agent 4 Microsite"
                  value={valueFormat('yesno', model.display_on_agent_4)}
                  url={model.meta.url.agent_4_site_url}
                /> : null }
              { model.display_on_website ?
                <MetaDetail
                  className="col-lg-6"
                  label="Feed to Portals"
                  value={valueFormat('yesno', model.feed_to_portals)}
                /> : null }
              { model.display_on_website ?
                <MetaDetail
                  className="col-lg-6"
                  label="Featured Property"
                  value={valueFormat('yesno', model.featured)}
                /> : null }
              { model.feature_on_newsletter ?
                <MetaDetail
                  className="col-lg-6"
                  label="Email Newsletter"
                  value={valueFormat('yesno', model.feature_on_newsletter)}
                /> : null }
              { model.display_on_website ?
                <MetaDetail
                  className="col-lg-6"
                  label="Featured On Agent Newsletter"
                  value={valueFormat('yesno', model.feature_on_agent_newsletter)}
                /> : null }
              { model.display_on_website ?
                <MetaDetail
                  className="col-lg-6"
                  label="Disable Bond Repayment Calculator"
                  value={valueFormat('yesno', model.disable_repayment_calulator)}
                /> : null }
              { model.display_on_website ?
                <MetaDetail
                  className="col-lg-6"
                  label="Disable Bond Costs Calculator"
                  value={valueFormat('yesno', model.disable_cost_calulator)}
                /> : null }
              { model.display_on_website ?
                <MetaDetail
                  className="col-lg-6"
                  label="Disable Transfer Costs Calculator"
                  value={valueFormat('yesno', model.disable_transfer_calulator)}
                /> : null }
            </div>
          }
        />
        <>
          <Card
            background
            header={
              <h3>Active Portals</h3>
            }
            bodyclass="cardbody portals publish-portals"
            body={
              <div className="input-group">
                { (!model.feed_to_portals && active.length > 0) ? (
                  <Tip heading='Note' text='"Feed to Portals" for this listing is currently set to No' />
                ) : null }
                { active.length > 0 ?
                  active.map(pid => {
                    const p = model.meta.portals.find(g => g.portal === pid.portal)
                    const portal = portals.global.find(g => g.id === p.portal)
                    const { slug, name } = portal
                    if (!portal.model_types.includes(model.model)) { return null }
                    if (slug === 'eos3') { return null }
                    if (slug === 'property24') {
                      return (
                        <div key={`portal-active-${p.portal}-${p.id}`} className="portal-col">
                          <div className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                            <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={p.name} />
                          </div>
                          <div className="portal-fields">
                            <div className="meta-detail">
                              <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_feature_notes)}</span>
                              <span className='portal-field-label'>Publish Feature Notes</span>
                            </div>
                            <div className="meta-detail">
                              <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_street_address)}</span>
                              <span className='portal-field-label'>Publish Street Address</span>
                            </div>
                            {p.multi_listing_service &&
                                <div className="meta-detail">
                                  <span className="portal-field-value">{valueFormat('yesnoicon', p.multi_listing_service)}</span>
                                  <span className='portal-field-label'>Multi-listing service</span>
                                </div>
                            }
                            {p.display_price &&
                                <div className="meta-detail">
                                  <label className='heavy flex-full'>Display Price</label>
                                  <span className="portal-field-value flex-full">{p.display_price}</span>
                                </div>
                            }
                            {p.project_id &&
                                <div className="meta-detail">
                                  <label className='heavy flex-full'>Project ID</label>
                                  <span className="portal-field-value flex-full">{p.project_id}</span>
                                </div>
                            }
                            {p.expiration_date &&
                                <div className="meta-detail">
                                  <label className='heavy flex-full'>Expiration Date</label>
                                  <span className="portal-field-value flex-full">{valueFormat('date', p.expiration_date)}</span>
                                </div>
                            }
                          </div>
                        </div>
                      )
                    }
                    if (slug === 'private-property') {
                      return (
                        <div key={`portal-active-${p.portal}-${p.id}`} className="portal-col">
                          <div className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                            <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={p.name} />
                          </div>
                          <div className="portal-fields">
                            <div className="meta-detail">
                              <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_street_address)}</span>
                              <span className='portal-field-label'>Publish Street Address</span>
                            </div>
                            {p.display_price &&
                                <div className="meta-detail">
                                  <label className='heavy flex-full'>Display Price</label>
                                  <span className="portal-field-value flex-full">{p.display_price}</span>
                                </div>
                            }
                            {p.expiration_date &&
                                <div className="meta-detail">
                                  <label className='heavy flex-full'>Expiration Date</label>
                                  <span className="portal-field-value flex-full">{valueFormat('date', p.expiration_date)}</span>
                                </div>
                            }
                          </div>
                        </div>
                      )
                    }
                    if (slug === 'gumtree') {
                      return (
                        <React.Fragment key={`portal-active-${p.portal}-${p.id}`}>
                          <div className="portal-col">
                            <div className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                              <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={p.name} />
                            </div>
                            <div className="portal-fields">
                              {p.expiration_date &&
                                  <div className="meta-detail">
                                    <label className='heavy flex-full'>Expiration Date</label>
                                    <span className="portal-field-value flex-full">{valueFormat('date', p.expiration_date)}</span>
                                  </div>
                              }
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    }
                    if (slug === 'property24-developments') {
                      return (
                        <div key={`portal-active-${p.portal}-${p.id}`} className="portal-col">
                          <div className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                            <img src={`${process.env.PUBLIC_URL}/portals/property24.png`} alt={p.name} />
                          </div>
                          <div className="portal-fields">
                            <div className="meta-detail">
                              <span className="portal-field-value">{valueFormat('yesnoicon', p.publish_street_address)}</span>
                              <span className='portal-field-label'>Publish Street Address</span>
                            </div>
                            {p.expiration_date &&
                                <div className="meta-detail">
                                  <label className='heavy flex-full'>Expiration Date</label>
                                  <span className="portal-field-value flex-full">{valueFormat('date', p.expiration_date)}</span>
                                </div>
                            }
                          </div>
                        </div>
                      )
                    }
                    return (
                      <div key={`portal-active-${p.portal}-${p.id}`} className={`portal${(model.feed_to_portals) ? ' portalon' : ' portaloff'}`}>
                        <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={name} />
                      </div>
                    )
                  }) : (
                    <div key="portal-none">
                      There are no portals active for this listing.
                    </div>
                  )
                }
              </div>
            }
          />
          <Card
            background
            header={
              <h3>Inactive Portals</h3>
            }
            bodyclass="cardbody portals publish-portals"
            body={
              inactive.length ? (
                <div className="input-group">
                  {inactive.map(p => {
                    const portal_id = portals.agency[p].portal
                    const global_portal = portals.global.find(gp => gp.id === portal_id)
                    const { slug, name } = global_portal
                    return (
                      <div key={`portal-inactive-${portals.agency[p].portal}-${portals.agency[p].id}`} className="portal portaloff">
                        <img src={`${process.env.PUBLIC_URL}/portals/${slug}.png`} alt={name} />
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div key="portal-inactive-none">
                  There are no inactive portals for this listing.
                </div>
              )
            }
          />
        </>
      </div>
    )
  }
}

Publish.propTypes = {
  model: PropTypes.object,
  portals: PropTypes.object
}

export default Publish
