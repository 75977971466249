import * as yup from 'yup'
import messages from './messages.json'


export const viewing_interaction = yup.object({
  interested: yup.string().oneOf([
    'Yes', 'No', 'Maybe'
  ]).required(messages.required).nullable(),
  viewing_date: yup.string().matches(/^\d\d\d\d-\d\d-\d\d$/).nullable(),
  feedback: yup.string().required(messages.required).nullable()
})
