

import React, { useState } from 'react'
import { components } from 'react-select'

import PropTypes from 'prop-types'
import InlineSelect from '../../common/forms/inputs/InlineSelect'
import { textToDate, valueFormat } from '../../../utils'
import date_options from '../../../config/date-options.json'


const CustomOption = props => {
  const { head, sub } = props.data
  return <components.Option {...props} >
    <div className="customopt">
      <div>
        {head}
        <span className="sub">{sub}</span>
      </div>
    </div>
  </components.Option>
}

CustomOption.propTypes = {
  data: PropTypes.object
}

const InlinePeriodSelect = ({ selectedValue, onChange, optionFilter }) => {
  const [ options ] = useState(optionFilter(date_options))
  return (
    <div className="filter-date-range">
      <InlineSelect
        id="period"
        name="period"
        className="inline-select"
        classNamePrefix="inline"
        selectedValue={selectedValue}
        options={options}
        onChange={e => {
          const { start, end, days } = textToDate(e.value)
          onChange({
            days: days,
            start: valueFormat('shortdate', start.toString()),
            end: valueFormat('shortdate', end.toString()),
            period: e.value
          })
        }}
        components={{ Option: CustomOption }}
      />
    </div>
  )
}

InlinePeriodSelect.propTypes = {
  defaultValue: PropTypes.any,
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
  optionFilter: PropTypes.func
}

export default InlinePeriodSelect
