import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import TooltipContext from '../tooltips/TooltipContext'
import { slugify } from '../../../utils'


const Head = props => {
  const inner = []
  const { rows } = props
  const rowAmount = rows.length
  if (!rowAmount) { return null }
  rows.forEach((row, ridx) => {
    row.forEach((col, cidx) => {
      rows[ridx][cidx] = {
        ...col,
        attributes: {
          colSpan: col.children ? col.children.length : 1,
          rowSpan: col.children ? rowAmount - 1 - ridx : rowAmount - ridx,
          style: col.headerStyle
        }
      }
    })
  })
  // Loop over header rows
  rows.forEach((row, ridx) => {
    const td = []
    // Inject the SelectAll checkbox
    if (ridx === 0 && props.sortable) {
      const attr = { key: `sort${ridx}` }
      attr.rowSpan = rows.length
      const spacer = (
        <th {...attr}>
          <div className="heading-wrapper">
          </div>
        </th>
      )
      td.push(spacer)
    }
    // Inject the SelectAll checkbox
    if (ridx === 0 && props.selectable) {
      const attr = { key: `check${ridx}` }
      attr.rowSpan = rows.length
      let checkall
      const check = (
        <th {...attr}>
          <div className="heading-wrapper">
            <label className="checkcontainer">
              <input
                ref={el => { checkall = el }}
                className="checkall"
                checked={props.selectedAll}
                type="checkbox"
                onChange={() => {}}
                onClick={e => props.toggleSelectAll(e)}
              />
              <span className={'checkmark checkall'}>
                {checkall && checkall.checked ? <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg> : null}
              </span>
            </label>
          </div>
        </th>
      )
      td.push(check)
    }

    // Loop over header columns and create elements
    row.forEach((col, cidx) => {
      const attr = { ...col.attributes, key: cidx }
      let label = col.acronym ? col.acronym : col.label
      if (col.tablelabel) {
        label = col.tablelabel
      }
      let c = (
        <th
          key={`r${ridx}c${cidx}`}
          {...attr}
          className={classNames(col.classes, `column-${slugify(col.name)}`, { grouper: !!col.children })}
        >
          {col.children ? (
            <div className="text-center">{(typeof label === 'string') ? label.toUpperCase() : label}</div>
          ) : (
            <div className="heading-wrapper">
              {(typeof label === 'string') ? label.toUpperCase() : label}
            </div>
          )}
        </th>
      )
      if (col.acronym) {
        c = (
          <th
            key={`r${ridx}c${cidx}`}
            {...attr}
            className={classNames(col.classes, `column-${slugify(col.name)}`, { grouper: !!col.children })}
          >
            <div className="heading-wrapper">
              {col.acronym.toUpperCase()}
              <label className="tooltip">{col.label}</label>
            </div>
          </th>
        )
      }

      if (col.orderable) {
        attr.className = 'orderable'
        let icon = '#glyph-Sort'
        let order_by = props.params ? props.params.order_by : ''
        if (cidx === 0) { attr.className += ' orderedby' }
        if (props.params && props.params.order_by) {
          let key_asc = ''
          if (Array.isArray(col.name)) { key_asc = col.name[0] } else { key_asc = col.name }
          let key_desc = `-${key_asc}`
          if (col.container && col.container === 'stats') {
            key_asc = `statistics__${key_asc}`
            key_desc = `-${key_asc}`
          }
          if (col.orderby) {
            if (Array.isArray(col.orderby)) {
              key_asc = col.orderby.join(',')
              key_desc = col.orderby.map(k => `-${k}`).join(',')
            } else {
              key_asc = col.orderby
              key_desc = `-${key_asc}`
            }
          }
          if (Array.isArray(order_by)) {
            order_by = order_by.join(',')
          }
          if (order_by.indexOf(key_desc) !== -1) {
            icon = '#glyph-TriangleDown'
          } else if (order_by.indexOf(key_asc) !== -1) {
            icon = '#glyph-TriangleUp'
          }
        }
        if (props.params && props.params.order_by_related) {
          let key = ''
          if (Array.isArray(col.name)) { key = col.name[0] } else { key = col.name }
          if (props.params.order_by_related.indexOf(`-${key}`) !== -1) {
            icon = '#glyph-TriangleDown'
          } else if (props.params.order_by_related.indexOf(key) !== -1) {
            icon = '#glyph-TriangleUp'
          }
        }
        c = (
          <th
            key={`r${ridx}c${cidx}`} {...attr}
            className={classNames(col.classes, `column-${slugify(col.name)}`, 'orderable')}
          >
            {col.acronym ? (
              <TooltipContext
                options={{ followCursor: false, arrow: 'top', shiftY: 8 }}
                className="heading-wrapper"
                title={props.title}
                content={col.label}
              >
                <span onClick={e => props.toggleOrder(col, e)} className='heading'>
                  {(typeof label === 'string') ? label.toUpperCase() : label}
                </span>
                <svg viewBox="0 0 16 16"><use href={`/images/glyphs.svg${icon}`} /></svg>
              </TooltipContext>
            ) : (
              <div className="heading-wrapper">
                <span onClick={e => props.toggleOrder(col, e)} className='heading'>
                  {(typeof label === 'string') ? label.toUpperCase() : label}
                </span>
                <svg viewBox="0 0 16 16"><use href={`/images/glyphs.svg${icon}`} /></svg>
              </div>
            )}
          </th>
        )
      }
      td.push(c)
    })
    const tr = React.createElement('tr', { key: `r${ridx}` }, td)
    if (props.rowActions && ridx === rows.length - 1) {
      td.push((
        <th key={`actions-${ridx}`} colSpan={1} rowSpan={rowAmount - ridx} className="column-actions">
          <div className="heading-wrapper"></div>
        </th>
      ))
    }
    inner.push(tr)
  })
  return React.createElement('thead', {}, inner)
}

Head.propTypes = {
  params: PropTypes.object,
  toggleOrder: PropTypes.func,
  toggleSelectAll: PropTypes.func,
  modelname: PropTypes.string,
  results: PropTypes.object,
  selectedOne: PropTypes.bool,
  generateClassNames: PropTypes.func,
  selectable: PropTypes.bool,
  sortable: PropTypes.bool,
  title: PropTypes.string,
  rows: PropTypes.array,
  selectedAll: PropTypes.bool,
  columns: PropTypes.array,
  rowAmount: PropTypes.number,
  rowActions: PropTypes.func
}

export default Head
