import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import { slugify } from '../../../utils'
import TooltipContext from '../tooltips/TooltipContext'


const Foot = ({ rows, selectable, selectedAll, toggleSelectAll, params, title, toggleOrder }) => {
  const inner = []
  const rowAmount = rows.length
  if (!rowAmount) { return null }
  rows.forEach((row, ridx) => {
    row.forEach((col, cidx) => {
      rows[ridx][cidx] = {
        ...col,
        attributes: {
          colSpan: col.children ? col.children.length : 1,
          rowSpan: col.nested ? rowAmount - ridx - 1 : rowAmount - ridx,
          style: col.headerStyle
        }
      }
    })
  })

  rows.forEach((row, ridx) => { // Loop over header rows
    const td = []
    if (ridx === 0 && selectable !== false) {
      const attr = { key: `check${ridx}` }
      attr.rowSpan = rows.length
      const check = (
        <th {...attr}>
          <div className="heading-wrapper">
            <label className="checkcontainer">
              <input
                className="checkall"
                type="checkbox"
                checked={selectedAll}
                onChange={toggleSelectAll}
              />
              <span className={'checkmark checkall'}>
                {selectedAll ? <svg viewBox="0 0 24 24"><use href="/images/icons-16.svg#icon16-Check-Small" /></svg> : null}
              </span>
            </label>
          </div>
        </th>
      )
      td.push(check)
    }

    row.forEach((col, cidx) => { // Loop over header columns and create elements
      const attr = { ...col.attributes, key: cidx }
      let label = col.acronym ? col.acronym : col.label
      if (col.tablelabel) {
        label = col.tablelabel
      }
      let c = (
        <th
          key={`r${ridx}c${cidx}`}
          {...attr}
          className={classNames(col.classes, `column-${slugify(col.name)}`, { grouper: !!col.children })}
        >
          {col.children ? (
            <div className="text-center">{label ? label.toUpperCase() : ''}</div>
          ) : (
            <div className="heading-wrapper">
              {label ? label.toUpperCase() : ''}
            </div>
          )}
        </th>
      )
      if (col.acronym) {
        c = (
          <th
            key={`r${ridx}c${cidx}`}
            {...attr}
            className={classNames(col.classes, `column-${slugify(col.name)}`, { grouper: !!col.children })}
          >
            <div className="heading-wrapper">
              {col.acronym.toUpperCase()}
              <label className="tooltip">{col.label}</label>
            </div>
          </th>
        )
      }

      if (col.orderable !== false) {
        attr.className = classNames('orderable', col.classes)
        let icon = '#glyph-Sort'
        let order_by = params ? params.order_by : ''
        if (cidx === 0) { attr.className += ' orderedby' }
        if (params && params.order_by) {
          let key_asc = ''
          if (Array.isArray(col.name)) { key_asc = col.name[0] } else { key_asc = col.name }
          let key_desc = `-${key_asc}`
          if (col.container && col.container === 'stats') {
            key_asc = `statistics__${key_asc}`
            key_desc = `-${key_asc}`
          }
          if (col.orderby) {
            if (Array.isArray(col.orderby)) {
              key_asc = col.orderby.join(',')
              key_desc = col.orderby.map(k => `-${k}`).join(',')
            } else {
              key_asc = col.orderby
              key_desc = `-${key_asc}`
            }
          }
          if (Array.isArray(order_by)) {
            order_by = order_by.join(',')
          }
          if (order_by.indexOf(key_desc) !== -1) {
            icon = '#glyph-TriangleDown'
          } else if (order_by.indexOf(key_asc) !== -1) {
            icon = '#glyph-TriangleUp'
          }
        }
        if (params && params.order_by_related) {
          let key = ''
          if (Array.isArray(col.name)) { key = col.name[0] } else { key = col.name }
          if (params.order_by_related.indexOf(`-${key}`) !== -1) {
            icon = '#glyph-TriangleDown'
          } else if (params.order_by_related.indexOf(key) !== -1) {
            icon = '#glyph-TriangleUp'
          }
        }
        c = (
          <th key={`r${ridx}c${cidx}`} {...attr}>
            {col.acronym ? (
              <TooltipContext
                options={{ followCursor: false, arrow: 'bottom', shiftY: -8 }}
                className="heading-wrapper"
                title={title}
                content={col.label}
              >
                <span onClick={e => toggleOrder(col, e)} className='heading'>
                  {label}
                </span>
                <svg viewBox="0 0 16 16"><use href={`/images/glyphs.svg${icon}`} /></svg>
              </TooltipContext>
            ) : (
              <div className="heading-wrapper">
                <span onClick={e => toggleOrder(col, e)} className='heading'>
                  {label}
                </span>
                <svg viewBox="0 0 16 16"><use href={`/images/glyphs.svg${icon}`} /></svg>
              </div>
            )}
          </th>
        )
      }
      td.push(c)
    })
    const tr = React.createElement('tr', { key: `r${ridx}` }, td)
    inner.push(tr)
  })
  return React.createElement('tfoot', {}, inner)
}

Foot.propTypes = {
  params: PropTypes.object,
  toggleOrder: PropTypes.func,
  toggleSelectAll: PropTypes.func,
  modelname: PropTypes.string,
  results: PropTypes.object,
  selectedOne: PropTypes.bool,
  selectable: PropTypes.bool,
  title: PropTypes.string,
  rows: PropTypes.array,
  selectedAll: PropTypes.bool,
  columns: PropTypes.array,
  rowAmount: PropTypes.number
}


export default Foot
