import { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'
import { getIn } from 'formik'
import { isConditional, parseClasses, useCustomCompareMemo } from '../../../../utils'


const getWatched = (props, state, idx) => {
  if (!props.form) {
    return []
  }
  return state.fields.filter(field => field.edit).map(field => {
    let field_name = field.parent ? `${field.parent}.${field.name}` : field.name
    if (![ null, undefined ].includes(idx)) {
      field_name = `${props.field.name}.${idx}.${field.name}`
    }
    const touched = getIn(props.form.touched, field_name)
    const f = {
      ...field,
      // Watched field immutables which should not change
      classes: field.classes ? parseClasses(field.classes, props.form.values) : '',
      collapsed: props.collapsed,
      touched,
      _value: getIn(props.form.values, field_name),
      defaultValue: getIn(props.form.initialValues, field_name),
      _errors: props.form.errors,
      _areas: !!state.areas,
      _suburbs: !!state.locations
    }
    if (![ null, undefined ].includes(idx)) {
      f.aidx = idx
      f.aname = props.field.name
      f.name = `${props.field.name}.${idx}.${field.name}`
    }
    f._disabled = isConditional(f, 'disabled', false, props.form, props.user, props.cache).toString()
    f._edit = isConditional(f, 'edit', false, props.form, props.user, props.cache).toString()
    f._required = isConditional(f, 'required', false, props.form, props.user, props.cache).toString()
    f._webedit = isConditional(f, 'webedit', false, props.form, props.user, props.cache).toString()
    f._quality = isConditional(f, 'quality', false, props.form, props.user, props.cache).toString()
    if (field.modelname) {
      if (!f.cache) {
        f.cache = {}
      }
      f.cache[field.modelname] = getIn(props.cache, field.modelname)
    }
    if (field.fields) {
      field.fields.forEach(fafield => { // Field arrays
        if (fafield.modelname) {
          if (!f.cache) {
            f.cache = {}
          }
          f.cache[fafield.modelname] = getIn(props.cache, fafield.modelname)
        }
      })
    }
    if ([ 'FileDropzone', 'ImageCrop', 'GallerySelector', 'AssetLookup' ].includes(field.input)) {
      // These components rely on meta data that needs to trigger updates as well
      const { values } = props.form
      if (values.id && getIn(props.cache, `${props.config?.modelname}.${values.id}.meta.${field_name}`)) {
        if (Array.isArray(values[field_name]) && values.id) { // Edit mode and value is array
          values[field_name].forEach(() => {
            f.meta = merge({}, getIn(props.cache, `${props.config?.modelname}.${values.id}.meta.${field_name}`))
          })
        } else if (values.id) { // Populate meta for ImageCrop
          f.meta = merge({}, getIn(props.cache, `${props.config?.modelname}.${values.id}.meta.${field_name}`))
        }
      } else if ([ 'images', 'documents' ].includes(props.config?.modelname)) {
        if (getIn(props.cache, `${props.config?.modelname}.${values.id}`)) {
          if (!f.meta) {
            f.meta = []
          }
          f.meta.push(getIn(props.cache, `${props.config?.modelname}.${values.id}`))
        }
      }
    }
    if (field.caches) {
      if (!f.cache) {
        f.cache = {}
      }
      field.caches.forEach(k => {
        f.cache[k] = getIn(props.cache, k)
      })
    }
    if (field.watch) {
      f._related = field.watch.map(val => {
        let wfield = props.config?.fields.find(fe => fe.name === val)
        let lock = false
        if (!wfield) {
          wfield = state.fields.find(fe => fe.name === val)
          if (wfield) {
            lock = true
          }
        }
        if (wfield) {
          const value = lock ? getIn(props.form.values, `${props.field.name}.${idx}.${val}`) : getIn(props.form.values, val)
          const cache = {}
          if (Array.isArray(value)) {
            value.forEach(v => {
              cache[v] = getIn(props.cache, `${wfield.modelname}.${v}`)
            })
          } else if (value) {
            cache[value] = getIn(props.cache, `${wfield.modelname}.${value}`)
          }
          return { value, cache }
        }
        return null
      })
    }
    if (field.dependent) {
      f._dependent = getIn(props.form.values, field.dependent)
    }
    if (field.limit) {
      f._limit = props.form.status
    }
    if (props.setInitVals) {
      f.setInitVals = props.setInitVals
    }
    return f
  })
}

const useWatched = (props, state, multi) => {
  const [ watched, setWatched ] = useState(multi ? (
    props.field.value?.map((v, idx) => getWatched(props, state, idx))
  ) : getWatched(props, state))

  useEffect(() => {
    const newWatched = multi ? (
      props.field.value?.map((v, idx) => getWatched(props, state, idx))
    ) : getWatched(props, state)
    if (!isEqual(newWatched, watched)) {
      setWatched(newWatched)
    }
  }, [ useCustomCompareMemo(props), useCustomCompareMemo(state) ])

  return watched || []
}

export default useWatched
