import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import merge from 'deepmerge'

import { groupBy, resolveError } from '../utils'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'


const ReportEditSidebar = props => {
  const { actions, config, field, match, modelname, report, setEditSchedule } = props
  const [ fields, setFields ] = useState({})
  const [ groups, setGroups ] = useState([])
  const [ initvals, setInitvals ] = useState({
    modelname,
    report: report?.id
  })
  const [ selected_template ] = useState(report?.template)

  useEffect(() => {
    if (!report) {
      return
    }
    // eslint-disable-next-line no-new
    new Promise((resolve, reject) => actions.fetchMany({
      values: {
        modelname: 'reports',
        endpoint: {
          read: `/reports/api/v1/report-templates/${report.id}/schedule/`
        }
      },
      resolve,
      reject
    })).then(response => {
      if (response.options?.length) {
        setInitvals({
          ...initvals,
          ...response.options[0],
          report: report.id
        })
      }
    }).catch(e => {
      console.error(e)
    })
    const base_groups = Object.keys(config.fieldgroups)
    const field_groups = groupBy([ ...config.fields ], 'group')
    setFields(field_groups)
    const sorted_groups = Object.keys(field_groups)
      .sort((a, b) => base_groups.indexOf(b) - base_groups.indexOf(a))

    setGroups(sorted_groups)
  }, [ selected_template ])

  const handleSubmit = values => {
    if (values.id) {
      new Promise((resolve, reject) => actions.updateModel({
        values: {
          ...values,
          modelname: 'report-schedules'
        },
        resolve,
        reject
      })).then(() => {
        setEditSchedule(null)
        actions.toggleWideSidebar()
      }).catch(e => {
        actions.notifyUser({ title: 'Error', body: resolveError(e, Object.keys(config.fields).map(group => config.fields[group]).reduce((a, b) => merge(a, b), [])) || e.error, type: 'error' })
      })
    } else {
      new Promise((resolve, reject) => actions.createModel({
        values: {
          ...values,
          modelname: 'report-schedules'
        },
        resolve,
        reject
      })).then(() => {
        setEditSchedule(null)
        actions.toggleWideSidebar()
      }).catch(e => {
        actions.notifyUser({ title: 'Error', body: resolveError(e, Object.keys(config.fields).map(group => config.fields[group]).reduce((a, b) => merge(a, b), [])) || e.error, type: 'error' })
      })
    }
  }
  return (
    <div id="report-edit-sidebar" className="report-edit-sidebar wide-sidebar">
      <div className="wide-sidebar-container">
        <Formik
          initialValues={initvals}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >{ formik => (
            <CustomForm
              render={() => (
                <div className="wide-sidebar-pane">
                  <div className="wide-sidebar-heading">
                    <h4>Schedule Report</h4>
                    <Button
                      type="button"
                      icon="#icon24-X-Large"
                      className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                      onClick={() => {
                        actions.toggleWideSidebar()
                        setEditSchedule(null)
                      }}
                    />
                  </div>
                  <Scrollbar
                    style={{ height: 'calc(100vh - 218px)' }}
                    renderView={({ style, ...rest }) => <div {...rest} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                  >
                    <div className="wide-sidebar-content">
                      <>
                        { groups.map((group, gidx) => (
                          <FieldGroup
                            key={`fs-${gidx}`}
                            groupname={group}
                            group={{}}
                            gidx={gidx}
                            fields={fields[group]}
                            creator={field?.name}
                            config={config}
                            match={match}
                            columns
                            render={({ renderFieldGroup, hidden }) => {
                              if (hidden) { return null }
                              return (
                                <fieldset className='editgroup'>
                                  <h5>{group}</h5>
                                  {renderFieldGroup(group)}
                                </fieldset>
                              )
                            }}
                          />
                        ))}
                        <div className="wide-sidebar-footer">
                          <Button
                            onClick={formik.submitForm}
                            type="button" // This cannot be submit otherwise sibling form is submitted
                            className="btn btn-primary"
                          >
                            Schedule Report
                          </Button>
                        </div>
                      </>
                    </div>
                  </Scrollbar>
                </div>
              )}
            />
          )}
        </Formik>
      </div>
    </div>
  )
}

ReportEditSidebar.propTypes = {
  config: PropTypes.object,
  modelname: PropTypes.string,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  readOnly: PropTypes.bool,
  comment: PropTypes.oneOfType([ PropTypes.object, PropTypes.bool ]),
  note: PropTypes.object,
  modelid: PropTypes.number,
  match: PropTypes.object,
  report: PropTypes.object,
  sidebar: PropTypes.string,
  modelconfig: PropTypes.object,
  setEditSchedule: PropTypes.func,
  templates: PropTypes.array
}

export default ReportEditSidebar
